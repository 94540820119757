import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { LoadingButton } from "../../../../../components";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Chip from '@mui/material/Chip';
import TextField from "@mui/material/TextField";
import Tag from "../../../../../assets/icons/addTag.svg";

const ModalTags = ({
  modal,
  closeModal,
  loading,
  modalFunction,
  title,
  tagsExternal,
}) => {
  const filter = createFilterOptions();
  const keyTag = title === "Add Tags" ? "added_tags" : "removed_tags"
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [form, setForm] = useState({
    [keyTag]: [],
  });

  const tagColorsWithBlackFont = ["#f8e71c", "#dff21b", ""]

  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(tagsExternal);
    } else {
      setTags(newValue);
    }
    let listPk = newValue.map((tag) => {
      return tag.pk;
    });
    setForm({
      [keyTag]: listPk,
    });
  };

  const saveFunction = () => {
    modalFunction(form)
  }

  useEffect(() => {
    setOptionsTags(tagsExternal);
  }, [tagsExternal]);

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title || "Modal Tag"}</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <label className="itp-component-tag-label">Tags</label>
            <div className="itp-component-tag-container">
              <img src={Tag} alt="Add Tag" className="itp-component-tag-img" />
              <div className="itp-component-tag-width">
              <Stack>
                <Autocomplete
                  disableClearable
                  disablePortal
                  filterSelectedOptions
                  disabled={false}
                  freeSolo
                  multiple
                  value={tags}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option?.inputValue || option.title}
                        {...getTagProps({ index })}
                        style={{ 
                          backgroundColor: option.tag_color || "#edf6fd" , 
                          color: tagColorsWithBlackFont.includes(option.tag_color) ? "black" : "white",
                        }}
                      />
                    ))
                  }
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return "error";
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    else{
                      return option.title;
                    }
                  }}
                  getOptionDisabled={(option) => !!option.disabled}
                  options={optionsTags}
                  id="tags-standard"
                  isOptionEqualToValue={(option, value) =>
                    option.title === value.title
                  }
                  onChange={(event, newValue) => handleTag(newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) => inputValue === option.title
                    );

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add Tags"
                    />
                  )}
                  renderOption={(props, option) => (
                    <Chip
                    {...props}
                    sx={{
                      '&:hover': {
                        backgroundColor: '#cfe3ff', // Cambiar al color más oscuro deseado
                        color: "black",
                      },
                      backgroundColor: option.tag_color || '#ffffff',
                      borderRadius: 0,
                      border: 0, // Mantener los bordes cuadrados
                      color: tagColorsWithBlackFont.includes(option.tag_color) ? "black" : "white",
                    }}
                    label={option.title}
                    variant="outlined"
                  />
                  )}
                />
              </Stack>
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={saveFunction}
        >
          {loading && <LoadingButton />}
          Save
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTags;
