import petitionPatch from "../../../../../services/petitionPatch";

const addOrRemoveTagsIncontactsService = async (setLoading, selectedAll, arrayContacts, reloadData, form) => {
  try {
    setLoading(true);

    let data = {};

    if (selectedAll) {
      data = {
        payload: form,
        use_filters: true,
      };
    } else {
      data = {
        contacts: arrayContacts,
        payload: form,
      };
    }

    await petitionPatch("updateMultipleContacts", { data })
    setLoading(false);
    reloadData(false, false);
  } catch (error) {
    console.log(error);
  }
};

export default addOrRemoveTagsIncontactsService;
