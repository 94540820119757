import petitionGet from "../petitionGet";

const fetchTagsService = async (query) => {
  try {
    const parameter = query || "?offset=0&limit=10000"
    const { data: result } = await petitionGet("tags", { parameter })

    result.result.forEach((element) => {
      element.title = element.name;
      element.disabled = false;
      element.label = element.name;
      element.value = element.pk;
    });

    return result.result
  } catch (error) {
    console.log(error)
  }
}

export default fetchTagsService;