const queueMemberAnswer = (socketInfo, forceUpdate, setPeopleWaitingQueue) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return;
  }
    
  const { queuecall_id } = socketInfo?.metadata;
    
  try {
    setPeopleWaitingQueue((prev) => {
    const currentData = prev
    const newArryPeople = currentData.filter((element) => element.queuecall_id !== queuecall_id.toString())
    return newArryPeople
    })
    forceUpdate()
  } catch (error) {
    console.log(error)
  }
}

export default queueMemberAnswer;