import React from "react";
import arrowDown from "../../../../../../assets/icons/arrowDown.svg";
import "./Tags.css";

const Tags = ({ element, column }) => {
  const MAX_ELEMENTS = 3;
  
  const arr = element[column.key];
  
  if (!arr || !Array.isArray(arr) || !arr.length) {
    // handle the case where arr is not an array or undefined
    return null;
  }
  if (arr.length <= MAX_ELEMENTS) {
    return arr.map((element, i) => (
      <span
        key={i}
        className="itp-array-tags"
        style={{ backgroundColor: `${element.tag_color || "#edf6fd"}` }}
      >
        <span
          style={{ color: element.tag_color == "#d0021b" ? "#fff" : "#437097" }}
        >
          {element.title}
        </span>
      </span>
    ));
  } else {
    return (
      <>
        {arr.slice(0, 3).map((element, i) => (
          <span
            className="itp-array-tags"
            key={i}
            style={{ background: `${element.tag_color || "#edf6fd"}` }}
          >
            <span
              style={{
                color: element.tag_color == "#d0021b" ? "#fff" : "#437097",
              }}
            >
              {element.title}
            </span>
          </span>
        ))}
        <span className="itp-array-tags with-arrow">
          + {arr.length - 3}{" "}
          {<img src={arrowDown} alt="Arrow Down" className="with-arrow" />}
        </span>
      </>
    );
  }
};

export default Tags;
