import Switch from "react-switch";
import "./Filters.css";

const Switchs = ({ switchUsersCall, setSwitchUsersCall, switchUsersOffline, setSwitchUsersOffline }) => (
  <div className="d-flex gap-4">
    <div className="d-flex gap-1">
      <label className="itp-livePanel-switch-filter-label">
        <b>On Call</b>
      </label>
      <Switch
        className="itp-livePanel-switch-filter"
        uncheckedIcon={false} // For no icon when switch is off
        checkedIcon={false} // For no icon when switch is onColor="#56c288"
        height={20} // Ajusta la altura según tus necesidades
        width={40}
        onChange={() => setSwitchUsersCall(!switchUsersCall)}
        checked={switchUsersCall}
        onColor="#56c288"
      />
    </div>
    <div className="d-flex gap-1">
      <label className="itp-livePanel-switch-filter-label">
        <b>Offline</b>
      </label>
      <Switch
        className="itp-livePanel-switch-filter"
        uncheckedIcon={false} // For no icon when switch is off
        checkedIcon={false} // For no icon when switch is onColor="#56c288"
        height={20} // Ajusta la altura según tus necesidades
        width={40}
        onChange={() => setSwitchUsersOffline(!switchUsersOffline)}
        checked={switchUsersOffline}
        onColor="#56c288"
      />
    </div>
  </div>
);

export default Switchs;
