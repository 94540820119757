import { convertUtcDateToTimeZoneDate } from "../../../../../utils/getDate";
import { formatTime } from "../../utils";
import petitionGet from "../../../../../services/petitionGet";
import getQueryString from "../../../../../utils/getQueryString";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const queueLogsService = async (e, paginacion, timeframe, isChageItems, setAllResult, filter, timeZone) => {
  const parameterPaginacion = getQueryString(paginacion).replaceAll("?", "&");

  const queue_id = e.queue_object.pk;
  const parameter = `?start_date=${timeframe.timeStart}&end_date=${timeframe.timeEnd}${parameterPaginacion}${filter ? filter : ""}`;

  const { data: result } = await petitionGet("queueLogReport", { queue_id, parameter })

  result.result.forEach((element) => {
    element.hasIcon = true;
    element.wait_time = formatTime(element.wait_time);
    element.duration = formatTime(element.duration);
    element.contactName = !element?.contact ? "Unknown number" : `${element?.contact?.firstname} ${element?.contact?.lastname || "" }`;
    element.agentName = element?.agent_obj?.firstname ? `${element?.agent_obj?.firstname} ${element?.agent_obj?.lastname || ""}` : "";
    element.outcome = element.outcome === "Unknown" ? "On Call" : element.outcome
    element.dateFormated = convertUtcDateToTimeZoneDate(element.date, timeZone);
    element.formatedNumber = formatPhoneNumber(element.number)
  });

  if ((result.total_pages !== undefined && result.offset !== undefined && result.items_per_page !== undefined && result.total_pages > 1) || isChageItems) {
    setAllResult(result);
  } else {
    setAllResult(null);
  }

  return result.result
};

export default queueLogsService;
