const replaceAndMergeArrays = (array1, array2) => {
  const length1 = array1.length;
  const length2 = array2.length;
  let newArray = [];

  // Reemplazar elementos en array1 con los de array2
  if (length2 >= length1) {
    for (let i = 0; i < length2; i++) {
      if (i < length1) {
        newArray[i] = array2[i];
      } else {
        newArray.push(array2[i]);
      }
    }
  } else {
    for (let i = 0; i < length1; i++) {
      if (i < length2) {
        newArray[i] = array2[i];
      } else {
        newArray.push(array1[i]);
      }
    }
  }

  return newArray;
};

export default replaceAndMergeArrays;
