import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../../components";

const ModalDelete = ({ modal, closeModal, loading, modalFunction, info, text }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader className="modal-header">Delete Contact List</ModalHeader>
      <ModalBody>
        {info.is_default
          ? "It is not possible to delete the default list"
          : text
          ? text
          : "Are You Sure?"}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Cancel
        </button>
        {!info.is_default && (
          <button
            disabled={loading}
            className="btn-primary loading"
            onClick={modalFunction}
          >
            {loading && <LoadingButton />}
            Delete
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
