import { timeZones } from "../../../../../const/timeZones";

export const addUserForm = {
  firstname: "",
  lastname: "",
  email: "",
  admin_group: false,
  billing_group: false,
  technical_group: false,
  time_zone: "",
};

export const autoCompleteValues = {
  time_zone: null
}

export const arrayUserForm = [
  { label: "First Name", value: "firstname", input: "text", placeholder: "Enter a First Name", mandatory: true },
  { label: "Last Name", value: "lastname", input: "text", placeholder: "Enter a Last Name", mandatory: true },
  { label: "E-Mail", value: "email", input: "text", placeholder: "Enter an Email", mandatory: true },
  { label: "Time Zone", value: "time_zone", input: "autoComplete", options: timeZones, mandatory: true },
  { label: "Admin Group", value: "admin_group", input: "text", disabled: true, defaultValue: "No" },
  { label: "Billing Group", value: "billing_group", input: "text", disabled: true, defaultValue: "No" },
  { label: "Technical Group", value: "technical_group", input: "text", disabled: true, defaultValue: "No" },
]