import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlignedRow } from "../../../../components";
import petitionPatch from "../../../../services/petitionPatch";
import Skeleton from "react-loading-skeleton";

const SubIndustries = ({
  subIndustries,
  currentChecks,
  setCurrentChecks,
  subIndustrySelectedIds,
  setSubIndustrySelectedIds,
  loading,
}) => {
  const handleChangeCheckbox = (name, element) => {
    const currentCheckbBoxs = currentChecks;
    const currentSubIndustrySelectedIds = subIndustrySelectedIds;
    let newSubIndustryIds = subIndustrySelectedIds;

    setCurrentChecks({
      ...currentChecks,
      [name]: !currentChecks[name],
    });

    if (subIndustrySelectedIds.includes(element.pk)) {
      newSubIndustryIds = newSubIndustryIds.filter((id) => id !== element.pk);
    } else {
      newSubIndustryIds.push(element.pk);
    }

    const data = {
      sub_industries: newSubIndustryIds,
    };

    petitionPatch("aiDetailsUpdate", { data })
      .then(({ data: result }) => {
        setSubIndustrySelectedIds(newSubIndustryIds);
      })
      .catch((error) => {
        console.log(error);
        setCurrentChecks(currentCheckbBoxs);
        setSubIndustrySelectedIds(currentSubIndustrySelectedIds);
      });
  };

  const fakeData = [{pk:1}, {pk:2}, {pk:3}]
  const subIndustriesList = loading ? fakeData : subIndustries

  return (
    <>
      <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1 " }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
      <br></br>

      {subIndustriesList.length > 0 && (
        <AlignedRow>
          <Card>
            <CardBody>
              <span className="itp-my-settings-card-title">Sub-Industry</span>

              <Row className="itp-my-settings-card-help-text">
                <Col md={12}>
                  <div className="d-flex justify-content-between flex-wrap">
                    {subIndustriesList.map((element) => (
                      <div
                        key={element.pk}
                        className="d-flex gap-1 align-items-center"
                      >
                        {loading ? (
                          <>
                            <div style={{ width: "14px", height: "14px" }} className="form-check-input itp-custom-checkbox-contact-details border-0">
                              <Skeleton width="100%" height="100%" />
                            </div>
                            <div>
                              <Skeleton width="3rem" height="1rem" />
                            </div>
                          </>
                        ) : (
                          <>
                            <input
                              type="checkbox"
                              checked={currentChecks[element?.name]}
                              onChange={() => {
                                handleChangeCheckbox(element?.name, element);
                              }}
                              className="form-check-input itp-custom-checkbox-contact-details mt-4"
                            />

                            <label className="itp-label-input__label mt-4">
                              {element?.name}
                            </label>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </AlignedRow>
      )}
    </>
  );
};

export default SubIndustries;
