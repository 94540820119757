import petitionGet from "../../../../../services/petitionGet";
import { parseQueueLog } from "../../utils";

const fetchCallEvents = async (details, timeZone, totalUsers, totalQueues, selectedReport) => {
  try {
    const callid = selectedReport === "Queues" ? details?.callid : details?.unique_id	
    const { data: result } = await petitionGet("callEvents", { callid })
    const parsedQueueLogs = parseQueueLog(result, timeZone, totalUsers, totalQueues)
    return parsedQueueLogs
  } catch (error) {
    console.log(error)
  }
}

export default fetchCallEvents;