import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlignedRow } from "../../../../components";
import Select from "react-select";
import infoIcon from "../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../services/petitionPatch";
import petitionGet from "../../../../services/petitionGet";
import Skeleton from "react-loading-skeleton";

const Industry = ({
  industryValue,
  setIndustryValue,
  industries,
  customStyles,
  setLoadingChangeIndustry,
  currentChecks,
  setCurrentChecks,
  subIndustries,
  setSubIndustries,
  subIndustrySelectedIds,
  setSubIndustrySelectedIds,
  loading,
}) => {
  const handleChangeIndustry = (e) => {
    //actualizamos el valor del industry que se muestra en el componente Select
    setIndustryValue(e);

    //ponemos en true la variable de loading para mostrar un indicador de carga mientras se actualiza
    //el industry y se obtienen los nuevos sub industries
    setLoadingChangeIndustry(true);

    //aquí guardamos los valores actuales de las variables para que en caso de un error en alguna de las
    //peticiones que se hacen se queden con estos valores
    const currentIndustryValue = industryValue;
    const currentChecksValues = currentChecks;
    const currentSubIndustries = subIndustries;
    const currentsubIndustrySelectedIds = subIndustrySelectedIds;

    //este es el paylod que se envía en el patch, al cambiar el industry, los sub_industries
    //también y cambían a un array vacio ya que en el nuevo industry no debe estar ningun sub industry
    //seleccionado
    const data = {
      industry_id: e.pk,
      sub_industries: [],
    };

    petitionPatch("aiDetailsUpdate", { data })
      .then(({ data: response }) => {
        //si todo sale bien al actualizar el industry y los sub industries, la variable que guarda los ids
        //de los sub insdustries se coloca como array vacío ya que ningún sub industry del nuevo industry debe
        //estar seleccionado
        setSubIndustrySelectedIds([]);

        //aquí hacemos una peticion para obtener los sub industries del nuevo industry seleccionado
        petitionGet("aiIndustryDetails", { industry_id: e.pk })
          .then(({ data: result }) => {
            // si todo sale bien se pregunta si existe el campo sub_industries y si ademas es un array
            //con longitud mayor a cero
            if (
              result?.result?.sub_industries &&
              result?.result?.sub_industries.length > 0
            ) {
              //si el campo existe y ademas es un array con longitud mayor a cero, primero se crea
              //una variable y se inicializa como objeto vacío
              let checkboxs = {};

              //aquí se recorren los sub_industries y se almacena en la variable que se inicializó como
              //objeto vacío, el nombre de cada sub industry, se guarda como key el nombre del sub industry
              //y como value el booleano false ya que como se cambió el industry ningún sub indsutry debe
              //estar seleccionado
              result?.result?.sub_industries.forEach((element) => {
                checkboxs[element?.name] = false;
              });

              //aquí se carga la variable que muestra la lista de los sub industries y la variable que
              //muestra si los sub industries están seleccionados o no, en este caso todos están
              //deseleccionados ya que se cambió el industry
              setSubIndustries(result?.result?.sub_industries);
              setCurrentChecks(checkboxs);
            } else {
              //si no existe el campo sub_industries o es un array con longitud cero, se carga la variable
              //de los sub industries como array vacio y la variable que contiene el nombre del sub industry
              //y el valor booleano se carga como objeto vacío ya que no hay sub industries.
              setSubIndustries([]);
              setCurrentChecks({});
            }

            //aquí se pone el false la variable que muestra el indicador de carga
            setLoadingChangeIndustry(false);
          })
          .catch((error) => {
            //si hay un error se vuelve al valor antes de la petición
            console.log(error);
            setSubIndustries(currentSubIndustries);
            setIndustryValue(currentIndustryValue);
            setCurrentChecks(currentChecksValues);
            setSubIndustrySelectedIds(currentsubIndustrySelectedIds);
            setLoadingChangeIndustry(false);
          });
      })
      .catch((error) => {
        //si hay un error se vuelve al valor antes de la petición
        console.log(error);
        setSubIndustries(currentSubIndustries);
        setIndustryValue(currentIndustryValue);
        setCurrentChecks(currentChecksValues);
        setSubIndustrySelectedIds(currentsubIndustrySelectedIds);
        setLoadingChangeIndustry(false);
      });
  };

  return (
    <>
      <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1 " }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
      <br></br>

      <AlignedRow>
        <Card>
          <CardBody>
            <span className="itp-my-settings-card-title">Industry</span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={9} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    Please select you Industry, this will help train the AI on
                    how to greet and response to contacts
                  </p>
                </div>
              </Col>

              <Col md={3}>
                {loading ? (
                  <div style={{ marginTop: "-1.2rem" }} className="d-flex gap-5 align-items-center pl-0 justify-content-end w-100">
                    <Skeleton height="2.2rem" width="15rem" />
                  </div>
                ) : (
                  <div className="d-flex gap-5 align-items-center pl-0 justify-content-end">
                    <Select
                      onChange={handleChangeIndustry}
                      className="basic-single itp-ai-select"
                      classNamePrefix="select"
                      value={industryValue}
                      isSearchable={true}
                      name="industry_id"
                      options={industries}
                      styles={customStyles} // Aplicas tus estilos personalizados aquí
                    />
                </div>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </AlignedRow>
    </>
  );
};

export default Industry;
