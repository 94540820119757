const selectPlaceHolder = (field) => {
  switch (field) {
    case "address":
      return "Enter Address";
    case "url":
      return "Enter URL";
    case "text/note":
      return "Enter Text";
    default:
      return "";
  }
};

export default selectPlaceHolder;
