//actualiza el campo route de cada objeto en el arry child para saber la ubiacion en la que está
//updates the route field of each object in the arry child to know the location it is in
const updateChildRoute = (data, parentRoute = "", key) => {
  if (!data) {
    return;
  }

  if (parentRoute === "" && !key) {
    data.route = "";
  } else if ((parentRoute || parentRoute === "") && key) {
    data.route = `${parentRoute === "" ? "" : `${parentRoute}.`}child.${key}`;
  }

  if (data.child && data.child.length > 0) {
    data.child.forEach((obj, index) => {
      updateChildRoute(obj, data.route, `${index}`);
    });
  }

  if (data.module === "voicemail" || data.module === "externaltransfer") {
    data.noMoreAction = true;
  } else {
    data.noMoreAction = null;
  }

  return data;
};

export default updateChildRoute;
