import React, { Fragment, useState } from "react";
import { Col } from "reactstrap";
import { BulkActionTags, BulkButtons, ChatItem, ChatListHeader } from "./components";
import { checkIfANyIsPinnedOrNot } from "./utils";
import { AlertNotificactions } from "../index";
import InfiniteScroll from "react-infinite-scroll-component";
import useScreenSize from "../../hooks/useScreenSize";
import "./ChatList.css";

const ChatList = ({
  activeChatWindow,
  showChatList,
  chatList,
  moreData,
  showAlertForNewMessage,
  addNewChat,
  onChageSelect,
  loading,
  noAddIcon,
  loadingAddOrDeletePinChat,
  loadingArchiveOrUnarchiveChat,
  optionsTags,
  setOptionsTags,
  tags,
  setTags,
  leastOnePinnedChat,
  deleteAllMessages,
  deleteFunction,
  timeZone,
  addTagsToContacts,
  chatWindowData,
  addPinToMessageThreads,
  deletePinToMessageThreads,
  archiveMessageThreads,
  unarchiveMessageThreads,
}) => {
  const { width, height } = useScreenSize();
  const [selectedOption, setSelectedoption] = useState("All");
  const [addChat, setAddChat] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set([]))
  const [selectedBulkAction, setSelectedBulkAction] = useState("")
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")

  const updatedAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }

  const closeModal = () => {
    setSelectedBulkAction("")
  }

  const handleClickAction = async (action) => {
    setSelectedBulkAction(action)

    if (action === "pin") {
      const existPinnedChatSelected = checkIfANyIsPinnedOrNot(chatList, selectedItems, true)

      if (existPinnedChatSelected) {
        updatedAlertVariables("error", "Error", "You cannot select pinned items for this action", 3000)
      } else if (!loadingAddOrDeletePinChat) {
        await addPinToMessageThreads(selectedItems, setSelectedItems)
      }

      return;
    }

    if (action === "unpin") {
      const existNotPinnedChatSelected = checkIfANyIsPinnedOrNot(chatList, selectedItems)

      if (existNotPinnedChatSelected) {
        updatedAlertVariables("error", "Error", "You cannot select unpinned items for this action", 3000)
      } else if (!loadingAddOrDeletePinChat) {
        await deletePinToMessageThreads(selectedItems, setSelectedItems)
      }

      return;
    }

    if (action === "archive") {
      if (!loadingArchiveOrUnarchiveChat) {
        await archiveMessageThreads(selectedItems, setSelectedItems)
      }

      return;
    }

    if (action === "unarchive") {
      if (!loadingArchiveOrUnarchiveChat) {
        await unarchiveMessageThreads(selectedItems, setSelectedItems)
      }

      return;
    }
  }

  const isCheckedItem = (element) => { 
    return selectedItems.has(element?.pk)
  }

  const handleCheckItem = (event, element) => {
    event.stopPropagation()

    setSelectedItems((prev) => {
      const newSelectedRows = new Set(prev);
      if (newSelectedRows.has(element?.pk)) {
        newSelectedRows.delete(element?.pk);
      } else {
        newSelectedRows.add(element?.pk);
      }

      return newSelectedRows;
    });
  }

  const handleClickCheckbox = (event) => {
    event.stopPropagation()
  }

  const selectHeight = () => {
    if (height < 760) {
      return 566;
    } else if (height > 759 && height < 800) {
      return 510;
    } else if (height > 799 && height < 883) {
      return 629;
    } else if (height > 882 && height < 1280) {
      return 810;
    } else if (height > 1279) {
      return 1020;
    }
  };

  const onChangeSelect = (e) => {
    if (loading) return;

    onChageSelect(e.target.value);
    setSelectedoption(e.target.value);
  };

  const selectTitle = (title) => {
    switch (title) {
      case "All":
        return "There are no open chats";
      case "Unread":
        return "No Unread Chats";
      case "AI":
        return "No AI Chats";
      case "Archived":
        return "No Archived Chats";
      default:
        return "Error, could not download chat list, please try again, if the problem persists, please try again later.";
    }
  };

  const nexFunction = () => {
    if (!loading) moreData()
  }

  const arrayChats = loading ? [{pk: 1}, {pk: 2}, {pk: 3}, {pk: 4}, {pk: 5}] : chatList
  
  return (
    <>
      {(showChatList || width > 767) && (
        <>
          <Col
            className="itp-chat-list d-flex flex-column"
            xs={11}
            sm={11}
            md={5}
            lg={width > 1099 ? 4 : 5}
            xl={4}
            xxl={4}
          >
            <ChatListHeader 
              deleteAllMessages={deleteAllMessages}
              deleteFunction={deleteFunction}
              selectedOption={selectedOption}
              onChangeSelect={onChangeSelect}
              addChat={addChat}
              addNewChat={addNewChat}
              noAddIcon={noAddIcon}
              setAddChat={setAddChat}
              loading={loading}
            />

            {!addChat && (
              <>
                {chatList === "error" && (
                  <h6 className="itp-chat-list__no-data">
                    {selectTitle("error")}
                  </h6>
                )}

                {Array.isArray(arrayChats) && arrayChats.length === 0 && (
                  <h6 className="itp-chat-list__no-data">
                    {selectTitle(selectedOption)}
                  </h6>
                )}

                <BulkButtons 
                  selectedItems={selectedItems} 
                  selectedOption={selectedOption} 
                  handleClickAction={handleClickAction}
                />

                {Array.isArray(arrayChats) && arrayChats.length > 0 && (
                  <div
                    id="scrollableDiv"
                    style={{
                      overflow: "auto",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={arrayChats.length}
                      next={nexFunction}
                      hasMore={(arrayChats.length > 0 && arrayChats.length % 15 === 0) || arrayChats.find((chat) => chat?.notIsSelf?.addedFronEnd) ? true : false}
                      height={selectHeight()}
                      scrollableTarget="scrollableDiv"
                    >
                      <div className="itp-chat-list-body">
                        {leastOnePinnedChat && !loading && (
                          <>
                            <h6 className="itp-chat-list-separator">Pinned</h6>
                            {arrayChats && arrayChats.map((element, i) => (
                              <Fragment key={i}>
                               {element?.pinned && (
                                 <ChatItem 
                                   element={element}
                                   activeChatWindow={activeChatWindow}
                                   showAlertForNewMessage={showAlertForNewMessage}
                                   loading={loading}
                                   timeZone={timeZone}
                                   isCheckedItem={isCheckedItem}
                                   handleCheckItem={handleCheckItem}
                                   handleClickCheckbox={handleClickCheckbox}
                                   chatWindowData={chatWindowData}
                                 />
                               )}
                              </Fragment>
                            ))}
                          </>
                        )}

                        <h6 className={`itp-chat-list-separator ${loading ? "mt-0" : ""}`}>
                          All Messages
                        </h6>
                        {arrayChats && arrayChats.map((element, i) => (
                          <Fragment key={i}>
                            {!element?.pinned && (
                              <ChatItem 
                                element={element}
                                activeChatWindow={activeChatWindow}
                                showAlertForNewMessage={showAlertForNewMessage}
                                loading={loading}
                                timeZone={timeZone}
                                isCheckedItem={isCheckedItem}
                                handleCheckItem={handleCheckItem}
                                handleClickCheckbox={handleClickCheckbox}
                                chatWindowData={chatWindowData}
                              />
                            )}
                          </Fragment>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                )}
              </>
            )}
          </Col>
        </>
      )}

      {selectedBulkAction === "tags" && (
        <BulkActionTags 
          modal={selectedBulkAction === "tags" ? true : false} 
          tags={tags} 
          setTags={setTags} 
          optionsTags={optionsTags} 
          setOptionsTags={setOptionsTags} 
          selectedItems={selectedItems} 
          setSelectedItems={setSelectedItems}
          chatList={chatList} 
          addTagsToContacts={addTagsToContacts} 
          closeModal={closeModal}
        />
      )}

      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />
    </>
  );
};

export default ChatList;