import petitionGet from "../../../../../../services/petitionGet";

const fetchTimeGroupDetails = async (pk, setTimeGroupName, setCurrentName, petition) => {
  try {
    const { data: result } = await petitionGet("timeGroups", { parameter: `/${pk}` })

    const objName = { name: result.result.name }
    setTimeGroupName(objName)
    setCurrentName(objName)

    await petition()
  } catch (error) {
    console.log(error)
  }
}

export default fetchTimeGroupDetails;