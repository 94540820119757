import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { LoadingButton } from "../../../../components";
import "../SignUp.css";

const Step2 = ({ nextStep, loading, errorMessage }) => {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleInputChange = (index, value) => {
    if (!isNaN(value) && value.length <= 1) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Enfocar en el siguiente input si se ingresó un dígito
      if (value !== "" && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pastedContent = e.clipboardData.getData('text');
    const regex = /^\d{6}$/;

    const isCodeNumber = regex.test(pastedContent)

    if (isCodeNumber) {
      const numbersInArry = pastedContent.split("")
      setCode(numbersInArry)

      numbersInArry.forEach((element, i) => {
        inputRefs[i].current.value = element
      })

      if (inputRefs[numbersInArry.length - 1].current) {
        inputRefs[numbersInArry.length - 1].current.focus();
      }
    }
  }

  const submitCode = (e) => {
    e.preventDefault();
    const codeNumber = code.join("")
    nextStep(codeNumber)
  }

  useEffect(() => {
    inputRefs[0].current.focus();
  }, [])

  return (
    <div className="itp-signUp">
      <Card className="itp-signUp__card">
        <CardBody>
          <h1 className="itp-signUp__card-body__title">
            Please enter the code
          </h1>

          <p className="itp-signUp__card-body__textInfo">
            Please enter the code from your email
          </p>

          <form onSubmit={submitCode} className="itp-signUp__card-body__form-container">
            <div className="itp-signUp__verification-code-container">
              {code.map((digit, index) => (
                <input
                  key={index}
                  ref={inputRefs[index]}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="itp-signUp__verification-code-input"
                  required
                  onInvalid={(e) => e.target.setCustomValidity("Please fill out this field.")}
                  onInput={(e) => e.target.setCustomValidity("")}
                  onPaste={handlePaste}
                />
              ))}
            </div>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <button
              type="submit"
              className="btn-primary btn-primary-signup loading"
              disabled={loading}
            >
              {loading && <LoadingButton />}
              Complete Verification
            </button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Step2;
