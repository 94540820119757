import { useEffect, useState } from "react";
import { myExtensionPetition } from "../../../services/myExtension.service";
import { CalendarIntegration, CallerID, CallForwarding, CallRecordings, Devices, Fax, IntercomPaging, SoundSettings, Voicemail } from "./components";
import { fetchAssignedNumbers } from "./services";

const MySettings = () => {
  const [myExtension, setMyExtension] = useState(null)
  const [completedRequests, setCompletedRequests] = useState(0);
  const [loading, setLoading] = useState(true);
  const [assignedNumbers, setAssignedNumbers] = useState(null)

  const myExtensionData = async () => {
    try {
      setLoading(true)
      const data = await myExtensionPetition();
      setMyExtension(data)

      const numbersResult = await fetchAssignedNumbers()
      setAssignedNumbers(numbersResult)

      setCompletedRequests(prevStata => prevStata + 1)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (completedRequests === 10) {
      setLoading(false)
    }
  }, [completedRequests])

  useEffect(() => {
    myExtensionData()
  }, [])

  return (
    <div className="mt-5 mb-4">
      <CalendarIntegration 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
        assignedNumbers={assignedNumbers}
      />

      <Devices 
        myExtensionTemp={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <CallForwarding 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <CallerID 
        myExtensionTemp={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
        assignedNumbers={assignedNumbers}
      />

      <CallRecordings 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <IntercomPaging 
        myExtensionTemp={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <Fax 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <Voicemail 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />

      <SoundSettings 
        myExtension={myExtension}
        setCompletedRequests={setCompletedRequests}
        loading={loading}
      />
    </div>
  )
};

export default MySettings;
