import petitionDelete from "../../../../../../services/petitionDelete";
import petitionPatch from "../../../../../../services/petitionPatch";
import petitionPost from "../../../../../../services/petitionPost";

const editOtherSwitchsService = async (loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, intercom, setIntercom, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true);

    const currentSwitchs = switchs;

    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    if (switchName === "switchIntercomCalls" && !switchs.switchIntercomCalls) {
      const data = {
        numbers: [`*80${details.presence_id}`],
        type: "UserIntercom",
        callflow_object: {
          flow: {
            data: {
              id: `${details.pk}`,
            },
            module: "intercom",
            children: {},
          },
        },
        enabled: true,
        owner_id: `${details.pk}`,
      };

      try {
        const { data: result } = await petitionPost("callflow", { data });
        setIntercom(result.result)
        setLoadPetition(false);
      } catch (error) {
        console.log(error);
        setSwitchs(currentSwitchs);
        setErrorEditing("An error has ocurred");
        setModalError(true);
        setLoadPetition(false);
      }
    } else if (switchName === "switchIntercomCalls" && switchs.switchIntercomCalls) {
      try {
        await petitionDelete("callFlow", { callflow_id: intercom.pk });
        setLoadPetition(false);
      } catch (error) {
        console.log(error);
        setSwitchs(currentSwitchs);
        setErrorEditing("An error has ocurred");
        setModalError(true);
        setLoadPetition(false);
      }
    } else if (switchName !== "switchIntercomCalls") {
      const data = { [`${switchName}`]: !switchs[switchName] };

      try {
        await petitionPatch("user", { data, user_id: details.pk });
        setLoadPetition(false);
      } catch (error) {
        console.log(error);
        setSwitchs(currentSwitchs);
        setErrorEditing("An error has ocurred");
        setModalError(true);
        setLoadPetition(false);
      }
    }
  }
};

export default editOtherSwitchsService;
