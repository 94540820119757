import petitionGet from "../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const fetchNumber = async (setTimeframe, setOptionsNumberReport, setSelectedNumbers) => {
  const { data: result } = await petitionGet("viewNumbers")

  result.result.forEach((element) => {
    element.label = formatPhoneNumber(element.number);
    element.value = element.number;
  });

  // We get the current date
  const dateTimeframe = new Date();

  // We get the current day, month and year
  const fullYear = dateTimeframe.getFullYear()
  const month = dateTimeframe.getMonth()
  const date = dateTimeframe.getDate()

  // We create a new date based on the current date
  const previousDay = new Date(fullYear, month, date - 1);

  // We get the day, month and year of the previous day
  const previousFullYear = previousDay.getFullYear();
  const previousMonth = previousDay.getMonth();
  const previousDate = previousDay.getDate();

  // We save the current date and the previous day each in the format yyyy-mm-dd
  const getTimeStart = `${previousFullYear}-${previousMonth + 1 < 10 ? `0${previousMonth + 1}` : `${previousMonth + 1}`}-${previousDate < 10 ? `0${previousDate}` : `${previousDate}`}`;
  const getTimeEnd = `${fullYear}-${month + 1 < 10 ? `0${month + 1}` : `${month + 1}`}-${date < 10 ? `0${date}` : `${date}`}`;

  setTimeframe({ timeStart: getTimeStart, timeEnd: getTimeEnd });

  setOptionsNumberReport(result.result);
  setSelectedNumbers(result.result);
}

export default fetchNumber;