export const defaultForm = {
  firstname: "",
  lastname: "",
  email: "",
  presence_id: "",
  priv_level: "",
  time_zone: "",
  default_outbound_callerid_number: null,
  default_outbound_callerid_name: "",
  callforward_number: "",
};

export const defaultSwitchs = {
  callforward_enable: false,
  callforward_keep_caller_caller_id: false,
  callforward_queue_calls: false,
  callforward_call_confirmation: false,
  switchOverrideDefaultCallerID: false,
  call_recording_internal: false,
  call_recording_external: false,
  switchIntercomCalls: false,
  faxing_settings_enable_notifications: false,
  jitter_buffer: false,
  sendvoicemail: "no",
  deletevoicemail: "no",
};
