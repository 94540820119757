import { Col, Row } from "reactstrap";
import { AddButton, CollapsePanel, CustomTableMain } from "../../../../../../../../components";

const Users = ({ dataTableUSers, activeAddUsers, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Users"
      subtitle="Assigned user are able to receive calls, SMS & Faxes"
    >
      <CustomTableMain
        data={dataTableUSers}
        loading={loading}
        m0={true}
        module="assigned users"
        actionsRight={[
          {
            component: (
              <AddButton handleClick={activeAddUsers} name="Add User" />
            ),
          },
        ]}
      />
    </CollapsePanel>
  </>
);

export default Users;
