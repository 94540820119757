import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { postLogin } from "../../../../redux/actions/login";
import { LoadingButton } from "../../../../components";
import congratulations from "../../../../assets/images/congratulations.png";
import Step5One from "./Step5.1";
import "../SignUp.css";

const Step5 = ({ number, email, password }) => {
  const dispatch = useDispatch();

  const [showSMS, setShowSMS] = useState(false);
  const [loading, setLoading] = useState(false);

  const activeShowSMS = () => {
    setShowSMS(true);
  };

  const onClickNotIntered = (sms) => {
    setLoading(true);
    const data = {
      username: email,
      password,
    };
    dispatch(postLogin(data, sms));
  };

  return (
    <>
      {!showSMS ? (
        <div>
          <figure>
            <img className="itp-signUp-image congrat" src={congratulations} />
          </figure>

          <div className="itp-signUp image-step position-absolute">
            <Card className="itp-signUp__card">
              <CardBody className="itp-signUp__card-body">
                <h1 className="itp-signUp__card-body__title">
                  Welcome aboard!
                </h1>

                <p className="itp-signUp__card-body__textInfo--last mt-3">
                  Here is your new phone number
                </p>

                <p className="itp-signUp__card-body__textInfo--number mt-3">
                  {number}
                </p>

                <p className="itp-signUp__card-body__textInfo--last mt-3">
                  Will you be sending Text Messages?
                </p>

                <button
                  onClick={activeShowSMS}
                  className="btn-primary btn-primary-signup w-100 mt-3"
                  disabled={loading}
                >
                  Yes
                </button>

                {loading ? (
                  <div className="d-flex w-100 justify-content-center align-items-center mt-4">
                    <LoadingButton />
                  </div>
                ) : (
                  <button
                    className="itp-signUp__card-body__form__button-text mt-4"
                    onClick={() => onClickNotIntered(false)}
                  >
                    <p className="itp-signUp__card-body__textInfo--last">
                      No, I'm not interested in SMS
                    </p>
                  </button>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      ) : (
        <>
          <Step5One 
            onClickSkip={onClickNotIntered} 
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export default Step5;
