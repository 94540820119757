import petitionPatch from "../../../../../services/petitionPatch";

const editFunnelService = async (funnel_id, currentFunnelName, funnelName, setFunnelName, setAlertType, setAlertTitle, setAlertMessage, setShowErrorTime) => {
  try {
    const data = { name: funnelName };
    
    await petitionPatch("funnel", { funnel_id, data })

    currentFunnelName.current = funnelName
  } catch (error) {
    console.log(error)
    setFunnelName(currentFunnelName.current)
    setAlertType("error")
    setAlertTitle("Error")
    setAlertMessage("An error has ocurred")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }
}

export default editFunnelService;