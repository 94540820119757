const createFormData = (body, file, number, toNumber) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  formData.append("body", body);
  formData.append("from_number", number);
  formData.append("item_type", "sms");
  formData.append("to_numbers_list", JSON.stringify({ list: [toNumber] }));

  return formData;
};

export default createFormData;
