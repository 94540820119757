import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../components";

const AddQueue = ({ formQueue, onChangeFormQueue, addQueue, loadingQueue }) => (
  <>
    <Row className="itp-container-label-input">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Queue Name</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          type="text"
          placeholder=": Add a Name here"
          className="form-control-itp itp-label-input__input"
          name="queue_name"
          value={formQueue.queue_name}
          onChange={onChangeFormQueue}
        />
      </Col>
    </Row>
    <Row className="itp-container-label-input">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Queue Description</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          type="text"
          placeholder=": Add a Description here"
          className="form-control-itp itp-label-input__input"
          name="queue_description"
          value={formQueue.queue_description}
          onChange={onChangeFormQueue}
        />
      </Col>
    </Row>

    <button
      onClick={addQueue}
      className="btn-primary itp-lead-detail-button-save loading"
      disabled={!formQueue.queue_name || !formQueue.queue_description || loadingQueue}
    >
      {loadingQueue && <LoadingButton />}
      Save
    </button>
  </>
);

export default AddQueue;
