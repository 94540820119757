import { threadListService } from "../index";
import { getNotIsSelfContactPks } from "../../utils";
import petitionPatch from "../../../../../../services/petitionPatch";

const addedTagsTocontactsService = async (tags, selectedItems, chatList, number, forceUpdate) => {
  try {
    const tagspk = tags.map((tag) => tag.pk)
    const payload = { added_tags: tagspk }
    const arrayContacts = getNotIsSelfContactPks(Array.from(selectedItems), chatList.current)
    const data = { contacts: arrayContacts, payload };

    await petitionPatch("updateMultipleContacts", { data })

    chatList.current = await threadListService(number)
    forceUpdate()
  } catch (error) {
    
  }
}

export default addedTagsTocontactsService;