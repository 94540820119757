export const defaultFormDateTime = {
  name: "",
  start_date: "",
  end_date: "",
  start_time: "",
  end_time: "",
  start_day_of_week: 0,
  end_day_of_week: 4,
};

export const wDaysOptions = [
  { label: "Monday", value: 0 },
  { label: "Tuesday", value: 1 },
  { label: "Wednesday", value: 2 },
  { label: "Thursday", value: 3 },
  { label: "Friday", value: 4 },
  { label: "Saturday", value: 5 },
  { label: "Sunday", value: 6 },
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const defaultQueueForm = {
  queue_name: "",
  queue_description: "",
  timeout: null,
  queue_timeout: null,
  ringinuse: null,
  autopause: null,
  strategy: null,
  joinempty: null,
  leavewhenempty: null,
  queue_args: null,
  wrapuptime: null,
  retry: null,
  skip_busy_agents: false,
};
