const startRecordSound = async (audioChunks, setAudioBlob, setAudioUrl, setRecording, setMediaRecorder) => {
  let mediaRecorderTemp;

  navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream) => {
      mediaRecorderTemp = new MediaRecorder(stream);

      mediaRecorderTemp.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunks.push(e.data);
        }
      };

      mediaRecorderTemp.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      };

      mediaRecorderTemp.start();
      setRecording(true);
      setMediaRecorder(mediaRecorderTemp);
    })
    .catch((error) => {
      console.error("Error accessing microphone:", error);
    });
};

export default startRecordSound;
