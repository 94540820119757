//verifica si todos los campos del objeto children son objetos
// checks if all fields of the children object are objects
const areAllChildrenObjects = (children) => {
  if (!children || typeof children !== "object") {
    return false; // Si no hay children o no es un objeto, retorna false.
  }
  for (const key in children) {
    if (children.hasOwnProperty(key)) {
      if (typeof children[key] !== "object") {
        return false; // Si alguna propiedad no es un objeto, retorna false.
      }
    }
  }
  return true; // Si todas las propiedades son objetos, retorna true.
};

export default areAllChildrenObjects;
