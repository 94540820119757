import React, { useEffect, useRef, useState } from "react";
import { downloadVoicemailService, fetchVoicemailsService } from "./services";
import { ChatList, ChatWindow } from "../../../../components";
import { scrollToBottom } from "./utils";
import petitionDelete from "../../../../services/petitionDelete";
import getQueryString from "../../../../utils/getQueryString";
import useScreenSize from "../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtility/ModalDelete";
import ModalError from "../ModalUtility/ModalError";
import json from "../../../../config.json";

const MyInbox = () => {
  const { width } = useScreenSize();

  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;

  const messagesContainerRef = useRef(null);
  const myExtension = useRef({});
  const chatWindowData = useRef({});
  const pagination = useRef({
    offset: 0,
    limit: 15,
  });

  const [loading, setLoading] = useState(true);
  const [voicemails, setVoicemails] = useState([]);
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingChatWindow, setLoadingChatWindow] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentChatOpen, setCurrentChatOpen] = useState("");
  const [modalError, setModalError] = useState(false);
  const [modalDeleteAll, setModalDeleteAll] = useState(false)

  const activeChatWindow = (chat) => {
    if (chat.number !== currentChatOpen) {
      setOpenChatWindow(true);
      setLoadingChatWindow(true);

      setTimeout(() => {
        chatWindowData.current = chat;
        setCurrentChatOpen(chat.pk);
        setMessages(chat.voicemails);

        setLoadingChatWindow(false);

        scrollToBottom(messagesContainerRef)
      }, 1000);
    }
  };

  const activeModalDelete = (message) => {
    setModalDelete(message);
  };

  const deleteAllMessagesModal = () => {
    setModalDeleteAll(true)
  }

  const selectModalDeleteFunction = () => {
    if (modalDelete) {
      deleteVoicemailChat()
    } else if (modalDeleteAll) {
      deleteAllVoicemails()
    }
  }

  const closeChatWindow = () => {
    setCurrentChatOpen("");
    setOpenChatWindow(false);
    setMessages([]);
    chatWindowData.current = {};
  };

  const closeModal = () => {
    setModalDelete(false);
    setModalError(false);
    setModalDeleteAll(false)
  };

  const deleteVoicemailChat = async () => {
    setLoadingDelete(true);
    try {
      await petitionDelete("voicemail", { media_id: modalDelete.pk })

      closeChatWindow();
      setLoadingDelete(false);
      closeModal();

      const parameter = getQueryString(pagination.current);
      petition();
    } catch (error) {
      console.log(error)
    }
  };

  const deleteAllVoicemails = async () => {
    setLoadingDelete(true)
    try {
      await petitionDelete("allVoicemails")
      const paginationTemp = { offset: 0, limit: 15 }
      const parameter = getQueryString(paginationTemp);
      closeChatWindow();
      setLoadingDelete(false);
      closeModal();
      petition();
      pagination.current = paginationTemp
    } catch (error) {
      console.log(error)
    }
  }

  const moreData = () => {
    // setLoadingScroll(true);
    // const paginationtemp = {
    //   offset: 0,
    //   limit: pagination.current.limit + pagination.current.limit,
    // };

    // const parameter = getQueryString(paginationtemp);

    // petition(parameter, true);
    // pagination.current = paginationtemp;
  };

  const handleDownloadVoicemail = async (data) => {
    try {
      await downloadVoicemailService(data, urlBase, id, token, setModalError);
      console.log('Download successful');
    } catch (error) {
      console.log('Download failed', error);
    }
  };

  const petition = async (parameter, noLoading) => {
    await fetchVoicemailsService(parameter, urlBase, id, token, setVoicemails, setLoading, setLoadingScroll, noLoading)
  };

  useEffect(() => {
    const parameter = getQueryString(pagination.current);
    petition();
  }, []);

  return (
    <>
      {(modalDelete || modalDeleteAll) && (
        <ModalDelete
          modal={modalDelete || modalDeleteAll}
          closeModal={closeModal}
          loading={loadingDelete}
          modalFunction={selectModalDeleteFunction}
          modalDeleteAll={modalDeleteAll}
        />
      )}
    
      {modalError && <ModalError modal={modalError} closeModal={closeModal} />}

      <div className="itp-all-chats">
        <ChatList
          activeChatWindow={activeChatWindow}
          showChatList={!openChatWindow}
          chatList={voicemails}
          moreData={moreData}
          loadingScroll={loadingScroll}
          onChageSelect={() => {}}
          loading={loading}
          chatWindowData={chatWindowData.current}
          noAddIcon={true}
          noHoverOptions={true}
          deleteAllMessages={true}
          deleteFunction={deleteAllMessagesModal}
        />

        {openChatWindow && (
          <div className="all-container-chat">
            <ChatWindow
              closeChatWindow={closeChatWindow}
              width={width}
              openMenu={() => {}}
              toggleMenu={() => {}}
              loading={loadingChatWindow}
              messages={messages}
              setMessages={setMessages}
              chatWindowData={chatWindowData.current}
              timeZone={myExtension?.current?.time_zone}
              number={""}
              sendMessages={() => {}}
              messagesContainerRef={messagesContainerRef}
              activeModalDelete={activeModalDelete}
              deleteMessage={() => {}}
              loadingMoreMessages={false}
              getMoreMessages={() => {}}
              changeAiEnabled={() => {}}
              noSendMessage={true}
              downloadVoicemail={handleDownloadVoicemail}
              myInbox={true}
              recordeAvatarImage={true}
              loadAudio={true}
            />
          </div>
        )}
      </div>
    </>
  )
};

export default MyInbox;
