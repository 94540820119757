import axios from "axios";

export default function ForgetPasswordPetition(val) {
  const json = require("../../../config.json");
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  var url;

  url = urlBase + `/crm/auth/reset-pw?username=${val}&from=voice360`
  return axios.get(url);
}
