import { processCalls } from "./utils";

const processViewLogs = (element, myExtension, isNumberReport) => {
  element.all_calls.forEach((call) => {
    processCalls(call, myExtension, isNumberReport);
  });

  if (element.incoming_calls) {
    element.incoming_calls.forEach((call) => {
      processCalls(call, myExtension, isNumberReport);
    });
  } else {
    element.incoming_calls = [];
  }

  if (element.outgoing_calls) {
    element.outgoing_calls.forEach((call) => {
      processCalls(call, myExtension, isNumberReport);
    });
  } else {
    element.outgoing_calls = [];
  }

  return element
};

export default processViewLogs;
