import React from "react"

export default function UserAvatar({
  firstname,
  lastname,
  onlyFirstName,
  className,
  minHeight,
  style,
  textColor,
  tagColor,
  contacts
}) {
  if (tagColor === undefined || tagColor === null || tagColor === "" || tagColor === "#fff") {
    tagColor = "#6d28d9";
  }
  const backgroundColor = `${tagColor}`;
  const fontColor= textColor || "#fff";

  return (
    <>
      {(firstname || lastname) && (
            <div
            className={`rounded-circle ${className || ""}`}
            style={{
              fontFamily: "Montserrat",
              backgroundColor: `${backgroundColor}`,
              alignItems: "center",
              alignSelf: "start",
              display: "flex",
              fontSize: `${minHeight ? "1rem" : "1.25rem"}`,
              justifyContent: "center",
              textAlign: "center",
              ...style || {},
              height: `${contacts ? "40px" : "50px"}`,
              width: `${contacts ? "40px" : "50px"}`,
            }}
          >
            <span className="w-100" style={{ color: `${fontColor}` }}>
              {onlyFirstName
                ? firstname.toUpperCase().charAt(0)
                : `${firstname ? firstname?.toUpperCase()?.charAt(0) : ""}` +
                  `${lastname ? lastname?.toUpperCase()?.charAt(0) : ""}`}
            </span>
          </div>
      )}
    </>
  );
}
