import petitionPost from "../../../../../services/petitionPost";

const queueMemberReportService = async (selectedQueueMembersReport, timeframe, setTotalCalls) => {
  const data = { agents: selectedQueueMembersReport.map((element) => `${element.pk}`)};
  const parameter = `?start_date=${timeframe.timeStart}&end_date=${timeframe.timeEnd}`;

  const { data: result } = await petitionPost("queueMemberReport", { data, parameter })

  let countingTotalCalls = 0;
  result.result.forEach((element) => {
    element.userName = `${element?.user?.firstname} ${element?.user?.lastname || ""}`;
    countingTotalCalls = countingTotalCalls + element.total_calls;
  });

  setTotalCalls(countingTotalCalls);

  return result.result
};

export default queueMemberReportService;
