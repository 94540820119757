const defaultFilters = (myExtension) => {
  //se pregunta primero si existe el campo que guarda los filtros de leads si no existe se retorna null
  if (
    myExtension?.crm_filters?.leads &&
    Object.keys(myExtension?.crm_filters?.leads).length > 0
  ) {
    let filterActive = {};

    // si existen los campos added_after y added_before existen se unen y se crea un nuevo campo con el nombre Lead Created Within
    if (
      myExtension?.crm_filters?.leads?.added_after &&
      myExtension?.crm_filters?.leads?.added_before
    ) {
      filterActive = {
        ...filterActive,
        "Lead Created Within": `${myExtension?.crm_filters?.leads?.added_after.replaceAll(
          "-",
          "/"
        )}-${myExtension?.crm_filters?.leads?.added_before.replaceAll(
          "-",
          "/"
        )}`,
      };
    }

    //luego se aceden a las keys del objeto que guardan los filtros
    Object.keys(myExtension?.crm_filters?.leads).forEach((element) => {
      //aquí solo se preguntán si la key es diferente a added_after y added_before
      //ya que se trataron arriba y no se tratan en esta sección
      if (element !== "added_after" && element !== "added_before") {
        //guardamos en una constante el valor del campo al que accedemos por el nombre
        const value = myExtension?.crm_filters?.leads[element];

        // si hay tags en los filtros se accede a sus nombres que están en un array y luego se unen con el caracter ", "
        if (element === "tags") {
          filterActive = {
            ...filterActive,
            Tags: value.map((tag) => tag.name).join(", "),
          };
        } else if (element === "lead_source_id") {
          filterActive = {
            ...filterActive,
            "Lead Source": value?.name || "null",
          };
        } else if (element === "funnel_id") {
          filterActive = {
            ...filterActive,
            Funnel: value?.name || "null",
          };
        } else {
          filterActive = {
            ...filterActive,
            [`${
              element === "owner_id"
                ? "Only Show Leads Assigned to Me"
                : element
            }`]: element === "owner_id" ? "Enabled" : value,
          };
        }
      }
    });

    return filterActive;
  } else {
    return null;
  }
};

export default defaultFilters;
