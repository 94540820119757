import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import infoIcon from "../../../../../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";

const Faxing = ({ switchs, handleChangeOtherSwitch, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel title="Faxing" subtitle="Adjust fax settings">
      <span className="itp-my-settings-card-title">Fax Notifications </span>

      <Row className="itp-my-settings-card-help-text">
        <Col md={10} className="d-flex">
          <div>
            <img src={infoIcon} alt="myExtensionTemp-icon" />
          </div>
          <div className="ms-3">
            <p>Send email when a fax has been received.</p>
          </div>
        </Col>

        <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
          {loading ? (
            <Skeleton width="5rem" height="2rem" />
          ) : (
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.faxing_settings_enable_notifications}
                onChange={() => handleChangeOtherSwitch("faxing_settings_enable_notifications")}
              />
            </div>
          )}
        </Col>
      </Row>
    </CollapsePanel>
  </>
);

export default Faxing;
