import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { logoutRequest } from "../../../../../redux/actions/login";
import ModalCloseAccount from "../../ModalUtility/ModalCloseAccount";
import petitionDelete from "../../../../../services/petitionDelete";

const ClosingAccount = ({ loading }) => {
  const dispatch = useDispatch();

  const [modalClose, setModalClose] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [messageError, setMessageError] = useState("");

  const onLogout = () => {
    dispatch(logoutRequest());
  };

  const closeModal = () => {
    setModalClose(false);
  };

  const openModal = () => {
    if (loading) return;
    setModalClose(true);
  };

  const closingAccount = () => {
    setLoadingModal(true);
    petitionDelete("closingAccount")
      .then(({ data: result }) => {
        onLogout();
        setTimeout(() => {
          setLoadingModal(false);
        }, 5000);
      })
      .catch((error) => {
        console.log(error);
        const message = "An error has occurred, please try again";
        setLoadingModal(false);
        setMessageError(message);
      });
  };

  return (
    <div id="ClosingAccount">
          {modalClose && (
            <ModalCloseAccount
              modal={modalClose}
              closeModal={closeModal}
              modalFunction={closingAccount}
              loading={loadingModal}
              error={messageError}
            />
          )}

          <br></br>
          <div>
            <Row>
              <Col style={{ zIndex: "-1 " }}>
                <div className="itp-my-settings-separator-line"></div>
              </Col>
            </Row>
          </div>
          <br></br>

          <div>
            <Row className="itp-my-settings-card-help-text me-2">
              <Col md={10} className="d-flex"></Col>

              <Col md={2}>
                <button onClick={openModal} className="btn-danger mb-4">
                  Close Account
                </button>
              </Col>
            </Row>
          </div>
    </div>
  );
};

export default ClosingAccount;
