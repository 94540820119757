import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { faEye, faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StartComponent, CustomTableBadge, Loading, MenuRight, LoadingButton } from "../../../../components";
import {
  branAlertTextNotCreated,
  brandAlertTextInProgress,
  brandAlertTextRejected,
  brandAlertTextApproved,
  brandAlertTextErrorSuport,

  brandCardTitleNotCreated,
  brandCardTitleInProgress,
  brandCardTitleRejected,
  brandCardTitleApproved,
  brandCardTitleErrorSuport,

  brandCardSubtitleNotCreated,
  brandCardSubtitleInProgress,
  brandCardSubtitleRejected,
  brandCardSubtitleApproved,
  brandCardSubtitleErrorSuport,

  brandButtonNameNotCreated,
  brandButtonNameInProgress,
  brandButtonNameRejected,
  brandButtonNameApproved,
  brandButtonErrorSuport,

  campaignAlertTextNotCreated,
  campaignCardTitleNotCreated,
  campaignCardSubtitleNotCreated,
  campaignButtonNameNotCreated,
  campaignAlerTextPending,
  campaignAlerTextRejected,
  campaignAlerTextApproved,
} from "./const/const";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import Select from "react-select";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SouthIcon from "@mui/icons-material/South";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import AddOrEditBrand from "./AddOrEditBrand/AddOrEditBrand";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionDelete from "../../../../services/petitionDelete";
import AddOrEditCampaign from "./AddOrEditCampaign.js";
import "./10dlc.css";

const Dlc = () => {
  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [brand, setBrand] = useState(null);
  const [dataTable, setDataTable] = useState(null);

  const [alertText, setAlertText] = useState("");
  const [cardTitle, setCardTitle] = useState("");
  const [cardSubtitle, setCardSubtitle] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [mode, setMode] = useState("");
  const [cardTitleMode, setcardTitleMode] = useState("");
  const [alertTextCampaign, setAlertTextCampaign] = useState("");
  const [cardTitleCampaign, setCardTitleCampaign] = useState("");
  const [cardSubtitleCampaign, setCardSubtitleCampaign] = useState("");
  const [buttonNameCampaign, setButtonNameCampaign] = useState("");
  const [modeCampaign, setModeCampaign] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [addOrEditBrand, setAddOrEditBrand] = useState(false);
  const [info, setInfo] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [arrError, setArrError] = useState([]);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [menuNumbers, setMenuNumbers] = useState(false);
  const [loadingDetailsNumbers, setLoadingDetailsNumbers] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [valueNumbers, setValueNumbers] = useState([]);
  const [loadingSaveNumbers, setLoadingSaveNumbers] = useState(false);
  const [modalDeleteCampaign, setModalDeleteCampaign] = useState(false);
  const [openCampaignMenu, setOpenCampaignMenu] = useState(false);
  const [modalViewReasons, setModalViewReasons] = useState("");
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [loadingMenuCampaignMenu, setLoadingMenuCampaignMenu] = useState(false);
  const [isStatusRejected, setIsStatusRejected] = useState(false)
  const [form, setForm] = useState({
    displayName: "",
    companyName: "",
    ein: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "US",
    email: "",
    vertical: "",
    entityType: "",
    vertical: "",
    website: "",
    confirmCheckbox: false,
  });

  const arrayStatusPending = ["PENDING"];
  const arrayStatusVerified = ["VERIFIED"];
  const arrayStatusRejected = ["REJECTED", "UNVERIFIED"];

  const reloadForm = () => {
    setForm({
      displayName: "",
      companyName: "",
      ein: "",
      phone: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",
      email: "",
      vertical: "",
      entityType: "",
      vertical: "",
      website: "",
      confirmCheckbox: false,
    });
  };

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const onchangeForm = (e, key) => {
    const { name, value } = e.target;

    // Manejo de errores
    if (arrError.includes(name)) {
      const newArrError = arrError.filter((item) => item !== name);
      setArrError(newArrError);
    }

    // Actualización del formulario
    const updatedForm = { ...form };

    if (name === "country") {
      updatedForm[name] = value;
      if (value === "US") {
        updatedForm["state"] = "";
      }
    } else if (name !== "entityType") {
      updatedForm[name] = value;
      if (key) {
        updatedForm[key] = value;
      }
    } else if (name === "entityType") {
      updatedForm[name] = value;
      if (value === "SOLE_PROPRIETOR") {
        updatedForm["ein"] = "";
      }
    }

    setForm(updatedForm);
    setErrorText("");
  };

  const closeMenu = () => {
    setErrorText("");
    setArrError([]);
    setLoadingBrand(false);
    setLoadingDetailsNumbers(false);
    setLoadingSaveNumbers(false);
    setLoadingCampaign(false);
    setLoadingMenuCampaignMenu(false);
    setInfo(null);
    reloadForm();
    setModalViewReasons(false);
    setModalDeleteCampaign(false);
    setOpenCampaignMenu(false);
    setValueNumbers([]);
    setMenuNumbers(false);
    setAddOrEditBrand(false);
    setOpenMenu(false);
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const activeAddBrand = () => {
    setAddOrEditBrand(true);
    setOpenMenu(true);
  };

  const activeUpdateBrand = (e) => {
    setInfo(e);

    let dataForm = {
      displayName: e.displayName,
      companyName: e.companyName,
      ein: e.ein,
      phone: e.phone,
      street: e.street,
      city: e.city,
      state: e.state,
      postalCode: e.postalCode,
      country: e.country,
      email: e.email,
      vertical: e.vertical,
      entityType: e.entityType,
      vertical: e.vertical,
      website: e.website,
      confirmCheckbox: true,
    }

    if (isStatusRejected) {
      dataForm.resubmit = true
    }

    setForm(dataForm);
    setAddOrEditBrand(true);
    setOpenMenu(true);
  };

  const selectHandleClick = () => {
    if (!brand) {
      activeAddBrand();
    }

    if (brand && arrayStatusRejected.includes(brand?.status)) {
      activeUpdateBrand(brand);
    }

    if (cardTitle === brandCardTitleInProgress) {
      petition();
    }
  };

  const activeAssignNumbers = (e) => {
    if (e.assign) {
      setOpenMenu(true);
      setMenuNumbers(true);
      setValueNumbers(e.assigned_numbers);
      setInfo(e);
    }
  };

  const activeViewReasonModal = (e) => {
    if (e.viewReason) {
      setModalViewReasons(e?.status_description || "");
    }
  };

  const activeDeleteCampaign = (e) => {
    setInfo(e);
    setModalDeleteCampaign(true);
  };

  const activeAddCampaign = () => {
    setOpenCampaignMenu(true);
    setOpenMenu(true);
  };

  const selectTitleMenu = () => {
    if (openMenu && !info && addOrEditBrand) {
      return "Add Brand";
    }

    if (openMenu && info && addOrEditBrand) {
      return "Update Brand";
    }

    if (menuNumbers && openMenu) {
      return "Assign Numbers";
    }
  };

  const validateFields = (obj) => {
    let invalidFields = [];
    for (const field in obj) {
      if (
        (field !== "ein" || obj["entityType"] !== "SOLE_PROPRIETOR") &&
        field !== "website" &&
        (!obj[field] || obj[field] === "")
      ) {
        invalidFields.push(field);
      }
    }

    if (invalidFields.length === 0) {
      return true;
    } else {
      setArrError(invalidFields);
      return false;
    }
  };

  const editBrand = () => {
    if (validateFields(form)) {
      setLoadingBrand(true);
      const data = form;
      delete data.confirmCheckbox;
      petitionPatch("brands", { brand_id: info.pk, data })
        .then(({ data: result }) => {
          setLoadingBrand(false);
          setIsStatusRejected(false)
          reloadData();
        })
        .catch((err) => {
          console.log(err);
          setLoadingBrand(false);

          const errors = err?.response?.data?.error?.error_detailed
            .map((element) => element?.description)
            .join("\n");

          setErrorText(errors);
        });
    }
  };

  const addBrandFunction = () => {
    if (validateFields(form)) {
      setLoadingBrand(true);
      const data = form;
      delete data.confirmCheckbox;
      petitionPost("brands", { data })
        .then(({ data: result }) => {
          setLoadingBrand(false);
          reloadData();
        })
        .catch((err) => {
          console.log(err);
          setLoadingBrand(false);

          const errors = err.response.data.error.error_detailed
            .map((element) => element.description)
            .join("\n");

          setErrorText(errors);
        });
    }
  };

  const sendNumbers = () => {
    setLoadingSaveNumbers(true);

    const data = {
      numbers: valueNumbers.map((number) => number.number),
    };

    petitionPatch("campaignNumbers", { data, campaign_id: info.pk })
      .then(({ data: result }) => {
        setLoadingSaveNumbers(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const deleteCampaign = () => {
    setLoadingCampaign(true);
    petitionDelete("campaign", { brand_id: info.pk })
      .then(({ data: result }) => {
        setLoadingCampaign(false);
        reloadData();
      })
      .catch((err) => console.log(err));
  };

  const saveFunctionCampaing = (data, setStepState) => {
    if (info) {
      setLoadingCampaign(true);
      petitionPatch("campaign", { data, brand_id: info.pk })
        .then(({ data: result }) => {
          setLoadingCampaign(false);
          reloadData();
          setStepState(0);
        })
        .catch((err) => console.log(err));
    } else {
      setLoadingCampaign(true);
      petitionPost("campaign", { data })
        .then(({ data: result }) => {
          setLoadingCampaign(false);
          reloadData();
          setStepState(0);
        })
        .catch((err) => console.log(err));
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("brands")
      .then(async ({ data: result }) => {
        //si result.result es un dato valido, si es un array y si ademas su longitus es igual a 1
        //se proceden a tratar el brand que està creado
        if (
          result?.result &&
          Array.isArray(result?.result) &&
          result?.result.length === 1
        ) {
          //si el estatus del brand es de alguno de los valores definidos para un brand pendig
          //se coloca los datos de un bran en progresso
          if (arrayStatusPending.includes(result?.result[0]?.status)) {
            setAlertText(brandAlertTextInProgress);
            setCardTitle(brandCardTitleInProgress);
            setCardSubtitle(brandCardSubtitleInProgress);
            setButtonName(brandButtonNameInProgress);
            setMode("primary");
            setcardTitleMode("success");
          } else if (arrayStatusVerified.includes(result?.result[0]?.status)) {
            //si el status del brand tiene uno de los valores definidos para un brand
            //veridicado se proceden a realizar las peticiones para mostrra los campaigns
            await petitionGet("viewNumbers")
              .then(({ data: result }) => {
                result.result.forEach((element) => {
                  element.label = element.number;
                  element.value = element.number;
                });

                setNumbers(result.result);
              })
              .catch((error) => console.log(error));

            await petitionGet("campaign")
              .then(({ data: result }) => {
                let petitions = [];

                const objectStatusIcon = {
                  VERIFIED: (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "rgba(0, 170, 0, 0.8)",
                      }}
                    />
                  ),
                  REJECTED: <ErrorOutlineIcon className="text-danger" />,
                  PENDING: "",
                  UNVERIFIED: "",
                };

                result.result.forEach((element, i) => {
                  //esto verifica si el campaign está verificado para poder permitir asignar numeros
                  element.assign = arrayStatusVerified.includes(element.status);

                  //esto verifica si el campaign está rechazado para permitir abrir un modal y mirar
                  //la razón
                  element.viewReason = arrayStatusRejected.includes(
                    element.status
                  );

                  //esta es la key para mostrar el icono en la columna estatus
                  element.icon = objectStatusIcon[element?.status];

                  //esta es la key con el contenido que se muestra al hacer hover en la columna
                  //statys
                  element.hoverRejected = arrayStatusRejected.includes(
                    element?.status
                  )
                    ? element?.status_description
                    : "";

                  //si el campaign está verificado hacemos una petición al endpoint de detalles para
                  //sacar los numeros asignados
                  if (arrayStatusVerified.includes(element.status)) {
                    petitions.push(
                      petitionGet("campaignDetails", {
                        campaign_id: element?.pk,
                      })
                        .then(({ data: response }) => {
                          response.result.assigned_numbers.forEach(
                            (elementTwo) => {
                              elementTwo.label = elementTwo.number;
                              elementTwo.value = elementTwo.number;
                            }
                          );

                          //asignamos el array de numeros asignados
                          element.assigned_numbers =
                            response.result.assigned_numbers;

                          //si no hay numeros asignados se muestra un texto
                          element.onlyNumbers =
                            response.result.assigned_numbers.length > 0
                              ? response.result.assigned_numbers.length
                              : "Add Numbers";

                          //esta es la key del icono para la columna Numbers Assigned, si no hay
                          //numeros asignados se muestra un icono de errror
                          element.iconNumbers =
                            response.result.assigned_numbers.length === 0 ? (
                              <ErrorOutlineIcon className="text-danger" />
                            ) : (
                              ""
                            );

                          //está es lo key para mostrar contenido al hacer hover, si no hay
                          //numeros asignados no se muestra nada, si lo hay se muestra la lista de
                          //esos numeros
                          element.hoverNumbers =
                            response.result.assigned_numbers.length === 0
                              ? ""
                              : response.result.assigned_numbers.map(
                                  (number) => number.number
                                );
                        })
                        .catch((error) => console.log(error))
                    );
                  } else {
                    //si el campaig no está verificado se coloca el valor de Pendig si está pendiente o
                    //nada si es rechazado
                    element.onlyNumbers = arrayStatusPending.includes(
                      element.status
                    )
                      ? element.status
                      : "";
                  }
                });

                //aquí nos aseguramos que todas las peticiones al endpoitn de detalles de todos los
                //campaigns se hayan hecho sastistactoriamente
                Promise.all(petitions)
                  .then((data) => {
                    //cons esta constante se sabe si al menos uno de los campaigns está rechazado
                    const anyRejectedCampaign = result.result.find((element) =>
                      arrayStatusRejected.includes(element?.status)
                    );

                    //cons esta constante se sabe si al menos uno de los campaigns está pendiente
                    const anyPendignCampaigns = result.result.find((element) =>
                      arrayStatusPending.includes(element?.status)
                    );

                    //cons esta constante todos los campaigns están aprovados
                    const allCompletedCampaigns = result.result.every(
                      (element) => arrayStatusVerified.includes(element.status)
                    );

                    // title: "Numbers Assigned",
                    // key: "onlyNumbers",
                    // hasIcon: true,
                    // keyIcon: "iconNumbers",
                    // hasHover: true,
                    // keyHover: "hoverNumbers",
                    // keyHoverIsArray: true

                    //el hasIcon en true significa que esta columna puede que tenga un icono, en este caso es la de
                    //Numbers Assigned que con la key onlyNumbers muestra el cantidad de numeros asignados pero también
                    //la de Status, el keyIcon tiene la key dentro del objeto que representa el icono, el hasHover en
                    //true indica que esta columna va a tener un contenido que se va a mostrar al hacer hover en el
                    //icono si es que hay uno o en el valor de la key principal y ese contenido es de la key keyHover,
                    //si keyHoverIsArray es true significa que ese contenido que se muestra al hacer hover es un array
                    //pero debe ser de strings o  numeros o combinados si keyHoverIsArray es false o undefined el
                    //contenido de keyHover es un string

                    setDataTable({
                      title: [
                        { title: "Campaign ID", key: "campaignId" },
                        { title: "Use Case", key: "usecase" },
                        {
                          title: "Status",
                          key: "status",
                          hasIcon: true,
                          keyIcon: "icon",
                          hasHover: true,
                          keyHover: "hoverRejected",
                        },
                        {
                          title: "Numbers Assigned",
                          key: "onlyNumbers",
                          hasIcon: true,
                          keyIcon: "iconNumbers",
                          hasHover: true,
                          keyHover: "hoverNumbers",
                          keyHoverIsArray: true,
                          handleClick: activeAssignNumbers,
                        },
                      ],
                      content: [...result.result],
                      actions: {
                        title: "Actions",
                        content: [
                          {
                            icon: <AssignmentIcon />,
                            name: "Assign Number",
                            handleClick: activeAssignNumbers,
                            canBeDisabled: true,
                            keyDisabled: "assign",
                          },
                          {
                            icon: <FontAwesomeIcon icon={faPenToSquare} />,
                            name: "Edit",
                            handleClick: (e) => {
                              setOpenCampaignMenu(true);
                              setOpenMenu(true);
                              setLoadingMenuCampaignMenu(true);
                              setInfo(e);
                              petitionGet("campaignDetails", {
                                campaign_id: e?.pk,
                              })
                                .then(({ data: result }) => {
                                  setInfo({
                                    ...e,
                                    mms_media1: result.result.mms_media1,
                                  });
                                  setLoadingMenuCampaignMenu(false);
                                })
                                .catch((err) => console.log(err));
                            },
                          },
                          {
                            icon: <FontAwesomeIcon icon={faEye} />,
                            name: "View Reason",
                            handleClick: activeViewReasonModal,
                            canBeDisabled: true,
                            keyDisabled: "viewReason",
                          },
                          {
                            icon: <FontAwesomeIcon icon={faTrash} />,
                            name: "Delete",
                            handleClick: activeDeleteCampaign,
                          },
                        ],
                      },
                      addButton: {
                        label: "New Campaign",
                        handleClick: activeAddCampaign,
                      },
                      iconFunction: (e) => console.log(e),
                    });

                    if (result.result.length === 0) {
                      //si no hay ningun campaign se muestra la vista para ningún campaign creado
                      setAlertTextCampaign(campaignAlertTextNotCreated);
                      setCardTitleCampaign(campaignCardTitleNotCreated);
                      setCardSubtitleCampaign(campaignCardSubtitleNotCreated);
                      setButtonNameCampaign(campaignButtonNameNotCreated);
                      setModeCampaign("primary");
                    } else {
                      //si hay uno o mas campaigns se pregunta, si hay al menos uno rechazado se muestra
                      //la tabla con todos los campaigns y un mensaje que dice que hay uno o mas campaigns
                      //rechazados
                      if (anyRejectedCampaign) {
                        setAlertTextCampaign(campaignAlerTextRejected);
                        setCardTitleCampaign("");
                        setCardSubtitleCampaign("");
                        setButtonNameCampaign("");
                        setModeCampaign("danger");
                      } else if (anyPendignCampaigns) {
                        //si no hay nigún campaign rechazado y hay uno o mas pendientes se muestra
                        //la tabla con todos los campaigns y un mensaje que dice que hay uno o mas campaigns
                        //pendientes
                        setAlertTextCampaign(campaignAlerTextPending);
                        setCardSubtitleCampaign("");
                        setCardSubtitleCampaign("");
                        setButtonNameCampaign("");
                        setModeCampaign("success");
                      } else if (allCompletedCampaigns) {
                        //si no hay nigún campaign rechazado ni ningún campaign pendinte y todos están cerificados
                        // se muestra la tabla con todos los campaigns y un mensaje que dice que todos los campaigns
                        //están verificado
                        setAlertTextCampaign(campaignAlerTextApproved);
                        setButtonNameCampaign("");
                        setCardSubtitleCampaign("");
                        setButtonNameCampaign("");
                        setModeCampaign("success");
                      }
                    }

                    //se muestran los datos de un brand que ha sido verificado y donde se permiten agregar
                    //campaigns
                    setAlertText(brandAlertTextApproved);
                    setCardTitle(brandCardTitleApproved);
                    setCardSubtitle(brandCardSubtitleApproved);
                    setButtonName(brandButtonNameApproved);
                    setMode("success");
                    setcardTitleMode("");
                  })
                  .catch((error) => console.log(error));
              })
              .catch((error) => console.log(error));
          } else if (arrayStatusRejected.includes(result?.result[0]?.status)) {
            //si el statua del brand tiene alguno de los valores para un bran rechazado
            //se tratand los datos para mostrar que el brand se rechazó
            setAlertText(brandAlertTextRejected);
            setCardTitle(brandCardTitleRejected);
            setCardSubtitle(
              brandCardSubtitleRejected +
                ": " +
                result?.result[0]?.status_description
            );
            setButtonName(brandButtonNameRejected);
            setMode("danger");
            setcardTitleMode("");
            setIsStatusRejected(true)
          }

          setBrand(result?.result[0]);
        } else if (
          //si result.result es un dato valido, es un array y tiene una longitud mayor a
          //uno se indica al usuario que contacte a soporte por un error
          result?.result &&
          Array.isArray(result?.result) &&
          result?.result.length > 1
        ) {
          setAlertText(brandAlertTextErrorSuport);
          setCardTitle(brandCardTitleErrorSuport);
          setCardSubtitle(brandCardSubtitleErrorSuport);
          setButtonName(brandButtonErrorSuport);
          setMode("danger");
          setcardTitleMode("");
        } else {
          //si result.result no es un datos valido o no es un array on es un array con longitud
          //cero o con mas de uno se tratan los datos como si no se hubiera creado ningùn brand
          setAlertText(branAlertTextNotCreated);
          setCardTitle(brandCardTitleNotCreated);
          setCardSubtitle(brandCardSubtitleNotCreated);
          setButtonName(brandButtonNameNotCreated);
          setMode("primary");
          setcardTitleMode("");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_ten_dlc
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition()
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <>
      <div
        onClick={handleBackClick}
        className={`itp-tex-back-container ${
          openMenu || modalViewReasons || modalDeleteCampaign
            ? "itp-advanced-callflow-text-back"
            : ""
        }`}
      >
      </div>

      {(modalViewReasons || modalDeleteCampaign) && (
        <ModalDelete
          modal={modalViewReasons ? modalViewReasons : modalDeleteCampaign}
          closeModal={closeMenu}
          loading={loadingCampaign}
          modalFunction={deleteCampaign}
          module="Campaign"
          title={modalViewReasons ? "View Reason" : ""}
          text={modalViewReasons ? modalViewReasons : ""}
          disabledSaveButton={modalViewReasons ? true : false}
        />
      )}

      <div style={{ marginTop: "3rem" }}>
        <StartComponent
          componentTitle="10 DLC Registration"
          alertText={alertText}
          cardTitle={cardTitle}
          cardSubtitle={cardSubtitle}
          buttonName={buttonName}
          onClickFunction={selectHandleClick}
          mode={mode}
          cardTitleMode={cardTitleMode}
          noPlusIconButton={true}
          loading={loading}
        />

        {brand && arrayStatusVerified.includes(brand?.status) && (
          <>
            <div className="d-flex justify-content-center align-items-center">
              <SouthIcon className="itp-10dlc-arrow-down" />
            </div>

            {dataTable && (
              <CustomTableBadge
                variant={modeCampaign}
                data={dataTable}
                alertText={alertTextCampaign}
                cardTitle={cardTitleCampaign}
                cardSubtitle={cardSubtitleCampaign}
                buttonName={buttonNameCampaign}
                onClickFunction={activeAddCampaign}
                textNoData="Campaigns"
                noPlusIconButton={true}
              />
            )}
          </>
        )}
      </div>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={selectTitleMenu()}
        subtitle=""
        to="to 10dlc"
      >
        {openMenu && addOrEditBrand && !menuNumbers && !openCampaignMenu && (
          <AddOrEditBrand
            form={form}
            setForm={setForm}
            handleChange={onchangeForm}
            errorText={errorText}
            arrError={arrError}
            setArrError={setArrError}
          />
        )}

        {openMenu && openCampaignMenu && !menuNumbers && !addOrEditBrand && (
          <>
            {loadingMenuCampaignMenu ? (
              <Loading />
            ) : (
              <AddOrEditCampaign
                saveFunction={(data) => saveFunctionCampaing(data)}
                info={info}
                brand={brand}
                loading={loadingCampaign}
              />
            )}
          </>
        )}

        {openMenu && addOrEditBrand && !openCampaignMenu && !menuNumbers && (
          <button
            className="btn-primary loading add-lead-new-contact"
            onClick={!info ? addBrandFunction : editBrand}
            disabled={loadingBrand}
          >
            {loadingBrand && <LoadingButton />}
            {!info ? "Submit" : "Resubmit"}
          </button>
        )}

        {openMenu && menuNumbers && !openCampaignMenu && !addOrEditBrand && (
          <>
            {loadingDetailsNumbers ? (
              <Loading />
            ) : (
              <>
                <label className="itp-label-input__label">Select Numbers</label>
                <Select
                  isMulti
                  onChange={(e) => setValueNumbers(e)}
                  className="basic-single mb-4"
                  classNamePrefix="select"
                  value={valueNumbers}
                  isSearchable={true}
                  name="assigned_numbers"
                  placeholder="Select Numbers"
                  options={numbers}
                />

                <button
                  className="btn-primary loading add-lead-new-contact"
                  onClick={sendNumbers}
                  disabled={loadingSaveNumbers || !valueNumbers.length}
                >
                  {loadingSaveNumbers && <LoadingButton />}
                  Save
                </button>
              </>
            )}
          </>
        )}
      </MenuRight>
    </>
  );
};

export default Dlc;
