const selectParameterByDropdown = (value, selectParameter, chatListPagination) => {
  let parameter = "";

  switch (value) {
    case "All":
      parameter = "?offset=0&limit=15";
      selectParameter.current = "";
      chatListPagination.current = { offset: 0, limit: 15 };
      break;

    case "Unread":
      parameter = "?offset=0&limit=15&threads_read=false";
      selectParameter.current = "&threads_read=false";
      chatListPagination.current = { offset: 0, limit: 15 };
      break;

    case "AI":
      parameter = "?offset=0&limit=15&ai_enabled=true";
      selectParameter.current = "&ai_enabled=true";
      chatListPagination.current = { offset: 0, limit: 15 };
      break;

    case "Archived":
      parameter = "?offset=0&limit=15&archived=true";
      selectParameter.current = "&archived=true";
      chatListPagination.current = { offset: 0, limit: 15 };
      break;

    default:
      parameter = "?offset=0&limit=15";
      selectParameter.current = "";
      chatListPagination.current = { offset: 0, limit: 15 };
      break;
  }

  return parameter
};

export default selectParameterByDropdown;
