import petitionGet from "../../../../../services/petitionGet";

const fetchLeadSources = async () => {
  const { data: result } = await petitionGet("sources", {});
  result.result.forEach((element) => {
    element.label = `${element?.name || ""}`;
    element.value = element?.pk || null;
  });
  return result.result;
};

export default fetchLeadSources;
