import { dateComparision } from "../../../../../utils/getDate";

const MessageSeparator = ({ timeZone, element, i, array }) => {

  if (element?.message_timestamp) {
    const messageTimestamp = element?.message_timestamp?.split(" ")[0]?.replaceAll("-", "/");
    const notCondition = array[i - 1]?.message_timestamp?.split(" ")[0]?.replaceAll("-", "/") === messageTimestamp ||  messageTimestamp === ""
    const { result } = dateComparision(element?.message_timestamp, timeZone)

    if (notCondition) return;

    return (
      <div className="itp-chat-window-content__container-separator">
        <div className="itp-chat-window-content__line-separator"></div>
        <label className="itp-chat-window-content__text-separator">{result}</label>
        <div className="itp-chat-window-content__line-separator"></div>
      </div>
    );
  }
};

export default MessageSeparator;
