import petitionPatch from "../../../../../services/petitionPatch";

const editLeadService = async (setLoadingLead, leadDetails, lead, editContact, contact) => {
  setLoadingLead(true);
  try {
    const lead_id = leadDetails.pk;
    const data = lead;
    await petitionPatch("leads", { lead_id, data });
    editContact(contact);
  } catch (error) {
    console.log(error);
  }
};

export default editLeadService;
