const getDefaultDate = (setStartDate, setEndDate, setCurrentStartDate, setCurrentEndDate) => {
  const currentDateTimeLocal = new Date();
  const currentDateTime = new Date(currentDateTimeLocal.toLocaleString("en-US", { timeZone: "US/Eastern" }));
  const defaultDate = currentDateTime.getFullYear() + "-" + (currentDateTime.getMonth() + 1 < 10 ? "0" + (currentDateTime.getMonth() + 1) : currentDateTime.getMonth() + 1) + "-" + currentDateTime.getDate();

  setStartDate(defaultDate);
  setEndDate(defaultDate);
  setCurrentStartDate(defaultDate);
  setCurrentEndDate(defaultDate);
}

export default getDefaultDate;