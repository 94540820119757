import { useCallback, useEffect } from 'react';
import callCalling from "../../../assets/icons/callCalling.svg";
import callRemove from "../../../assets/icons/callRemove.svg";
import callAdd from "../../../assets/icons/callAdd.svg";
import onCall from "../../../assets/icons/onCall.svg";

const useCallStatus = (currentData, setData, columnConfig) => {
  //Function to render when there is an incoming call
  const renderRingingStatus = () => (
    <>
      <img src={callCalling} alt="Ringing" /> Ringing
    </>
  );

  //Function to render when a call was answered
  const renderOnCallStatus = (channel) => {
    const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
    const timeString = new Date((regex.test(channel?.time) ? channel?.time : 0) * 1000).toISOString().substr(11, 8);

    return (
      <>
        <img src={onCall} alt="On Call" /> {`On Call ${channel.withNumber} ${timeString}`}
      </>
    );
  };

  //Function to render when a call was hung up
  const renderTerminatedCallStatus = (channel, pk) => {
    const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
    const timeString = new Date((regex.test(channel?.time) ? channel?.time : 0) * 1000).toISOString().substr(11, 8);

    handleTermination(pk, channel.uniqueid);

    return (
      <>
        <img src={callRemove} alt="Call Terminated" /> {`Call Terminated ${timeString}`}
      </>
    );
  };

  //Function to render when there is more than one call
  const renderMultipleCallsStatus = (channels, pk) => {
    return (
      <div className="itp-livePanel-extensions__multple-call-container">
        <div className="itp-livePanel-extensions__multple-call-info">
          {channels.map((element, i) => {
            let time = ""

            if (element?.time) {
              time = new Date((typeof channels[0]?.time === "number" ? channels[0]?.time : 0) * 1000).toISOString().substr(11, 8);
            }

            if (element.terminated) {
              handleTermination(pk, element.uniqueId)
              return (
                <div key={i} className="d-flex gap-1">
                  {" "}
                  <img src={callRemove} alt="" /> <span>{`Call Terminated ${time}`}</span>
                </div>
              );
            }

          
            if (!element.answered) {
              return (
                <div className="d-flex gap-1">
                  {" "}
                  <img src={callCalling} alt="" /> Ringing
                </div>
              );
            }

            return (
              <div className="d-flex gap-1">
                <img style={{ width: "12px", height: "12px" }} src={onCall} alt="" /> {`On Call (${element.withNumber}) - ${time}`}
              </div>
            )
          })}
      </div>
      <img src={callAdd} alt="" /> On Call {`(${channels.length})`}
    </div>
    )
  }

  const handleTermination = useCallback((pk, uniqueId) => {
    setTimeout(() => {
      setData((prevState) => {
        const updatedContent = prevState.content.map((element) => {
          if (element.pk === pk) {
            return {
              ...element,
              channels: element.channels.filter((channel) => channel.uniqueid !== uniqueId)
            };
          }
          return element;
        });
        
        // We only update if there really is a change
        if (JSON.stringify(prevState.content) !== JSON.stringify(updatedContent)) {
          return { ...prevState, content: updatedContent };
        }
        
        return prevState;
      });
    }, 1000)
  }, [currentData, setData]);

  const renderCallStatus = useCallback((element) => {
    const { channels, pk } = element;

    //no calls
    if (channels.length === 0) return;

    //there is more than one call
    if (channels.length > 1) return renderMultipleCallsStatus(channels, pk)
  
    const channel = channels[0];

    //hung up call
    if (channel.terminated) return renderTerminatedCallStatus(channel, pk);

    //incoming call unanswered
    if (!channel.answered) return renderRingingStatus();


    //answered call
    return renderOnCallStatus(channel);
  }, [handleTermination]);

  const updateCallTimes = useCallback(() => {
    const updatedData = currentData.map((element) => {
      if (element.channels && element.channels.length > 0) {
        return {
          ...element,
          channels: element.channels.map(channel => ({
            ...channel,
            time: channel.time + 1,
          })),
        };
      }
      return element;
    });

    setData((prevState) => ({
      ...prevState,
      content: [...updatedData],
    }));
  }, [currentData, setData]);

  useEffect(() => {
    if (columnConfig?.callKeyStatus) {
      const interval = setInterval(() => {
        updateCallTimes();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [updateCallTimes, columnConfig]);

  return renderCallStatus;
};

export default useCallStatus;
