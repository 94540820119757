import React from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddButton = ({ handleClick, notIcon, name, loading, className }) => (
  <>
    {loading ? (
      <div className="button-skeleton"></div>
    ) : (
      <button onClick={handleClick} className={`btn-primary ${className || ""}`}>
        {!notIcon && (
          <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />
        )}{" "}
        {name}
      </button>
    )}
  </>
);

export default AddButton;
