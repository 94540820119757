import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { CollapsePanel, LoadingButton, MenuRight, CustomTableMain, AddButton } from "../../../../components";
import { capitalizeWords } from "../../../../utils/capitalizeWords";
import infoIcon from "../../../../assets/icons/information-icon.svg";
import AsyncSelect from "react-select/async";
import petitionPatch from "../../../../services/petitionPatch";
import petitionGet from "../../../../services/petitionGet";
import getQueryString from "../../../../utils/getQueryString";
import ModalDelete from "../ModalUtility/ModalDelete";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPost from "../../../../services/petitionPost";
import Skeleton from "react-loading-skeleton";

const FollowUps = ({ customStyles, tagValue, setTagValue, loading }) => {
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [openMenu, setOpenMenu] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [showAddOrEditAIFollowUp, setShowAddOrEditAIFollowUp] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);

  const [form, setForm] = useState({
    interval: "",
    interval_type: "",
  });

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Interval", key: "interval" },
      { name: "Interval Type", key: "cpaitalizeIntervalType" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const intervalNumberOptions = Array.from({ length: 366 }, (_, i) => (i + 1).toString());

  const closeMenu = () => {
    if (showAddOrEditAIFollowUp) {
      setShowAddOrEditAIFollowUp(false);
    } else {
      setOpenMenu(false);
    }

    setInfo(null);
    setForm({
      interval: "",
      interval_type: "",
    });
  };

  const handleChangeTag = (e) => {
    const currentTagValue = tagValue;

    setTagValue(e);

    const data = {
      no_response_tag_id: e.pk,
    };

    petitionPatch("aiDetailsUpdate", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setTagValue(currentTagValue);
      });
  };

  const loadTags = (inputValue, callback) => {
    petitionGet("tags", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((tag) => {
          tag.label = tag.name;
          tag.value = tag.pk;
        });

        callback(result.result);
      })
      .catch((error) => console.log(error));
  };

  const activeAddOrEditAIFollowUp = (e) => {
    if (e) {
      setInfo(e);
      setForm({
        interval: e.interval,
        interval_type: e.interval_type,
      });
    }

    setShowAddOrEditAIFollowUp(true);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const selectTitleMenu = () => {
    if (!info && !showAddOrEditAIFollowUp) {
      return "AI Follow Up Schedule";
    } else if (!info && showAddOrEditAIFollowUp) {
      return "Add AI Follow Up Schedule";
    } else if (info && showAddOrEditAIFollowUp) {
      return "Edit AI Follow Up Schedule";
    }
  };

  const deleteAIFollowUp = () => {
    setLoadingModal(true);
    petitionDelete("aiFollowUpSchedule", { schedule_id: info.pk })
      .then(({ data: result }) => {
        let parameter = "";

        if (allResult.result.length === 1 && pagination.current.offset > 0) {
          const paginationTemp = {
            offset: pagination.current.offset - pagination.current.limit,
            limit: 10,
          };
          parameter = getQueryString(paginationTemp);
          pagination.current = paginationTemp;
        } else {
          parameter = getQueryString(pagination.current);
        }

        setLoadingModal(false);
        closeModal()
        activeOpenMenu(parameter);
      })
      .catch((error) => console.log(error));
  };

  const addOrEditAIFollowUp = () => {
    setLoadingButton(true);

    const parameter = getQueryString(pagination.current);

    if (info) {
      petitionPatch("aiFollowUpSchedule", { schedule_id: info.pk, data: form })
        .then(({ data: result }) => {
          setLoadingButton(false);
          closeMenu();
          activeOpenMenu(parameter);
        })
        .catch((error) => console.log(error));
    } else {
      petitionPost("aiFollowUpSchedule", { data: form })
        .then(({ data: result }) => {
          setLoadingButton(false);
          closeMenu();
          activeOpenMenu(parameter);
        })
        .catch((error) => console.log(error));
    }
  };

  const closeModal = () => {
    setModalDelete(false);
    setInfo(null);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    if (name === "interval" && (!value || value.match(/^\d+$/))) {
      setForm({ ...form, interval: value });
    } else if (name !== "interval") {
      setForm({ ...form, [name]: value });
    }
  };

  const changePage = (offset) => {
    setLoadingPagItems(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp)

    activeOpenMenu(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeOpenMenu = (parameter, noLoading) => {
    if (!noLoading) setLoadingMenu(true);

    setOpenMenu(true)

    petitionGet("aiFollowUpSchedule", { parameter })
      .then(({ data: result }) => {
        setAllResult(result);

        result.result.forEach((element) => {
          element.cpaitalizeIntervalType = capitalizeWords(element.interval_type)
        })

        setDataTable({
          columns: [
            { name: "Interval", key: "interval" },
            { name: "Interval Type", key: "cpaitalizeIntervalType" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeAddOrEditAIFollowUp,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteModal,
              },
            ],
          },
        });

        setLoadingPagItems(false)
        setLoadingMenu(false)
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingModal}
          modalFunction={deleteAIFollowUp}
          module="AI Follow Up Schedule"
        />
      )}

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Follow Ups"
        subtitle="configure the schedule and tags"
        className="mb-4"
      >
        <span className="itp-my-settings-card-title">
          Automatic Follow Ups
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={9} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                If enabled under a number, follow up based on schedule
              </p>
            </div>
          </Col>

          <Col md={3}>
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <button
                onClick={() => {
                  if (loading) return;
                  activeOpenMenu(getQueryString(pagination.current));
                }}
                className="btn-primary"
              >
                View Schedule
              </button>
            </div>
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">
          No Response Tag
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={9} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                If Contact fails to respond after follow up schedule,
                 assign tag
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={3}>
            {loading ? (
              <Skeleton height="2.2rem" width="100%" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end mt-2">
                <AsyncSelect
                  onChange={handleChangeTag}
                  value={tagValue}
                  className="basic-single itp-ai-select"
                  classNamePrefix="select"
                  loadOptions={loadTags}
                  isSearchable={true}
                  name="no_response_tag_id"
                  placeholder="Search Tag"
                  defaultOptions
                  cacheOptions
                  styles={customStyles} // Aplicas tus estilos personalizados aquí
                />
            </div>
              )}
          </Col>
        </Row>
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title={selectTitleMenu()}
        to={showAddOrEditAIFollowUp ? "AI Follow Up Schedule" : ""}
      >
        {openMenu && !showAddOrEditAIFollowUp && (
          <>
            <CustomTableMain
              data={dataTable}
              loading={loadingMenu}
              loadingPagItems={loadingPagItems}
              module="ai follow up schedule"
              m0={true}
              isBackend={true}
              paginationData={10}
              totalPages={allResult?.total_pages}
              onPageChange={changePage}
              offset={pagination.current.offset}
              actionsLeft={[
                {
                  component: (
                    <AddButton 
                      handleClick={() => {
                        if (loadingMenu || loadingPagItems) return;
                        activeAddOrEditAIFollowUp()
                      }}
                      name="Add AI Follow Up Schedule"
                    />
                  )
                }
              ]}
            />
          </>
        )}

        {openMenu && showAddOrEditAIFollowUp && (
          <>
            <Row className="itp-container-label-select itp-ai-followup__addSchedle-container">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={2}
                xl={2}
                xxl={2}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label mt-3">Interval</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={10}
                xl={10}
                xxl={10}
                className="itp-label-input-col"
              >
                <div className="d-flex gap-2 align-items-center">
                  <select
                    className="form-select-itp"
                    name="interval"
                    value={form.interval}
                    onChange={handleChangeForm}
                  >
                    <option value="" disabled>
                      Select Option
                    </option>
                    {intervalNumberOptions.map((number) => (
                      <option key={number} value={number}>{number}</option>
                    ))}
                  </select>
                  <select
                    className="form-select-itp"
                    name="interval_type"
                    value={form.interval_type}
                    onChange={handleChangeForm}
                  >
                    <option value="" disabled>
                      Select Option
                    </option>
                    <option value="minute">Minute</option>
                    <option value="hour">Hour</option>
                    <option value="day">Day</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </select>
                </div>
              </Col>
            </Row>

            <button
              style={{ width: "15rem" }}
              className="btn-primary itp-lead-detail-button-save loading mb-4"
              onClick={addOrEditAIFollowUp}
              disabled={!form.interval || !form.interval_type}
            >
              {loadingButton && <LoadingButton />}
              {!info ? "Create" : "Update"} AI Follow Up Schedule
            </button>
          </>
        )}
      </MenuRight>
    </>
  );
};

export default FollowUps;
