import petitionPatch from "../../../../../services/petitionPatch";

const editContactService = async (form, leadDetails, setErrorEditContact, setLoadingLead, reloadData) => {
  setErrorEditContact("");
  setLoadingLead(true);
  try {
    const contact_id = leadDetails.contact.pk
    await petitionPatch("contact", { data: form, contact_id })
    setLoadingLead(false);
    reloadData(false);
  } catch (error) {
    console.log(error);

    if (error?.response?.status === 400) {
      const messageError = error?.response?.data?.error?.error_message;
      setErrorEditContact(messageError);
    }

    setLoadingLead(false);
  }
};

export default editContactService;
