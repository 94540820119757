const formatData = (data, timezone, isQueue) => {
  const order = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];

  const secondOrder = {
    0: "12 AM",
    1: "1 AM",
    2: "2 AM",
    3: "3 AM",
    4: "4 AM",
    5: "5 AM",
    6: "6 AM",
    7: "7 AM",
    8: "8 AM",
    9: "9 AM",
    10: "10 AM",
    11: "11 AM",
    12: "12 PM",
    13: "1 PM",
    14: "2 PM",
    15: "3 PM",
    16: "4 PM",
    17: "5 PM",
    18: "6 PM",
    19: "7 PM",
    20: "8 PM",
    21: "9 PM",
    22: "10 PM",
    23: "11 PM",
  };

  const convertedData = {};
  for (const key in data) {
    const keySpliter = isQueue ? secondOrder[key] : key;
    const [hours, period] = keySpliter.split(" ");
    let hour = parseInt(hours);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    const time = new Date();
    time.setUTCHours(hour);
    const formattedTime = time.toLocaleString("en-US", {
      timeZone: timezone,
      hour: "numeric",
      hour12: true,
    });
    convertedData[formattedTime] = !isQueue ? data[key] : data[key].total_calls;
  }

  const orderedObject = {};
  order.forEach((key) => {
    if (key in convertedData) {
      orderedObject[key] = convertedData[key];
    }
  });

  return orderedObject;
};

export default formatData;
