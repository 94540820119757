import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import Skeleton from "react-loading-skeleton";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Tag from "../../assets/icons/addTag.svg";

const filter = createFilterOptions();
const tagColorsWithBlackFont = [
  "#f8e71c",
  "#dff21b",
  "",
  null,
  undefined,
  false,
];

const TagComponent = ({ disabled, tags, optionsTags, handleTag, loading, refer, imageClick, icon }) => (
  <>
    {loading ? (
      <Skeleton height={50} width="100%" />
    ) : (
      <div className="itp-component-tag-container">
        {icon ? (
          <LocalOfferOutlinedIcon onClick={typeof imageClick === "function" ? imageClick : () => {}} />
        ) : (
          <img onClick={typeof imageClick === "function" ? imageClick : () => {}} src={Tag} alt="Add Tag" className="itp-component-tag-img" />
        )}

        <div ref={refer || null} className="itp-component-tag-width">
          <Stack>
            <Autocomplete
              disableClearable
              disablePortal
              filterSelectedOptions
              disabled={disabled}
              freeSolo
              multiple
              value={tags}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option?.inputValue || option.title}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: option.tag_color || "#edf6fd",
                      color: tagColorsWithBlackFont.includes(option.tag_color)
                        ? "black"
                        : "white",
                    }}
                  />
                ))
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return "error";
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                } else {
                  return option.title;
                }
              }}
              getOptionDisabled={(option) => !!option.disabled}
              options={optionsTags}
              id="tags-standard"
              isOptionEqualToValue={(option, value) =>
                option.title === value.title
              }
              onChange={(event, newValue) => handleTag(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );

                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Add Tags"
                />
              )}
              renderOption={(props, option) => (
                <Chip
                  {...props}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#cfe3ff", // Cambiar al color más oscuro deseado
                      color: "black",
                    },
                    backgroundColor: option.tag_color || "#ffffff",
                    borderRadius: 0,
                    border: 0, // Mantener los bordes cuadrados
                    color: tagColorsWithBlackFont.includes(option.tag_color)
                      ? "black"
                      : "white",
                  }}
                  label={option.title}
                  variant="outlined"
                />
              )}
            />
          </Stack>
        </div>
      </div>
    )}
  </>
);

export default TagComponent;
