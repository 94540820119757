import petitionPatch from "../../../../../../services/petitionPatch";
import petitionPost from "../../../../../../services/petitionPost";

const addOrEditDateTimeService = async (setLoadingDateTime, formAddEditDateTime, pk, info, reloadData) => {
  setLoadingDateTime(true);

  let newData = {
    name: formAddEditDateTime?.name,
    start_hour: parseInt(formAddEditDateTime?.start_time.split(":")[0]),
    start_minute: parseInt(formAddEditDateTime?.start_time.split(":")[1]),
    start_month: parseInt(formAddEditDateTime?.start_date.split("-")[1]),
    start_month_day: parseInt(formAddEditDateTime?.start_date.split("-")[2]),
    start_month_year: parseInt(formAddEditDateTime?.start_date.split("-")[0]),
    end_hour: parseInt(formAddEditDateTime?.end_time.split(":")[0]),
    end_minute: parseInt(formAddEditDateTime?.end_time.split(":")[1]),
    end_month: parseInt(formAddEditDateTime?.end_date.split("-")[1]),
    end_month_day: parseInt(formAddEditDateTime?.end_date.split("-")[2]),
    end_month_year: parseInt(formAddEditDateTime?.end_date.split("-")[0]),
    start_day_of_week: parseInt(formAddEditDateTime?.start_day_of_week),
    end_day_of_week: parseInt(formAddEditDateTime?.end_day_of_week),
    timegroup_id: pk.toString(),
  };

  if (!newData.end_month) delete newData.end_month;
  if (!newData.end_month_day) delete newData.end_month_day;
  if (!newData.end_month_year) delete newData.end_month_year;
  if (!newData.start_month) delete newData.start_month;
  if (!newData.start_month_day) delete newData.start_month_day;
  if (!newData.start_month_year) delete newData.start_month_year;

  if (!info) {

    try {
      await petitionPost("timeRules", { data: newData })

      setLoadingDateTime(false);
      reloadData(true);
    } catch (error) {
      console.log(error)
    }

  } else {

    try {
      await petitionPatch("timeRules", { data: newData, time_id: info.pk })

      setLoadingDateTime(false);
      reloadData(true);
    } catch (error) {
      console.log(error)
    }
  }

};

export default addOrEditDateTimeService;
