const handleChangeSearchNumber = (e, setSearchValue, allData, setData) => {
  const { value } = e.target;

  setSearchValue(value);

  let numbersTemp = [];

  if (value !== "") {
    allData.forEach((element) => {
      if (element.number.number.includes(value)) {
        numbersTemp.push(element);
      }
    });

    setData(numbersTemp);
  } else {
    setData(allData);
  }
};

export default handleChangeSearchNumber;
