import arrowDownIcon from "../../../../../assets/icons/arrowDownIcon.svg";
import arrowUp from "../../../../../assets/icons/arrowUp.svg";

const selectArrow = (element) => {
  switch (element.last_activity_type) {
    case "call_inbound":
      return <img style={{ width: "16px", height: "16px" }} src={arrowDownIcon} alt="" />;

    case "call_outbound":
      return <img style={{ width: "16px", height: "16px" }} src={arrowUp} alt="" />;

    case "sms_received":
      return <img style={{ width: "16px", height: "16px" }} src={arrowDownIcon} alt="" />;

    case "sms_sent":
      return <img style={{ width: "16px", height: "16px" }} src={arrowUp} alt="" />;

    default:
      return ""
  }
}

export default selectArrow;