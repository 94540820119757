import petitionGet from "../../../../../../services/petitionGet";

const fetchNumbers = async (formTemp, myExtensionTemp) => {
  const { data: result } = await petitionGet("viewNumbers")
  const numbers = result.result.map((element) => {
    element.label = element.number;
    element.value = element.number;
    element.fieldName = "default_outbound_callerid_number"

    return element
  });

  const defaultNumber = result.result.find((element) => element?.number === myExtensionTemp?.default_outbound_callerid_number);

  const newFormTemp = {
    ...formTemp,
    default_outbound_callerid_name: myExtensionTemp?.default_outbound_callerid_name || "",
    default_outbound_callerid_number: defaultNumber || null,
  }

  return { numbers, newFormTemp }
}

export default fetchNumbers;