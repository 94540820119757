const callparkingParked = (socketInfo, users, setParkingCall) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log('Invalid socketInfo or metadata');
    return;
  }   
  
  const { from_user_id, parking_slot, uniqueid } = socketInfo?.metadata

  const findUser = users.find((user) => user.pk === parseInt(from_user_id))

  if (findUser) {
    setParkingCall((prev) => {
      const newData = prev.map((element) => {
        if (element.slot_number === parking_slot) {
          element.call_id = uniqueid
          element.callerid_num = findUser?.presence_id || "error"
        }

        return element
      })

      return newData
    })
  }
}

export default callparkingParked;