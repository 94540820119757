import petitionDelete from "../../../../../services/petitionDelete";

const deleteLeadService = async (info, setLoadingLead, allResult, reloadData, pagination) => {
  setLoadingLead(true);

  try {
    await petitionDelete("lead", { lead_id: info.pk })

    setLoadingLead(false);

    if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
      const paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      reloadData(paginationTemp);
    } else {
      reloadData(false);
    }
  } catch (error) {
    console.log(error);
  }
}

export default deleteLeadService;