export default function getValuesInObject(form, keyIgnored){
    let keys = Object.keys(form);
    let newObject = {}
    keys.forEach((key, i) => {
        if(typeof form[key] === "string" || typeof form[key] === "number"){
          newObject[key] = form[key]
        } else if (typeof form[key] === "boolean" && key !== keyIgnored) {
          newObject[key] = form[key] ? "Enabled" : "Disabled"
        } else if(typeof form[key] === "object" && !Array.isArray(form[key])) {
          newObject[key] = form[key].label
        } else if(Array.isArray(form[key])){
          let listLabels = []
          form[key].forEach((element) => {
            listLabels.push(element.label)
          })

          newObject[key] = listLabels.join(",")
        }
    });

    return newObject;
}