const returnCheck = (state, reverse) => {
  const arrayTrueValues = [true, "true", "True", "enabled", "Enabled"]
  const arrayFalseValues = [false, "false", "False", "", null, undefined, "disabled", "Disabled"] 

  if (arrayTrueValues.includes(state)) return reverse ? false : true

  if (arrayFalseValues.includes(state)) return reverse ? true : false

  return reverse ? true : false
}

export default returnCheck;