const updateChatList = (pk, chatList) => {
  const newChats = chatList.current.map((element) => {
    if (element?.pk.toString() === pk) {
      element.thread_read = true;
    }
    return element;
  });

  return newChats
};

export default updateChatList;
