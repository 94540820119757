import React, { useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import { capitalizeWords } from "../../../../../utils/capitalizeWords";

const Step4 = ({ form, handleChangeForm, setForm, updatedAlertStates, edit }) => {
  const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  const [customSchedule, setCustomSchedule] = useState(edit ? true : false);

  const handleChangeCustomSchedule = () => {
    setCustomSchedule(!customSchedule)
  }

  const handleChangeCheckbox = (name) => {
    if (name === "determine_time_zone") {
      setForm({
        ...form,
        determine_time_zone: !form.determine_time_zone,
      });
    } else {
      const newForm = {
        ...form,
        schedule_days: {
          ...form.schedule_days,
          [name]: !form.schedule_days[name],
        },
      }

      const allFalse = Object.values(newForm.schedule_days).every((day) => !day);

      if (allFalse) {
        updatedAlertStates("error", "Error", "Please select at least one day of the week", 4000)
      } else {
        setForm(newForm);
      }
    }
  };

  const handleChangeTime = (e) => {
    const { name, value } = e.target
    const currentForm = form
    handleChangeForm(e)

    const numericValue = parseInt(value.replaceAll(":", ""))
    const numericCurrentStartTime = parseInt(form.schedule_start_time.replaceAll(":", ""))
    const numericCurrentEndTime = parseInt(form.schedule_end_time.replaceAll(":", ""))

    if (name === "schedule_start_time" && numericValue >= numericCurrentEndTime) {
      const messageError = "Start time cannot be past End Time"
      updatedAlertStates("error", "Error", messageError, 3000)
      setForm(currentForm)
    } else if (name === "schedule_end_time" && numericValue <= numericCurrentStartTime) {
      const messageError = "End time cannot be earlier than start time" 
      updatedAlertStates("error", "Error", messageError, 3000)
      setForm(currentForm)
    }
  }

  return (
    <>
      {!edit && (
        <div className="d-flex gap-1 align-items-center">
          <input
            type="checkbox"
            checked={customSchedule}
            onChange={handleChangeCustomSchedule}
            className="form-check-input itp-custom-checkbox-contact-details mt-4"
          />
          <label className="itp-label-input__label mt-4">
            Use custom schedule
          </label>
        </div>
      )}

      {customSchedule && (
        <>
          <span className="table-alt-text-title-description">
            If you prefer to send messages to a contact within a specific
            schedule, please select it here. Please note, this can delay
            messages from sending and may take longer to send messages to all
            contacts in this campaign
          </span>

          {days.map((day) => (
            <div key={day} className="d-flex gap-1 align-items-center">
              <input
                type="checkbox"
                checked={form.schedule_days[day]}
                onChange={() => handleChangeCheckbox(day)}
                className="form-check-input itp-custom-checkbox-contact-details mt-4"
              />
              <label className="itp-label-input__label mt-4">
                {capitalizeWords(day)}
              </label>
            </div>
          ))}

          <div className="d-flex gap-1 align-items-center">
            <input
              type="checkbox"
              checked={form.determine_time_zone}
              onChange={() => handleChangeCheckbox("determine_time_zone")}
              className="form-check-input itp-custom-checkbox-contact-details mt-4"
            />
            <label className="itp-label-input__label mt-4">
              Determine User Time Zone by number
            </label>
          </div>
          <span className="table-alt-text-title-description cursor-help">
            Enabling this will attempt to determine the users time zone by their
            area code and will send the message at the times specified.
          </span>

          <label className="itp-label-input__label mt-4">Set Time</label>
          <Row>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
              <div className="container-date-range justify-content-around">
                <div className="d-flex gap-1">
                  <label className="date-range-label">From:</label>
                  <input
                    type="time"
                    className="date-range-input bgc-transparent"
                    name="schedule_start_time"
                    value={form.schedule_start_time}
                    onChange={handleChangeTime}
                  />
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="date-range-separator"
                  />
                </div>
                <div style={{ marginRight: "1rem" }} className="d-flex">
                  <label className="date-range-label">To:</label>
                  <input
                    type="time"
                    className="date-range-input bgc-transparent"
                    name="schedule_end_time"
                    value={form.schedule_end_time}
                    onChange={handleChangeTime}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Step4;
