import React from "react";
import { Row, Col } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import AsyncSelect from "react-select/async";
import petitionGet from "../../../../../../services/petitionGet";
import "react-phone-input-2/lib/style.css";
import "./Manually.css";

const Manually = ({
  form,
  setForm,
  valueContactList,
  setValueContactList,
  onChangeForm,
  errorEmail,
  error,
  setError,
}) => {
  const loadOptions = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        if (!valueContactList) {
          setForm({ ...form, contact_list_id: result.result[0].pk })
          setValueContactList(result.result[0])
        }
        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">First Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add a name of contact"
            className="form-control-itp itp-label-input__input"
            value={form.firstname}
            onChange={onChangeForm}
            name="firstname"
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Last Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add a name of contact"
            className="form-control-itp itp-label-input__input"
            value={form.lastname}
            onChange={onChangeForm}
            name="lastname"
          />
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">E-mail</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter email address"
            className="form-control-itp itp-label-input__input"
            value={form.email}
            onChange={onChangeForm}
            name="email"
          />
        </Col>
      </Row>
       {errorEmail && (
            <p style={{ marginTop: "-12px" }} className="text-danger">Invalid Email Address</p>
       )}
      <label className="itp-add-contact-manually-label">Phone Number</label>
      <PhoneInput
        // disableCountryFlags={true}
        // enableSearch={true}
        // countryCodeEditable={false}
        // country={"us"}
        // value={form.phone}
        // className="itp-add-contact-phone-input"
        // onChange={(e) => {
        //   setError("")
        //   setForm({ ...form, phone: e });
        // }}

        enableSearch={true}
        country={"us"}
        value={form.phone}
        onChange={(e) => {
          setError("")
          setForm({ ...form, phone: e });
        }}
      />

      
      <div className="spacer-10"></div>

      <label className="itp-add-contact-manually-label">Contact List</label>
      <AsyncSelect
        onChange={(e) => {
          setError("")
          setForm({ ...form, contact_list_id: e.value });
          setValueContactList(e);
        }}
        loadOptions={loadOptions}
        className="basic-single itp-add-contact-manually-select"
        classNamePrefix="select"
        value={valueContactList}
        isSearchable={true}
        name="contact_list_id"
        placeholder="Select Option"
        defaultOptions
        cacheOptions
      />

      <div className="spacer-10"></div>

      {error && <p className="text-danger mt-2">{error}</p>}
    </>
  );
};

export default Manually;
