import { createFormData } from "../../utils";
import petitionPost from "../../../../../../services/petitionPost";

const sendMessageService = async (body, file, number, toNumber) => {
  try {
    const formData = createFormData(body, file, number, toNumber);
    return petitionPost('sendSms', { data: formData, number });
  } catch (error) {
    console.log(error)
  }
}

export default sendMessageService;