import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton, MenuRight, CustomTableMain, AddButton, CollapsePanel } from "../../../../../components";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import SettingsIcon from "@mui/icons-material/Settings";
import "../../WorkspaceSettings.css";

const FeatureCodes = ({ loading, setCompletedRequests, openMenu, setOpenMenu }) => {
  const featuresCallflow = useRef({
    intercomCode: null,
    parkCode: null,
    parkPick: null,
  });

  const dataFeatureCodes = useRef(null);

  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [errorSwitch, setErrorSwitch] = useState(false);
  const [showEditCallPickup, setShowEditCallPickup] = useState(false);
  const [parkingSlot, setParkingSlot] = useState("5");
  const [timeout, setTimeout] = useState(null);
  const [dataTableCallPickup, setDataTableCallPickup] = useState(null);
  const [loadingCallPickup, setLoadingCallPickup] = useState(false);
  const [info, setInfo] = useState(null);
  
  const [dataTableFeatureCode, setDataTableFeatureCode] = useState({
    columns: [
      { name: "Feature Code", key: "feature_code" },
      { name: "Name", key: "name" },
      { name: "Description", key: "description" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const closeMenu = () => {
    setShowEditCallPickup(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const onHandleSave = async () => {
    if (!loadingSwitch && !loading) {
      try {
        setLoadingSwitch(true);

        if (
          featuresCallflow.current.intercomCode.length > 0 &&
          !dataFeatureCodes.current[0].switch
        )
          await petitionDelete("callFlow", {
            callflow_id: featuresCallflow.current.intercomCode[0].pk,
          }).catch((err) => {
            throw err;
          });
        else if (
          !featuresCallflow.current.intercomCode.length > 0 &&
          dataFeatureCodes.current[0].switch
        ) {
          await petitionPost("callflow", {
            data: {
              callflow_object: {
                type: "featureCode",
                flow: {
                  children: {},
                  data: { action: "compose" },
                  module: "intercom",
                },
                featurecode: { name: "intercom", number: "0" },
                patterns: ["^\\*0([0-9]*)$"],
                metadata: { itpvoice: { name: "intercom" } },
              },
              type: "featureCode",
              numbers: [],
            },
          }).catch((error) => {
            throw error;
          });
        }

        if (
          featuresCallflow.current.parkCode.length > 0 &&
          !dataFeatureCodes.current[1].switch
        ) {
          await petitionDelete("callFlow", {
            callflow_id: featuresCallflow.current.parkCode[0].pk,
          }).catch((err) => {
            throw err;
          });
          if (featuresCallflow.current.parkPick.length > 0)
            featuresCallflow.current.parkPick.map((element) =>
              petitionDelete("callFlow", { callflow_id: element.pk }).catch(
                (err) => {
                  throw err;
                }
              )
            );
        } else if (
          !featuresCallflow.current.parkCode.length > 0 &&
          dataFeatureCodes.current[1].switch
        ) {
          await petitionPost("callflow", {
            data: {
              callflow_object: {
                flow: {
                  children: {},
                  data: {
                    action: "park",
                    timeout: "120",
                  },
                  module: "callparking",
                },
                featurecode: { name: "park_call", number: "70" },
                type: "featureCode",
              },
              type: "featureCode",

              numbers: ["70"],
            },
          }).catch((err) => {
            throw err;
          });

          let objectDataCallflow = (slot) => {
            return {
              data: {
                callflow_object: {
                  flow: {
                    data: {
                      action: "pickup",
                      slot_number: `${slot}`,
                    },
                    module: "callparking",
                    children: {},
                  },
                  featurecode: { name: "park_pickup", number: "70" },
                  type: "featureCode",
                },
                type: "featureCode",
                numbers: [`${slot}`],
              },
            };
          };

          await petitionPost("callparking", {
            data: { slots: [71, 72, 73, 74, 75] },
          }).catch((err) => {
            throw err;
          });

          await petitionPost("callflow", objectDataCallflow("71")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("72")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("73")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("74")).catch(
            (err) => {
              throw err;
            }
          );
          await petitionPost("callflow", objectDataCallflow("75")).catch(
            (err) => {
              throw err;
            }
          );
        }

        let allPetitionFeatureCodes = [];

        dataFeatureCodes.current.forEach((element) => {
          if (element.updateEnable) {
            allPetitionFeatureCodes.push(
              petitionPatch("callFlow", {
                callflow_id: element.pk,
                data: {
                  callflow_object: {
                    flow: element.flow,
                    enabled: element.switch,
                  },
                  numbers: element.numbers,
                  name: element.name || "",
                },
              })
            );
          }
        });

        Promise.all(allPetitionFeatureCodes)
          .then(() => {
            petitionFeatureCode();
          })
          .catch((err) => {
            setErrorSwitch(true);
            setTimeout(() => {
              setErrorSwitch(false);
              setLoadingSwitch(false);
            }, 3000);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        setErrorSwitch(true);
        setTimeout(() => {
          setErrorSwitch(false);
          setLoadingSwitch(false);
        }, 3000);
      }
    }
  };

  const handleSwitch = (e, dataFeatures, parkPick) => {
    let newData = [];
    dataFeatures.forEach((element) => {
      newData.push({
        ...element,
        switch: element.pk === e.pk ? !element.switch : element.switch,
      });
    });

    dataFeatureCodes.current = newData;
    setDataTableFeatureCode({
      columns: [
        { name: "Feature Code", key: "feature_code" },
        { name: "Name", key: "name" },
        { name: "Description", key: "description" },
      ],
      content: [...newData],
      actions: {
        title: "Enabled",
        isSwitch: true,
        content: [
          {
            switch: true,
            keySwitch: "switch",
            handleClick: (e) => {
              handleSwitch(e, newData, parkPick);
            },
          },
          {
            component: (e) => {
              if (e.icon && e.switch && parkPick?.length > 0)
                return (
                  <div
                    onClick={() => {
                      console.log({
                        ...e,
                        featureCodeTimeout: featuresCallflow.current.parkCode,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                    className=" mx-2 h-100 d-flex align-items-center"
                  >
                    <SettingsIcon />{" "}
                  </div>
                );
            },
            type: "component",
          },
        ],
      },
    });
    forceUpdate();
  };

  const activeEditCallPickupMenu = (e) => {
    if (featuresCallflow.current.parkPick && e) {
      setDataTableCallPickup(null);
      let codeList = [];
      setTimeout(featuresCallflow.current.parkCode[0].flow.data.timeout);
      featuresCallflow.current.parkPick.forEach((element) => {
        codeList.push({ code: element.numbers[0] });
      });
      setDataTableCallPickup({
        columns: [{ name: "Parking Slots", key: "code" }],
        content: codeList,
      });
      setParkingSlot(featuresCallflow.current.parkPick.length);

      setShowEditCallPickup(true);
      setInfo(e);
      setOpenMenu(3);
    }
  };

  const updateParkPickup = () => {
    setLoadingCallPickup(true);

    let slotsObject = [];
    for (let i = 1; i <= parseInt(parkingSlot); i++) {
      slotsObject.push(70 + i);
    }

    petitionPatch("callFlow", {
      data: {
        callflow_object: {
          flow: {
            children: {},
            data: {
              action: "park",
              timeout: timeout,
            },
            module: "callparking",
          },
          featurecode: { name: "park_call", number: "70" },
          type: "featureCode",
        },
        type: "featureCode",

        numbers: ["70"],
      },
      callflow_id: featuresCallflow.current.parkCode[0].pk,
    }).catch((err) => {
      throw err;
    });

    if (featuresCallflow.current.parkPick.length > 0) {
      let allDelete = [];

      featuresCallflow.current.parkPick.map((element) =>
        allDelete.push(
          petitionDelete("callFlow", { callflow_id: element.pk }).catch(
            (err) => {
              console.log(err);
            }
          )
        )
      );

      Promise.all(allDelete)
        .then((result) => {
          let objectDataCallflow = (slot) => {
            return {
              data: {
                callflow_object: {
                  flow: {
                    data: {
                      action: "pickup",
                      slot_number: `${slot}`,
                    },
                    module: "callparking",
                    children: {},
                  },
                  type: "featureCode",
                  featurecode: { name: "park_pickup", number: "70" },
                },
                type: "featureCode",

                numbers: [`${slot}`],
              },
              callflow_id: featuresCallflow.current.parkPick[0].pk,
            };
          };

          let updatearray = [];

          slotsObject.map((element) => {
            updatearray.push(
              petitionPost("callflow", objectDataCallflow(element))
            );
          });
          Promise.all(updatearray)
            .then((result) => {
              petitionPost("callparking", { data: { slots: slotsObject } })
                .then((result) => {
                  petitionFeatureCode();
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const petitionFeatureCode = () => {    
    petitionGet("allCallflows")
    .then(({ data: result }) => {
      const findIntercomCode = result.result.filter(
        (element) => element.featurecode?.name === "intercom"
      );

      const findParkCode = result.result.filter(
        (element) => element.featurecode?.name === "park_call"
      );

      const findParkPickCode = result.result.filter(
        (element) => element.featurecode?.name === "park_pickup"
      );

      const saveAllCallflowsFeactures = result.result.filter(
        (element) =>
          element.type === "featureCode" &&
          element.featurecode?.name !== "intercom" &&
          element.featurecode?.name !== "park_pickup" &&
          element.featurecode?.name !== "park_call"
      );

      let dataFeatureCodesIntern = [
        {
          pk: 0,
          switch: findIntercomCode.length > 0 ? true : false,
          feature_code: "*0",
          name: "Intercom",
          description:
            "Allows for calling an extension and automatically answering the call",
        },
        {
          pk: 1,
          icon: true,
          switch: findParkCode.length > 0 ? true : false,
          feature_code: "*70",
          name: "Park Call",
          description:
            "Place a call in one of the available parking slots automatically",
        },
      ];

      saveAllCallflowsFeactures.forEach((element) => {
        dataFeatureCodesIntern.push({
          ...element,
          feature_code: element.numbers,
          description: "Feature Code",
          switch: element.enabled ? true : false,
          updateEnable: true,
        });
      });

      setDataTableFeatureCode({
        columns: [
          { name: "Feature Code", key: "feature_code" },
          { name: "Name", key: "name" },
          { name: "Description", key: "description" },
        ],
        content: [...dataFeatureCodesIntern],
        actions: {
          title: "Enabled",
          isSwitch: true,
          content: [
            {
              switch: true,
              keySwitch: "switch",
              handleClick: (e) => {
                handleSwitch(e, dataFeatureCodesIntern, findParkPickCode);
              },
              type: "",
            },
            {
              component: (e) => {
                if (e.icon && e.switch && findParkPickCode.length > 0)
                  return (
                    <div
                      onClick={() => {
                        activeEditCallPickupMenu({
                          ...e,
                          featureCodeTimeout:
                            featuresCallflow.current.parkCode,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                      className=" mx-2 h-100 d-flex align-items-center"
                    >
                      <SettingsIcon />{" "}
                    </div>
                  );
              },
              type: "component",
            },
          ],
        },
      });

      featuresCallflow.current = {
        intercomCode: findIntercomCode,
        parkCode: findParkCode,
        parkPick: findParkPickCode,
      };

      dataFeatureCodes.current = dataFeatureCodesIntern;

      setLoadingSwitch(false);
      setLoadingCallPickup(false);
      setCompletedRequests(prevStata => prevStata + 1)
      closeMenu();
      forceUpdate();
    })
    .catch((error) => console.log(error))
  }

  useEffect(() => {
    petitionFeatureCode();
  }, []);

  return (
    <>
      <MenuRight
        show={openMenu === 3}
        closeMenu={closeMenu}
        title={`${!info ? "Add" : "Edit"} Feature Code`}
        to=""
        contentStyles={{ overflow: "hidden auto" }}
      >
        {openMenu === 3 && showEditCallPickup && (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Park Timeout</label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add Park Timeout"
                  className="form-control-itp itp-label-input__input"
                  name="name"
                  value={timeout}
                  onChange={(e) => {
                    setTimeout(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="itp-container-label-select">
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label itp-analytics-label-select">
                  Parking Slots
                </label>
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                xxl={6}
                className="itp-label-input-col"
              >
                <select
                  className="form-select-itp"
                  onChange={(e) => {
                    setParkingSlot(e.target.value);
                  }}
                  name="selectedReport"
                  value={parkingSlot}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                </select>
              </Col>
            </Row>
            <div style={{ width: "100%", marginLeft: "" }}>
              {dataTableCallPickup && (
                <CustomTableMain data={dataTableCallPickup} />
              )}
            </div>
            <button
              onClick={updateParkPickup}
              className="btn-primary itp-lead-detail-button-save loading mb-4"
              disabled={!parkingSlot || !timeout || loadingCallPickup}
            >
              {loadingCallPickup && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>

      <br></br>
      <div className="mt-1">
        <Row>
          <Col style={{ zIndex: "-1" }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="featureCode"
        title="Feature Codes"
        subtitle="Special codes to dial for features"
      >
        <CustomTableMain 
          data={dataTableFeatureCode}
          loading={loading}
          m0={true}
          module="Feature Codes"
          actionsRight={[
            {
              component: (
                <AddButton 
                  handleClick={onHandleSave}
                  name="Save"
                  notIcon={true}
                />
              )
            }
          ]}
        />
      </CollapsePanel>

      <div className={`itp-general-settings-update-alert ${loadingSwitch ? "open" : "hidden"}`}>
        <label className="itp-general-settings-update-alert-label">
          {errorSwitch ? "an error has occurred" : "Updating, please do not reload or leave the page."}
        </label>
      </div>
    </>
  );
};

export default FeatureCodes;
