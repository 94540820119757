import React from "react";

const Check = ({ element, column }) => {
  const state = element[column.key];

  const arrCheckedValues = [true, "true", "True", "enabled", "Enabled"];

  return (
    <input
      checked={arrCheckedValues.includes(state)}
      onChange={(event) => event.stopPropagation()}
      type="checkbox"
      className="form-check-input itp-custom-checkbox-contact-details"
    />
  );
};

export default Check;
