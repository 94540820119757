import getQueryString from "../../../../../utils/getQueryString";

const getFilterModal = (pagination, form, myExtension) => {
  const paginationTemp = {
    limit: pagination.current.limit,
    offset: 0,
  };

  if (!form.owner_id) {
    delete form.owner_id;
  } else {
    form.owner_id = myExtension.current?.pk;
  }

  const filterModal = getQueryString(form).replaceAll("?", "&");
  const parameter = getQueryString(paginationTemp) + filterModal;

  return { filterModal, parameter, paginationTemp }
};

export default getFilterModal;
