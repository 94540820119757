import petitionGet from "../../../../../../services/petitionGet";

const fetchAccountDetails = async (setAccountDetails) => {
  const { data: result } = await petitionGet("accountDetails")
  result.result = {
    ...result.result,
    voice_account: {
      realm: result.result.realm,
    },
  };

  setAccountDetails(result.result)
}

export default fetchAccountDetails;