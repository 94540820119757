import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlignedRow } from "../index";
import apiKey from "../../assets/images/apiKey.svg";
import informationBlue from "../../assets/icons/information-blue.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Skeleton from "react-loading-skeleton";

const StartComponent = ({
  componentTitle,
  alertText,
  cardTitle,
  cardSubtitle,
  buttonName,
  onClickFunction,
  mode,
  noPlusIconButton,
  cardTitleMode,
  alertTextMode,
  icon,
  loading,
  noCard,
}) => {
  //los tipos de varianter son default, primary, success y danger
  const variant = mode || "default";
  const cardTitleVariant = cardTitleMode || "";
  const alertTextVariant = alertTextMode || ""

  return (
    <div id="startComponent">
      {componentTitle && (
        <AlignedRow>
          <h3 className="itp-accordion-header-title mb-5">{componentTitle || ""}</h3>
        </AlignedRow>
      )}

      {!noCard && (
        <AlignedRow>
              <Card>
                <CardBody>
                  <Row className={`itp-alert-text alert-background-${loading ? "" : (alertTextVariant || variant)} d-flex align-items-center mb-4`}>
                    <Col md={12} className={loading ? "" : "d-flex justify-content-start"}>
                      {loading ? (
                        <Skeleton borderRadius="1.5rem" height="2.5rem" width="100%" />
                      ) : (
                        <div className="d-flex alig-items-center">
                          {variant && variant !== "default" && (
                            <ErrorOutlineIcon className={`itp-alert-icon-${variant}`} />
                          )}
      
                          {(!variant || variant === "default") && (
                            <div className="itp-alert-icon-default">
                              <img  src={informationBlue} alt="myExtensionTemp-icon" />
                            </div>
                          )}
      
                          <div className={`itp-alert-data-${variant}`}>
                            {alertText || ""}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row className="itp-api-key__add-key-row">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                    <Col
                      style={{ marginRight: variant === "success" && !icon ? "-9rem" : "" }}
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                    >
                      {variant !== "success" && !icon && <img src={apiKey} alt="" />}
                          
                      {icon && <>{icon}</>}
      
                      {variant && variant === "success" && !icon && (
                        <CheckCircleOutlineIcon
                          style={{
                            width: "6rem",
                            height: "6rem",
                            color: "rgba(0, 170, 0, 0.8)",
                          }}
                        />
                      )}
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                      {!loading && (
                        <>
                          <h6 className={`itp-api-key__add-title-${cardTitleVariant ? cardTitleVariant : variant}`}>
                            {cardTitle || ""}
                          </h6>
      
                          <p className={`itp-api-key__add-text-${variant}`}>
                            {cardSubtitle || ""}
                          </p>
      
                          {buttonName && (
                            <button
                              onClick={onClickFunction}
                              className="btn-primary itp-add-button"
                              style={{ minWidth: "10rem" }}
                            >
                              {!noPlusIconButton && (
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  className="itp-icon-plus"
                                />
                              )}
                              {buttonName || ""}
                            </button>
                          )}
                        </>
                      )}
      
                      {loading && (
                        <>
                          <Skeleton height="1.3rem" width="8rem" />
                          <Skeleton height="1.5rem" width="100%" />
                        </>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
        </AlignedRow>
      )}

      {noCard && (
        <>
          <Row className={`itp-alert-text alert-background-${loading ? "" : (alertTextVariant || variant)} d-flex align-items-center mb-4`}>
            <Col md={12} className={loading ? "" : "d-flex justify-content-start"}>
              {loading ? (
                <Skeleton borderRadius="1.5rem" height="2.5rem" width="100%" />
              ) : (
                <div className="d-flex alig-items-center">
                  {variant && variant !== "default" && (
                    <ErrorOutlineIcon className={`itp-alert-icon-${variant}`} />
                  )}

                  {(!variant || variant === "default") && (
                    <div className="itp-alert-icon-default">
                      <img  src={informationBlue} alt="myExtensionTemp-icon" />
                    </div>
                  )}

                  <div className={`itp-alert-data-${variant}`}>
                    {alertText || ""}
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row className="itp-api-key__add-key-row">
            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
            <Col
              style={{ marginRight: variant === "success" && !icon ? "-9rem" : "" }}
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              xxl={4}
            >
              {variant !== "success" && !icon && <img src={apiKey} alt="" />}
                    
              {icon && <>{icon}</>}

              {variant && variant === "success" && !icon && (
                 <CheckCircleOutlineIcon
                   style={{
                     width: "6rem",
                     height: "6rem",
                     color: "rgba(0, 170, 0, 0.8)",
                   }}
                 />
             )}
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
              {!loading && (
                <>
                  <h6 className={`itp-api-key__add-title-${cardTitleVariant ? cardTitleVariant : variant}`}>
                      {cardTitle || ""}
                  </h6>

                  <p className={`itp-api-key__add-text-${variant}`}>
                      {cardSubtitle || ""}
                  </p>

                  {buttonName && (
                    <button
                        onClick={onClickFunction}
                        className="btn-primary itp-add-button"
                        style={{ minWidth: "10rem" }}
                      >
                        {!noPlusIconButton && (
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="itp-icon-plus"
                          />
                        )}
                        {buttonName || ""}
                    </button>
                  )}
                </>
              )}

              {loading && (
                <>
                  <Skeleton height="1.3rem" width="8rem" />
                  <Skeleton height="1.5rem" width="100%" />
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default StartComponent;
