import petitionDelete from "../../../../../../services/petitionDelete";

const deleteDateTimeService = async (setLoadingDateTime, info, reloadData) => {
  try {
    setLoadingDateTime(true);

    await petitionDelete("timeRule", { time_id: info.pk })

    setLoadingDateTime(false);
    reloadData(true);
  } catch (error) {
    console.log(error)
  }
}

export default deleteDateTimeService;