import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import infoIcon from "../../../../../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

const CallerId = ({ 
  switchs, 
  handleChangeSwitchCallerID, 
  handleChange, 
  form, 
  numbersCallerId, 
  handleBlurInputs, 
  loading,
 }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Caller ID"
      subtitle="Adjust your outbound caller ID settings when placing calls"
    >
      <span className="itp-my-settings-card-title">
        Override default Caller ID{" "}
      </span>

      <Row className="itp-my-settings-card-help-text">
        <Col md={10} className="d-flex">
          <div>
            <img src={infoIcon} alt="myExtensionTemp-icon" />
          </div>
          <div className="ms-3">
            <p>
              Enabling this option will force your devices to use a specific
              phone number when placing a call
            </p>
          </div>
        </Col>

        <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
          {loading ? (
            <Skeleton width="5rem" height="2rem" />
          ) : (
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.switchOverrideDefaultCallerID}
                onChange={() => handleChangeSwitchCallerID()}
              />
            </div>
          )}
        </Col>
      </Row>

      {switchs.switchOverrideDefaultCallerID && !loading && (
        <>
          <span className="itp-my-settings-card-title">
            Caller ID Number:
          </span>

          <Row className="itp-my-settings-card-help-text">
            <Col md={7} className="d-flex">
              <div>
                <img src={infoIcon} alt="myExtensionTemp-icon" />
              </div>
              <div className="ms-3">
                <p>Select the number to use for outbound calls</p>
              </div>
            </Col>

            <Col md={5}>
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Select
                  onChange={(e) => handleChange(e, true, "user")}
                  className="basic-single"
                  classNamePrefix="select"
                  value={form.default_outbound_callerid_number}
                  isSearchable={true}
                  name="default_outbound_callerid_number"
                  options={numbersCallerId}
                />
              </div>
            </Col>
          </Row>

          <span className="itp-my-settings-card-title">Caller ID Name:</span>

          <Row className="itp-my-settings-card-help-text">
            <Col md={9} className="d-flex">
              <div>
                <img src={infoIcon} alt="myExtensionTemp-icon" />
              </div>
              <div className="ms-3">
                <p>Type the name that appears when placing outbound calls</p>
              </div>
            </Col>

            <Col md={3}>
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <input
                  type="text"
                  placeholder=""
                  className="form-control-itp"
                  name="default_outbound_callerid_name"
                  value={form.default_outbound_callerid_name}
                  onChange={handleChange}
                  onBlur={() => handleBlurInputs("default_outbound_callerid_name", "user")}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </CollapsePanel>
  </>
);

export default CallerId;
