import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import petitionPost from "../../../../../services/petitionPost";
import petitionDelete from "../../../../../services/petitionDelete";
import petitionGet from "../../../../../services/petitionGet";
import "../../MySettings.css";

const IntercomPaging = ({ myExtensionTemp, setCompletedRequests, loading }) => {
  const myExtension = useRef({});

  const [intercom, setIntercom] = useState(null);
  const [switchs, setSwitchs] = useState({ switchIntercomCalls: false });

  const handleChangeSwitchs = () => {
    setSwitchs({ switchIntercomCalls: !switchs.switchIntercomCalls });

    if (!switchs.switchIntercomCalls) {
      let data = {
        numbers: [`*80${myExtension.current.presence_id}`],
        type: "UserIntercom",
        callflow_object: {
          flow: {
            data: {
              id: `${myExtension.current.pk}`,
            },
            module: "intercom",
            children: {},
          },
        },
        enabled: true,
        owner_id: `${myExtension.current.pk}`,
      };

      petitionPost("intercom", { data })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    } else if (switchs.switchIntercomCalls) {
      petitionDelete("intercom", { pk: intercom.pk })
        .then(({ data: result }) => {})
        .catch((error) => console.log(error));
    }
  };

  const petition = () => {
    petitionGet("intercom")
      .then(({ data: result }) => {
        setIntercom(result.result);
        setSwitchs({ switchIntercomCalls: result.result ? true : false });
        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtensionTemp && Object.keys(myExtensionTemp).length) {
      myExtension.current = myExtensionTemp;
      petition();
    }
  }, [myExtensionTemp]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="intercomPaging"
        title="Intercom / Paging"
        subtitle="Intercom and Paging Settings"
      >
        <span className="itp-my-settings-card-title">Intercom Calls </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                If enabled, any intercom attemps will automatically be answered
                by your device if supported. Does not work on Web Phone
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs.switchIntercomCalls}
                  onChange={handleChangeSwitchs}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default IntercomPaging;
