import petitionPatch from "../../../../../services/petitionPatch";

const pinOrUnpinService = async (setLoading, selectAll, pin, arrayContacts, reloadData, noLoading) => {
  try {
    setLoading(true);

    let data = {}

    if (selectAll) {
      data = {
        payload: {
          pinned: pin ? true : false,
        },
        use_filters: true,
      };
    } else {
      data = {
        contacts: arrayContacts,
        payload: {
          pinned: pin ? true : false,
        },
      };
    }

    await petitionPatch("updateMultipleContacts", { data })

    setLoading(false);
    reloadData(noLoading, false);
  } catch (error) {
    console.log(error)
  }
}

export default pinOrUnpinService;