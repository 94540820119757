import { objError } from "../../const/messageError";
import petitionPatch from "../../../../../../services/petitionPatch";

const editUserService = async (key, details, field, form, setForm, currentForm, setCurrentForm, setErrorEditing, setModalError) => {
  const string = typeof form[field] === "string" ? true : false
  const object = typeof form[field] === "object" ? true : false

  if ((string && form[field] !== currentForm[field]) || (object && form[field]?.value !== currentForm[field]?.value)) {
    try {
      const data = { [field]: string ? form[field] : form[field]?.value };
      const crm_contact_id = details.crm_contact_id;
      const user_id = details.pk

      await petitionPatch(key, { crm_contact_id, user_id, data });
      setCurrentForm(form);
    } catch (error) {
      console.log(error);
      const error400 = error?.response?.status === 400 ? true : false
      const error_detailed = error?.response?.data?.error?.error_detailed?.error?.error_message
      const messageInvaliEmaildAddress = error?.response?.data?.message?.email?.includes("Not a valid email address.") ? "Not a valid email address." : ""
      const messageAlreadyExist = error?.response?.data?.error?.error_message

      const errorsToCheck = [
        { value: error_detailed },
        { value: messageInvaliEmaildAddress },
        { value: messageAlreadyExist },
        // Puedes agregar más constantes aquí en el futuro
      ];
      
      let showedError = "An error has occurred";
      
      if (error400) {
        for (const errorToCheck of errorsToCheck) {
          if (objError[errorToCheck.value]) {
            showedError = objError[errorToCheck.value];
            break;
          }
        }
      }
      setForm(currentForm);
      setErrorEditing(showedError)
      setModalError(true)
    }
  }
};

export default editUserService;
