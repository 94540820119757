// devuelve el nombre del timeGroup en base al pk que se identifique
// returns the name of the timeGroup based on the pk that is identified
const returnTimeGroup = (id, timeGroups, fatherModule, tempData) => {
  if (tempData) return "Loading information..."

  if (id === "_") {
    return "All other times";
  }

  if (fatherModule !== "timecondition") return id;

  const dataFind = timeGroups.find((element) => parseInt(element.pk) === parseInt(id));

  if (dataFind) {
    return dataFind?.name;
  } else {
    return "This Time Condition Was Removed";
  }
};

export default returnTimeGroup;
