import petitionGet from "../../../../../../services/petitionGet";

const fetchIntercomAdmin = async (pk, switchs, myExtensionTemp) => {
  const { data: result } = await petitionGet("intercomAdmin", { user_id: pk })
  const dataFind = result.result.find((element) => element.type === "UserIntercom");

  const switchOverrideDefaultCallerID = !myExtensionTemp?.default_outbound_callerid_number && !myExtensionTemp.default_outbound_callerid_name ? false : true

  const switchsTemp = {
     ...switchs,
     faxing_settings_enable_notifications: myExtensionTemp.faxing_settings_enable_notifications,
     switchIntercomCalls: dataFind ? true : false,
     call_recording_internal: myExtensionTemp?.call_recording_internal === "enabled",
     call_recording_external: myExtensionTemp?.call_recording_external === "enabled",
     callforward_enable: myExtensionTemp.callforward_enable || false,
     callforward_keep_caller_caller_id:  myExtensionTemp.callforward_keep_caller_caller_id || false,
     callforward_queue_calls: myExtensionTemp.callforward_queue_calls || false,
     callforward_call_confirmation: myExtensionTemp.callforward_call_confirmation || false,
     jitter_buffer: myExtensionTemp.jitter_buffer || false,
     switchOverrideDefaultCallerID,
  }

  return { dataFind, switchsTemp }
}

export default fetchIntercomAdmin;