import petitionPatch from "../../../../../../services/petitionPatch";

const editSwitchsCallForwardingService = async (loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true)

    const currentSwitchs = switchs

    try {
      setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });
  
      let data = {};
  
      if (switchName === "callforward_enable" && switchs.callforward_enable) {
        data = {
          callforward_enable: false,
          callforward_keep_caller_caller_id: false,
          callforward_queue_calls: false,
          callforward_call_confirmation: false,
          callforward_number: "",
        };
  
        setSwitchs({
          ...switchs,
          callforward_enable: false,
          callforward_keep_caller_caller_id: false,
          callforward_queue_calls: false,
          callforward_call_confirmation: false,
        });
      } else {
        data = { [switchName]: !switchs[switchName] };
      }
  
      await petitionPatch("user", { data, user_id: details.pk })
      setLoadPetition(false)
    } catch (error) {
      console.log(error)
      setLoadPetition(false)
      setSwitchs(currentSwitchs)
      setErrorEditing("An error has ocurred")
      setModalError(true)
    }
  }
}

export default editSwitchsCallForwardingService;