import { useState, useEffect, useMemo, useCallback } from "react";

/**
 * Hook to manage pagination in both frontend and backend.
 *
 * @param {Array} data - rows of the table to paginate.
 * @param {number} itemsPerPage - Number of items per page.
 * @param {boolean} isBackend - Indicates whether paging is handled in the backend.
 * @param {number} totalPages - Total pages, provided by the backend.
 * @param {Function} onPageChange - Function to handle page changes in the backend.
 * @param {number} offset - indicates the initial offset to know the page number.
 */
const usePagination = (
  data,
  itemsPerPage,
  isBackend = false,
  totalPages = 0,
  onPageChange = () => {},
  offset = 0,
  loading,
) => {

  const [currentPage, setCurrentPage] = useState((isBackend || loading) ? Math.floor(offset / itemsPerPage) + 1  : 1);
  const [frontendTotalPages, setFrontendTotalPages] = useState(0)
  const [currentData, setCurrentData] = useState([])
  const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    const page = (isBackend || loading) ? Math.floor(offset / itemsPerPage) + 1  : 1
    setCurrentPage(page)
  }, [offset, itemsPerPage, isBackend, loading])

  // Calculate total pages for frontend pagination
  useEffect(() => {
    if (isBackend) {
      setFrontendTotalPages(0)
    } else {
      const total = Math.ceil((loading ? 0 : data?.length) / itemsPerPage)
      setFrontendTotalPages(total)
    }
  }, [data?.length, itemsPerPage, isBackend, loading])


  // Notify page changes for backend pagination.
  useEffect(() => {
    if (isBackend) {
      const newOffset = (currentPage - 1) * itemsPerPage;
      
      if (newOffset !== offset) onPageChange(newOffset);
    }
  // }, [currentPage, itemsPerPage, isBackend, onPageChange, loading, offset]);
  }, [currentPage, isBackend]);

  // Determine the maxPage depending on the paging mode.
  useEffect(() => {
    setMaxPage(loading ? 1 : isBackend ? totalPages + 1 : frontendTotalPages)
  }, [loading, frontendTotalPages, isBackend, totalPages])

  // Change the current page.
  const handleSetCurrentPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Calculate currentData for pagination in the frontend.
  useEffect(() => {
    if (loading) {
      const fakeData = Array.from({ length: itemsPerPage }, () => ({}));
      setCurrentData(fakeData)
    } else if (!isBackend) {
      const start = (currentPage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setCurrentData(data?.slice(start, end));
    } else {
      setCurrentData(data)
    }
  }, [isBackend, currentPage, data, itemsPerPage, loading])

  return {
    currentData,
    currentPage,
    setCurrentPage: handleSetCurrentPage,
    maxPage,
  };
};

export default usePagination;
