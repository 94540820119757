import axios from "axios";
import json from "../../../../../config.json";

const deleteContactsBatchService = async (selectAll, arrayContacts, allResult, pagination) => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const url = urlBase + `/360/accounts/${id}/my-account/contacts/batch`;

  let data = {}

  if (selectAll) {
    data = {
      use_filters: true,
    };
  } else {
    data = {
      contact_ids: arrayContacts,
    };
  }

  const headers = { Authorization: "Bearer " + token.replace(/['"]+/g, "") };

  await axios.delete(url, { headers, data })

  let paginationTemp = {};

  if (allResult.result.length === 1 && pagination.current.offset > 0) {
    paginationTemp = {
      ...pagination.current,
      offset: pagination.current.offset - 10,
    };
  } else {
    paginationTemp = pagination.current;
  }

  return paginationTemp
};

export default deleteContactsBatchService;
