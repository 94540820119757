import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionGet from "../../../../../services/petitionGet";
import petitionPatch from "../../../../../services/petitionPatch";

const Voicemail = ({ myExtension, setCompletedRequests, loading }) => {
  const [sendvoicemail, setSendvoicemail] = useState({ sendvoicemail: "no" });
  const [deleteVoicemail, setDeleteVoicemail] = useState({
    deletevoicemail: "no",
  });

  const handleChangeSendVoicemail = () => {
    const currentStateSwitch = sendvoicemail;

    const data = {
      sendvoicemail: sendvoicemail.sendvoicemail === "yes" ? "no" : "yes",
    };

    setSendvoicemail(data);

    petitionPatch("voicemailUserSetings", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setSendvoicemail(currentStateSwitch);
      });
  };

  const handleChangeDeleteVoicemail = (key) => {
    const currentStateSwitch = deleteVoicemail;

    const data = {
      deletevoicemail: deleteVoicemail.deletevoicemail === "yes" ? "no" : "yes",
    };

    setDeleteVoicemail(data);

    petitionPatch("voicemailUserSetings", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setDeleteVoicemail(currentStateSwitch);
      });
  };

  const petition = () => {
    petitionGet("voicemailUserSetings")
      .then(({ data: result }) => {
        const sendVM = result?.result?.sendvoicemail === "yes" ? "yes" : "no";
        const deleteVM =
          result?.result?.deletevoicemail === "yes" ? "yes" : "no";
        setSendvoicemail({ sendvoicemail: sendVM });
        setDeleteVoicemail({ deletevoicemail: deleteVM });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length) {
      petition();
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Voicemails"
        subtitle="Send email or delete voicemail, when has been received"
      >
        <span className="itp-my-settings-card-title">Voicemail</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Send email when a voicemail is received to your direct mailbox
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={sendvoicemail.sendvoicemail === "yes" ? true : false}
                  onChange={handleChangeSendVoicemail}
                />
              </div>
            )}
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">Delete Voicemail</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Automatically delete messages when a user leaves a message from
                my mailbox.
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={
                    deleteVoicemail.deletevoicemail === "yes" ? true : false
                  }
                  onChange={handleChangeDeleteVoicemail}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default Voicemail;
