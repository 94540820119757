import React from "react";

const Select = ({ allData, element, config }) => {
  const handleChangeSelect = (e) => {
    const { value } = e.target;
    config.onChange(allData, element["row"], value);
  };

  return (
    <select
      onChange={handleChangeSelect}
      className="form-select-itp"
      defaultValue={config.defaultValue}
    >
      <option value=""> Select Option</option>
      {config.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default Select;
