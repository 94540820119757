import "./Actions.css"

const Actions = ({ actionsLeft, actionsRight, className }) => (
  <div className={`actions-container ${className || ""}`}>
    {/* Container for actions on the left */}
    <div className="actions-left">
      {/* Here you can insert actions that you always want on the left side. */}
      {actionsLeft.map((action, index) => (
        <div key={index} className="me-auto">
          {action.component}
        </div>
      ))}
    </div>

    {/* Container for actions on the right */}
    <div className="actions-right">
      {actionsRight.map((action, index) => (
        <div key={index} className="ms-auto">
          {action.component}
        </div>
      ))}
    </div>
  </div>
);

export default Actions;
