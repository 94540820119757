import petitionPatch from "../../../../../../services/petitionPatch"

const handleChangeFunnelService = async (e, number, funnelValue, setFunnelValue, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage) => {
  const currentFunnelValue = funnelValue

  try {
    setFunnelValue(e)

    const data = {
      ai_lead_manager_funnel_id: e.pk,
    }

    await petitionPatch("numberEdit", { number, data })
  } catch (error) {
    console.log(error)
    setFunnelValue(currentFunnelValue)
    setAlertType("error")
    setAlertTitle("Error")
    setAlertMessage("An error has ocured")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }
}

export default handleChangeFunnelService;