import petitionPost from "../../../../../../services/petitionPost";

const markReadService = async (chatWindowData, number, threadId) => {
  try {
    const thread = threadId || chatWindowData.current?.notIsSelf?.message_thread_id
    const data = {}
    await petitionPost("markRead", { thread, number, data })
  } catch (error) {
    console.log(error)
  }
}

export default markReadService;