import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Loading, LoadingButton, ShowDateFormated, TagComponent, RowSelect, LabelInput, LabelSelect, Notes, CustomFields } from "../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { addTagService, fetchContactListDetails, fetchUserDetails, fetchUsers, searchContactListService } from "./services";
import { formatTags, processCustomfields, returnCheck } from "./utils";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import ModalTagsColor from "../AddContact/ModalUtility/ModalTagsColor";
import "./ContactDetails.css";
 
const ContactDetails = ({ contactDetailsMenu, tagsExternal, petitionTag, customFields, editContact, loadingEdit, myExtension, setAddedCustomField, errorEditContact }) => {
  const [disabled, setDisabled] = useState(false);
  const [tags, setTags] = useState([...contactDetailsMenu.tags]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [asignedUser, setAsignedUser] = useState(null);
  const [isLoadingSelectUsers, setIsLoadingSelectUsers] = useState(false);
  const [valueContactList, setValueContactList] = useState(null);
  const [openModalColor, setOpenModalColor] = useState(false);
  const [finishFetchNotes, setFinishFetchNotes] = useState(false)
  const [finishPetition, setFinishPetition] = useState(false)
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [form, setForm] = useState({
    firstname: contactDetailsMenu.firstname,
    lastname: contactDetailsMenu.lastname || "",
    email: contactDetailsMenu.email || "",
    phone: contactDetailsMenu.phone || "",
    address: contactDetailsMenu.address || "",
    dnd_sms: contactDetailsMenu.dnd_sms || false,
  });

  const handleTag = async (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(tagsExternal);
      setTags([]);
    } else if (newValue[newValue.length - 1]?.inputValue) {
      const tag = newValue[newValue.length - 1]
      setLoading(true); 
      setDisabled(true);
      await addTagService(tag, newTags, petitionTag, setNewTags, setLoading, setDisabled)
      setTags(newValue);
    } else {
      setTags(newValue);
    }
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSaveChange = () => {
    if (loading) return;

    let data = {
      tags: formatTags(tags, newTags),
      contact_list_id: valueContactList.value,
      firstname: form.firstname,
      lastname: form.lastname,
      ...form,
      phone: form.phone.length > 0 ? `+${form.phone}` : ""
    };

    if (asignedUser) data.owner_id = asignedUser.value;

    editContact(data);
  };

  const closeModal = () => {
    setOpenModalColor(false);
  };

  const loadOptions = async (inputValue, callback) => {
    if (isInitialLoad) {
      const options = await searchContactListService(""); // Carga inicial sin filtro
      setDefaultOptions(options);
      callback(options);
      setIsInitialLoad(false);
    } else {
      const options = await searchContactListService(inputValue);
      callback(options);
    }
  };

  const petition = async () => {
    try {
      setLoading(true);

      await fetchContactListDetails(contactDetailsMenu, setValueContactList)

      if (contactDetailsMenu?.owner_id) {
        await fetchUserDetails(contactDetailsMenu, setAsignedUser)
        setFinishPetition(true)
      } else {
        setFinishPetition(true)
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (finishPetition && finishFetchNotes) {
      setLoading(false)
    }
  }, [finishFetchNotes, finishPetition])

  useEffect(() => {
    if (isLoadingSelectUsers) {
      fetchUsers(setUsers, setIsLoadingSelectUsers)
    }
  }, [isLoadingSelectUsers])

  useEffect(() => {
    processCustomfields(form, customFields, contactDetailsMenu, setForm)
  }, [contactDetailsMenu]);

  useEffect(() => {
    setOptionsTags(tagsExternal);
  }, [tagsExternal]);

  useEffect(() => {
    petition();
  }, []);

  let test = false

  return test ? (
    <Loading />
  ) : (
    <>
      {openModalColor && (
        <ModalTagsColor
          modal={openModalColor}
          closeModal={closeModal}
          title={"Add tag"}
          sendDatatoParent={handleTag}
          tagsExternal={tags}
        />
      )}

      <Row>
        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
          {!loading && <label className="itp-component-tag-label">Tag</label>}
          <TagComponent 
             disabled={disabled}
             tags={tags}
             optionsTags={optionsTags}
             handleTag={handleTag}
             loading={loading}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <div className="d-flex align-items-end h-100">
            <button 
              className="btn-primary itp-add-button" 
              onClick={() => {
                if (loading) return;
                setOpenModalColor(!openModalColor)
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add tag
            </button>
          </div>
        </Col>
      </Row>
      
      <RowSelect
        label="Contact List"
        hoverInfo="Select the Contact List this Contact belongs to"
        loading={loading}
        borderBottom={true}
      >
        <AsyncSelect
          onChange={(e) => {
            setValueContactList(e);
          }}
          className="basic-single mt-2 mb-2"
          classNamePrefix="select"
          value={valueContactList}
          isSearchable={true}
          name="contact_list_id"
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          cacheOptions
          onFocus={() => {
            if (isInitialLoad) {
              loadOptions("", () => {}); // Carga inicial en foco
            }
          }}
        />
      </RowSelect>

      <LabelInput label="First Name" loading={loading}>
        <input
          type="text"
          placeholder=": Enter contact First Name"
          className="form-control-itp itp-label-input__input"
          name="firstname"
          value={form.firstname}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="Last Name" loading={loading}>
        <input
          type="text"
          placeholder=": Enter contact Last Name"
          className="form-control-itp itp-label-input__input"
          name="lastname"
          value={form.lastname}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelInput label="E-mail" loading={loading}>
        <input
          type="text"
          placeholder=": Enter email address"
          className="form-control-itp itp-label-input__input"
          name="email"
          value={form.email}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelSelect borderBottom={true} label="Phone Number" loading={loading}>
        <PhoneInput
          enableSearch={true}
          country={"us"}
          value={form.phone}
          onChange={(e) => {
            setForm({ ...form, phone: e });
          }}
        />
      </LabelSelect>

      <LabelInput className="border-none mt-2" label="Last Activity" loading={loading}>
        <div className="d-flex gap-1 align-items-center">
          <div>{contactDetailsMenu.selectIcon}</div>
          <div>{contactDetailsMenu.selectArrow}</div>

          {contactDetailsMenu.keyDate && (
            <ShowDateFormated  date={contactDetailsMenu.keyDate} />
          )}
        </div>
      </LabelInput>

      <LabelInput label="Address" loading={loading}>
        <input
          type="text"
          placeholder=": Enter address"
          className="form-control-itp itp-label-input__input"
          name="address"
          value={form.address}
          onChange={onchangeForm}
        />
      </LabelInput>

      <LabelSelect label="Opted out (SMS)" loading={loading}>
        <input
          checked={returnCheck(form.dnd_sms) || false}
          name="dnd_sms"
          onChange={() => {
            setForm({
              ...form,
              dnd_sms: returnCheck(form.dnd_sms || false, true),
            });
          }}
          type="checkbox"
          className="form-check-input itp-custom-checkbox-contact-details"
        />
      </LabelSelect>

      <CustomFields 
        customFields={customFields}
        form={form}
        setForm={setForm}
        onchangeForm={onchangeForm}
        loading={loading}
        myExtension={myExtension}
        setAddedCustomField={setAddedCustomField}
      />

      <RowSelect
        label="Assigned User"
        hoverInfo="Select the assigned user for this contact"
        loading={loading}
        borderBottom={true}
      >
        <Select
          onChange={(e) => setAsignedUser(e)}
          className="basic-single"
          classNamePrefix="select"
          name="owner_id"
          placeholder="Select a User"
          options={users}
          isLoading={isLoadingSelectUsers}
          isSearchable={true}
          value={asignedUser}
          onMenuOpen={() => {
            if (!users.length) {
              // Descargar opciones solo si no hay opciones cargadas
              setIsLoadingSelectUsers(true);
            }
          }}
        />
      </RowSelect>

      <div className="itp-contact-details-separator"></div>

      <Notes 
        contactId={contactDetailsMenu.pk}
        setFinishFetchNotes={setFinishFetchNotes}
        loading={loading}
        myExtension={myExtension}
      />

      {errorEditContact && (
        <p className="text-danger">{errorEditContact}</p>
      )}

      <button
        onClick={onSaveChange}
        className="btn-primary itp-contact-detail-button-save loading mb-4"
      >
        {loadingEdit && <LoadingButton />}
        Save Changes
      </button>
    </>
  );
};

export default ContactDetails;
