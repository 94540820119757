const dataFilterValues = (firstLoad, myExtensionTemp, form) => {
  let inputValueAddedAfter = ""
  let inputValueAddedBeffore = ""
  let checkboxDate = false
  let inputValueAssignedLead = false
  let inputValueTags = []
  let checkboxValueTags = false
  let inputValueLeadSource = []
  let checkboxValueLeadSource = false
  let inputValueFunnel = []
  let checkboxValueFunnel = false

  if (form) {
    inputValueAddedAfter = form.added_after || ""
    inputValueAddedBeffore = form.inputValue_added_before || ""
    checkboxDate = form.added_after && form.added_before ? true : false
    inputValueAssignedLead = form.owner_id ? true : false
    inputValueTags = form.tags || []
    checkboxValueTags = form.tags ? true : false
    inputValueLeadSource = form.lead_source_id || []
    checkboxValueLeadSource = form.lead_source_id ? true : false
    inputValueFunnel = form.funnel_id || []
    checkboxValueFunnel = form.funnel_id ? true : false
  } else if (!firstLoad.current || myExtensionTemp?.crm_filters?.leads) {
    inputValueAddedAfter = myExtensionTemp?.crm_filters?.leads?.added_after || ""
    inputValueAddedBeffore = myExtensionTemp?.crm_filters?.leads?.added_before || ""
    checkboxDate = myExtensionTemp?.crm_filters?.leads?.added_after && myExtensionTemp?.crm_filters?.leads?.added_before ? true : false
    inputValueAssignedLead = myExtensionTemp?.crm_filters?.leads?.owner_id ? true : false
    inputValueTags = myExtensionTemp?.crm_filters?.leads?.tags || []
    checkboxValueTags = myExtensionTemp?.crm_filters?.leads?.tags ? true : false
    inputValueLeadSource = myExtensionTemp?.crm_filters?.leads?.lead_source_id || []
    checkboxValueLeadSource = myExtensionTemp?.crm_filters?.leads?.lead_source_id ? true : false
    inputValueFunnel = myExtensionTemp?.crm_filters?.leads?.funnel_id || []
    checkboxValueFunnel = myExtensionTemp?.crm_filters?.leads?.funnel_id ? true : false
  }

  return { 
    inputValueAddedAfter, 
    inputValueAddedBeffore, 
    checkboxDate, 
    inputValueAssignedLead, 
    inputValueTags, 
    checkboxValueTags, 
    inputValueLeadSource, 
    checkboxValueLeadSource, 
    inputValueFunnel, 
    checkboxValueFunnel, 
  }
}

const getDataFilter = (firstLoad, setDataFilter, myExtensionTemp, tagsTemp, leadSourcesTemp, funnelsTemp, onSaveFunction, form) => {
  const {
    inputValueAddedAfter, 
    inputValueAddedBeffore, 
    checkboxDate, 
    inputValueAssignedLead, 
    inputValueTags, 
    checkboxValueTags, 
    inputValueLeadSource, 
    checkboxValueLeadSource, 
    inputValueFunnel, 
    checkboxValueFunnel, 
  } = dataFilterValues(firstLoad, myExtensionTemp, form)

  const dataFilter = {
    onSaveFunction: onSaveFunction,
    onHidde: () => console.log("hidde"),
    parameters: [
      {
        title: "Date",
        inputs: [
          {
            label: "Lead Created Within",
            input: "date",
            inputValue_added_after: inputValueAddedAfter,
            inputValue_added_before: inputValueAddedBeffore,
            checkbox: true,
            checkboxValue: checkboxDate,
            checkboxName: "checkbox_date",
            key_added_after: "added_after",
            key_added_before: "added_before",
          },
        ],
      },
      {
        title: "Assigned Lead",
        inputs: [
          {
            label: "Only Show Leads Assigned to Me",
            input: "switch",
            inputValue: inputValueAssignedLead,
            key: "owner_id",
          },
        ],
      },
      {
        title: "Tags",
        inputs: [
          {
            label: "Tags Added to a Contact",
            input: "multiSelect",
            inputValue: inputValueTags,
            checkbox: true,
            checkboxValue: checkboxValueTags,
            checkboxName: `checkbox_tags`,
            options: tagsTemp,
            key: "tags",
          },
        ],
      },
      {
        title: "Lead Source",
        inputs: [
          {
            label: "Lead Assigned to a Lead Source",
            input: "selectAutoComplete",
            inputValue: inputValueLeadSource,
            checkbox: true,
            checkboxValue: checkboxValueLeadSource,
            checkboxName: `checkbox_lead_source`,
            options: leadSourcesTemp,
            key: "lead_source_id",
          },
        ],
      },
      {
        title: "Funnel",
        inputs: [
          {
            label: "Lead Assigned to a Funnel",
            input: "selectAutoComplete",
            inputValue: inputValueFunnel,
            checkbox: true,
            checkboxValue: checkboxValueFunnel,
            checkboxName: `checkbox_funnel`,
            options: funnelsTemp,
            key: "funnel_id",
          },
        ],
      },
    ],
  }

  setDataFilter(dataFilter);
};

export default getDataFilter;
