import React, { useEffect, useState } from "react";
import { Loading, LoadingButton } from "../../../../components";
import petitionGet from "../../../../services/petitionGet";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const SendToFunnel = ({ defaultContact, loadingLead, addNewLead }) => {
  const [loading, setLoading] = useState(true);
  const [leadSource, setLeadSource] = useState([]);
  const [funnels, setFunnels] = useState([]);
  const [stages, setStages] = useState([]);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);
  const [form, setForm] = useState({
    lead_source_id: null,
    funnel_id: null,
    funnel_pipeline_stage_id: null,
    contact_id: defaultContact || null,
  });

  const onChangeFunnel = (e) => {
    setForm({ ...form, funnel_id: e });

    setLoadingChangeFunnel(true);

    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        }

        setForm({ ...form, funnel_id: e, funnel_pipeline_stage_id: null });
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const loadOptions = (inputValue, callback) => {
    petitionGet("contacts", {
      parameter: `?search_query=${inputValue}`,
    })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname || ""}`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("sources", { parameter: "?offset=0&limit=1000" })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.name}`;
          element.value = element.pk;
        });

        setLeadSource(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("funnels", { parameter: "?offset=0&limit=1000" })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel.name;
          funnel.value = funnel.pk;
        });

        setFunnels(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petition();
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Contact</label>
        <AsyncSelect
          onChange={(e) => {
            setForm({ ...form, contact_id: e });
          }}
          value={form.contact_id}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          loadOptions={loadOptions}
          isSearchable={true}
          name="contact_id"
          placeholder="Choose contact"
          defaultOptions
          cacheOptions
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Lead Source</label>
        <Select
          onChange={(e) => {
            setForm({ ...form, lead_source_id: e });
          }}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={form.lead_source_id}
          isSearchable={true}
          name="lead_source_id"
          placeholder="Choose lead source"
          options={leadSource}
        />
      </div>
      <div className="add-lead-container-field">
        <label className="itp-label-input__label">Funnel</label>
        <Select
          onChange={onChangeFunnel}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={form.funnel_id}
          isSearchable={true}
          name="funnel_id"
          placeholder="Choose a funnel"
          options={funnels}
        />
      </div>
      {loadingChangeFunnel ? (
        <Loading />
      ) : (
        <>
          {form.funnel_id && (
            <>
              {stages.length > 0 ? (
                <div className="add-lead-container-field">
                  <label className="itp-label-input__label">Funnel Stage</label>
                  <Select
                    onChange={(e) => {
                      setForm({ ...form, funnel_pipeline_stage_id: e });
                    }}
                    className="basic-single add-lead-select"
                    classNamePrefix="select"
                    value={form.funnel_pipeline_stage_id}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Choose funnel stage"
                    options={stages}
                  />
                </div>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}

      <button
        className="btn-primary loading add-lead-new-contact"
        disabled={
          !form.lead_source_id ||
          !form.funnel_id ||
          !form.funnel_pipeline_stage_id ||
          !form.contact_id
        }
        onClick={() => addNewLead(form)}
      >
        {loadingLead && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default SendToFunnel;
