import petitionGet from "../../../../services/petitionGet";

const fetchCustomFields = async (customFields) => {
 const { data: result } = await petitionGet("customFields")

 result.result.forEach((element) => {
    element.label = element.name;
    element.value = element.pk;
  });

  customFields.current = result.result;
}

export default fetchCustomFields;