import petitionGet from "../../../../../services/petitionGet";

const fetchLeads = async (parameter, setAllResult) => {
  const { data: result } = await petitionGet("leads", { parameter });
  setAllResult(result)

  return result.result;
};

export default fetchLeads;
