import React from "react";
import { Loading } from "../../../../../../../components";
import { states } from "../../../../../../../const/states";
import Select from "react-select";

const Step2 = ({
  listOfNumbers,
  selectedNumbers,
  setSelectedNumbers,
  filterNumbers,
  prefix,
  npa,
  npaNxx,
  cityState,
  loading,
}) => {
  const handleChangeNumbers = (e) => {
    e.forEach((element) => {
      if (filterNumbers.category === "tool") {
        element.type = "Toll";
        element.code = prefix.code;
        element.number = element.value;
      } else {
        switch (filterNumbers.type) {
          case "NPA":
            element.type = filterNumbers.type;
            element.code = npa.code;
            element.number = element.value;
            break;

          case "NPANXX":
            element.type = filterNumbers.type;
            element.code = `${npaNxx.npa}${npaNxx.nxx}`;
            element.number = element.value;
            break;

          case "City/State":
            const stateInfo = states.find(
              (state) => state.shortcode === cityState.state
            );
            const newLetter = stateInfo.name[0].toUpperCase();

            element.type = filterNumbers.type;
            element.code = `${
              cityState.city
            } - US/${newLetter}${stateInfo.name.slice(1)}`;
            element.number = element.value;
            break;

          default:
            break;
        }
      }
    });

    setSelectedNumbers(e);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      <label className="itp-label-input__label mb-0 mt-4">Numbers</label>
      <div className="w-100 mt-2">
        <Select
          isMulti
          onChange={(e) => handleChangeNumbers(e)}
          className="basic-single"
          classNamePrefix="select"
          value={selectedNumbers}
          isSearchable={true}
          name="contact_list_id"
          placeholder="Select Option"
          options={listOfNumbers}
        />
      </div>
    </>
  );
};

export default Step2;
