import axios from "axios";
import json from "../../../../../../config.json"

const resetPasswordService = async (setLoadingUser, info, closeModal, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage) => {
  try {
    setLoadingUser(true)

    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const url = urlBase + `/crm/auth/reset-pw?username=${info.email}&from=voice360`

    await axios.get(url);

    closeModal();
    setLoadingUser(false);

    setAlertType("success")
    setAlertTitle("Success")
    setAlertMessage("A link to reset your password has been sent to your email. Please check your inbox or spam folders")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  } catch (error) {
    console.log(error)
    closeModal();
    setLoadingUser(false);

    setAlertType("error")
    setAlertTitle("Error")
    setAlertMessage("An error has ocurred")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }
}

export default resetPasswordService;