import petitionPost from "../../../../../../services/petitionPost";

const addTagService = async (tag, newTags, petitionTag, setNewTags, setLoading, setDisabled) => {
  try {
    const data = {
      name: tag.inputValue,
      tag_color: tag?.tag_color || "",
    }

    const { data: result } = await petitionPost("addTags", { data })
    const newTag = result.result
    const newArrayTags = [...newTags, { ...newTag }]
    petitionTag();
    setDisabled(false)
    setNewTags(newArrayTags);
  } catch (error) {
    console.log(error)
  }
  setLoading(false);
}

export default addTagService;