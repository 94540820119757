const getNotIsSelfContactPks = (selectedPks, chatList) => {
  // We filter the chats whose pk are in the selected array
  const selectedChats = chatList.filter(chat => selectedPks.includes(chat.pk));
  
  // We map the notIsSelf.contact.pk fields of each selected chat
  const contactPks = selectedChats.map(chat => chat.notIsSelf?.contact?.pk).filter(pk => pk !== undefined);
  
  return contactPks;
};

export default getNotIsSelfContactPks;