import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { timeZones } from "../../../../../const/timeZones";
import { CollapsePanel } from "../../../../../components";
import petitionGet from "../../../../../services/petitionGet";
import petitionPatch from "../../../../../services/petitionPatch";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import Select from "react-select";
import Switch from "react-switch";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import Skeleton from "react-loading-skeleton";

const GeneralForwardingID = ({ loading, setCompletedRequests }) => {
  //general settings
  const [valueTimeZone, setValueTimeZone] = useState(null);
  const [musicOnHold, setMusicOnHold] = useState([]);
  const [valueMusicOnHold, setValueMusicOnHold] = useState(null);
  const [switchB2B, setSwitchB2B] = useState(false);

  //caller id
  const [formName, setFormName] = useState({ name: "" });
  const [allNumbers, setAllNumbers] = useState([]);
  const [valueNumber, seValueNumber] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  // caller id functions
  const onChangeNumberCallerId = (e) => {
    seValueNumber(e);

    let data = {
      default_outbound_callerid_number: e.value,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const onChangeName = (e) => {
    setFormName({ ...formName, [e.target.name]: e.target.value });
  };

  const handleChangeswitch = () => {
    setSwitchB2B(!switchB2B);

    const data = {
      accounts_b2b_enabled: !switchB2B,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!firstLoad) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name: formName.name,
        };

        petitionPatch("accountDetails", { data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formName.name]);

  //-------------------------------------------------

  // general settings functions
  const handleChangeMusicOnHold = (e) => {
    setValueMusicOnHold(e);

    const data = {
      music_on_hold_id: e.value,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const handleChangeTimeZone = (e) => {
    setValueTimeZone(e);

    const data = {
      time_zone: e.value,
    };

    petitionPatch("accountDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const petitionGeneralSettings = async () => {
    let musicOnHoldTemp = [];
    let tempDefaultOutboundNumber = null;

    await petitionGet("musicOnHold")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        musicOnHoldTemp = result.result;
        setMusicOnHold(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("accountDetails")
      .then(({ data: result }) => {
        //caller id
        if (result.result.default_outbound_callerid_number) {
          tempDefaultOutboundNumber =
            result.result.default_outbound_callerid_number;
          seValueNumber({
            name: result.result.default_outbound_callerid_number,
            value: result.result.default_outbound_callerid_number,
            label: formatPhoneNumber(result.result.default_outbound_callerid_number),
          });
        }

        if (result.result.default_outbound_callerid_name) {
          setFormName({ name: result.result.default_outbound_callerid_name });
        }
        //----------------------------

        const defaultTimeZone = timeZones.find(
          (element) => element.value === result.result.time_zone
        );
        const defaultMusicOnHold = musicOnHoldTemp.find(
          (element) => element.pk === result.result.moh_media_id
        );

        if (defaultTimeZone) {
          setValueTimeZone(defaultTimeZone);
        }

        if (defaultMusicOnHold) {
          setValueMusicOnHold(defaultMusicOnHold);
        }

        setSwitchB2B(result.result.accounts_b2b_enabled);
      })
      .catch((error) => console.log(error));

    await petitionGet("viewNumbers")
      .then(({ data: result }) => {
        let preAllData = [];

        result.result.forEach((element) => {
          preAllData.push({
            name: element.number,
            value: element.number,
            label: formatPhoneNumber(element.number),
          });
        });

        let findUsedNumber = result.result.find(
          (element) => element.number === tempDefaultOutboundNumber
        );

        if (!findUsedNumber)
          preAllData.push({
            name: tempDefaultOutboundNumber,
            value: tempDefaultOutboundNumber,
            label: formatPhoneNumber(tempDefaultOutboundNumber),
          });

        preAllData.push({ name: "Empty", value: "", label: "Empty" });

        setAllNumbers(preAllData);
        setFirstLoad(false);
        setCompletedRequests(prevStata => prevStata + 1)
      })
      .catch((error) => console.log(error));
  };
  //-----------------------------------------------

  useEffect(() => {
    petitionGeneralSettings();
  }, []);

  return (
    <>
      <CollapsePanel
        id="generalSettings"
        title="General Settings"
        subtitle="General settings that affect your entire workspace"
      >
        <span className="itp-my-settings-card-title">
          Time Zone
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={8} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtensionTemp-icon" />
            </div>
            <div className="ms-3">
              <p>Used to determine TimeZone in Callflows or workflows</p>
            </div>
          </Col>

          <Col md={4}>
            {loading ? (
              <Skeleton height="2rem" width="100%" />
            ) : (
              <Select
                onChange={(e) => handleChangeTimeZone(e)}
                className="basic-single"
                classNamePrefix="select"
                value={valueTimeZone}
                isSearchable={true}
                name="time_zone"
                options={timeZones}
              />
            )}
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">
          Music On Hold
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={8} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtensionTemp-icon" />
            </div>
            <div className="ms-3">
              <p>
                Default music to play when a customer is on hold in a
                Queue or when placed on hold manually.
              </p>
            </div>
          </Col>

          <Col md={4}>
            {loading ? (
              <Skeleton height="2rem" width="100%" />
            ) : (
              <Select
                onChange={(e) => handleChangeMusicOnHold(e)}
                className="basic-single"
                classNamePrefix="select"
                value={valueMusicOnHold}
                isSearchable={true}
                name="music_on_hold_id"
                options={musicOnHold}
              />
            )}
          </Col>
        </Row>
      </CollapsePanel>

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="callForwarding"
        title="Advanced Features"
        subtitle="Features that are not enabled by default can be enabled below for advanced users"
      >
        <span className="itp-my-settings-card-title">
          Enable B2B Accounts
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtensionTemp-icon" />
            </div>
            <div className="ms-3">
              <p>
                This option will enable the 'Accounts' field in
                Contacts which is useful for Business to Business
                Contacts. Account objects can have multiple contacts
                under an Account and allows for a more centralized
                view when dealing with organizations.
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchB2B}
                  onChange={handleChangeswitch}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="callerId"
        title="Caller ID"
        subtitle="Default Caller ID to be used if not set on the User"
      >
        <Row className="itp-my-settings-card-help-text">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex align-items-center">
            <span className="itp-my-settings-card-title">
              Default Name
            </span>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            {loading ? (
              <Skeleton width="14rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <input
                  name="name"
                  value={formName.name}
                  onChange={onChangeName}
                  className="form-control-itp"
                />
              </div>
            )}
          </Col>
        </Row>

        <Row className="itp-my-settings-card-help-text mt-4">
          <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={8} className="d-flex align-items-center">
            <span className="itp-my-settings-card-title">
              Default Number
            </span>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            {loading ? (
              <Skeleton height="2rem" width="14rem"/>
            ) : (
              <Select
                onChange={(e) => onChangeNumberCallerId(e)}
                className="basic-single"
                lassNamePrefix="select"
                value={valueNumber}
                isSearchable={true}
                name="music_on_hold_id"
                options={allNumbers}
              />
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default GeneralForwardingID;
