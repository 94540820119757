import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AddButton, CustomTableMain, LoadingButton, CollapsePanel } from "../../../../../components";
import Select from "react-select";
import useScreenSize from "../../../../../hooks/useScreenSize";
import ModalDelete from "../../ModalUtility/ModalDelete";
import ModalError from "../../ModalUtility/ModalError";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionDelete from "../../../../../services/petitionDelete";

const InternationalCalls = ({
  loading,
  setCompletedRequests,
  modalDelete,
  setModalDelete,
}) => {
  const { width } = useScreenSize();

  const [allCountries, setAllCountries] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [loadingInterCountry, setLoadingInterCountry] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [errorNumber, setErrorNumber] = useState("");
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataTableInterCalls, setDataTableInterCalls] = useState({
    columns: [{ name: "Country", key: "label" }],
    actions: { title: "Actions", content: [] }
  });

  const closeMenu = () => {
    setModalError(false);
    setModalDelete(false);
    setInfo(null);
  };

  const activeDeleteModalCountry = (e) => {
    setInfo(e);
    setModalDelete(4);
  };

  const deleteInterCountry = () => {
    setLoadingModal(true);
    petitionDelete("blockedCountries", { country_id: info.pk })
      .then(({ data: result }) => {
        setDataTableInterCalls((prevStata) => {
          const newContent = prevStata.content.filter(
            (element) => element.pk !== info.pk
          );

          return {
            ...prevStata,
            content: [...newContent],
          };
        });
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const addInterCountry = () => {
    setLoadingInterCountry(true);

    let addedCountries = selectedCountries.map((element) => element.pk);

    let data = {
      country_pks: addedCountries,
    };
    petitionPost("blockedCountries", { data })
      .then(({ data: result }) => {
        const newBlockedCountries = result.result.map((element, i) => {
          element.label = selectedCountries[i]?.name;
          element.value = element.pk;
          element.country = {
            pk: selectedCountries[i]?.pk,
            abbreviation: selectedCountries[i]?.abbreviation,
            name: selectedCountries[i]?.name,
          };

          return element;
        });

        setDataTableInterCalls((prevStata) => {
          const newContent = [...prevStata.content, ...newBlockedCountries];

          return {
            ...prevStata,
            content: newContent,
          };
        });
        setLoadingInterCountry(false);
        closeMenu();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data.error.error_detailed.error.error_message
        ) {
          setLoadingInterCountry(false);
          setErrorNumber(
            error.response.data.error.error_detailed.error.error_message
          );
          setModalError(true);
        } else {
          console.log(error);
        }
      });
  };

  const petitonInterCalls = async () => {
    await petitionGet("allCountries")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });
        setAllCountries(result.result);
      })
      .catch((error) => console.log(error));

    await petitionGet("blockedCountries")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.country.name;
          element.value = element.pk;
        });

        setDataTableInterCalls({
          columns: [{ name: "Country", key: "label" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteModalCountry,
              },
            ],
          },
        });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitonInterCalls();
  }, []);

  return (
      <>
        {modalError && (
          <ModalError
            modal={modalError}
            closeModal={closeMenu}
            error={errorNumber}
          />
        )}

        {modalDelete === 4 && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeMenu}
            loading={loadingModal}
            modalFunction={deleteInterCountry}
            module="Country"
          />
        )}

        <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1" }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>
        
        <CollapsePanel
          id="internationalCalls"
          title="Blocked Countries"
          subtitle="Adding a Country here will prevent this country from calling any of your numbers"
        >
          <CustomTableMain 
            data={dataTableInterCalls}
            m0={true}
            loading={loading}
            module="Blocked Countries"
            actionsRight={[
                  {
                    component: (
                      <>
                        {!showAdd ? (
                          <AddButton 
                            handleClick={() => {
                              if (loading) return;
                              setShowAdd(true)
                            }}
                            name="Add Country"
                          />
                        ) : (
                          <div
                            style={{ width: width > 991 ? "17rem" : "auto" }}
                            className={`d-flex flex-column itp-add-button ${width > 991 ? "centered" : ""}`}
                          >
                            <Select
                              isMulti
                              onChange={(e) => setSelectedCountries(e)}
                              className="basic-single"
                              classNamePrefix="select"
                              value={selectedCountries}
                              isSearchable={true}
                              name="country_pks"
                              options={allCountries}
                            />
                            <div style={{ gap: "8px" }} className="d-flex mt-2">
                              <button
                                onClick={() => {
                                  setSelectedCountries([]);
                                  setShowAdd(false);
                                }}
                                className="btn-light"
                              >
                                Cancel
                              </button>
                              <button
                                onClick={addInterCountry}
                                className="btn-primary loading"
                                disabled={selectedCountries.length > 0 ? false : true}
                              >
                                {loadingInterCountry && <LoadingButton />}
                                  Save
                              </button>
                            </div>
                          </div>
                        )}
                      </>
                    )
                  }
            ]}
           />
        </CollapsePanel>
      </>
  );
};

export default InternationalCalls;
