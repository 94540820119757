import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AlertNotificactions } from "../../../../../../components";
import { downloadVoicemailService, editNumberService, fetchFunnels, fetchNumberDetails, fetchUsers, handleBlurService, handleChangeFunnelService, saveUsersService, sendVoicemailService, toggleAddvancedCallflowService } from "../../services";
import { handleChangeForms, handleChangeSwitchs, startRecordSound } from "../../utils";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { editNumberDefaultForm } from "../../const";
import { AdvancedCallflows, AI, BasicSettings, DeleteNumber, Title, Users } from "./components";
import ModalDelete from "../../../ModalUtility/ModalDelete";
import ModalConfirm from "../../../ModalUtility/ModalConfirm";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";
import "./EditNumber.css";

const EditNumber = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const param = searchParams.get('number');
  const number = "+" + param.slice(1)
  const formatedNumber = formatPhoneNumber("+" + param.slice(1)) 

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();
  const navigate = useNavigate()

  const inputRef = useRef();

  const [info, setInfo] = useState({})
  const [advanceCallFlows, setAdvanceCallFlows] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [loadingNumber, setLoadingNumber] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invalidStrategy, setInvalidStrategy] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [showLoadVoicemail, setShowLoadVoicemail] = useState(false);
  const [loadingVoicemail, setLoadingVoicemail] = useState(false);
  const [selectedOptionVoicemail, setSelectedOptionVoicemail] = useState("");
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  const [reload, setReload] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const [funnelValue, setFunnelValue] = useState(null)
  const [funnels, setFunnels] = useState([])
  const [modalDelete, setModalDelete] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [form, setForm] = useState(editNumberDefaultForm);
  const [currentForm, setCurrentForm] = useState(form)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")

  const [dataTableUSers, setDataTableUsers] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "Email", key: "email" },
      { name: "Extension", key: "presence_id" },
      { name: "Permissions", key: "priv_level" },
    ],
    actions: { title: "Actions", content: [] }
  });

  let audioChunks = [];

  const onChangeOptionLoadVoicemail = (e) => {
    inputRef.current = null;
    setRecording(null);
    setAudioBlob(null);
    setMediaRecorder([]);
    setAudioUrl("");
    setReload(1);
    audioChunks = [];
    if (e) {
      setSelectedOptionVoicemail(e.target.value);
    }
  };

  const startRecording = () => {
    startRecordSound(audioChunks, setAudioBlob, setAudioUrl, setRecording, setMediaRecorder)
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const activeLoadVoicemail = () => {
    setOpenMenu(true);
    setShowLoadVoicemail(true);
  };

  const activeModalDelete = (e) => {
    if (loading) return;
    setInfo(e);
    setModalDelete(true);
  };

  const handleChangeswitch = async (name) => {
    const formError = { ...form, [name]: form[name] }
    const data = handleChangeSwitchs(name, form, setForm, setCurrentForm)

    await editNumberService(number, data, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage, formError, form, setForm, setCurrentForm)
  }

  const activeConfirmModal = () => {
    if (loading) return
    setModalConfirm(true);
  };

  const activeModalDeleteAssignedUser = (e) => {
    setUsersSelected((prevState) => prevState.filter((element) => element.pk !== e.pk));
    setModalDelete(true);
  };

  const toggleCallFlows = async () => {
    await toggleAddvancedCallflowService(number, advanceCallFlows, setAdvanceCallFlows, setLoadingConfirm, closeMenu, petition)
  };

  const handleChangeForm = async (e) => {
    const formError = form
    const data = handleChangeForms(e, form, setForm, setCurrentForm)

    if (data) {
      await editNumberService(number, data, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage, formError, form, setForm, setCurrentForm)
    }
  }

  const onChangeFunnel = async (e) => {
    await handleChangeFunnelService(e, number, funnelValue, setFunnelValue, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
  }

  const closeMenu = () => {
    setShowLoadVoicemail(false);
    setInvalidStrategy(null);
    setModalConfirm(false);
    setModalDelete(false);
    setOpenMenu(false);
  };

  const activeAddUsers = () => {
    if (loading) return;
    setOpenMenu(true);
  };

  const saveNumbers = async () => {
    const data = { sms_users: usersSelected.map((user) => user.pk) };
    const newData = { ...info, sms_assigned_users: usersSelected };

    await saveUsersService(setLoadingNumber, number, data, newData, organizeDataUsers, closeMenu, setInfo, allUsers, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
  };

  const organizeDataUsers = (dataNumber, users) => {
    let assignedUsers = [];

    users.forEach((user) => {
      const dataFind = dataNumber.sms_assigned_users.find((element) => element.pk === user.pk);
      if (dataFind) assignedUsers.push(user);
    });

    setUsersSelected(assignedUsers);
    setDataTableUsers({
      columns: [
        { name: "Name", key: "name" },
        { name: "Email", key: "email" },
        { name: "Extension", key: "presence_id" },
        { name: "Permissions", key: "priv_level" },
      ],
      content: [...assignedUsers],
      actions: {
        title: "Actions",
        content: [
          {
            name: "Delete",
            icon: <FontAwesomeIcon icon={faTrash} />,
            handleClick: activeModalDeleteAssignedUser,
          },
        ],
      },
    });
  };

  const saveLoadVoicemail = async () => {
    try {
      setLoadingVoicemail(true);
      await sendVoicemailService(selectedOptionVoicemail, inputRef, audioBlob, number)

      setLoadingVoicemail(false);
      petition();
      closeMenu();
      onChangeOptionLoadVoicemail()
    } catch (error) {
      console.log(error)
    }
  };

  const downloadVoicemail = async () => {
    const response = await downloadVoicemailService(number)
    return response
  };

  const handleBlurInputs = async (key) => {
    await handleBlurService(key, form, setForm, currentForm, setCurrentForm, number, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
  }

  const petition = async () => {
    try {
      const allUsersTemp = await fetchUsers()
      setAllUsers(allUsersTemp)

      const numberDetails = await fetchNumberDetails(number, setAdvanceCallFlows, setForm, setCurrentForm)
      organizeDataUsers(numberDetails, allUsersTemp);
      setInfo(numberDetails);

      await fetchFunnels(numberDetails, setFunnelValue, setFunnels)
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!param) {
      navigate("/Phone-Numbers")
    } else {
      setDynamicFunction(() => () => {
        navigate("/Phone-Numbers")
      });
      setIsArrowVisible(true);
      petition();
    }
  }, []);

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingNumber}
          modalFunction={saveNumbers}
          module="Assigned User"
        />
      )}

      {modalConfirm && (
        <ModalConfirm
          modal={modalConfirm}
          closeModal={closeMenu}
          loading={loadingConfirm}
          modalFunction={toggleCallFlows}
          invalidStrategy={invalidStrategy}
        />
      )}

      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <Title number={formatedNumber} />

      <BasicSettings 
        form={form}
        handleChangeForm={handleChangeForm}
        handleBlurInputs={handleBlurInputs}
        loading={loading}
      />

      <Users 
        dataTableUSers={dataTableUSers}
        activeAddUsers={activeAddUsers}
        loading={loading}
      />

      <AdvancedCallflows 
        advanceCallFlows={advanceCallFlows}
        activeConfirmModal={activeConfirmModal}
        handleChangeForm={handleChangeForm}
        form={form}
        handleBlurInputs={handleBlurInputs}
        downloadVoicemail={downloadVoicemail}
        activeLoadVoicemail={activeLoadVoicemail}
        openMenu={openMenu}
        closeMenu={closeMenu}
        showLoadVoicemail={showLoadVoicemail}
        setUsersSelected={setUsersSelected}
        usersSelected={usersSelected}
        allUsers={allUsers}
        saveNumbers={saveNumbers}
        loadingNumber={loadingNumber}
        onChangeOptionLoadVoicemail={onChangeOptionLoadVoicemail}
        selectedOptionVoicemail={selectedOptionVoicemail}
        reload={reload}
        setReload={setReload}
        inputRef={inputRef}
        saveLoadVoicemail={saveLoadVoicemail}
        loadingVoicemail={loadingVoicemail}
        recording={recording}
        startRecording={startRecording}
        stopRecording={stopRecording}
        audioBlob={audioBlob}
        audioUrl={audioUrl}
        loading={loading}
      />

      <AI 
        form={form}
        handleChangeswitch={handleChangeswitch}
        handleChangeForm={handleChangeForm}
        onChangeFunnel={onChangeFunnel}
        funnelValue={funnelValue}
        funnels={funnels}
        loading={loading}
      />

      <DeleteNumber 
        activeModalDelete={activeModalDelete}
        info={info}
      />
    </>
  );
};

export default EditNumber;
