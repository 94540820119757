import petitionGet from "./petitionGet";
import json from "../config.json";

const onLogout = () => {
  const host = json.prod
    ? json.voice360.prod.split("//")[1]
    : json.voice360.dev.split("//")[1];

  const url = json.prod ? json.voice360.prod : json.voice360.dev;

  const linkVoice360 =
    window.location.host !== host
      ? `http://${window.location.host}/login`
      : `${url}/login`;

  const params = new URL(document.location).searchParams;
  const redirect = params.get("redirect");

  localStorage.removeItem("userDetails");
  localStorage.removeItem("webphone");

  window.location.href = `${linkVoice360}${
    redirect ? `?redirect=${redirect}` : ""
  }`;
};

export const myExtensionService = async () => {
  return await petitionGet("userDetails")
    .then(async ({ data: result }) => {
      const userDetails = result.result;
      let realm = result.result.voice_account.realm;
      let newData = {};

      await petitionGet("userDevices")
        .then(({ data: result }) => {
          const dataFind = result.result.find(
            (element) => element.device_type === "web_phone"
          );

          newData = {
            ...userDetails,
            userDevices: result.result,
            voice_account: {
              ...userDetails.voice_account,
              web_phone: {
                ...dataFind,
              },
            },
          };
          localStorage.setItem(
            "webphone",
            JSON.stringify({ ...dataFind, realm })
          );
        })
        .catch((error) => {
          console.log(error?.response);
          if (
            error?.response &&
            (error?.response?.status === 500 || error?.response?.status === 401)
          ) {
            onLogout();
          }
        });

      return newData;
    })
    .catch((error) => {
      console.log(error?.response);
      if (
        error?.response &&
        (error?.response?.status === 500 || error?.response?.status === 401)
      ) {
        onLogout();
      }
    });
};

export const myExtensionPetition = async () => {
  return await petitionGet("userDetails")
   .then(async ({ data: result }) => {
    const userDetails = result?.result

    return userDetails
   })
   .catch((error) => {
    console.log("my Extension Petition Error", error)
   })
}