import { createDownloadLink } from "../../utils";
import { downloadFileService } from "../index";

const createURL = (blob) => URL.createObjectURL(blob);

const downloadVoicemailService = async (data, urlBase, id, token, setModalError) => {
  try {
    const element = data.pk;
    const url = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${element}`;
    
    const blob = await downloadFileService(url, token);
    const fileURL = createURL(blob);
    
    createDownloadLink(fileURL, "file.mp3");
    
    return true;
  } catch (error) {
    setModalError(true);
    throw error;
  }
}

export default downloadVoicemailService;