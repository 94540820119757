import React, { createContext, useContext, useState } from 'react';

const DynamicFunctionContext = createContext();

export const DynamicFunctionProvider = ({ children }) => {
  const [dynamicFunction, setDynamicFunction] = useState(null);
  const [isArrowVisible, setIsArrowVisible] = useState(false);

  const resetFunction = () => setDynamicFunction(null);

  return (
    <DynamicFunctionContext.Provider value={{ dynamicFunction, setDynamicFunction, resetFunction, isArrowVisible, setIsArrowVisible }}>
      {children}
    </DynamicFunctionContext.Provider>
  );
};

export const useDynamicFunction = () => useContext(DynamicFunctionContext);
