import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch.js";
import "../../MySettings.css";

const CallRecordings = ({ myExtension, setCompletedRequests, loading }) => {
  const [switchs, setSwitchs] = useState({
    call_recording_internal: false,
    call_recording_external: false,
  });

  const handleChangeSwitchCallRecording = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {
      [switchName]: !switchs[switchName] ? "enabled" : "disabled",
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length > 0) {
      setSwitchs({
        ...switchs,

        call_recording_internal:
          myExtension?.call_recording_internal === "enabled",

        call_recording_external:
          myExtension?.call_recording_external === "enabled",
      });

      setCompletedRequests((prevStata) => prevStata + 1);
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="callRecording"
        title="Call Recording"
        subtitle="Adjust your call recording preferences"
      >
        <span className="itp-my-settings-card-title">Internal Calls </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>Record all internal calls (User to User)</p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs?.call_recording_internal}
                  onChange={() =>
                    handleChangeSwitchCallRecording("call_recording_internal")
                  }
                />
              </div>
            )}
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">External Calls </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>Record all external calls (Incoming and Outgoing)</p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs?.call_recording_external}
                  onChange={() =>
                    handleChangeSwitchCallRecording("call_recording_external")
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default CallRecordings;
