import { put, takeLatest } from "redux-saga/effects";
import { defaultSidebarSucces, hiddenSidebarSucces } from "../actions/sidebar";

function* defaultSidebarMenu() {
  yield put(defaultSidebarSucces());
}

function* hiddenSidebarMenu() {
  yield put(hiddenSidebarSucces());
}

export function* defaultSidebarSaga() {
  yield takeLatest("DEFAULT_SIDEBAR", defaultSidebarMenu);
}

export function* hiddenSidebarSaga() {
  yield takeLatest("HIDDEN_SIDEBAR", hiddenSidebarMenu);
}
