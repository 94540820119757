import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../components";

const AddSwitch = ({
  formSwitch,
  onChangeFormSwitch,
  addSwitch,
  loadingSwitch,
}) => (
  <>
    <Row className="itp-container-label-input">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Switch Name</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          type="text"
          placeholder=": Add Name to this switch"
          className="form-control-itp itp-label-input__input"
          name="name"
          value={formSwitch.name}
          onChange={onChangeFormSwitch}
        />
      </Col>
    </Row>

    <button
      onClick={addSwitch}
      className="btn-primary itp-lead-detail-button-save loading"
      disabled={formSwitch.name ? false : true}
    >
      {loadingSwitch && <LoadingButton />}
      Save
    </button>
  </>
);

export default AddSwitch;
