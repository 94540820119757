import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../components";

const AddTimeGroup = ({
  formNameTimeGroup,
  setFormNameTimeGroup,
  addTimeGroup,
  loadingTimeGroup,
}) => (
  <>
    <Row className="itp-container-label-input">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Time Group Name</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          type="text"
          placeholder=": Add Name to this time group"
          className="form-control-itp itp-label-input__input"
          name="name"
          value={formNameTimeGroup.name}
          onChange={(e) => setFormNameTimeGroup({ name: e.target.value })}
        />
      </Col>
    </Row>

    <button
      onClick={addTimeGroup}
      className="btn-primary itp-lead-detail-button-save loading"
      disabled={formNameTimeGroup.name ? false : true}
    >
      {loadingTimeGroup && <LoadingButton />}
      Save
    </button>
  </>
);

export default AddTimeGroup;
