import React from "react";
import { formatDate } from "../../../../../../utils/getDate";
import "./Date.css"

const Date = ({ element, config, column, style }) => {
  const date = element[column?.key] || "";
  const spliter = config?.spliter

  const formatedDate = formatDate(date, spliter);
  const day = formatedDate.split("at ")[0];
  const hour = formatedDate.split("at ")[1];
  return (
    <>
      <span>{day}</span>
      <span style={style || {}} className="itp-hour">
        {" at "}
        {hour}
      </span>
    </>
  );
};

export default Date;
