import { myExtensionPetition } from "../../../../../services/myExtension.service";
import { defaultFilters } from "../../utils";
import getQueryString from "../../../../../utils/getQueryString";

const fetchMyExtension = async (myExtension, petition, pagination, objectFilter) => {
  try {
    const data = await myExtensionPetition();

    if (data && Object.keys(data).length > 0) {
      myExtension.current = data;

      const filterActive = defaultFilters(data);

      petition(getQueryString(pagination.current), data);
      objectFilter.current = filterActive;
    }
  } catch (error) {
    console.log(error);
  }
};

export default fetchMyExtension;
