import verifyPhoneNumber from "./verifyPhoneNumber"

const formatPhoneNumber = (phone) => {
  const {isValid, countryCode, phoneNumber} = verifyPhoneNumber(phone)

  if (!phone || !isValid) {
    return phone
  }
  
  const prefix = `+${countryCode}`
  const firstThreeNumber = phoneNumber.slice(0, 3)
  const secondThreeNumbers = phoneNumber.slice(3, 6)
  const lastNumbers = phoneNumber.slice(6)

  return `${prefix} (${firstThreeNumber}) ${secondThreeNumbers}-${lastNumbers}`
}

export default formatPhoneNumber;