import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch";

const SoundSettings = ({ myExtension, setCompletedRequests, loading }) => {
  const [soundEnabled, setSoundEnabled] = useState(true);

  const handleChangeSoundSwitch = async () => {
    const currentSwitchValue = soundEnabled

    try {
      setSoundEnabled(!soundEnabled)

      const data = {
        user_settings: {
          sound_enabled: !soundEnabled
        }
      } 

      await petitionPatch("userDetails", { data })
    } catch (error) {
      console.log(error)
      setSoundEnabled(currentSwitchValue)
    }
  }

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length) {
      if (
        !myExtension?.user_settings ||
        myExtension?.user_settings?.sound_enabled !== false
      ) {
        setSoundEnabled(true);
        setCompletedRequests((prev) => prev + 1);
      } else {
        setSoundEnabled(false);
        setCompletedRequests((prev) => prev + 1);
      }
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="soundSettings"
        title="Sound Settings"
        subtitle="Settings for incoming messages"
      >
        <span className="itp-my-settings-card-title">Sound</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Enable sound for incoming messages
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={soundEnabled}
                  onChange={handleChangeSoundSwitch}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default SoundSettings;
