import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { Loading, LoadingButton, MenuRight, CustomTableMain, AddButton } from "../../../../components/index.js";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionGet from "../../../../services/petitionGet.js";
import petitionPost from "../../../../services/petitionPost.js";
import petitionPatch from "../../../../services/petitionPatch.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import informationBlue from "../../../../assets/icons/information-blue.svg";
import apiKey from "../../../../assets/images/apiKey.svg";
import useScreenSize from "../../../../hooks/useScreenSize.js";

const Webhooks = () => {
  const { width } = useScreenSize();

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [webhooks, setWebhooks] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingWebhook, setLoadingWebhook] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);

  const [form, setForm] = useState({
    name: "",
    url: "",
    http_method: "GET",
    hook: "USER_DIAL",
    retries: "1",
  });
  
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "Url", key: "url" },
      { name: "Type", key: "http_method" },
      { name: "Hook", key: "replaceHook" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const reloadForm = () => {
    setForm({
      name: "",
      url: "",
      http_method: "GET",
      hook: "USER_DIAL",
      retries: "1",
    });
  };

  const onchangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const closeMenu = () => {
    reloadForm();
    setLoadingWebhook(false)
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const activeAddWebhook = () => {
    if (loading) return;
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeEditWebhook = (e) => {
    setInfo(e);
    setForm({
      name: e.name,
      url: e.url,
      http_method: e.http_method,
      hook: e.hook,
      retries: e.retries,
    });
    setOpenMenu(true);
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const deleteWebhook = () => {
    setLoadingWebhook(true);
    petitionDelete("webhook", { webHookId: info.pk })
      .then(({ data: result }) => {
        setLoadingWebhook(false);
        reloadData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editWebhook = () => {
    setLoadingWebhook(true);
    petitionPatch("webhook", { data: form, webhookId: info.pk })
      .then(({ data: result }) => {
        setLoadingWebhook(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addWebhook = () => {
    setLoadingWebhook(true);
    petitionPost("webhook", { data: form })
      .then(({ data: result }) => {
        setLoadingWebhook(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const petition = useCallback(() => {
    setLoading(true);
    petitionGet("webhooks")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.replaceHook = element.hook.replaceAll("_", " ");
        });

        setDataTable({
          columns: [
            { name: "Name", key: "name" },
            { name: "Url", key: "url" },
            { name: "Type", key: "http_method" },
            { name: "Hook", key: "replaceHook" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditWebhook,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
          addButton: {
            label: "Add Webhook",
            handleClick: activeAddWebhook,
          },
        });

        setWebhooks(result.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleBackClick = useCallback(() => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  }, [dispatch, navigate]);

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_webhooks
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, [handleBackClick, petition]);

  return (
    <div style={{ marginTop: "3rem" }}>
      {!loading && webhooks.length === 0 ? (
      <div id="generalsettings">
        <div className="itp-table-container-centered">
          <Row>
            <Col>
              <div className="text-align-with-table">
                <span className="table-alt-text-title">Webhooks</span>
                <br></br>
                <span className="table-alt-text-title-description"></span>
              </div>
              <Card className="itp-my-settings-card-col">
                <CardBody>
                  <Row className="itp-alert-text alert-background-default d-flex align-items-center">
                    <Col md={12}>
                      <div className="itp-alert-icon-default">
                        <img
                          src={informationBlue}
                          alt="myExtensionTemp-icon"
                        />
                      </div>

                      <div className="itp-alert-data-default">
                        No webhook is created in the system yet!
                      </div>
                    </Col>
                  </Row>
                  <Row className="itp-api-key__add-key-row">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                      <img src={apiKey} alt="" />
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                      <h6 className="itp-api-key__add-title-default">
                        Add you first Webhook
                      </h6>
                      <p className="itp-api-key__add-text-default">
                        Webhooks allow you to communicate with third party
                        services or APIs during certain events.
                      </p>
                      <button
                        onClick={activeAddWebhook}
                        className="btn-primary itp-add-button"
                      >
                        <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" />{" "}
                        Add Webhook
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      ) : (
      <>
        {modalDelete && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeMenu}
            loading={loadingWebhook}
            modalFunction={deleteWebhook}
            module="Webhook"
          />
        )}

        <CustomTableMain 
          data={dataTable} 
          loading={loading}
          centered={width >= 1697 ? true : false}
          actionsLeft={[
            { 
              component: (
                <div className="d-flex flex-column gap-2">
                  <h3 className="itp-accordion-header-title">Webhooks</h3>
                  <h6 className="itp-accordion-header-subtitle">
                    Webhooks allow you to communicate with third 
                    party services or APIs during certain events.
                  </h6>
                </div>
              )
            }
          ]}
          actionsRight={[
            {
              component: (
                <AddButton 
                  name="Add Webhook"
                  handleClick={activeAddWebhook}
                />
              )
            }
          ]}
        />
      </>
      )}

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Add Webhook"
        subtitle="Select User for Api Key"
        to="to Webhooks"
      >
        <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Enter Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Name"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={form.name}
            onChange={onchangeForm}
          />
        </Col>
        </Row>
        <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Enter url</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter url"
            className="form-control-itp itp-label-input__input"
            name="url"
            value={form.url}
            onChange={onchangeForm}
          />
        </Col>
        </Row>
        <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Enter http method
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={onchangeForm}
            name="http_method"
            value={form.http_method}
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
          </select>
        </Col>
        </Row>
        <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Enter Hook</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={onchangeForm}
            name="hook"
            value={form.hook}
          >
            <option value="USER_HANGUP"> USER HANGUP</option>
            <option value="USER_DIAL"> USER DIAL</option>
            <option value="USER_ANSWER"> USER ANSWER</option>
            <option value="QUEUE_MEMBER_DIAL"> QUEUE MEMBER DIAL</option>
            <option value="QUEUE_MEMBER_DIAL_HANGUP">
              QUEUE MEMBER DIAL HANGUP
            </option>
            <option value="QUEUE_HANGUP">QUEUE HANGUP</option>
            <option value="INCOMING_EXTERNAL_CALL">
              {" "}
              INCOMING EXTERNAL CALL
            </option>
            <option value="SMS_RECEIVED"> INCOMING TEXT MESSAGE</option>
            <option value="SMS_DELIVERED"> DELIVERED TEXT MESSAGE</option>
          </select>
        </Col>
        </Row>
        <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Enter retries</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className="form-select-itp"
            onChange={onchangeForm}
            name="retries"
            value={form.retries}
          >
            <option value="1"> 1 </option>
            <option value="2"> 2</option>
            <option value="3"> 3</option>
          </select>
        </Col>
        </Row>
        <button
        className="btn-primary loading add-lead-new-contact"
        disabled={!form.name || !form.url}
        onClick={!info ? addWebhook : editWebhook}
      >
        {loadingWebhook && <LoadingButton />}
        {!info ? "Add" : "Edit"}
        </button>
      </MenuRight>
  </div>
  );
};

export default Webhooks;
