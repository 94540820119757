import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import { itemsMenu, secondItemsMenu } from "../../../../../../../../const/itemsMenu";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";

const GranullarPermissions = ({ form, granularPermissions, handleChangeSwitchGranular, loading }) => (
  <>
    {!loading && form?.priv_level !== "admin" && (
      <>
        <br></br>
        <div>
          <Row>
            <Col>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <CollapsePanel
          title="Granular Permissions"
          subtitle="Grant permissions to certain admin pages for non-admin users"
        >
          <div className="itp-edit-user-permissions-content">
            {itemsMenu.map((item, i) => (
              <Fragment key={i}>
                {!item.prod && item.key_permission && (
                  <div className="d-flex justify-content-between mt-4">
                    <label className="itp-edit-number-label-1">
                      {item.name}
                    </label>
                    
                    {loading ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width="5rem" height="2rem" />
                      </div>
                    ) : (
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Switch
                          className="itp-custom-table-switch itp-edit-callFlow-numbers-switch"
                          onColor="#626ed4"
                          height={20} // Ajusta la altura según tus necesidades
                          width={40}
                          checked={granularPermissions[item.key_permission]}
                          onChange={() => handleChangeSwitchGranular(item.key_permission)}
                        />
                      </div>
                    )}          
                  </div>
                )}
              </Fragment>
            ))}

            {secondItemsMenu[1].items.map((item, i) => (
              <Fragment key={i}>
                {!item.prod && item.key_permission && (
                  <div className="d-flex justify-content-between mt-4">
                    <label className="itp-edit-number-label-1">
                      {item.name}
                    </label>
                    {loading ? (
                      <div className="d-flex justify-content-end">
                        <Skeleton width="5rem" height="2rem" />
                      </div>
                    ) : (
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Switch
                          className="itp-custom-table-switch itp-edit-callFlow-numbers-switch"
                          onColor="#626ed4"
                          height={20} // Ajusta la altura según tus necesidades
                          width={40}
                          checked={granularPermissions[item.key_permission]}
                          onChange={() => handleChangeSwitchGranular(item.key_permission)}
                        />
                      </div>
                    )}   
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </CollapsePanel>
      </>
    )}
  </>
);

export default GranullarPermissions;
