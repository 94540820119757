import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch";
import "../../MySettings.css";

const CallForwarding = ({ myExtension, setCompletedRequests, loading }) => {
  const [firstrender, setFirstRender] = useState(true);

  const [switchs, setSwitchs] = useState({
    callforward_enable: false,
    callforward_keep_caller_caller_id: false,
    callforward_queue_calls: false,
    callforward_call_confirmation: false,
  });

  const [formCallerId, setFormCallerId] = useState({
    default_outbound_callerid_name: "",
    callforward_number: "",
  });

  const handleChangeSwitchs = (switchName) => {
    setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });

    let data = {};

    if (switchName === "callforward_enable" && switchs.callforward_enable) {
      data = {
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
        callforward_number: "",
      };

      setSwitchs({
        ...switchs,
        callforward_enable: false,
        callforward_keep_caller_caller_id: false,
        callforward_queue_calls: false,
        callforward_call_confirmation: false,
      });
    } else {
      data = { [switchName]: !switchs[switchName] };
    }

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (formCallerId.default_outbound_callerid_name && !firstrender) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name:
            formCallerId.default_outbound_callerid_name,
        };

        petitionPatch("userDetails", { data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formCallerId.default_outbound_callerid_name]);

  useEffect(() => {
    if (formCallerId.callforward_number && !firstrender) {
      const timer = setTimeout(() => {
        let data = {
          callforward_number: formCallerId.callforward_number,
        };

        petitionPatch("userDetails", { data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formCallerId.callforward_number]);

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length > 0) {
      setSwitchs({
        ...switchs,

        callforward_enable: myExtension.callforward_enable || false,

        callforward_keep_caller_caller_id: myExtension.callforward_keep_caller_caller_id || false,

        callforward_queue_calls: myExtension.callforward_queue_calls || false,

        callforward_call_confirmation: myExtension.callforward_call_confirmation || false,
      });

      setFormCallerId({
        default_outbound_callerid_name: myExtension.default_outbound_callerid_name || "",
        callforward_number: myExtension.callforward_number,
      });

      setFirstRender(false);
      setCompletedRequests((prevStata) => prevStata + 1);
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="callForwarding"
        title="Call Forwarding"
        subtitle="Adjust call forwarding settings for direct calls or queue calls"
      >
        <span className="itp-my-settings-card-title">
          Enable Call Forwarding
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Enabling this option will forward any incoming calls to your
                user and will not ring any of your devices, including the web
                phone
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs.callforward_enable}
                  onChange={() => {
                    handleChangeSwitchs("callforward_enable");
                  }}
                />
              </div>
            )}
          </Col>
        </Row>

        {switchs.callforward_enable && !loading && (
          <>
            <span className="itp-my-settings-card-title">
              Forward the call to:
            </span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={10} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    You can enter an extension number or a phone numbr to
                    forward calls to
                  </p>
                </div>
              </Col>

              <Col md={2}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <input
                    type="text"
                    placeholder=""
                    className="form-control-itp"
                    name="numberCallForwarding"
                    value={formCallerId.callforward_number}
                    onChange={(e) => {
                      setFormCallerId({
                        ...formCallerId,
                        callforward_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>

            <span className="itp-my-settings-card-title">
              Keep Original Caller ID
            </span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={10} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    If enabled, this will show the caller's phone number on your
                    screen. If disabled, the number that appears will either be
                    the default caller ID or your customized outbound caller ID
                    number.
                  </p>
                </div>
              </Col>

              <Col md={2}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Switch
                    className="itp-custom-table-switch"
                    onColor="#626ed4"
                    height={20} // Ajusta la altura según tus necesidades
                    width={40}
                    checked={switchs.callforward_keep_caller_caller_id}
                    onChange={() => {
                      handleChangeSwitchs("callforward_keep_caller_caller_id");
                    }}
                  />
                </div>
              </Col>
            </Row>

            <span className="itp-my-settings-card-title">
              Forward queue calls
            </span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={10} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    If enabled, any queues you are a member of will have calls
                    forwarded. If disabled, queue calls will not be forwarded
                    and will only ring your device or web phone.
                  </p>
                </div>
              </Col>

              <Col md={2}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Switch
                    className="itp-custom-table-switch"
                    onColor="#626ed4"
                    height={20} // Ajusta la altura según tus necesidades
                    width={40}
                    checked={switchs.callforward_queue_calls}
                    onChange={() => {
                      handleChangeSwitchs("callforward_queue_calls");
                    }}
                  />
                </div>
              </Col>
            </Row>

            <span className="itp-my-settings-card-title">Call screening</span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={10} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    If enabled, when answering a forwarded call, a message will
                    prompt you to accept the call or deny.
                  </p>
                </div>
              </Col>

              <Col md={2}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Switch
                    className="itp-custom-table-switch"
                    onColor="#626ed4"
                    height={20} // Ajusta la altura según tus necesidades
                    width={40}
                    checked={switchs.callforward_call_confirmation}
                    onChange={() => {
                      handleChangeSwitchs("callforward_call_confirmation");
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </CollapsePanel>
    </>
  );
};

export default CallForwarding;
