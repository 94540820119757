import petitionGet from "../../../../../../services/petitionGet";

const fetchQueueDetails = async (pk, setForm, setCurrentForm, petition) => {
  try {
    const { data: result } = await petitionGet("queues", { parameter: `/${pk}` })

    const processForm = {
      queue_name: result?.result?.queue_name || "",
      queue_description: result?.result?.queue_description || "",
      timeout: result?.result?.timeout || null,
      queue_timeout: result?.result?.queue_timeout || null,
      ringinuse: result?.result?.ringinuse || null,
      autopause: result?.result?.autopause || null,
      strategy: result?.result?.strategy || null,
      joinempty: result?.result?.joinempty || null,
      leavewhenempty: result?.result?.leavewhenempty || null,
      queue_args: result?.result?.queue_args || null,
      wrapuptime: result?.result?.wrapuptime || null,
      retry: result?.result?.retry || null,
      skip_busy_agents: result?.result?.skip_busy_agents || false,
    }

    setForm(processForm)
    setCurrentForm(processForm)

    await petition()
  } catch (error) {
    console.log(error)
  }
}

export default fetchQueueDetails;