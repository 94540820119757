// import { createNewChannel, updateUserChannels } from "./utils";

const kmDevicesAnswer = (socketInfo, users) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return users;
  }

  // Hacemos una destructuración de los campos que vienen en la metadata del evento
  const { call_direction, kamailio_call_id, from_user_id, to_user_id } = socketInfo.metadata;

  try {
    const newUserState = users.map((user) => {
      // Con !user.channels.length (longitud igual a cero del campo channels del user), el call_direction 
      // y el pk del user igual al from_user_id o al to_user_id, sabemos que el evento de KM_DEVICE_ANSWER 
      // llegó primero que el KM_DEVICE_DIAL y para este caso agregamos un objeto al campo channels del user
      // con answered en false para indicar que está timbbrando

      if (!user.channels.length && call_direction === 'outbound' && user.pk === from_user_id) {
        const newChannel = { answered: false, withNumber: "", kamailio_call_id, time: 0 }

        user.channels = [{ ...newChannel }]
      } else if (!user.channels.length && call_direction === 'inbound' && user.pk === to_user_id) {
        const newChannel = { answered: false, withNumber: "", kamailio_call_id, time: 0 }

        user.channels = [{ ...newChannel }]
      } else if (!!user.channels.length) {
          // Con !!user.channels.length (longitud mayor a cero del campo channels del user) verificamos
          // si hay algún objeto dentro de channels donde su campo kamailio_call_id sea igual al
          // kamailio_call_id del evento actual, en caso de que si lo haya significa que llegó primero
          // el KM_DEVICE_DIAL y vamos a marcar la llamada como contestada con el eventó de KM_DEVICE_ANSWER
          // que acaba de llegar
          user.channels.forEach((channel) => {
           if (channel.kamailio_call_id === kamailio_call_id) {
             user.devices_registered = true;
             user.time_end = 0;
             channel.answered = true;
             channel.kamailio_call_id = kamailio_call_id;
             channel.time = 0;
           }
         });
      }

      return user;
    });
  
    return newUserState;
  } catch (error) {
    console.log(error);
    return users;
  }
};

export default kmDevicesAnswer;
