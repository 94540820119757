import { Card, CardBody, Col, Row } from "reactstrap";
import { AlignedRow } from "../../../../../../../../components";

const DeleteNumber = ({ activeModalDelete, info }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <AlignedRow className="mb-4">
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <label className="itp-edit-number-label-1">Delete Number</label>
              <label className="itp-edit-number-label-2">
                Number will be deleted permantly from system
              </label>
            </div>
            <button
              onClick={() => activeModalDelete(info)}
              className="btn-primary itp-edit-number-button-3"
            >
              Delete
            </button>
          </div>
        </CardBody>
      </Card>
    </AlignedRow>
  </>
);

export default DeleteNumber;
