import { convertUtcDateToTimeZoneDate } from "../../../../../utils/getDate";
import { selectArrow, selectIcon } from "../index";
import pin from "../../../../../assets/icons/pin.svg";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const processContacts = (contacts, myExtension) => {
  const processedContacts = contacts.map((element) => {
    element.formatedPhone = formatPhoneNumber(element.phone)
    element.hasIcon = element.pinned ? <img src={pin} alt="Pin" className="icon-hover" /> : false
    element.selectIcon = selectIcon(element)
    element.selectArrow = selectArrow(element)
    element.keyDate = element?.last_activity_time ? convertUtcDateToTimeZoneDate(element?.last_activity_time.replaceAll(" ", "T"), myExtension.time_zone) : ""
    element.contactName = `${element?.firstname || ""} ${element.lastname || ""}`;
    element.tags = element.tags.map((tag) => ({
      ...tag,
      title: tag.tag.name,
      tag_color: tag.tag.tag_color,
      pk: tag.tag_id,
    }));

    return element
  });

  return processedContacts
}

export default processContacts;