import phoneBlack from "../../../../../assets/icons/phoneBlack.svg";
import messageIcon from "../../../../../assets/icons/messageIcon.svg";

const selectIcon = (element) => {
  switch (element.last_activity_type) {
    case "call_inbound":
      return <img style={{ width: "16px", height: "16px" }} src={phoneBlack} alt="" />;

    case "call_outbound":
      return <img style={{ width: "16px", height: "16px" }} src={phoneBlack} alt="" />;

    case "sms_received":
      return <img style={{ width: "16px", height: "16px" }} src={messageIcon} alt="" />;

    case "sms_sent":
      return <img style={{ width: "16px", height: "16px" }} src={messageIcon} alt="" />;

    default:
      return ""
  }
}

export default selectIcon;