import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../components";

const ModalDelete = ({ modal, closeModal, loading, modalFunction, title, text }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : "Delete Smart Campaign"}</ModalHeader>
      <ModalBody>
        <p className="itp-label-input__label mt-4">Are You Sure?</p>
        {text && (
          <span className="table-alt-text-title-description cursor-help">{text}</span>
        )}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={modalFunction}
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
