import { fetchCustomFields } from "../index"
import petitionPatch from "../../../../services/petitionPatch"

const editCustomFieldService = async (infoCustomField, closeModal, customFields, setModalDelete, setError) => {
  try {
    const data = infoCustomField
    const tempPk = data.pk
    delete data.label
    delete data.value
    delete data.pk
    delete data.account_id
    data.name = document.getElementById(tempPk).value;

    await petitionPatch("customField", { data, custom_field_id: tempPk })

    const fieldData = document.getElementsByClassName('itp-edit-contact-custom-field-actions')

    for (let index = 0; index < fieldData.length; index++) {
      const element = fieldData[index];
      element.style.visibility = "hidden";
    }    

    await fetchCustomFields(customFields)
    closeModal()
  } catch (error) {
    console.log(error)

    if (error?.response?.status === 400 && error?.response?.data?.error?.error_message) {
      setModalDelete(true)
      setError(error?.response?.data?.error?.error_message)
    }
  }
}

export default editCustomFieldService;