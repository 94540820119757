import { Col, Row } from "reactstrap";
import { LoadingButton } from "../../../../../../components";

const EditSwitch = ({
  formSwitch,
  onChangeFormSwitch,
  checkValue,
  setCheckValue,
  error,
  editSwitch,
  loadingSwitch,
}) => (
  <>
    <Row className="itp-container-label-input">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Switch Name</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          type="text"
          placeholder=": Add Name to this switch"
          className="form-control-itp itp-label-input__input"
          name="name"
          value={formSwitch.name}
          onChange={onChangeFormSwitch}
        />
      </Col>
    </Row>
    <Row className="itp-container-label-select">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Switch Status</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <select
          onChange={onChangeFormSwitch}
          value={formSwitch.status}
          name="status"
          className="form-select-itp"
        >
          <option value={true}> True </option>
          <option value={false}> False </option>
        </select>
      </Col>
    </Row>
    <Row className="itp-container-label-select">
      <Col
        xs={5}
        sm={5}
        md={5}
        lg={5}
        xl={5}
        xxl={5}
        className="itp-label-input-col"
      >
        <label className="itp-label-input__label">Enable Feature Code</label>
      </Col>
      <Col
        xs={7}
        sm={7}
        md={7}
        lg={7}
        xl={7}
        xxl={7}
        className="itp-label-input-col"
      >
        <input
          checked={checkValue}
          name=""
          onChange={() => setCheckValue(!checkValue)}
          type="checkbox"
          className="form-check-input itp-custom-checkbox-contact-details"
        />
      </Col>
    </Row>
    {checkValue && (
      <Row className="itp-container-label-input">
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Feature Code To Enable or Disable
          </label>
        </Col>
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <input
            type="number"
            placeholder=": Enter an extension number"
            className="form-control-itp itp-label-input__input"
            name="extensioNumber"
            value={formSwitch.extensioNumber.slice(1)}
            onChange={onChangeFormSwitch}
          />
        </Col>
      </Row>
    )}
    {error && <p className="text-danger">{error}</p>}
    <button
      onClick={editSwitch}
      className="btn-primary itp-lead-detail-button-save loading"
      disabled={ !formSwitch.name || (checkValue && (!formSwitch.extensioNumber || formSwitch.extensioNumber === "*"))}
    >
      {loadingSwitch && <LoadingButton />}
      Save
    </button>
  </>
);

export default EditSwitch;
