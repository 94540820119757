const defaultValueAddField = (field) => {
  switch (field) {
    case "address":
      return "Address";
    case "url":
      return "URL";
    case "date":
      return "Date";
    case "text/note":
      return "Text / Note";
    case "checkbox":
      return "Checkbox";
    default:
      return "error";
  }
};

export default defaultValueAddField;
