import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Modules.css";

const Switches = ({ saveModule, edit, data, editModule, switches }) => {
  const [valueSwitch, setValueSwitch] = useState(null);

  const saveQueue = () => {
    const sendData = {
      id: valueSwitch.pk,
    };

    if (edit) {
      editModule(sendData, "switch");
    } else {
      saveModule(sendData, "switch");
    }
  };

  useEffect(() => {
    if (data) {
      if (edit) {
        const defaultSwitch = switches.find(
          (element) => element.pk === data.data.id
        );
        setValueSwitch(defaultSwitch);
      }
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Switch</label>
        <Select
          onChange={(e) => setValueSwitch(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueSwitch}
          isSearchable={true}
          name="queue"
          placeholder="Select a switch"
          options={switches}
        />
        <button
          onClick={saveQueue}
          className="btn-primary mt-4"
          disabled={valueSwitch ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default Switches;
