import React from "react";

const ChatBot = ({ saveModule }) => {
  const saveChatbot = () => {
    const sendData = {};
    saveModule(sendData, "chatbot");
  };

  return (
    <>
      <button onClick={saveChatbot} className="btn-primary mt-4">
        Save
      </button>
    </>
  );
};

export default ChatBot;
