import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../../../components";

const ModalDelete = ({ modal, closeModal, loading, modalFunction, module }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Delete {module ? module : ""}</ModalHeader>
      <ModalBody>Are You Sure?</ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={modalFunction}
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
