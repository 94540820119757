import { Col, Row } from "reactstrap";
import useScreenSize from "../../hooks/useScreenSize";

const AlignedRow = ({ children, className, style, m0, m1, noCentered, m3 }) => {
  const { width } = useScreenSize()

  const margin = m3 ? "ms-5 me-5" : (width >= 992 && !m0 && !m1) ? "ms-5 me-5" : (m0 || width < 992) ? "ms-0 me-0" : m1 ? "ms-3 me-3" : "ms-5 me-5"

  const rowContainerClassName = `${margin} ${(width >= 1697 && !noCentered) ? "justify-content-center" : ""}`;

  return (
    <Row style={style || {}} className={`${rowContainerClassName} ${className || ""}`}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={(width >= 1697 && !noCentered) ? 10 : 12}>
        {children}
      </Col>
    </Row>
  )
}

export default AlignedRow;