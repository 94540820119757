import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Card, CardBody } from "reactstrap";
import { MenuRight, CustomTableMain, AddButton, SearchBoxFilter, AlignedRow } from "../../../../../../components";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { addOrEditDateTimeService, deleteDateTimeService, fetchTimeGroupDetails, fetchTimeRules, handleBlurTimeGroupNameService } from "../../services"
import { defaultFormDateTime } from "../../const";
import { formatDateTime, handleChangeSearchTimeDate } from "../../utils";
import { AddEditTimeDate } from "./components";
import useScreenSize from "../../../../../../hooks/useScreenSize";
import ModalDelete from "../ModalUtitlity/ModalDelete";
import "./TimeGroupsDetails.css";
import Skeleton from "react-loading-skeleton";

const TimeGroupsDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get('pk');
  const systemTimeZone = searchParams.get('systemTimeZone');

  const { setDynamicFunction } = useDynamicFunction();
  const navigate = useNavigate()

  const { width } = useScreenSize()

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [timeRules, setTimeRules] = useState([]);
  const [info, setInfo] = useState(null);
  const [loadingDateTime, setLoadingDateTime] = useState(false);
  const [timeGroupName, setTimeGroupName] = useState({ name: "" });
  const [currentName, setCurrentName] = useState(timeGroupName)
  const [modalDelete, setModalDelete] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [formAddEditDateTime, setformAddEditDateTime] = useState(defaultFormDateTime);
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "ID", key: "pk" },
      { name: "Start Date", key: "startDate" },
      { name: "End Date", key: "endDate" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const reloadFormAddEditDateTime = () => {
    setformAddEditDateTime(defaultFormDateTime);
  };

  const handleChangeSearch = (e) => {
    if (loading) return;
    handleChangeSearchTimeDate(e, setSearchValue, timeRules, setDataTable)
  };

  const handleChangeForm = (e) => {
    setformAddEditDateTime({  ...formAddEditDateTime, [e.target.name]: e.target.value });
  };

  const handleChangeTimeGroupName = (e) => {
    const { value } = e.target
    setTimeGroupName({ name: value })
  }

  const closeModal = () => {
    reloadFormAddEditDateTime();
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete("other");
  };

  const activeAddDateTime = () => {
    if (loading) return;
    setOpenMenu("other");
  };

  const activeEditDateTime = (e) => {
    formatDateTime(e, setInfo, setformAddEditDateTime, setOpenMenu)
  };

  const reloadData = (noLoading) => {
    closeModal();
    petition(noLoading);
  };

  const deleteDateTime = async () => {
    await deleteDateTimeService(setLoadingDateTime, info, reloadData)
  };

  const addEditDateTime = async () => {
    await addOrEditDateTimeService(setLoadingDateTime, formAddEditDateTime, pk, info, reloadData)
  };

  const handleBlurTimeGroupName = async () => {
    await handleBlurTimeGroupNameService(timeGroupName, currentName, setCurrentName, pk)
  }

  const petition = async (noLoading) => {
    try {
      if (!noLoading) setLoading(true);

      const timeRulesTemp = await fetchTimeRules(pk)

      setDataTable({
        columns: [
          { name: "Name", key: "name" },
          { name: "ID", key: "pk" },
          { name: "Start Date", key: "startDate" },
          { name: "End Date", key: "endDate" },
        ],
        content: [...timeRulesTemp],
        actions: {
          title: "Actions",
          content: [
            {
              name: "Edit time Dates",
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              handleClick: activeEditDateTime,
            },
            {
              name: "Delete",
              icon: <FontAwesomeIcon icon={faTrash} />,
              handleClick: activeModalDelete,
            },
          ],
        },
      });

      setTimeRules(timeRulesTemp);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const getData = async () => {
    await fetchTimeGroupDetails(pk, setTimeGroupName, setCurrentName, petition)
  }

  useEffect(() => {
    if (!pk || !systemTimeZone) {
      navigate("/Advanced-Call-flows")
    } else {
      setDynamicFunction(() => () => {
        navigate("/Advanced-Call-flows")
      });

      getData();
    }
  }, []);

  return (
    <div className="mt-5">
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingDateTime}
          modalFunction={deleteDateTime}
          module="Time Date"
        />
      )}

      <AlignedRow>
        <h6 className="itp-accordion-header-subtitle">
          Time Group Details : Manage settings for this Time group
        </h6>
      </AlignedRow>

      <AlignedRow>
        <h3 className="itp-accordion-header-title mb-3">
          Time Group : {!loading ? currentName.name : <Skeleton height="1rem" width="8rem" />}
        </h3>
      </AlignedRow>

      <AlignedRow>
        <h6 className="itp-accordion-header-subtitle">
          Current TimeZone is: {systemTimeZone}
        </h6>
      </AlignedRow>

      <AlignedRow>
        <SearchBoxFilter 
          handleChange={handleChangeSearch}
          value={searchValue}
          placeholder="Search time date"
        />
      </AlignedRow>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <AlignedRow>
        <Card>
          <CardBody>  
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Name</label>
                <label className="itp-edit-number-label-2">
                  Change the name of the specific time group
                </label>
              </div>

              {loading ? (
                <div className="itp-edit-number-input">
                  <Skeleton width="100%" height="2rem"/>
                </div>
              ) : (
                <input
                  placeholder="Add Name"
                  className="form-control-itp itp-edit-number-input"
                  name="name"
                  value={timeGroupName.name}
                  onChange={handleChangeTimeGroupName}
                  onBlur={handleBlurTimeGroupName}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </AlignedRow>

      <div className="mt-5 mb-4">
        <CustomTableMain
          data={dataTable}
          loading={loading}
          module="time groups"
          m3={true}
          centered={width >= 1697}
          noDataMessage={searchValue && !dataTable.content.length ? "No results found" : ""}
          actionsLeft={[
            {
              component: (
                <div className="d-flex flex-column gap-2">
                  <h3 className="itp-accordion-header-title">
                    Time Dates for group
                  </h3>
                  <h6 className="itp-accordion-header-subtitle">
                    Create & manage dates for this specific time group
                  </h6>
                </div>
              )
            }
          ]}
          actionsRight={[
            {
              component: (
                <AddButton 
                  handleClick={activeAddDateTime}
                  name="Add Time Date"
                />
              )
            }
          ]}
        />
      </div>

      <MenuRight
        show={openMenu}
        closeMenu={closeModal}
        title="Add Time Date"
        to=""
      >
        {openMenu && (
          <AddEditTimeDate 
            formAddEditDateTime={formAddEditDateTime}
            handleChangeForm={handleChangeForm}
            loadingDateTime={loadingDateTime}
            addEditDateTime={addEditDateTime}
            timeZone={systemTimeZone}
          />
        )}
      </MenuRight>
    </div>
  );
};

export default TimeGroupsDetails;
