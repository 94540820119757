import onCall from "../../../../../../../assets/icons/onCall.svg";
import callRemove from "../../../../../../../assets/icons/callRemove.svg";
import callCalling from "../../../../../../../assets/icons/callCalling.svg";
import callAdd from "../../../../../../../assets/icons/callAdd.svg";

export const selectNameStatus = (element, users) => {
  let conferenceActive = false;
  if (element.channels.length !== 0) {
    users.forEach((elementTwo) => {
      if (elementTwo.channels.length > 1) {
        elementTwo.channels.forEach((elementThree) => {
          if (
            element.presence_id === elementThree.callee_id_number ||
            element.presence_id === elementThree.caller_id_number
          ) {
            conferenceActive = true;
          }
        });
      }
    });
  }

  const type = `${
    element.channels.length === 0
      ? "READY"
      : element.channels.length > 1 || conferenceActive
      ? "conference"
      : element.channels[0].answered
  }`;

  if (element.devices_registered === false && element.channels.length === 0)
    return "Offline";
  else if (type === "READY") return "Available";
  else if (type === "true" || type === "false" || type === "conference")
    return "Busy";
};

export const selectColor = (element, users) => {
  let conferenceActive = false;
  if (element.channels.length !== 0) {
    users.forEach((elementTwo) => {
      if (elementTwo.channels.length > 1) {
        elementTwo.channels.forEach((elementThree) => {
          if (
            element.presence_id === elementThree.callee_id_number ||
            element.presence_id === elementThree.caller_id_number
          ) {
            conferenceActive = true;
          }
        });
      }
    });
  }

  const type = `${
    element.channels.length === 0
      ? "READY"
      : element.channels.length > 1 || conferenceActive
      ? "conference"
      : element.channels[0].answered
  }`;

  if (element.devices_registered === false && element.channels.length === 0)
    return "#c8c8c8";
  else if (type === "READY") return "#199F28";
  else if (type === "true" || type === "false" || type === "conference")
    return "#D60C0C";
};

export const getData = (channels, pk, users, setUsers) => {
  const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
  const realCalls = channels.filter((channel) => !channel?.firstBye)

  if (realCalls.length === 0) 
    return (
      <div className="v-hidden w-100 h2r m-auto">
        {" "}
        <img src={onCall} alt="" className="width-icon" /> {`On Call ${123456789806554} ${12434}`}
      </div>
    )

    if (realCalls.length > 1) {
      return (
        <div className="itp-livePanel-extensions__multple-call-container">
          <div className="itp-livePanel-extensions__multple-call-info">
            {realCalls.slice(0, 2).map((element, i) => {
             let time = ""

             if (element?.time) {
                time = new Date((typeof realCalls[i]?.time === "number" ? realCalls[i]?.time : 0) * 1000).toISOString().substr(11, 8);
              }

              if (element.terminated) {
                handleTermination(pk, element.kamailio_call_id, users, setUsers)
                return (
                  <div key={i} className="d-flex gap-1">
                    {" "}
                    <img src={callRemove} alt="" /> <span>{`Call Terminated ${time}`}</span>
                  </div>
                );
              }

              
              if (!element.answered) {
                return (
                  <div key={i} className="d-flex gap-1">
                    {" "}
                    <img src={callCalling} alt="" /> Ringing
                  </div>
                );
              }

              return (
                <div key={i} className="d-flex gap-1">
                  <img style={{ width: "12px", height: "12px" }} src={onCall} alt="" /> {`On Call (${element.withNumber}) - ${time}`}
                </div>
              )
            })}
          </div>
          <img src={callAdd} alt="" /> On Call {`(${realCalls.slice(0, 2).length})`}
        </div>
      );
    }

    let time = new Date((regex.test(realCalls[0]?.time) ? realCalls[0]?.time : 0) * 1000).toISOString().substr(11, 8);

    if (realCalls[0].terminated) {
      handleTermination(pk, realCalls[0].kamailio_call_id, users, setUsers)
      return (
        <div className="w-100 h2r m-auto">
          {" "}
          <img src={callRemove} alt="" className="width-icon" /> {`Call Terminated ${time}`}
        </div>
      );
    }

    if (!realCalls[0].answered) {
      return (
        <div className="w-100 h2r m-auto">
          {" "}
          <img src={callCalling} alt="" className="width-icon" /> Ringing
        </div>
      );
    }

    return (
      <div className="w-100 h2r m-auto">
        {" "}
        <img src={onCall} alt="" className="width-icon" /> {`On Call ${realCalls[0].withNumber} ${time}`}
      </div>
    );
};

export const notShowSpyEvent = (element, event, myExtension, dataUsers, usersInSpye, permissions) => {
    const usersInSpyeTemp = usersInSpye.current
    // Si la cuenta no tieene el permiso de algun evento no lo muestra
    if (!permissions.includes(event)) return true

    // Si el pk del user en la fila (element) es igual al pk de la cuenta
    // y este usuario está en una llamada no puede espiar ninguna otra llamada
    if (element.pk === myExtension.pk) return true

    if (element.pk !== myExtension.pk) {
      // Si el user no tiene ninguna llamada no los muestra
      if (element.channels.length === 0) return true

      // Si el user tiene una llamada terminada tampoco muestra los actions
      const terminatedCall = element?.channels?.find((channel) => channel?.terminated)
      const unansweredCall = element?.channels?.find((channel) => channel?.answered === false)

      if (terminatedCall || unansweredCall) return true

      const findUserAccount = dataUsers.find((user) => user.pk === myExtension.pk)
      const findUserInEvents = usersInSpyeTemp?.find((spyEvent) => spyEvent?.target_user_id === element.pk.toString())

      if (findUserAccount.channels.length > 0 && !findUserInEvents) return true

      // Busca si algun evento en la lista de eventos que llegan por el websocket es igual al nombre del 
      // evento correspondiente a laa fila
      const findEvent = usersInSpyeTemp?.find((spyEvent) => spyEvent?.event === event)

      // si el evento de la fila no es igual al evento que llegó entonces no lo muestra
      if (usersInSpyeTemp?.length > 0 && !findEvent) return true

      if (findEvent) {
        const findUser = usersInSpyeTemp?.find((spyEvent) => spyEvent?.target_user_id === element.pk.toString())

        return !findUser
      }

      return false
    }
}

export const isActive = (element, event, usersInSpye) => {
  const usersInSpyeTemp = usersInSpye.current
  const spyEvent = usersInSpyeTemp?.find((spyEvent) => spyEvent?.event === event)

  if (spyEvent && spyEvent?.target_user_id === element.pk.toString()) return true

  return false
}

const handleTermination = (pk, kamailio_call_id, users, setUsers) => {
    setTimeout(() => {
      const updatedUsers = users.map(user => {
        if (user.pk === pk) {
          let newChannels = []

          user.channels.forEach((channel) => {
            if (channel.kamailio_call_id === kamailio_call_id && channel.firstBye)  {
              // channel.notShow = true
              newChannels.push(channel)
            } else if (channel.kamailio_call_id !== kamailio_call_id) {
              // delete channel?.notShow
              newChannels.push(channel)
            }
          })

          user.channels = newChannels
        } 
        return user;
      })
  
      setUsers(updatedUsers);
    }, 3000); // Delay in milliseconds (3 seconds)
}