import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { LoadingButton } from "../.././../../components"
import json from "../../../../config.json"
import ReCAPTCHA from "react-google-recaptcha";
import "../SignUp.css";

const Step1 = ({ 
  nextStep, 
  loading, 
  errorCatpcha, 
  setErrorCatcha, 
  validEmail, 
  setValidEmail, 
  captchaValue,
  setCaptchaValue,
}) => {
  const recaptchaRef = useRef(null);

  const [check, setCheck] = useState(false)
  const [form, setForm] = useState({
    name: "",
    email: "",
  });

  const onChange = (value) => {
    setErrorCatcha(false)
    setCaptchaValue(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });

    if (name === "email" && value) {
      setValidEmail(validateEmail(value));
    } else if (name === "email" && !value) {
      setValidEmail(true);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    nextStep(form);
  };

  useEffect(() => {
    if (!captchaValue) {
      recaptchaRef.current.reset();
    }
  }, [captchaValue])

  return (
    <div className="itp-signUp">
      <Card className="itp-signUp__card">
        <CardBody>
          <h1 className="itp-signUp__card-body__title">Sign up to Voice360</h1>

          <p className="itp-signUp__card-body__textInfo">
            Streamline your communications with our all-in-one hosted PBX
            <br />
            platform for seamless phone calls and messaging
          </p>

          <form
            onSubmit={submitForm}
            className="itp-signUp__card-body__form-container"
          >
            <label htmlFor="name">
              <span></span>
              <input
                className="itp-signUp__card-body__form__input"
                name="name"
                id="name"
                placeholder="Name"
                autoComplete="name"
                required
                onInvalid={(e) => e.target.setCustomValidity("Please fill out this field.")}
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={handleChangeForm}
              />
            </label>

            <label htmlFor="email">
              <span></span>
              <input
                className="itp-signUp__card-body__form__input"
                name="email"
                id="email"
                placeholder="E-mail Address"
                autoComplete="email"
                required
                onInvalid={(e) => e.target.setCustomValidity("Please fill out this field.")}
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={handleChangeForm}
              />
            </label>

            {(!validEmail) && (
              <p className="text-danger">Invalid Email Address</p>
            )}

            <div className="itp-signUp__card-body__form__confirm-container">
              <input
                name="check"
                id="check"
                type="checkbox"
                className="form-check-input"
                required
                onInvalid={(e) => e.target.setCustomValidity("Check this box if you want to continue.")}
                onInput={(e) => e.target.setCustomValidity("")}
                checked={check}
                onChange={() => setCheck(!check)}
              />
              <label
                htmlFor="check"
                className="itp-signUp__card-body__form__confirm__text"
              >
                By continuing, you are stating that you accept the
                <br />
                Terms of service https://itp360.com/legal, have read the Privacy Policy and, if in
                <br />
                the EEA or UK, agree to the European Electronic
                <br />
                Communications Codes Rights Waiver
              </label>
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={json.siteKey}
              onChange={onChange}
            />
            {errorCatpcha && (
              <p className="text-danger">Please complete the reCAPTCHA.</p>
            )}

            <button
              type="submit"
              className="btn-primary btn-primary-signup loading"
              disabled={loading || !validEmail}
            >
              {loading && <LoadingButton />}
              Signup
            </button>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Step1;
