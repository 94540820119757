import petitionDelete from "../../../../../../services/petitionDelete";

const deleteContactListService = async (setLoading, info, allResult, pagination, reloadData) => {
  try {
    setLoading(true);
    await petitionDelete("contactList", { contact_list_id: info.pk });
    setLoading(false);
    if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
      const paginationTemp = {
        limit: pagination.current.limit,
        offset: pagination.current.offset - pagination.current.limit,
      };
      reloadData(paginationTemp);
    } else {
      reloadData(false);
    }
  } catch (error) {
    console.log(error);
  }
};

export default deleteContactListService;
