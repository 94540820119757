import petitionPost from "../../../../../services/petitionPost";

const addLeadService = async (form, setLoadingLead, reloadData) => {
  setLoadingLead(true);

  try {
    await petitionPost("leads", { data: form })

    setLoadingLead(false);
    reloadData(false);
  } catch (error) {
    console.log(error)
  }
}

export default addLeadService;