import petitionGet from "../../../../../../services/petitionGet";

const fetchUserDetails = async (contactDetailsMenu, setAsignedUser) => {
  const { data: result } = await  petitionGet("user", { user_id: contactDetailsMenu?.owner_id })

  result.result.label = `${result?.result?.firstname} ${result?.result?.lastname}`
  result.result.value = result?.result?.pk

  setAsignedUser(result.result);
}

export default fetchUserDetails;