const getLastColor = (tags) => {
  // Iniciamos la búsqueda desde el último elemento del array
  if (Array.isArray(tags)) {
    for (let i = tags.length - 1; i >= 0; i--) {
      const obj = tags[i];
      const tag = obj.tag;

      // Verificamos si el objeto actual tiene el campo "tag_color" y no es nulo
      if (
        tag.hasOwnProperty("tag_color") &&
        tag.tag_color !== null &&
        tag.tag_color !== "" &&
        tag.tag_color !== "#fff"
      ) {
        // Si el objeto tiene un color, retornamos el color
        return tag.tag_color;
      }
    }
  }
  // Si no se encontró un color en ningún objeto, retornamos "#6D28D9"
  return "#6D28D9";
};

export default getLastColor;
