import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const findAndFormatPhoneNumbers = (string) => {
  // Regular expression to look up phone numbers with country code.
  const phoneRegex = /\+\d{1,4}\d{7,15}/g;

  // Replace phone numbers in text with formatted version
  const formattedText = string.replace(phoneRegex, (match) => formatPhoneNumber(match));

  return formattedText;
};

export default findAndFormatPhoneNumbers;
