import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "../../../../../index";
import { getLastColor } from "../../../../utils";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";

// Por defecto la clase itp-chat-list__item-checkbox tiene la propiedad visibility en hidden y la clase 
// itp-chat-list__item-avatar tiene la propiedad visibility en visible. Cuando se hace hover en la 
// clase ipt-chat-list-item se invierte el valor de la propiedad de cada clase, pero si algún checkbox 
// se marca queda visible el checkbox y el avatar no, se haga o no se haga hover
const AvatarCheckbox = ({ 
  isCheckedItem, 
  handleCheckItem, 
  handleClickCheckbox, 
  element, 
  loading,
 }) => {
 
  const showCheckbox = element && isCheckedItem(element) && !loading && !element.isFake ? true : false
  const showAvatar = element?.isContact && !loading && !element.isFake ? true : false
  const showSkeleton = (loading || element.isFake) ? true : false

  return (
    <div className="itp-chat-list-item__avatar-checkbox">
      <div onClick={handleClickCheckbox} className={`itp-chat-list__item-checkbox ${showCheckbox ? "v-visible" : ""}`}>
        <input 
          type="checkbox"
          className="form-check-input itp-chat-list-checkbox"
          checked={isCheckedItem(element)}
          onChange={(event) => handleCheckItem(event, element)}
        />
      </div>

      <div className={`itp-chat-list__item-avatar ${isCheckedItem(element) ? "v-hidden" : ""}`}>
        {showAvatar ? (
          <Avatar
            firstname={element?.firstname}
            lastname={element?.lastname}
            tagColor={getLastColor(element?.notIsSelf?.contact?.tags)}
          />
        ) : (
          <div className="itp-chat-list-item__avatar-not-contact">
            {showSkeleton ? (
              <Skeleton width="36px" height="36px" borderRadius="50%" />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}
          </div>
        )}
      </div>
    </div>
  )
 }

export default AvatarCheckbox;