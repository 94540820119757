import { Card, CardBody } from "reactstrap";
import { AlignedRow } from "../../../../../components";

const CardInfo = ({ activeMenuReport }) => (
  <AlignedRow noCentered={true}>
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="itp-analytics-title mb-0">Call Reports</h2>
          <button
            onClick={activeMenuReport}
            className="btn-primary itp-analytics-button-report"
          >
            Run Report
          </button>
        </div>
        <div className="mt-2">
          <label className="itp-analytics-label">User Reports</label>
          <p className="itp-analytics-description mb-0">
            Generate a report by adding few details / filters to get a report of
            your preferred time
          </p>
        </div>
        <div className="mt-3">
          <label className="itp-analytics-label">Number Reports</label>
          <p className="itp-analytics-description mb-0">
            Generate a report by adding few details / filters to get a report of
            your preferred time
          </p>
        </div>
        <div className="mt-3">
          <label className="itp-analytics-label">Queue Reports</label>
          <p className="itp-analytics-description mb-0">
            Generate a Report based on your Queues which can provide insights
            such as average hold time, average call time and more.
          </p>
        </div>
        <div className="mt-3">
          <label className="itp-analytics-label">Queue Member Reports</label>
          <p className="itp-analytics-description mb-0">
            Generate a Report based on your Users that are part of a Queue. This
            can provide insights such as missed calls, average call time and
            more.
          </p>
        </div>
      </CardBody>
    </Card>
  </AlignedRow>
);

export default CardInfo;
