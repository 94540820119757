import React, { useEffect, useState } from "react";
import { CustomTableMain } from "../../../../../../../components";

const Step2 = ({ supportedLosingCarriers }) => {
  const [dataTable, setDataTable] = useState(null)

  useEffect(() => {
    if (Array.isArray(supportedLosingCarriers)) {
      const dataTables = supportedLosingCarriers.map((element) => {
        const content = typeof element.TnList.Tn === "string" ? [{ key: element.TnList.Tn }] : element.TnList.Tn.map((el) => ({ key: el }))

        const dataTable = {
          columns: [{ name: element.LosingCarrierName, key: "key" }],
          content,
        }

        return dataTable
      })
      setDataTable(dataTables)
    }
  }, [supportedLosingCarriers])

  return (
    <div>
      {dataTable && dataTable.map((data) => (
        <>
          <CustomTableMain 
            data={data}
            style={{ margin: "0 3rem 0 -1rem" }}
          />
        </>
      ))}
    </div>
  );
};

export default Step2;