const formatTags = (tags, newTags) => {
  let dataTagsNew = [];
  tags.map((element) => {
    if (typeof element !== "string") {
      if (element.inputValue) {
        let dataFilter = newTags.filter(
          (elementTwo) => elementTwo?.name === element?.inputValue
        );
        dataTagsNew.push(dataFilter[0]?.pk);
      } else {
        dataTagsNew.push(element.pk);
      }
    }
  });

  let result = dataTagsNew.filter((item, index) => {
    return dataTagsNew.indexOf(item) === index;
  });

  return result;
};

export default formatTags;
