import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import infoIcon from "../../../../../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";

const CallForwarding = ({ switchs, handleChangeSwitchCallForwarding, form, handleChange, handleBlurInputs, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Call Forwarding"
      subtitle="Adjust call forwarding settings for direct calls or queue calls"
    >
      <span className="itp-my-settings-card-title">
        Enable Call Forwarding
      </span>

      <Row className="itp-my-settings-card-help-text">
        <Col md={10} className="d-flex">
          <div>
            <img src={infoIcon} alt="result.result-icon" />
          </div>
          <div className="ms-3">
            <p>
              Enabling this option will forward any incoming calls to your user
              and will not ring any of your devices, including the web phone
            </p>
          </div>
        </Col>

        <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
          {loading ? (
            <Skeleton width="5rem" height="2rem" />
          ) : (
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.callforward_enable}
                onChange={() => handleChangeSwitchCallForwarding("callforward_enable")}
              />
            </div>
          )}
        </Col>
      </Row>
    </CollapsePanel>

    {switchs.callforward_enable && !loading && (
      <CollapsePanel
        title="Call Forward Settings"
        subtitle="Adjust the different options when forwarding a call"
        className="mt-4"
      >
        <span className="itp-my-settings-card-title">
          Forward the call to:
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="result.result-icon" />
            </div>
            <div className="ms-3">
              <p>
                You can enter an extension number or a phone numbr to forward
                calls to
              </p>
            </div>
          </Col>

          <Col md={2}>
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <input
                type="text"
                placeholder=""
                className="form-control-itp"
                name="callforward_number"
                value={form.callforward_number}
                onChange={handleChange}
                onBlur={() => handleBlurInputs("callforward_number", "user")}
              />
            </div>
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">
          Keep Original Caller ID
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="result.result-icon" />
            </div>
            <div className="ms-3">
              <p>
                If enabled, this will show the caller's phone number on your
                screen. If disabled, the number that appears will either be the
                default caller ID or your customized outbound caller ID number.
              </p>
            </div>
          </Col>

          <Col md={2}>
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.callforward_keep_caller_caller_id}
                onChange={() => handleChangeSwitchCallForwarding("callforward_keep_caller_caller_id")}
              />
            </div>
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">
          Forward queue calls
        </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="result.result-icon" />
            </div>
            <div className="ms-3">
              <p>
                If enabled, any queues you are a member of will have calls
                forwarded. If disabled, queue calls will not be forwarded and
                will only ring your device or web phone.
              </p>
            </div>
          </Col>

          <Col md={2}>
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.callforward_queue_calls}
                onChange={() => handleChangeSwitchCallForwarding("callforward_queue_calls")}
              />
            </div>
          </Col>
        </Row>

        <span className="itp-my-settings-card-title">Call screening</span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="result.result-icon" />
            </div>
            <div className="ms-3">
              <p>
                If enabled, when answering a forwarded call, a message will
                prompt you to accept the call or deny.
              </p>
            </div>
          </Col>

          <Col md={2}>
            <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
              <Switch
                className="itp-custom-table-switch"
                onColor="#626ed4"
                height={20} // Ajusta la altura según tus necesidades
                width={40}
                checked={switchs.callforward_call_confirmation}
                onChange={() => handleChangeSwitchCallForwarding("callforward_call_confirmation")}
              />
            </div>
          </Col>
        </Row>
      </CollapsePanel>
    )}
  </>
);

export default CallForwarding;
