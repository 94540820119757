const messageError = [
  "The user already exists in the Asterisk Users Database",
  "A Required Field is missing or is an invalid data type.",
  "There is already another contact with this email.",
  "Not a valid email address.",
];

const showError = [
  "Extension Number already exists",
  "The extension number must be a minimum of three digits",
  "Email is already being used elsewhere!",
  "Not a valid email address.",
];

export const objError = messageError.reduce((acc, key, index) => {
  acc[key] = showError[index];
  return acc;
}, {});
