import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { CustomTableMain, SipDetailsModal, CollapsePanel } from "../../../../../components";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";
import petitionPatch from "../../../../../services/petitionPatch";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionGet from "../../../../../services/petitionGet";
import "../../MySettings.css"

const Devices = ({ myExtensionTemp, setCompletedRequests, loading }) => {
  const myExtension = useRef({})

  const [selectedSipDevice, setSelectedSipDevice] = useState(null);
  const [isSipDetailsModalOpen, setIsSipDetailsModalOpen] = useState(false);
  const [switchs, setSwitchs] = useState({ jitter_buffer: false });
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "deviceName" },
      { name: "Device Type", key: "device_type" },
      { name: "Details", key: "details" },
    ],
  })

  const closeSipDetailsModal = () => {
    setSelectedSipDevice(null);
    setIsSipDetailsModalOpen(false);
  };

  const openSipDetailsModal = (device) => {
    setSelectedSipDevice(device);
    setIsSipDetailsModalOpen(true);
  };

  const handleChangeOtherSwitch = () => {
    setSwitchs({ jitter_buffer: !switchs.jitter_buffer });

      let data = { jitter_buffer: !switchs.jitter_buffer };

      petitionPatch("userDetails", { data })
        .then(({ data: result }) => {
          const newUserDetails = {
            ...myExtension.current,
            ...result.result,
          };

          myExtension.current = newUserDetails
        })
        .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtensionTemp && Object.keys(myExtensionTemp).length > 0) {
      petitionGet("userDevices")
      .then(({ data: result }) => {
        const devices = result.result
  
        const userDevices = devices.map((element) => {
          element.deviceName = element.device_type;
          element.details = "Details";
          return element;
        });
  
        myExtension.current = myExtensionTemp
        setSwitchs({ jitter_buffer: myExtensionTemp?.jitter_buffer || false })
        setDataTable({
          columns: [
            { name: "Name", key: "deviceName" },
            { name: "Device Type", key: "device_type" },
            { name: "Details", key: "details" },
          ],
          content: [...userDevices]
        })
        setCompletedRequests(prevStata => prevStata + 1)
      })
      .catch((error) => console.log(error) );
    }
  }, [myExtensionTemp]);

  return (
    <>
      {isSipDetailsModalOpen && (
        <SipDetailsModal
          modal={isSipDetailsModalOpen}
          closeModal={closeSipDetailsModal}
          device={selectedSipDevice}
          myExtension={myExtension.current}
        />
      )}

      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="Devices"
        title="Devices"
        subtitle="Here is all the information related to devices integrated with their respective SIP Credential details & You can register the devices manually if you have a desk phone."
      >
        <CustomTableMain 
          data={dataTable}
          loading={loading}
          paginationData={3}
          m0
          noDataMessage="No data available"
          noShowPagination={true}
          columnConfig={{
            "Details": {
            type: "clickColumn",
            handleClick: openSipDetailsModal,
            }
          }}
        />

        <span className="itp-my-settings-card-title ms-2">
          Jitter Buffer
        </span>

        <Row className="itp-my-settings-card-help-text ms-1">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Enabling this feature can improve call quality on
                unstable internet connections.
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs.jitter_buffer}
                  onChange={handleChangeOtherSwitch}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default Devices;
