import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";

const Play = ({ saveModule, edit, data, editModule, plays, loadingCallFlowChart }) => {
  const [valueMedia, setValueMedia] = useState(null);

  const saveMedia = () => {
    if (loadingCallFlowChart) return;

    const sendData = {
      id: valueMedia.pk
    }

    if (edit) {
      editModule(sendData, "play")
    } else {
      saveModule(sendData, "play")
    }
  }

  useEffect(() => {
    if (data) {
      if (edit) {
        const defaultMedia = plays.find((element) => element.pk === data.data.id)
        setValueMedia(defaultMedia)
      }
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">Media File</label>
        {loadingCallFlowChart ? (
          <div className="w-100">
            <Skeleton width="100%" height="2.5rem" />
          </div>
        ) : (
          <Select
            onChange={(e) => setValueMedia(e)}
            className="basic-single"
            classNamePrefix="select"
            value={valueMedia}
            isSearchable={true}
            name="queue"
            placeholder="Select a Media File"
            options={plays}
          />
        )}
        <button
          onClick={saveMedia}
          className="btn-primary mt-4"
          disabled={valueMedia ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default Play;
