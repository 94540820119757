import petitionPatch from "../../../../../../services/petitionPatch";

const checkNumberService = async (selectedUsers, element, setFormNumbersSwitch, setSelectedUsers, callFlowDetails, callflowName) => {
  try {
    const findOne = selectedUsers.find((elementTwo) => elementTwo.number.number === element.number.number);

    let newSelectedUsers;

    if (findOne) {
      newSelectedUsers = selectedUsers.filter((elementTwo) => elementTwo.number.number !== element.number.number);
    } else {
      newSelectedUsers = [...selectedUsers, element];
    }

    let formSwitchs = {};

    newSelectedUsers.forEach((element) => {
      formSwitchs[element.number.number] = true;
    });

    setFormNumbersSwitch(formSwitchs);
    setSelectedUsers(newSelectedUsers);

    const data = {
      numbers: newSelectedUsers.map((element) => element.number.number),
      type: "CustomByUser",
      name: callflowName,
      callflow_object: {
        flow: callFlowDetails.flow,
        type: "CustomByUser",
      },
    };

    await petitionPatch("callFlow", { data, callflow_id: callFlowDetails.pk })
  } catch (error) {
    console.log(error)
  }
}

export default checkNumberService;