import { generateFakeChats } from "../index"
import getQueryString from "../../../../../../utils/getQueryString";

const moreChatList = (chatList, forceUpdate, chatListPagination, petition, selectParameter, number) => {
  const fakeChats = generateFakeChats();
  chatList.current = chatList.current.concat(fakeChats);
  forceUpdate();

  const paginationtemp = {
    offset: chatListPagination.current.offset + chatListPagination.current.limit,
    limit: 15,
  };

  const parameter = getQueryString(paginationtemp) + selectParameter.current;

  console.log("moreChatList")
  petition(number, parameter, true, false, false, true);
  chatListPagination.current = paginationtemp;
};

export default moreChatList;
