import { AvatarCheckbox, ChatItemDate, ChatItemInfo } from "./components";
import "../../ChatList.css";

const ChatItem = ({
  element,
  activeChatWindow,
  showAlertForNewMessage,
  loading,
  timeZone,
  isCheckedItem,
  handleCheckItem,
  handleClickCheckbox,
  chatWindowData,
}) => {
  const handleClickItem = () => {
    activeChatWindow(element, element?.notIsSelf?.contact?.pk);
  }

  const showUnreadMessages = (!loading && !element.isFake && (element?.pk == showAlertForNewMessage || !element?.thread_read) && element?.unread_messages > 0) ? true : false

  return (
    <div onClick={handleClickItem} className={`ipt-chat-list-item ${showUnreadMessages ? "unread" : ""} ${loading ? "loading" : ""} ${chatWindowData?.pk === element?.pk ? "active" : ""}`}>
      <AvatarCheckbox 
        isCheckedItem={isCheckedItem}
        handleCheckItem={handleCheckItem}
        handleClickCheckbox={handleClickCheckbox}
        element={element}
        loading={loading}
      />

      <ChatItemInfo 
        element={element} 
        loading={loading}
      />

      <ChatItemDate 
        element={element} 
        showUnreadMessages={showUnreadMessages} 
        loading={loading}
        timeZone={timeZone}
      />
    </div>
  );
};

export default ChatItem;
