import { useEffect, useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelInput, LabelSelect, SelectComponent } from "../index";
import LoadingButton from "../LoadingButton/LoadingButton";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import petitionGet from "../../services/petitionGet";

const LeadSource = ({
  leadSource,
  setLeadSource,
  defaultLeadSource,
  form,
  handleChangeForm,
  loading,
}) => {
  const [valueLeadSource, setValueLeadSource] = useState(defaultLeadSource);
  const [showAddLeadSource, setShowAddLeadSource] = useState(false);
  const [showdeleteLeadSource, setShowdeleteLeadSource] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [newLeadSource, setNewLeadSource] = useState("");
  const [infoToDelete, setInfoToDelete] = useState(null);

  const addLeadSource = () => {
    if (loadingButton || form.list_name === "") return;

    setLoadingButton(true);
    petitionPost("leadSource", { data: { name: newLeadSource } })
      .then(({ data: result }) => {
        setLeadSource((prevState) => {
          result.result.label = `${result.result.name}`;
          result.result.value = result.result.pk;
          const newContent = [...prevState, result.result];
          return newContent;
        });
        setShowAddLeadSource(false);
        setShowdeleteLeadSource(false);
        setLoadingButton(false);
        setValueLeadSource(result.result);
      })
      .catch((error) => console.log(error));
  };

  const deleteLeadSource = () => {
    setLoadingButton(true);
    petitionDelete("leadSource", {
      source_lead_id: infoToDelete?.pk,
    })
      .then(({ data: result }) => {
        setLeadSource((prevState) => {
          let tempLeadSources = prevState.filter(
            (objeto) => objeto.value !== infoToDelete?.pk
          );
          const newContent = [...tempLeadSources];
          return newContent;
        });
        setLoadingButton(false);
        back();
        setValueLeadSource(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptionsSource = (inputValue, callback) => {
    petitionGet("leadSource", { parameter: `?name=${inputValue}` });
    leadSource.forEach((element) => {
      if (element.value !== "add") {
        if (element.list_name !== "Default") {
          element.icon = <FontAwesomeIcon icon={faTrash} />;
        }
      }

      element.label = element.name
      element.value = element.pk
    });

    let existAdd = leadSource.some((objeto) => objeto.value === "add");

    if (!existAdd) {
      leadSource.unshift({
        label: "Add New Lead Source",
        value: "add",
      });
    }

    callback(leadSource);
  };

  const stopFunction = (e, info) => {
    setShowdeleteLeadSource(true);
    setInfoToDelete(info);
  };

  const back = () => {
    setShowAddLeadSource(false);
    setShowdeleteLeadSource(false);
    setInfoToDelete(null);
  };

  const handleChangeLeadSource = (e) => {
    if (e.value === "add") {
      setShowAddLeadSource(true);
    } else {
      e.target = {
        name: "lead_source_id",
        value: e.pk,
      };

      handleChangeForm(e);
      setValueLeadSource(e);
    }
  };

  useEffect(() => {
    setValueLeadSource(defaultLeadSource)
  }, [defaultLeadSource])

  return (
    <>
      {!showAddLeadSource && !showdeleteLeadSource && (
        <LabelSelect label="Lead Source" loading={loading}>
          <SelectComponent
            onChange={handleChangeLeadSource}
            defaultValue={valueLeadSource}
            isSearchable={true}
            name="lead_source_id"
            placeholder="Choose lead source"
            includeOption={true}
            functionOption={stopFunction}
            asyncSelectOptions={true}
            loadOptions={loadOptionsSource}
          />
        </LabelSelect>
      )}

      {showAddLeadSource && !showdeleteLeadSource && (
        <LabelInput label="Lead Source Name List" loading={loading}>
          <div className="label-input-add-contact-list">
            <input
              onChange={(e) => setNewLeadSource(e.target.value)}
              name="lead_source"
              className="form-control-itp input-add-contact-list"
              type="text"
            />
          </div>

          <div className="add-contact-list-buttons">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>

            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={addLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Add
            </button>
          </div>
          <br />
        </LabelInput>
      )}

      {!showAddLeadSource && showdeleteLeadSource && (
        <LabelInput label="Are You Sure?" loading={loading}>
          <div className="add-contact-list-buttons">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>
            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={deleteLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Delete
            </button>
          </div>
          <br />
        </LabelInput>
      )}
    </>
  );
};

export default LeadSource;
