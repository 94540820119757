import { fetchCustomFields } from "../index"
import petitionDelete from "../../../../services/petitionDelete";

const deleteCustomFieldService = async (setLoadingModal, details, customFields, closeModal) => {
  try {
    setLoadingModal(true);

    await petitionDelete("customField", { custom_field_id: details.pk })
    
    await fetchCustomFields(customFields)

    setLoadingModal(false);
    closeModal();
  } catch (error) {
    console.log(error)
  }
}

export default deleteCustomFieldService;