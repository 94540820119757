import petitionDelete from "../../../../services/petitionDelete";

const deleteNoteService = async (deleteNote, setLoadingNote, backNote, getNotes) => {
  try {
    setLoadingNote(true);

    await petitionDelete("deleteNote", { note_id: deleteNote })

    setLoadingNote(false);
    backNote();
    getNotes();
  } catch (error) {
    console.log(error)
  }
}

export default deleteNoteService;