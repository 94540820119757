import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { addUserForm, arrayUserForm, autoCompleteValues } from "../../const/addUserConst";
import { LoadingButton } from "../../../../../../components";
import Select from "react-select";

const AddUser = ({ openMenu, loading, addUser }) => {
  const [form, setForm] = useState(addUserForm);
  const [autoCompleteValue, setAutoCompleteValue] = useState(autoCompleteValues);
  const [invalidFields, setInvalidFields] = useState([])

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setInvalidFields((prev) => {
      const newFields = prev.filter((element) => element !== name)

      return newFields
    })

    setForm({ ...form, [name]: value });
  };

  const handleChangeAutoComplete = (e, key) => {
    setAutoCompleteValue({ ...autoCompleteValue, [key]: e })

    setInvalidFields((prev) => {
      const newFields = prev.filter((element) => element !== key)
  
      return newFields
    })

    setForm({ ...form, [key]: e.value });
  };

  const AddFunction = () => {
    let invalidFieldsTemp = []
    arrayUserForm.forEach((element) => {
      if (element.mandatory && !form[element.value]) invalidFieldsTemp.push(element.value)
    })

    if (invalidFieldsTemp.length || loading) {
      setInvalidFields(invalidFieldsTemp)
    } else {
      addUser(form)
    }
  }

  useEffect(() => {
    if (!openMenu) {
      setForm(addUserForm);
      setAutoCompleteValue(autoCompleteValues);
    }
  }, [openMenu]);

  return (
    <>
      {arrayUserForm.map((element) => (
        <Fragment key={element.value}>
          {element.input === "text" && (
            <Row className={`itp-container-label-input ${invalidFields.includes(element.value) && "itp-container-label-input-error"}`}>
              <Col
                xs={5}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                xxl={5}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">
                  {element.label}
                </label>
              </Col>
              <Col
                xs={7}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                xxl={7}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  className="form-control-itp itp-label-input__input"
                  placeholder={element?.placeholder || ""}
                  value={element?.defaultValue || form[element.value]}
                  disabled={element?.disabled || false}
                  name={element.value}
                  id={element.value}
                  onChange={handleChangeForm}
                />
              </Col>
            </Row>
          )}

          {element.input === "autoComplete" && (
            <Row className="itp-contact-details-row-select">
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <label className="itp-contact-details-label">
                  {element.label}
                </label>
              </Col>
              <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                <Select
                  onChange={(e) => handleChangeAutoComplete(e, element.value)}
                  className="basic-single"
                  classNamePrefix={`select ${invalidFields.includes(element.value) && "select-prefix-error"}`}
                  name={element.value}
                  placeholder={element.placeholder}
                  options={element.options}
                  isSearchable={true}
                  value={autoCompleteValue[element.value]}
                />
              </Col>
            </Row>
          )}
        </Fragment>
      ))}

      <button
        onClick={AddFunction}
        className="btn-primary loading mb-4"
      >
        {loading && <LoadingButton />}
        Add User
      </button>
    </>
  );
};

export default AddUser;
