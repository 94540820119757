import petitionPatch from "../../../../../../services/petitionPatch";

const editQueueBlurService = async (key, form, setForm, currentForm, setCurrentForm, pk, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage) => {
  if (form[key] !== currentForm[key]) {
    try {
      const data = {
        queue_name: form.queue_name, 
        queue_description: form.queue_description,
        [key]: form[key],
      }

      await petitionPatch("queue", { queue_id: pk, data })

      setCurrentForm(form)
    } catch (error) {
      console.log(error)
      setForm(currentForm)
      setAlertType("error")
      setAlertTitle("Error")
      setAlertMessage("An error has ocurred")
      setShowErrorTime(3000)

      setTimeout(() => {
        setShowErrorTime(false)
        setAlertType("")
        setAlertTitle("")
        setAlertMessage("")
      }, 3300)
    }
  }
}

export default editQueueBlurService;