import React, { useState } from "react";
import { LoadingButton, AlertNotificactions } from "../../../../../components";
import PhoneInput from "react-phone-input-2";
import WidgetsIcon from "@mui/icons-material/Widgets";
import "./Steps.css";

const Step6 = ({ form, handleSendMessage, loadingSend }) => {
  const [phone, setPhone] = useState("")

  const handleChangePhone = (e) => {
    setPhone(e)
  }

  return (
    <div className="phone">
      <div style={{ borderBottom: "1px solid grey" }}>
        <WidgetsIcon
          style={{ width: "20px", height: "20px", margin: "10px 0 8px 10px" }}
        />
      </div>
      <div className="screen">
        <div className="message">
          {form.message_text}
          {form.mms_file && form.mms_mime && form.media_filename && (
            <>
              <div className="image-item">
                <img src={`${form.mms_mime}${form.mms_file}`} alt="" />
              </div>
            </>
          )}
        </div>
      </div>
      <div className="input-area">
        <PhoneInput
          enableSearch={true}
          onlyCountries={['us']}
          preferredCountries={['us']}
          country={'us'}
          value={phone}
          onChange={handleChangePhone}
        />
        <button 
          onClick={() => handleSendMessage(phone)} 
          className="btn-primary loading"
        >
          {loadingSend && <LoadingButton />}
          Send
        </button>
      </div>
    </div>
  );
};

export default Step6;
