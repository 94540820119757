import React, { useEffect, useState } from "react";

const MenuOptions = ({ data, saveDataBranch }) => {
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState({
    menu: "",
  });

  const defaultOptions = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "0", value: "0" },
    { label: "*", value: "*" },
    { label: "Timeout", value: "timeout" },
    { label: "Invalid", value: "invalid" },
  ];

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const sendMenuOptions = () => {
    const name = defaultOptions.find((option) => option.value === form.menu).label

    saveDataBranch({ id: form.menu, name });
  };

  useEffect(() => {
    if (data) {
      const optionsSelected = data.child.map((element) => element.isKeyObject);

      let availableOptions = [];

      defaultOptions.forEach((item) => {
        if (!optionsSelected.includes(item.value)) availableOptions.push(item);
      });

      setOptions(availableOptions);
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mt-4">Option</label>
        <select
          onChange={onChangeForm}
          value={form.menu}
          name="menu"
          className="form-select-itp"
        >
          <option value="" disabled>
            Select option
          </option>
          {options.map((element) => (
            <option key={element.value} value={element.value}>
              {element.label}
            </option>
          ))}
        </select>

        <button
          onClick={sendMenuOptions}
          className="btn-primary mt-4"
          disabled={form.menu ? false : true}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default MenuOptions;
