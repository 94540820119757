import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalRedirect = ({ modal, modalFunction }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>session has timed out</ModalHeader>
      <ModalBody>
        Your session has timed out, please create account again by clicking
        below
      </ModalBody>
      <ModalFooter>
        <button className="btn-primary" onClick={modalFunction}>
          Back to Step One
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalRedirect;
