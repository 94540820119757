import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AlertNotificactions, MenuRight  } from "../../../../components";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { defaultSidebar, hiddenSidebar } from "../../../../redux/actions/sidebar";
import { defaultHeader, hiddenHeader } from "../../../../redux/actions/header";
import { transformDataCallFlows, updateRoute, updateChildRoute, convertToNestedObject } from "./utils";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import { AddCallflow, AddQueue, AddSwitch, AddTimeGroup, CallflowChart, Callflows, EditSwitch, ModalDelete, Queues, Switches, TimeGroups } from "./components"
import petitionGet from "../../../../services/petitionGet";
import petitionPost from "../../../../services/petitionPost";
import petitionPatch from "../../../../services/petitionPatch";
import petitionDelete from "../../../../services/petitionDelete";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber"
import "./AdvancedCallFlows.css";

const AdvancedCallFlows = () => {
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  const timeGroups = useRef([]);
  const callFlowDetails = useRef(null);

  const dispatch = useDispatch();
  const dispatchMenu = useMenuDispatch();
  const navigate = useNavigate();
  const { setDynamicFunction, resetFunction } = useDynamicFunction();

  const toggleMenu = useSelector((state) => state.sidebar.isDefault);
  const showHeader = useSelector((state) => state.header.show);

  const systemTimeZone = useRef("")

  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingTimeGroup, setLoadingTimeGroup] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddTimeGroup, setShowAddTimeGroup] = useState(false);
  const [formNameTimeGroup, setFormNameTimeGroup] = useState({ name: "" });
  const [deleteCallFlows, setDeleteCallFlows] = useState(false);
  const [loadingCallFlows, setLoadingCallFlows] = useState(false);
  const [showAddCallFlow, setShowAddCallFlow] = useState(false);
  const [valueNameCallFlow, setValueNameCallFlow] = useState("");
  const [openPanelCallFlows, setOpenPanelCallFlows] = useState(null);
  const [activeEditChartCallFlow, setActiveEditChartCallFlow] = useState(false);
  const [editDetails, setEditDetails] = useState(null);
  const [isEnd, setIsEnd] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loadingCallFlowChart, setLoadingCallFlowChart] = useState(false)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("Unable to perform action Listen on this call")

  const [dataTableCallFlows, setDataTableCallFlows] = useState({
    columns: [
      { name: "My Call flows", key: "name" },
      { name: "Numbers involved", key: "numberString" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [dataTableTimeGroups, setDataTableTimeGroups] = useState({
    columns: [{ name: "Groups", key: "name" }],
    actions: { title: "Actions", content: [] }
  });

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  //voicemails
  const voicemails = useRef([]);

  //users
  const users = useRef([]);

  //plays
  const plays = useRef([]);

  //queues
  const queues = useRef([]);
  const [showAddQueue, setShowAddQueue] = useState(false);
  const [loadingQueue, setLoadingQueue] = useState(false);
  const [activeDeleteQueue, setActiveDeleteQueue] = useState(false);

  const [formQueue, setFormQueue] = useState({
    queue_name: "",
    queue_description: "",
    timeout: "300",
    joinempty: "yes",
  });

  const [dataTableQueues, setDataTableQueues] = useState({
    columns: [
      { name: "Name", key: "queue_name" },
      { name: "Description", key: "queue_description" },
    ],
    actions: { title: "Actions", content: [] }
  });

  //switches
  const switches = useRef([]);
  const [showAddSwitch, setShowAddSwitch] = useState(false);
  const [showEditSwitch, setShowEditSwitch] = useState(false);
  const [activeDeleteSwitch, setActiveDeleteSwitch] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);
  const [checkValue, setCheckValue] = useState(null);
  const [error, setError] = useState(false);

  const [formSwitch, setFormSwitch] = useState({
    name: "",
    status: "",
  });

  const [dataTableSwitches, setDataTableSwitches] = useState({
    columns: [{ name: "Switch Name", key: "name" }],
    actions: { title: "Actions", content: [] }
  });

  const numberActionMenuOptions = 12;

  const handleBackClick = () => {
    if (!callFlowDetails.current) {
      dispatchMenu({ type: "SET_DEFAULT_MENU" });
      navigate("/Dashboard");
    } else {
      reloadData();
    }
  };

  const activePanelCallFlows = (data, end, edit) => {
    if (data.module !== "timecondition" || end || (data.module === "timecondition" && data.child.length === 1)) {
      setIsEnd(end);
      setActiveEditChartCallFlow(edit);
      setOpenPanelCallFlows(data);
    }
  };

  const closePanelCallFlows = () => {
    setSelectedAction(null);
    setActiveEditChartCallFlow(false);
    setOpenPanelCallFlows(null);
  };

  const closeModal = () => {
    setError(false)
    setFormQueue({
      queue_name: "",
      queue_description: "",
      timeout: "300",
      joinempty: "yes",
    })
    setFormSwitch({
      name: "",
      status: "",
    })
    setShowEditSwitch(false)
    setActiveDeleteSwitch(false);
    setShowAddSwitch(false);
    setOpenMenu(false);
    setActiveDeleteQueue(false);
    setShowAddQueue(false);
    setEditDetails(null);
    setValueNameCallFlow("");
    setShowAddCallFlow(false);
    setFormNameTimeGroup({ name: "" });
    setShowAddTimeGroup(false);
    setInfo(null);
    setModalDelete(false);
    callFlowDetails.current = null;
  };

  const activeDeleteCallFlow = (e) => {
    setDeleteCallFlows(true);
    setInfo(e);
    setModalDelete(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeAddCallFlow = () => {
    if (loading) return;
    setShowAddCallFlow(true);
    setOpenMenu(true);
  };

  const activeAddTimeGroup = () => {
    if (loading) return;
    setOpenMenu(true);
    setShowAddTimeGroup(true);
  };

  const activeEditCallFlowNumbers = (e) => {
    const { pk } = e
    navigate(`/Advanced-Call-flows/Edit-Numbers?pk=${pk}`)
  };

  const toggleFullScreen = () => {
    if ((!toggleMenu && showHeader) || (toggleMenu && showHeader)) {
      dispatch(hiddenSidebar());
      dispatch(hiddenHeader());
    } else if ((toggleMenu && !showHeader) || (!toggleMenu && !showHeader)) {
      dispatch(defaultSidebar());
      dispatch(defaultHeader());
    }
  };

  const activeEditTimeGroup = (e) => {
    const { pk } = e
    navigate(`/Advanced-Call-flows/Time-Group-Details?pk=${pk}&systemTimeZone=${systemTimeZone.current}`)
  };

  const selectSubTitle = () => {
    if (showAddTimeGroup) return `Current TimeZone is: ${systemTimeZone.current}`;

    if (showAddCallFlow) return "Add the name for this call flow";
  };

  const selectTitle = () => {
    if (showAddTimeGroup) return "Add New time group";

    if (showAddCallFlow) return "Add Call Flow";
  };

  const reloadData = (noLoading) => {
    closeModal();
    petition(noLoading);
  };

  const editFlowChart = () => {
    if (loadingCallFlowChart) {
      setAlertType("error")
      setAlertTitle("Error")
      setAlertMessage("Please wait for the data to load so that you can save the changes.")
      setShowErrorTime(3000)

      setTimeout(() => {
        setShowErrorTime(false)
        setAlertType("")
        setAlertTitle("")
        setAlertMessage("")
      }, 3300)

      return;
    }


    setLoading(true);

    const newData = convertToNestedObject(callFlowDetails.current.child[0]);
    const secondNewData = updateRoute(newData);

    callFlowDetails.current = null;

    const editData = {
      type: "CustomByUser",
      name: editDetails.name,
      callflow_object: {
        flow: secondNewData,
        type: "CustomByUser",
      },
    };

    petitionPatch("callFlow", { callflow_id: editDetails.pk, data: editData })
      .then(({ data: result }) => {
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const deleteModule = (element) => {
    const route = element.route;
    const newSegments = route.split(".");
    const segments = newSegments.slice(0, newSegments.length - 2);
    let newGeneralObject = { ...callFlowDetails.current };
    let pointer = newGeneralObject;

    for (const segment of segments) {
      pointer = pointer[segment];
    }

    const currentChild = pointer.child;

    let newChild = [];

    currentChild.forEach((element, i) => {
      if (i !== parseInt(newSegments[newSegments.length - 1])) {
        newChild.push(element);
      }
    });

    pointer.child = newChild;

    const formatedData = updateChildRoute(newGeneralObject);

    callFlowDetails.current = formatedData;
    forceUpdate();
    closePanelCallFlows();
  };

  const editModuleWithBranch = (element) => {
    if (element.module === "timecondition") {
      if (element.child.length !== timeGroups.current.length + 1) {
        setIsEnd(false);
        setActiveEditChartCallFlow(false);
        setOpenPanelCallFlows(element);
      }
    }

    if (element.module === "menu") {
      if (element.child.length !== numberActionMenuOptions) {
        setIsEnd(false);
        setActiveEditChartCallFlow(false);
        setOpenPanelCallFlows(element);
      }
    }
  };

  const activeEditCallFlow = async (e) => {
    setLoadingCallFlowChart(true)

    const newDetails = {
      route: "",
      module: `Name: ${e.name}`,
      data: { id: e.pk },
      children: e.flow,
      isFirst: true,
    };

    const tempData = transformDataCallFlows(newDetails, false, [], activePanelCallFlows, [], [], [], [], [], false, true);
    const updatedRoute = updateChildRoute(tempData);
    callFlowDetails.current = updatedRoute;

    setEditDetails(e);

    let voicemailsTemp = [];
    let usersTemp = [];
    let playsTemp = [];

    await petitionGet("musicOnHoldMedia")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        playsTemp = result.result;

        plays.current = playsTemp;
      })
      .catch((error) => console.log(error));

    await petitionGet("voicemail-v2")
      .then(({ data: result }) => {
        result?.result?.sort((a, b) => a?.owner?.firstname?.localeCompare(b?.owner?.firstname))

        result?.result?.forEach((element) => {
          element.label = `${element?.owner?.firstname} ${element?.owner?.lastname} (${element?.owner?.presence_id})`;
          element.value = element?.pk;
        });

        voicemailsTemp = result.result;
        voicemails.current = voicemailsTemp;
      })
      .catch((error) => console.log(error));

    await petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result.sort((a, b) => a.firstname.localeCompare(b.firstname)).forEach((element) => {
          element.label = `${element.firstname} ${element.lastname} (${element.presence_id})`;
          element.value = element.pk;
        });

        usersTemp = result.result;

        users.current = usersTemp;

        const data = transformDataCallFlows(newDetails, false, timeGroups.current, activePanelCallFlows, queues.current, switches.current, voicemailsTemp, usersTemp, playsTemp);

        const updatedRoute = updateChildRoute(data);
        callFlowDetails.current = updatedRoute;

        setLoadingCallFlowChart(false)
        forceUpdate()
      })
      .catch((error) => console.log(error));
  };

  const deleteCallFlow = () => {
    setLoadingCallFlows(true);
    petitionDelete("callFlow", { callflow_id: info.pk })
      .then(({ data: result }) => {
        setDataTableCallFlows((prev) => {
          const newContent = prev.content.filter((element) => element.pk !== info.pk)

          return { ...prev, content: [...newContent] }
        })
        setLoadingCallFlows(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const deleteTimeGroup = () => {
    if (info.id) {
      setLoadingTimeGroup(true);
      petitionGet("timeRules", { parameter: `?timegroup_id=${info.id}` })
        .then(({ data: result }) => {
          let petitions = [];
  
          result.result.forEach((element) => {
            petitions.push(
              petitionDelete("deleteTimeRules", { time_id: element.pk })
            );
          });
  
          if (info.forced_disable_code_callflow_id) {
            petitions.push(
              petitionDelete("callFlow", {
                callflow_id: info.forced_disable_code_callflow_id,
              })
            );
          }
  
          if (info.forced_enable_code_callflow_id) {
            petitions.push(
              petitionDelete("callFlow", {
                callflow_id: info.forced_enable_code_callflow_id,
              })
            );
          }
  
          if (info.forced_reset_code_callflow_id) {
            petitions.push(
              petitionDelete("callFlow", {
                callflow_id: info.forced_reset_code_callflow_id,
              })
            );
          }
  
          Promise.all(petitions)
            .then((result) => {
              petitionDelete("timeGroup", { time_id: info.id })
                .then(async ({ data: result }) => {
                  await petitionTimeGroups()
                  setLoadingTimeGroup(false);
                  closeModal();
                })
                .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
  };

  const addCallFlow = () => {
    setLoadingCallFlows(true);

    let data = {
      name: valueNameCallFlow,
      numbers: [],
      type: "CustomByUser",
      callflow_object: {
        type: "CustomByUser",
        flow: {},
      },
    };

    petitionPost("callflow", { data })
      .then(async ({ data: result }) => {
        await petitonCallFlows()
        setLoadingCallFlows(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const addTimeGroup = () => {
    setLoadingTimeGroup(true);
    petitionPost("timeGroup", { data: formNameTimeGroup })
      .then(async ({ data: result }) => {
        await petitionTimeGroups()
        setLoadingTimeGroup(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const activeAddQueue = () => {
    if (loading) return;
    setOpenMenu(true);
    setShowAddQueue(true);
  };

  const onChangeFormQueue = (e) => {
    setFormQueue({ ...formQueue, [e.target.name]: e.target.value });
  };

  const addQueue = () => {
    setLoadingQueue(true);
    petitionPost("queue", { data: formQueue })
      .then(async ({ data: result }) => {
        await petitionQueue()
        setLoadingQueue(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const deleteQueue = () => {
    setLoadingTimeGroup(true);
    petitionDelete("queue", { queue_id: info.pk })
      .then(({ data: result }) => {
        setDataTableQueues((prev) => {
          const newContent = prev.content.filter((element) => element.pk !== info.pk)
          queues.current = newContent;

          return { ...prev, content: [...newContent] }
        })
        setLoadingTimeGroup(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const activeModalDeleteQueue = (e) => {
    setInfo(e);
    setActiveDeleteQueue(true);
    setModalDelete(true);
  };

  const activeEditQueue = (e) => {
    const { pk } = e
    navigate(`/Advanced-Call-flows/Edit-Queue?pk=${pk}`)
  };

  const petitionQueue = async () => {
    await petitionGet("queues")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.queue_name;
          element.value = element.pk;
        })

        queues.current = result?.result;

        setDataTableQueues({
          columns: [
            { name: "Name", key: "queue_name" },
            { name: "Description", key: "queue_description" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditQueue,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDeleteQueue,
              },
            ],
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const activeAddSwitch = () => {
    if (loading) return;
    setShowAddSwitch(true);
    setOpenMenu(true);
  };

  const addSwitch = () => {
    setLoadingSwitch(true);

    const data = {
      name: formSwitch.name,
    };

    petitionPost("switch", { data })
      .then(({ data: result }) => {
        setDataTableSwitches((prev) => {
          const newItem = { ...result.result }
          const newContent = [...prev.content, { ...newItem }]

          return { ...prev, content: [...newContent] }
        })
        setLoadingSwitch(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const activeModalDeleteSwitch = (e) => {
    setInfo(e);
    setActiveDeleteSwitch(true);
    setModalDelete(true);
  };

  const deleteSwitch = () => {
    setLoadingTimeGroup(true);
    petitionDelete("switch", { switch_id: info.pk })
      .then(({ data: result }) => {
        setDataTableSwitches((prev) => {
          const newContent = prev.content.filter((element) => element.pk !== info.pk)

          return { ...prev, content: [...newContent] }
        })
        setLoadingTimeGroup(false);
        closeModal();
      })
      .catch((error) => console.log(error));
  };

  const activeEditSwitch = (e) => {
    setFormSwitch({
      name: e.name,
      status: e.status,
      extensioNumber: e.callflow ? e.callflow.numbers[0] : "",
    });
    setCheckValue(e.callflow_id ? true : false);
    setInfo(e);
    setShowEditSwitch(true);
    setOpenMenu(true);
  };

  const onChangeFormSwitch = (e) => {
    if (e.target.name === "status") {
      setFormSwitch({
        ...formSwitch,
        status: e.target.value === "true" ? true : false,
      });
    } else if (e.target.name === "extensioNumber") {
      setFormSwitch({ ...formSwitch, extensioNumber: `*${e.target.value}` });
    } else {
      setFormSwitch({ ...formSwitch, [e.target.name]: e.target.value });
    }
  };

  const editSwitch = async () => {
    setLoadingSwitch(true);
    if (checkValue === info.callflow_id || (info.callflow_id && checkValue) || (!info.callflow_id && !checkValue)) {
      if (!checkValue) {
        const data = {
          name: formSwitch.name,
          status: formSwitch.status,
        };
        petitionPatch("switch", {
          switch_id: info.pk,
          data,
        })
          .then(({ data: result }) => {
            setDataTableSwitches((prev) => {
              const newContent = prev.content.map((element) => {
                if (info.pk === element.pk) {
                  const newObject = { ...result.result, callflow: element.callflow }
                  element = newObject
                }

                return element
              })

              switches.current = newContent
              return {
                ...prev,
                content: [...newContent]
              }
            })
            setLoadingSwitch(false);
            closeModal();
          })
          .catch((error) => console.log(error));
      } else {
        if (
          (!info.callflow.numbers &&
            (!formSwitch.extensioNumber ||
              formSwitch.extensioNumber === "*")) ||
          (info.callflow.numbers &&
            (!formSwitch.extensioNumber ||
              formSwitch.extensioNumber === info.callflow.numbers[0]))
        ) {
          const data = {
            name: formSwitch.name,
            status: formSwitch.status,
          };
          petitionPatch("switch", {
            switch_id: info.pk,
            data,
          })
            .then(({ data: result }) => {
              setDataTableSwitches((prev) => {
                const newContent = prev.content.map((element) => {
                  if (info.pk === element.pk) {
                    const newObject = { ...result.result, callflow: element.callflow }
                    element = newObject
                  }
  
                  return element
                })
  
                switches.current = newContent
                return {
                  ...prev,
                  content: [...newContent]
                }
              })
              setLoadingSwitch(false);
              closeModal();
            })
            .catch((error) => console.log(error));
        } else if (info.callflow.numbers && formSwitch.extensioNumber !== "*") {
          const dataForm = {
            switch_id: info.pk,
            data: {
              name: formSwitch.name,
              status: formSwitch.status,
            },
          };

          const dataExtensionNumber = {
            callflow_id: info.callflow_id,
            data: { numbers: [formSwitch.extensioNumber] },
          };
          
          await petitionPatch("callFlow", dataExtensionNumber)
            .then(() => {})
            .catch((error) => console.log(error))

          await petitionPatch("switch", dataForm)
            .then(async ({ data: result }) => {
              await petitionSwitch()
              setLoadingSwitch(false);
              closeModal();
            })
            .catch((error) => console.log(error))
        }
      }
    } else {
      if (checkValue && formSwitch.extensioNumber !== "*") {
        const dataForm = {
          switch_id: info.pk,
          data: {
            name: formSwitch.name,
            status: formSwitch.status,
          },
        };
        const dataNewCallFlow = {
          id: id,
          data: {
            numbers: [formSwitch.extensioNumber],
            type: "featureCode",
            name: "Switch Feature Code",
            callflow_object: {
              flow: {
                children: {},
                data: {
                  id: info.pk,
                  action: "switch",
                },
                module: "switch",
              },
            },
          },
        };
        await petitionPatch("switch", dataForm)
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));

        await petitionPost("callflow", dataNewCallFlow)
          .then(({ data: result }) => {
            petitionPatch("switch", {
              switch_id: info.pk,
              data: {
                callflow_id: result.result.pk,
              },
            })
              .then(async ({ data: response }) => {
                await petitionSwitch()
                setLoadingSwitch(false);
                closeModal();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 400) {
              setError(
                error.response.data.error.error_detailed.error.error_message
              );
              setLoadingSwitch(false);
            }
          });
      } else if (!checkValue) {
        const dataForm = {
          switch_id: info.pk,
          data: {
            name: formSwitch.name,
            status: formSwitch.status,
          },
        };

        const deleteCallFlow = {
          callflow_id: info.callflow_id,
        };

        petitionDelete("callFlow", deleteCallFlow)
          .then(({ data: result }) => {
            petitionPatch("switch", dataForm)
              .then(async ({ data: response }) => {
                await petitionSwitch()
                setLoadingSwitch(false);
                closeModal();
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      }
    }
  };

  const petitionSwitch = async () => {
    await petitionGet("switches")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
        });

        switches.current = result.result;

        setDataTableSwitches({
          columns: [{ name: "Switch Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditSwitch,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDeleteSwitch,
              },
            ],
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const petitionTimeGroups = async () => {
    await petitionGet("timeGroups")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk.toString();

          element.id = element.pk.toString();
          element.pk = element.pk.toString();
        });

        timeGroups.current = result.result;

        setDataTableTimeGroups({
          columns: [{ name: "Groups", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit Time Group",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditTimeGroup,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeModalDelete,
              },
            ],
          },
        });

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const petitonCallFlows = async () => {
    await petitionGet("allCallflows")
      .then(({ data: result }) => {
        let lengthNumbers = [];

        result.result.forEach((element) => {
          if (element.type === "CustomByUser") {
            if (element.numbers.length === 0) {
              element.numberString = "Not ASsigned";
            } else {
              element.numberString = element.numbers.map((number) => formatPhoneNumber(number)).join();

              if (element.numbers.length > 3) {
                lengthNumbers = element.numbers.slice(0, 3);
                lengthNumbers.push("...");
                element.numberString = lengthNumbers.map((number) => formatPhoneNumber(number)).join();
              } else {
                element.numberString = element.numbers.map((number) => formatPhoneNumber(number)).join();
              }
            }
          }
        });

        let tempResult = [];

        result.result.forEach((element) => {
          if (element.type === "CustomByUser") {
            tempResult.push({
              ...element,
              numbers: element.numbers.map((elementTwo) => elementTwo),
              numbersText:
                element.numbers.length > 0
                  ? element.numbers.length > 5
                    ? lengthNumbers
                    : element.numbers.map((elementTwo) => elementTwo)
                  : "Not assigned numbers",
            });
          }
        });

        setDataTableCallFlows({
          columns: [
            { name: "My Call flows", key: "name" },
            { name: "Numbers involved", key: "numberString" },
          ],
          content: [...tempResult],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit Numbers",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeEditCallFlowNumbers,
              },
              {
                name: "Edit Call flow Details",
                icon: <FontAwesomeIcon icon={faPen} />,
                handleClick: activeEditCallFlow,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteCallFlow,
              },
            ],
          },
        });
      })
      .catch((error) => console.log(error));
  };

  const accountDetails = async () => {
    await petitionGet("accountDetails")
    .then(({ data: result }) => {
      const timeZone = result?.result?.time_zone || ""

      systemTimeZone.current = timeZone
    })
    .catch((error) => console.log(error));
  }

  const petition = async (noLoading) => {
    if (!noLoading) setLoading(true);

    await accountDetails()

    await petitionSwitch();

    await petitionQueue();

    await petitonCallFlows();

    await petitionTimeGroups();
  };

  useEffect(() => {
    if (!callFlowDetails.current) {
      resetFunction();
    } else {
      setDynamicFunction(() => () => {
        reloadData();
      });
    }
  }, [callFlowDetails.current])

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_advanced_call_flows
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition()
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <div style={{ marginTop: "3rem" }} className="itp-test">
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

    {modalDelete && modalDelete !== "other" && (
      <ModalDelete
        modal={modalDelete}
        closeModal={closeModal}
        loading={!deleteCallFlows ? loadingTimeGroup : loadingCallFlows}
        modalFunction={() => {
          if (deleteCallFlows && !activeDeleteQueue) deleteCallFlow();
          if (!deleteCallFlows && !activeDeleteQueue) deleteTimeGroup();
          if (activeDeleteQueue && !deleteCallFlows) deleteQueue();
          if (activeDeleteSwitch && !deleteCallFlows) deleteSwitch();
        }}
        module={deleteCallFlows ? "Call Flow" : ""}
      />
    )}

    {!callFlowDetails.current && (
      <>
        <Callflows 
          dataTableCallFlows={dataTableCallFlows}
          activeAddCallFlow={activeAddCallFlow}
          loading={loading}
        />

        <TimeGroups 
          dataTableTimeGroups={dataTableTimeGroups}
          activeAddTimeGroup={activeAddTimeGroup}
          loading={loading}
        />
        
        <Queues 
          dataTableQueues={dataTableQueues}
          activeAddQueue={activeAddQueue}
          loading={loading}
        />

        <Switches 
          dataTableSwitches={dataTableSwitches}
          activeAddSwitch={activeAddSwitch}
          loading={loading}
        />
      </>
    )}

    {callFlowDetails.current && (
      <CallflowChart 
        toggleMenu={toggleMenu} 
        openPanelCallFlows={openPanelCallFlows} 
        toggleFullScreen={toggleFullScreen} 
        showHeader={showHeader} 
        editFlowChart={editFlowChart}
        deleteModule={deleteModule}
        editModuleWithBranch={editModuleWithBranch}
        callFlowDetails={callFlowDetails}
        setSelectedAction={setSelectedAction}
        selectedAction={selectedAction}
        closePanelCallFlows={closePanelCallFlows}
        activeEditChartCallFlow={activeEditChartCallFlow}
        activePanelCallFlows={activePanelCallFlows}
        timeGroups={timeGroups}
        isEnd={isEnd}
        queues={queues}
        switches={switches}
        voicemails={voicemails}
        users={users}
        plays={plays}
        loadingCallFlowChart={loadingCallFlowChart}
      />
    )}

    <MenuRight
      show={openMenu}
      closeMenu={closeModal}
      title={selectTitle()}
      sizeSubtitle={10}
      subtitle={selectSubTitle()}
      to=""
    >
      {openMenu && showAddTimeGroup && (
        <AddTimeGroup 
          formNameTimeGroup={formNameTimeGroup}
          setFormNameTimeGroup={setFormNameTimeGroup}
          addTimeGroup={addTimeGroup}
          loadingTimeGroup={loadingTimeGroup}
        />
      )}

      {openMenu && showAddCallFlow && (
        <AddCallflow 
          valueNameCallFlow={valueNameCallFlow}
          setValueNameCallFlow={setValueNameCallFlow}
          addCallFlow={addCallFlow}
          loadingCallFlows={loadingCallFlows}
        />
      )}

      {openMenu && showAddQueue && (
        <AddQueue 
          formQueue={formQueue}
          onChangeFormQueue={onChangeFormQueue}
          addQueue={addQueue}
          loadingQueue={loadingQueue}
        />
      )}

      {openMenu && showAddSwitch && (
        <AddSwitch 
          formSwitch={formSwitch}
          onChangeFormSwitch={onChangeFormSwitch}
          addSwitch={addSwitch}
          loadingSwitch={loadingSwitch}
        />
      )}

      {openMenu && showEditSwitch && (
        <EditSwitch 
          formSwitch={formSwitch}
          onChangeFormSwitch={onChangeFormSwitch}
          checkValue={checkValue}
          setCheckValue={setCheckValue}
          error={error}
          editSwitch={editSwitch}
          loadingSwitch={loadingSwitch}
        />
      )}
    </MenuRight>
  </div>
  );
};

export default AdvancedCallFlows;
