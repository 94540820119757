import React, { useEffect, useState } from "react";
import { UserAgent, Registerer } from "sip.js";
import onInviteCall from "./onInvite"
import config  from "../../../config.json"

function SipRegistration({ sipconfiguration, agent, setAgent, setInvitationSession, setCallSessionsFunctionEnd, callAudio, setCallAudio, ringtone, enablePhone,handleAnswerOnInvite,sessionIncall }) {
  const [isMounted, setIsMounted] = useState(true);
  const [firstConnect, setFirstConnect] = useState(true);

  const registerSip = async (enablePhonetemp) => {

    if (!enablePhonetemp) {
      unregisterSip()
      console.log("test0")
    } else {
      try {
        // console.log("registro4",agent)
        const uri = UserAgent.makeURI(`sip:${sipconfiguration.username}@${sipconfiguration.realm}`);
        // console.log(`sip:${sipconfiguration.username}@${sipconfiguration.realm}`)
        // console.log(sipconfiguration.password)
        // console.log(sipconfiguration.username)
        const userAgentOptions = {
          authorizationPassword: sipconfiguration.password,
          authorizationUsername: sipconfiguration.username,
          transportOptions: sipconfiguration.transportOptions,
          delegate: {
            onInvite: (e) => { onInviteCall(e, setInvitationSession, setCallSessionsFunctionEnd, callAudio, setCallAudio, ringtone,handleAnswerOnInvite,sessionIncall); sipconfiguration.onIncomingCall() },
            // onInvite: ()=>{console.log("lllllllllllllllllllllllllllllllllll")},
            onDisconnect: () => {
              // Manejar la desconexión aquí

              sipconfiguration.onUnregistered()
              // Intentar registrarse nuevamente después de un tiempo (por ejemplo, 5 segundos)
              if (enablePhonetemp) {
                setTimeout(() => {
                  console.log("registro6")
                  registerSip(); // Llamar a la función de registro nuevamente
                }, 5000); // Esperar 5 segundos antes de intentar de nuevo
              }
            },
            onUnregistered: () => {
              // Manejar la desconexión aquí
              sipconfiguration.onUnregistered()
              // Intentar registrarse nuevamente después de un tiempo (por ejemplo, 5 segundos)
              if (enablePhonetemp) {
                setTimeout(() => {
                  registerSip(); // Llamar a la función de registro nuevamente
                }, 5000); // Esperar 5 segundos antes de intentar de nuevo
              }
            },
          },
          uri,
          peerConnectionOptions: {
            rtcConfiguration: {
              iceServers: []
            },
            iceCheckingTimeout: config.iceTimeOut
          },
          iceCheckingTimeout: config.iceTimeOut,
          iceGatheringTimeout: config.iceTimeOut
        };

        const agentTemp = new UserAgent(userAgentOptions);
        await agentTemp.start();
        console.log("agentTemp",agentTemp);
        const registerer = new Registerer(agentTemp);
        registerer.register();
        sipconfiguration.onRegistered()

        if (isMounted) {
          setAgent(agentTemp);
          console.log("registro5", agentTemp)
        }
      } catch (error) {
        console.error("SIP registration failed:", error);
        // Manejar el error según sea necesario
      }
    }
  };

  const unregisterSip = async () => {
    try {
      // Detener y desconectar el agente SIP
      await agent.stop();
      // Realizar cualquier otra limpieza necesaria

      // setIsRegistered(false);
    } catch (error) {
      console.error("SIP unregistration failed:", error);
      // Manejar el error según sea necesario
    }
  };

  useEffect(() => {
    if (!firstConnect) {
      registerSip(enablePhone)
      // if (enablePhone) {
      console.log("registro1")
      //   registerSip(enablePhone)
      // } else {
      //   unregisterSip()
      // }
    }

  }, [enablePhone])

  useEffect(() => {
    if (!firstConnect) {
      registerSip();
      console.log("registro2")
      return () => {
        setIsMounted(false);
      };
    }
  }, [sipconfiguration, setAgent]);

  useEffect(() => {
    registerSip(enablePhone);
    console.log("registro3")
    setFirstConnect(false)

  }, [sipconfiguration.reconnected]);


  return null;
}

export default SipRegistration;