import { formatDate } from "../../utils/getDate";

const ShowDateFormated = ({ date, spliter }) => {
  const formatedDate = formatDate(date, spliter);
  const day = formatedDate.split("at ")[0];
  const hour = formatedDate.split("at ")[1];
  return (
    <>
      <span>{day}</span>
      <span className="itp-showDateFormated-hour">
        {" at "}
        {hour}
      </span>
    </>
  );
};

export default ShowDateFormated;
