import petitionPatch from "../../../../../../services/petitionPatch";

const handleBlurInputNameService = async (callflowName, currentName, selectedUsers, setCurrentName, callFlowDetails) => {
  if (callflowName !== currentName) {
    try {
      const data = {
        numbers: selectedUsers.map((element) => element.number.number),
        type: "CustomByUser",
        name: callflowName,
        callflow_object: {
          flow: callFlowDetails.flow,
          type: "CustomByUser",
        },
      };

      await petitionPatch("callFlow", { data, callflow_id: callFlowDetails.pk });
      setCurrentName(callflowName);
    } catch (error) {
      console.log(error);
    }
  }
};

export default handleBlurInputNameService;
