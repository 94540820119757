import React, { useEffect, useRef, useState } from "react";
import { TabMenu, UserAvatar } from "../../../../../components/index";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import profile from "../../../../../assets/images/profile.png";
import paperclip from "../../../../../assets/icons/paperclip.svg";
import profileIcon from "../../../../../assets/icons/profileIcon.svg";
import EditIcon from "@mui/icons-material/Edit";
import ContactDetails from "../TabsMenuDetails/ContactDetails";
import ImagesDetails from "../TabsMenuDetails/ImagesDetails";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import "./ChatMenu.css";

const ChatMenu = ({
  openMenu,
  toggleMenu,
  chatWindowData,
  setAddedOrEditedContact,
  number,
  timeZone,
  handleActiveCall,
  myExtension,
  changedNumberOrContact,
  setChangedNumberOrContact,
}) => {
  const editRef = useRef(null);
  const refForm = useRef({});

  const [editName, setEditName] = useState(false);
  const [loading, setLoading] = useState(true);
  const [addContact, setAddContact] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
  });
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onChangeName = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const activeEditName = () => {
    if (loading) return;
    setEditName(true);
  };

  const handleClickOutside = (event) => {
    if (editRef.current && !editRef.current.contains(event.target)) {
      save();
    }
  };

  const getLastColor = (tags) => {
    // Iniciamos la búsqueda desde el último elemento del array
    if (Array.isArray(tags)){
      for (let i = tags.length - 1; i >= 0; i--) {
        const obj = tags[i];
        const tag = obj.tag ? obj.tag : obj;

        // Verificamos si el objeto actual tiene el campo "tag_color" y no es nulo
        if (tag.hasOwnProperty("tag_color") && tag.tag_color !== null && tag.tag_color !== "" && tag.tag_color !== "#fff") {
          // Si el objeto tiene un color, retornamos el color
          return tag.tag_color;
        }
      }
    }
  }

  const save = () => {
    setEditName(false);
    if (refForm.current.firstname) {
      setLoading(true);

      const data = {
        ...refForm.current,
        phone: refForm.current.phone.length > 0 ? refForm.current.phone[0] === "+" ? refForm.current.phone :  `+${refForm.current.phone}` : ""
      }
      
      if (!chatWindowData.current.isContact) {
        petitionPost("contact", { data })
          .then(({ data: response }) => {
            const parameter = `/${response.result.pk}`;

            petitionGet("contacts", { parameter })
              .then(({ data: result }) => {
                const newChatWindowData = {
                  ...chatWindowData.current,
                  firstname: result?.result?.firstname,
                  lastname: result?.result?.lastname,
                  isContact: true,
                  nameContact: `${result?.result?.firstname} ${
                    result?.result?.lastname || ""
                  }`,
                  notIsSelf: {
                    ...chatWindowData.current.notIsSelf,
                    contact: { ...result?.result },
                  },
                };

                chatWindowData.current = newChatWindowData;
                forceUpdate();
                setChangedNumberOrContact(newChatWindowData);
                setAddContact(false);
              })
              .catch((error) => console.log(error));
          })
          .catch((error) => console.log(error));
      } else {
        const data = {
          firstname: refForm.current.firstname,
          lastname: refForm.current.lastname,
        };
        petitionPatch("contact", {
          data,
          contact_id: chatWindowData?.current?.notIsSelf?.contact?.pk,
        })
          .then(({ data: result }) => {
            const newChatWindowData = {
              ...chatWindowData.current,
              firstname: result?.result?.firstname,
              lastname: result?.result?.lastname,
              nameContact: `${result?.result?.firstname} ${
                result?.result?.lastname || ""
              }`,
              notIsSelf: {
                ...chatWindowData.current.notIsSelf,
                contact: {
                  ...chatWindowData.current.notIsSelf.contact,
                  ...result?.result,
                },
              },
            };

            chatWindowData.current = newChatWindowData;
            forceUpdate();
            setChangedNumberOrContact(newChatWindowData);
          })
          .catch((error) => console.log(error));
      }
    }
  };

  useEffect(() => {
    refForm.current = {
      ...refForm.current,
      ...form,
    }
  }, [form]);

  useEffect(() => {
    if (openMenu) {
      if (chatWindowData.current.isContact) {
        setForm({
          firstname:
            chatWindowData?.current?.firstname ||
            chatWindowData?.current?.numberChat,
          lastname: chatWindowData?.current?.lastname || "",
        });
      } else {
        refForm.current = {
          phone: chatWindowData?.current?.numberChat,
        };

        setForm({
          firstname: "",
          lastname: "",
        });
      }
    }
  }, [openMenu]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const tabs = [
    {
      title: (
        <img
          src={profileIcon}
          alt=""
          className="itp-chat-menu-body__icon-header"
        />
      ),
      component: (
        <ContactDetails
          chatWindowData={chatWindowData}
          openMenu={openMenu}
          form={form}
          setForm={setForm}
          setAddedOrEditedContact={setAddedOrEditedContact}
          handleActiveCall={handleActiveCall}
          myExtension={myExtension}
          loading={loading}
          setLoading={setLoading}
          addContact={addContact}
          setAddContact={setAddContact}
          changedNumberOrContact={changedNumberOrContact}
          setChangedNumberOrContact={setChangedNumberOrContact}
          refForm={refForm}
          addContactFunction={save}
        />
      ),
    },
    {
      title: (
        <img
          src={paperclip}
          alt=""
          className="itp-chat-menu-body__icon-header margin-tab"
        />
      ),
      component: (
        <ImagesDetails
          chatWindowData={chatWindowData}
          number={number}
          timeZone={timeZone}
          openMenu={openMenu}
        />
      ),
    },
  ];


  return (
    <>
      <div className="itp-chat-menu">
        <div className={`${openMenu ? "back-icon" : "d-none"}`}>
          <KeyboardDoubleArrowRightIcon
            onClick={toggleMenu}
            style={{ width: "20px", height: "20px" }}
            className={`itp-chat-windiow__header-show-profile
                        ${openMenu ? " open-menu-data" : ""}
                      `}
          />
        </div>
        <div className="itp-chat-menu-header">
          {chatWindowData.current.isContact ? (
            <UserAvatar
              firstname={chatWindowData.current.firstname}
              lastname={chatWindowData?.current?.lastname || ""}
              tagColor={getLastColor(chatWindowData?.current?.notIsSelf?.contact?.tags)}
              className={`itp-chat-window__header-user-img mt-3 ${
                editName ? "edit-name" : ""
              }`}
            />
          ) : (
            <img
              src={profile}
              alt="Chat"
              className="itp-chat-window__header-user-img rounded-circle"
            />
          )}

          <div className="mt-3">
            {!editName && (
              <div
                className={`${editName ? "" : " itp-chat-menu-container-name"}`}
              >
                <p className="itp-chat-window__header-user-name">
                  {chatWindowData.current.isContact
                    ? `${form.firstname} ${form.lastname || ""}`
                    : formatPhoneNumber(chatWindowData?.current?.numberChat)}
                </p>
                <div className="itp-chat-list-edit-icon-container">
                  {chatWindowData.current.isContact && (
                    <>
                      <EditIcon
                        className="itp-chat-list-edit-icon"
                        onClick={activeEditName}
                      />
                    </>
                  )}
                </div>
              </div>
            )}

            {editName && !loading && (
              <div ref={editRef}>
                <input
                  className="form-control-itp itp-chat-menu-input"
                  placeholder="Enter a contact first name"
                  onChange={onChangeName}
                  value={form?.firstname}
                  disabled={loading}
                  name="firstname"
                />
                <input
                  className="form-control-itp itp-chat-menu-input"
                  placeholder="Enter a contact last name"
                  onChange={onChangeName}
                  value={form?.lastname}
                  disabled={loading}
                  name="lastname"
                />
              </div>
            )}
          </div>
        </div>
        <div className="itp-chat-manu-body">
          <div className="itp-chat-menu-body__header">
            <TabMenu tabs={tabs} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatMenu;
