import React, { useEffect, useState } from "react";

const ExternalTransfer = ({ saveModule, edit, data, editModule }) => {
  const [form, setForm] = useState({
    number: edit ? data.data.destination : "",
    require_keypress: edit ? data.data.confirm_call : false,
    keep_caller_id: edit ? data.data.keep_original_callerid : true,
    timeout: edit ? data.data.dial_timeout : "30",
  });

  const handleChangeForm = (e) => {
    if (
      e.target.name === "require_keypress" ||
      e.target.name === "keep_caller_id"
    ) {
      setForm({
        ...form,
        [e.target.name]: e.target.value === "true" ? true : false,
      });
    } else {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  const saveExternalTransfer = () => {
    if (edit) {
      editModule(form, "externaltransfer");
    } else {
      saveModule(form, "externaltransfer");
    }
  };

  useEffect(() => {
    console.log(data);
    if (data && edit) {
      setForm({
        number: edit
          ? data.data.destination
            ? data.data.destination
            : data.data.number
          : "",

        require_keypress: edit
          ? data.data.confirm_call
            ? data.data.confirm_call
            : data.data.require_keypress
          : false,

        keep_caller_id: edit
          ? data.data.keep_original_callerid
            ? data.data.keep_original_callerid
            : data.data.keep_caller_id
          : true,

        timeout: edit
          ? data.data.dial_timeout
            ? data.data.dial_timeout
            : data.data.timeout
          : "30",
      });
    }
  }, [data, edit]);

  return (
    <>
      <label className="itp-label-input__label mt-4">External Number</label>
      <input
        className="form-control-itp"
        value={form.number}
        name="number"
        onChange={handleChangeForm}
        type="number"
      />

      <label className="itp-label-input__label mt-4">Timeout</label>
      <input
        className="form-control-itp"
        value={form.timeout}
        name="timeout"
        onChange={handleChangeForm}
        type="number"
      />

      <label className="itp-label-input__label mt-4">Confirm Call?</label>
      <select
        onChange={handleChangeForm}
        value={form.require_keypress}
        name="require_keypress"
        className="form-select-itp"
      >
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>

      <label className="itp-label-input__label mt-4">
        Kepp Caller's Caller ID?
      </label>
      <select
        onChange={handleChangeForm}
        value={form.keep_caller_id}
        name="keep_caller_id"
        className="form-select-itp"
      >
        <option value="" disabled>
          Select option
        </option>
        <option value={true}>Yes</option>
        <option value={false}>No</option>
      </select>

      <button
        onClick={saveExternalTransfer}
        className="btn-primary mt-4 loading"
        disabled={!form.number || !form.timeout}
      >
        Save
      </button>
    </>
  );
};

export default ExternalTransfer;
