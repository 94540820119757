import React, { useEffect, useState } from "react";
import { count } from "sms-length";
import { TabMenu, TextAreaVarSelect } from "../../../../../components";
import ImageUploading from "react-images-uploading";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";

const Step2 = ({
  form,
  setForm,
  handleChangeForm,
  imgMessages,
  setImgMessages,
  optionsMessageBody,
}) => {
  const [contMessage, setContMessage] = useState({});

  const onChangeOneImage = (e) => {
    const mms_file = e[0]?.data_url.split("base64,")[1];
    const mms_mime = `${e[0]?.data_url.split("base64,")[0]}base64,`;
    const media_filename = e[0]?.file.name;

    setForm({ ...form, mms_file, mms_mime, media_filename });
    setImgMessages(e);
  };

  const handleChangeMessageBody = (e) => {
    setForm({ ...form, message_text: e })
  }

  useEffect(() => {
    if (form.message_text) {
      setContMessage(count(form.message_text))
    }
  }, [form.message_text])

  const tabs = [
    {
      title: "SMS",
      component: (
        <>
          <label className="itp-label-input__label">Message Body Text</label>
          <TextAreaVarSelect
            options={optionsMessageBody}
            onChangeTextArea={handleChangeMessageBody}
            textAreaDefaultValue={form.message_text}
          />
          {form.message_text && (
            <>
              <p>Characters: {contMessage.length}/{contMessage.characterPerMessage}</p>
              <p>Messages: {contMessage.messages}</p>
            </>
          )}
        </>
      ),
    },
    {
      title: "Image",
      component: (
        <>
          <ImageUploading
            multiple
            value={imgMessages}
            onChange={(e) => {
              onChangeOneImage(e);
            }}
            maxNumber={1}
            dataURLKey="data_url"
            imgExtension={["jpg", "gif", "png", "jpeg"]}
            maxFileSize={3145728}
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="row mt-3">
                {imageList.map((image, index) => (
                  <div key={index} className={`upload__image-wrapper col-4`}>
                    <div key={index} className="image-item">
                      <img src={image["data_url"]} alt="" width="100" />
                      <div className="image-item__btn-remove mt-3 mb-3">
                        <button
                          className="btn-primary"
                          onClick={() => onImageRemove(index)}
                        >
                          {" "}
                          <HighlightOffIcon />
                        </button>
                      </div>
                      <div className="image-item__btn-edit">
                        <button
                          className="btn-primary"
                          onClick={() => onImageUpdate(index)}
                        >
                          <EditIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                {!imageList.length && (
                  <div
                    className={`upload__image-wrapper col-4 ${
                      isDragging && "upload__image-wrapper-hover"
                    }`}
                    {...dragProps}
                  >
                    <button
                      className="btn-primary loading mt-4"
                      onClick={onImageUpload}
                    >
                      <AddIcon />
                      <span>Add Image</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </>
      ),
    },
  ];

  return (
    <>
      <TabMenu tabs={tabs} />
    </>
  );
};

export default Step2;
