import Skeleton from "react-loading-skeleton";
import { CollapsePanel } from "../../../../../../../../components";

const BasicSettings = ({ form, handleChangeForm, handleBlurInputs, loading }) => (
  <CollapsePanel
    title="Basic Settings"
    subtitle="Edit the description and caller id of the number"
  >
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column">
        <label className="itp-edit-number-label-1">Description</label>
        <label className="itp-edit-number-label-2">
          Add a description to this number
        </label>
      </div>

      {loading ? (
        <div className="itp-edit-number-input">
          <Skeleton height="100%" width="100%" />
        </div>
      ) : (
        <input
          placeholder="Add Name"
          className="form-control-itp itp-edit-number-input"
          name="description"
          value={form.description}
          onChange={handleChangeForm}
          onBlur={() => handleBlurInputs("description")}
        />
      )}
    </div>

    <div className="d-flex justify-content-between mt-4">
      <div className="d-flex flex-column">
        <label className="itp-edit-number-label-1">Caller ID (CNAM)</label>
        <label className="itp-edit-number-label-2">
          Change the Name that appears while dialing other numbers
        </label>
      </div>

      {loading ? (
        <div className="itp-edit-number-input">
          <Skeleton height="100%" width="100%" />
        </div>
      ) : (
        <input
          placeholder="Add Name"
          className="form-control-itp itp-edit-number-input"
          name="caller_id_name"
          value={form.caller_id_name}
          onChange={handleChangeForm}
          onBlur={() => handleBlurInputs("caller_id_name")}
        />
      )}
    </div>

    <div className="d-flex justify-content-between mt-4">
      <div className="d-flex flex-column">
        <label className="itp-edit-number-label-1">Caller ID Prefix</label>
        <label className="itp-edit-number-label-2">
          Add prefix text to the Caller ID for inbound Calls
        </label>
      </div>

      {loading ? (
        <div className="itp-edit-number-input">
          <Skeleton height="100%" width="100%" />
        </div>
      ) : (
        <input
          placeholder="Add Prefix"
          className="form-control-itp itp-edit-number-input"
          name="callerid_prefix"
          value={form.callerid_prefix}
          onChange={handleChangeForm}
          onBlur={() => handleBlurInputs("callerid_prefix")}
        />
      )}
    </div>
  </CollapsePanel>
);

export default BasicSettings;
