const Image = ({ element, urlBase, api_id, token, handleClick, showFullScreen, handleClose }) => {
  const showImage = element.message_mms_media && element.mms_file_type !== "vcf" && element.item_type !== "call" && !element.audio_message ? true : false

  const showImageUrl = element.url && element.mms_file_type !== "vcf" && element.item_type !== "call" && !element.audio_message ? true : false

  const showImageFullScreen = showFullScreen && showFullScreen === element?.message_mms_media && element.item_type !== "call" && !element.audio_message ? true : false

  if (showImage) {
    return (
      <img
        src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
        className="img-fluid itp-chat-window-footer-img"
        onClick={() => handleClick(element.message_mms_media)}
      />
    )
  } else if (showImageUrl) {
    return (
      <img
        src={element.url}
        className="img-fluid itp-chat-window-footer-img"
      />
    )
  } else if (showImageFullScreen) {
    return (
      <div
        className="container-img-full-screen"
        onClick={handleClose}
      >
        <img
          src={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
          alt="FullScreen"
          className="img-full-screen"
        />
      </div>
    )
  }
};

export default Image;
