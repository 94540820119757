import React, { useEffect, useRef, useState } from "react";
import { CustomTableMain, DateReportCalls, AlignedRow } from "../../../../../components";
import { selectDefaultColumns } from "../../const";
import contacts from "../../../../../assets/icons/contacts.svg";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import "./DataReport.css";

const ViewLogs = ({
  data,
  loadingCall,
  From,
  to,
  completedLogs,
  selectedReport,
  loading,
  onChangeFilterqueueLog,
  onChangeFilterqueueMemberLogs,
  currentDataQueueLog,
  allResult,
  loadingFilter,
  selectedFilter,
  itemsPerPage,
  loadingTable,
  pagination,
}) => {
  const objtReport = {
    Users: selectDefaultColumns.userNumberLogs,
    Numbers: selectDefaultColumns.userNumberLogs,
    Queues: selectDefaultColumns.queueLogs,
    "Queue Members": selectDefaultColumns.queueMemberLogs,
  }

  const dataTable = useRef({ columns: objtReport[selectedReport], });

  const [valueLog, setValueLog] = useState("all");
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const onChangeLogs = (e) => {
    if (loading || loadingTable || loadingFilter) return;

    setValueLog(e.target.value);
    if (e.target.value === "all") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.all_calls],
      };
      forceUpdate();
    }

    if (e.target.value === "in") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.incoming_calls],
      };
      forceUpdate();
    }

    if (e.target.value === "out") {
      dataTable.current = {
        ...dataTable.current,
        content: [...completedLogs.outgoing_calls],
      };
      forceUpdate();
    }
  };

  const onChangeQueueLogs = (e) => {
    if (loading || loadingTable || loadingFilter) return;

    if (!loadingFilter) {
      setValueLog(e.target.value);
      onChangeFilterqueueLog(e.target.value);
    }
  };

  const onChangeQueueMemberLogs = (e) => {
    if (loading || loadingTable || loadingFilter) return;

    if (!loadingFilter) {
      setValueLog(e.target.value);
      onChangeFilterqueueMemberLogs(e.target.value);
    }
  };

  const activeItemsPerPageUserLogs = (data) => {
    if (loading || loadingTable || loadingFilter) return;

    itemsPerPage.current = data;
    forceUpdate();
  };

  useEffect(() => {
    if (data) {
      dataTable.current = data;
      forceUpdate();
    } else {
      setValueLog("all");
    }
  }, [data]);

  return (
    <>
      {selectedReport !== "Numbers" && (
        <>
          <AlignedRow noCentered>
            <h3 className="itp-accordion-header-title">
              {selectedReport === "Queues"
                ? currentDataQueueLog?.queue_object?.queue_name
                : selectedReport === "Queue Members"
                ? currentDataQueueLog?.userName
                : selectedReport === "Numbers" 
                ? completedLogs.number	 
                : completedLogs.user}
            </h3>
          </AlignedRow>

          <AlignedRow noCentered>
            <h6 className="itp-accordion-header-subtitle">
              Individual call logs for this{" "}
              {selectedReport.slice(0, selectedReport.length - 1)}
            </h6>
          </AlignedRow>
        </>
      )}

      <div className="mb-5">
        <CustomTableMain
          data={dataTable.current}
          isBackend={selectedReport === "Queues" || selectedReport === "Queue Members" ? true : false}
          showSelectSingleCheckbox={true}
          loadingIcon={loadingCall}
          loading={loading || loadingFilter}
          loadingPagItems={loadingTable}
          module="Logs"
          paginationData={parseInt(itemsPerPage.current)}
          totalPages={allResult?.total_pages}
          onPageChange={dataTable?.current?.changePage}
          onChangeItemsPerPage={selectedReport === "Queues" || selectedReport === "Queue Members" ? dataTable.current.itemsPerPage : activeItemsPerPageUserLogs }
          offset={pagination?.current?.offset}
          m3={true}
          columnConfig={{
            "Date & Time": {
              type: "date",
            },
            Disposition: {
              type: "badge",
              keyStyles: "style",
            },
            Date: {
              type: "date",
            },
            Contact: {
              type: "rowLink",
              to: "/Contacts?",
              key: "contact.pk",
              icon: (
                <img style={{ marginRight: "0.2rem" }} src={contacts} alt="" />
              ),
            },
            From: {
              type: "hoverInfo",
              hoverKey: "fromNumberHover",
              text: "Extension Number :",
            },
            To: {
              type: "hoverInfo",
              hoverKey: "toNumberHover",
              text: "Extension Number :",
            },
          }}
          actionsLeft={ [
            {
              component: (
                <>
                  {selectedReport === "Users" && (
                    <select
                      onChange={onChangeLogs}
                      value={valueLog}
                      className="form-select-itp width-12r"
                    >
                      <option value="all">All Calls</option>
                      <option value="in">Inbound</option>
                      <option value="out">Outbound</option>
                    </select>
                  )}

                  {selectedReport === "Numbers" && (
                    <>
                      <h3 className="itp-accordion-header-title">
                        {formatPhoneNumber(completedLogs.number)}
                      </h3>

                      <h6 className="itp-accordion-header-subtitle mb-0">
                        Individual call logs for this Number
                      </h6>
                    </>
                  )}

                  {selectedReport === "Queues" && (
                    <select
                      onChange={onChangeQueueLogs}
                      value={valueLog}
                      className="form-select-itp width-12r"
                    >
                      <option value="all">All Calls</option>
                      <option value="Abandoned">Abandoned</option>
                      <option value="Completed by Agent">
                        Completed by Agent
                      </option>
                      <option value="Completed by Caller">
                        Completed by Caller
                      </option>
                      <option value="Exit With Timeout">
                        Exit With Timeout
                      </option>
                    </select>
                  )}

                  {selectedReport === "Queue Members" && (
                    <select
                      onChange={onChangeQueueMemberLogs}
                      defaultValue={selectedFilter}
                      className="form-select-itp width-12r"
                    >
                      <option value="Answered Calls">
                        Answered Calls
                      </option>
                      <option value="Missed Calls">Missed Calls</option>
                    </select>
                  )}
                </>
              ),
            },
          ]}
          actionsRight={[
            {
              component: (
                <DateReportCalls
                  reportDateText="Report Date :"
                  from={From}
                  to={to}
                  totalCallsText="Total Calls :"
                  totalCalls={
                    selectedReport === "Queues"
                      ? currentDataQueueLog?.total_calls
                      : dataTable?.current?.content?.length
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default ViewLogs;
