import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { Loading, LoadingButton, MenuRight, CustomTableMain, AddButton } from "../../../../components/index.js";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import ModalDelete from "../ModalUtility/ModalDelete.js";
import petitionGet from "../../../../services/petitionGet.js";
import petitionPost from "../../../../services/petitionPost.js";
import petitionDelete from "../../../../services/petitionDelete.js";
import informationBlue from "../../../../assets/icons/information-blue.svg";
import apiKey from "../../../../assets/images/apiKey.svg";
import useScreenSize from "../../../../hooks/useScreenSize.js";
import Select from "react-select";

const APIKeys = () => {
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  const { width } = useScreenSize();

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [apiKeys, setApiKeys] = useState([]);
  const [users, setUsers] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [valueAsignedUser, setValueAsignedUser] = useState(null);
  const [loadingApiKey, setLoadingApiKey] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Api Key", key: "api_key" },
      { name: "User", key: "user_name" },
    ],
    actions: { title: "keys", content: [] }
  });

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const closeMenu = () => {
    setModalDelete(false);
    setInfo(null);
    setValueAsignedUser(null);
    setOpenMenu(false);
  };

  const activeAddApiKey = () => {
    if (loading) return;
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const handleChangeUser = (e) => {
    setValueAsignedUser(e);
  };

  const reloadData = () => {
    closeMenu();
    petition();
  };

  const deleteApiKey = () => {
    setLoadingApiKey(true);
    petitionDelete("apiKey", { api_key_id: info.pk })
      .then(({ data: result }) => {
        setLoadingApiKey(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addApiKey = () => {
    setLoadingApiKey(true);

    let data = {
      user_id: valueAsignedUser.pk,
    };

    petitionPost("apiKeys", { data })
      .then(({ data: result }) => {
        setLoadingApiKey(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    setLoading(true);
    let users = [];
    await petitionGet("users")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.value = element.pk;
          element.label = `${element.firstname} ${element.lastname} ${element.presence_id}`;
        });

        users = result.result;
        setUsers(result.result);
      })
      .catch((error) => console.log(error));
    await petitionGet("apiKeys", { parameter: "?user_null=false" })
      .then(({ data: result }) => {
        let keys = [];

        result.result.forEach((element) => {
          let findUser = users.find(
            (elementTwo) => elementTwo.pk.toString() === element.user_id
          );

          if (findUser) {
            keys.push({
              ...element,
              user_name: `${findUser.firstname} ${findUser.lastname}`,
            });
          }
        });

        setDataTable({
          columns: [
            { name: "Api Key", key: "api_key" },
            { name: "User", key: "user_name" },
          ],
          content: [...keys],
          actions: {
            title: "Keys",
            content: [
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
        });

        setApiKeys(keys);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_api_keys
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition()
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <div style={{ marginTop: "3rem" }}>
      {!loading && apiKeys.length === 0 ? (
            <div id="generalsettings">
              <div className="itp-table-container-centered">
                <Row>
                  <Col>
                    <div className="text-align-with-table">
                      <span className="table-alt-text-title">API Keys</span>
                      <br></br>
                      <span className="table-alt-text-title-description"></span>
                    </div>
                    <Card className="itp-my-settings-card-col">
                      <CardBody>
                        <Row className="itp-alert-text alert-background-default d-flex align-items-center">
                          <Col md={12}>
                            <div className="itp-alert-icon-default">
                              <img
                                src={informationBlue}
                                alt="myExtensionTemp-icon"
                              />
                            </div>

                            <div className="itp-alert-data-default">
                              No api keys have been generated yet!
                            </div>
                          </Col>
                        </Row>
                        <Row className="itp-api-key__add-key-row">
                          <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                            <img src={apiKey} alt="" />
                          </Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                            <h6 className="itp-api-key__add-title-default">
                              Add you first API Key
                            </h6>
                            <p className="itp-api-key__add-text-default">
                              API Keys can be used to integrate 3rd party
                              systems with your account. Be careful with these
                              keys as they can access and delete all information
                              within your account.
                            </p>
                            <button
                              onClick={activeAddApiKey}
                              className="btn-primary itp-api-key__add-button"
                            >
                              Generate Key
                            </button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
      ) : (
        <>
          {modalDelete && (
                <ModalDelete
                  modal={modalDelete}
                  closeModal={closeMenu}
                  loading={loadingApiKey}
                  modalFunction={deleteApiKey}
                  module="Api Key"
                />
          )}

          <CustomTableMain
            data={dataTable}
            module="api keys"
            centered={width >= 1697 ? true : false}
            loading={loading}
            actionsLeft={[
                  {
                    component: (
                      <div className="d-flex flex-column gap-2">
                        <h3 className="itp-accordion-header-title">API Keys</h3>
                        <h6 className="itp-accordion-header-subtitle">
                          Generate an API Key if you wish to use our API. API documention can be found on our website: <a href="https://docs.voice360.app">https://docs.voice360.app</a>
                        </h6>
                        <h6 className="itp-accordion-header-subtitle">
                          Your Account ID is {id} 
                        </h6>
                      </div>
                    )
                  }
            ]}
            actionsRight={[
                  {
                    component: (
                      <AddButton 
                        handleClick={activeAddApiKey}
                        name="Add User API Keys"
                      />
                    )
                  }
            ]}
          />
        </>
      )}

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Add Api Key"
        subtitle="Select User for Api Key"
        to="to Api Keys"
      >
        <label>Assigned User</label>
        <Select
          onChange={(e) => handleChangeUser(e)}
          className="basic-single add-lead-select"
          classNamePrefix="select"
          value={valueAsignedUser}
          isSearchable={true}
          name="contact_list_id"
          placeholder="Select User"
          options={users}
        />
        <button
          className="btn-primary loading add-lead-new-contact"
          disabled={valueAsignedUser ? false : true}
          onClick={addApiKey}
        >
          {loadingApiKey && <LoadingButton />}
           Add
        </button>
      </MenuRight>
    </div>
  );
};

export default APIKeys;
