const onNotifyFunction = (notification, transferNumber, setCallSessionsFunctionEnd, handleCancel) => {
    try {
        // Extract the Subscription-State header
        const subscriptionStateHeader = notification.request.headers['Subscription-State']?.[0]?.raw;
        console.log('Received NOTIFY:', subscriptionStateHeader);

        // Respond to NOTIFY with 200 OK
        notification.accept().then((data) => {
            console.log("successNotify", data);
        }).catch((error) => {
            console.error("errorNotify", error);
        });

        // Handle different states in Subscription-State header
        if (subscriptionStateHeader) {
            if (subscriptionStateHeader.includes('terminated')) {
                console.log('Received SIP notify termination confirmation');
                // Handling for terminated state
                setTimeout(() => {
                    transferNumber.bye();
                    setCallSessionsFunctionEnd(transferNumber);
                    handleCancel();
                }, 1000);
            } else if (subscriptionStateHeader.includes('active')) {
                // Handling for active state
                console.log('Subscription active');
            } else {
                // Handling for other states
                console.log('Other subscription state:', subscriptionStateHeader);
            }
        }
    } catch (error) {
        console.error("Error handling NOTIFY message:", error);
    }
}

export default onNotifyFunction;