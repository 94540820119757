import petitionDelete from "../../../../../services/petitionDelete";

const deleteContactService = async (setLoading, contact_id, allResult, pag, reloadData) => {
  try {
    setLoading(true);
    await petitionDelete("contact", { contact_id });

    setLoading(false);
    if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
      const paginationTemp = {
        offset: pag.current.offset - pag.current.limit,
        limit: pag.current.limit,
      };
      reloadData(false, paginationTemp);
    } else {
      reloadData();
    }
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export default deleteContactService;
