import React, { useState } from "react";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
import backgroundImage from "../../../assets/images/difuminedImage.png";
import axios from "axios";
import json from "../../../config.json";
import ModalRedirect from "./ModalUtility/ModalRedirect";

const SignUp = () => {
  const urlBasePublic = json.prod ? json.public.prod : json.public.dev;

  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorCatpcha, setErrorCatcha] = useState(false)
  const [validEmail, setValidEmail] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [sessionTimeOut, setSessionTimeOut] = useState(false)

  const stepOneGenerateCode = (form) => {
    setLoading(true);

    const url = `${urlBasePublic}/crm/itpvoice/email-verification?g-recaptcha-response=${captchaValue}`;
    const data = form;

    axios
      .post(url, data)
      .then(({ data: result }) => {
        setEmail(data.email);
        setStep(step + 1);
        setLoading(false);
        setStep(step + 1);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          setErrorCatcha(true)
        } else if (error?.response?.status === 400) {
          setCaptchaValue(null)
          setValidEmail(false)
        }

        setLoading(false);
      });
  };

  const stepTwoVerifyCode = (code) => {
    setLoading(true);

    const url =`${urlBasePublic}/crm/itpvoice/email-verification/verify?g-recaptcha-response=${captchaValue}`;
    const data = { email, code };

    axios
      .post(url, data)
      .then(({ data: result }) => {
        setLoading(false);
        setStep(step + 1);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 400) {
          setErrorMessage(
            error?.response?.data?.error?.error_message ||
              "An Error Has Ocurred, please try again"
          );
        } else if (error?.response?.status === 401) {
          setSessionTimeOut(true)
        }
        setLoading(false);
      });
  };

  const stepThreeConfirmPassword = (pass) => {
    setPassword(pass);
    setStep(step + 1);
  };

  const stepFourOrderAccount = (form) => {
    setLoading(true);

    const url = `${urlBasePublic}/crm/itpvoice/order?g-recaptcha-response=${captchaValue}`;
    const data = {
      ...form,
      email,
      password,
    };

    axios
      .post(url, data)
      .then(({ data: result }) => {
        if (result?.result && Object.keys(result?.result).length) {
          const id = result.result.pk;
          const service_address_id = result.result.service_address_id;

          const data = { service_address_id };
          const url = `${urlBasePublic}/crm/itpvoice/${id}/order-number?g-recaptcha-response=${captchaValue}`;

          axios
            .post(url, data)
            .then(({ data: result }) => {
              const number = result.result.added_number;
              const firstThree = `(${number.slice(2, 5)})`;
              const secondThree = number.slice(5, 8);
              const lastFour = number.slice(8);
              const formatedNumber = `${firstThree} ${secondThree} - ${lastFour}`;

              setPhoneNumber(formatedNumber);
              setLoading(false);
              setStep(step + 1);
            })
            .catch((error) => {
              console.log(error)
              setLoading(false);
            });
        } else {
          setAlreadyExist(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);

        if (error?.response?.status === 401) {
          setSessionTimeOut(true)
        }

        if (error?.response?.status === 400) {
          const messageError = "There is already another contact with this email."

          if (error?.response?.data?.error?.error_message === messageError) {
            setAlreadyExist(true);
          }
        }

        setLoading(false);
      });
  };

  const nextStep = (data) => {
    switch (step) {
      case 0:
        return stepOneGenerateCode(data);
      case 1:
        return stepTwoVerifyCode(data);
      case 2:
        return stepThreeConfirmPassword(data);
      case 3:
        return stepFourOrderAccount(data);
    }
  };

  const backToStepOne = () => {
    setStep(0)
    setEmail("")
    setPassword("")
    setPhoneNumber("")
    setAlreadyExist(false)
    setErrorMessage("")
    setErrorCatcha("")
    setValidEmail(true)
    setCaptchaValue(null)
    setSessionTimeOut(false)
  }

  return (
    <>
      <figure>
        <img
          className="itp-signUp-image position-relative"
          src={backgroundImage}
        />
      </figure>

      {sessionTimeOut && (
        <ModalRedirect 
          modal={sessionTimeOut}
          modalFunction={backToStepOne}
        />
      )}

      {step === 0 && (
        <Step1 
          nextStep={nextStep} 
          loading={loading} 
          errorCatpcha={errorCatpcha}
          setErrorCatcha={setErrorCatcha}
          validEmail={validEmail}
          setValidEmail={setValidEmail}
          captchaValue={captchaValue}
          setCaptchaValue={setCaptchaValue}
        />
      )}

      {step === 1 && (
        <Step2
          nextStep={nextStep}
          loading={loading}
          errorMessage={errorMessage}
        />
      )}

      {step === 2 && <Step3 nextStep={nextStep} loading={loading} />}

      {step === 3 && (
        <Step4
          nextStep={nextStep}
          alreadyExist={alreadyExist}
          loading={loading}
        />
      )}

      {step === 4 && (
        <Step5
          nextStep={nextStep}
          email={email}
          password={password}
          number={phoneNumber}
        />
      )}
    </>
  );
};

export default SignUp;
