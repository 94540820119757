import petitionPost from "../../../../../services/petitionPost";

const addContactService = async (setLoading, form, back, reloadData, setError) => {
  try {
    setLoading(true);

    const data = {
      ...form,
      phone: form.phone.length > 0 ? `+${form.phone}` : "",
      contact_list_id: form.contact_list_id,
    };

    await petitionPost("contact", { data });

    setLoading(false);
    back();
    reloadData();
  } catch (error) {
    console.log(error);

    if (error.response.status === 400) {
      const errorDetailed = error.response.data.error.error_detailed;
      setError(errorDetailed);
    }

    setLoading(false);
  }
};

export default addContactService;
