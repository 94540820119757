import petitionGet from "../../../../../../services/petitionGet";

const fetchUsers = async () => {
  const { data: result } = await petitionGet("users")
  result.result.forEach((user) => {
    user.name = `${user.firstname} ${user.lastname || ""}`;
    user.label = `${user.firstname} ${user.lastname || ""}`;
    user.value = user.pk;
  });

  return result.result
}

export default fetchUsers;