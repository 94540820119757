import { downloadCsv } from "../../utils";
import petitionPost from "../../../../../services/petitionPost";

const exportContactsService = async (setLoading, selectAll, arrayContacts, contactFilters, closeMenu) => {
  try {
    setLoading(true);

    const data = {
      contacts: selectAll ? [] : arrayContacts,
    };

    const splterParameter = contactFilters.current.split("&");
    const joinQuery = splterParameter.length > 2 ? splterParameter.slice(2).join("&") : "";
    const formatedParameter = joinQuery ? `?${joinQuery}` : "";
    const parameter = selectAll ? formatedParameter : "";

    const { data: result } = await petitionPost("exportContacts", { data, parameter  })
    const fileName = "contacts.csv"
    const dataCSV = result.result

    downloadCsv(dataCSV, fileName);
    setLoading(false);
    closeMenu(false);
  } catch (error) {
    console.log(error);
  }
};

export default exportContactsService;
