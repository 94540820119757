import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { ChartLine, CustomTableMain, DateReportCalls, AlignedRow } from "../../../../../components";
import { numberReportService, queueLogsService, queueMemberLogsService, queueMemberReportService, queueReportService, userReportService } from "../../services";
import { selectDefaultColumns } from "../../const";
import Skeleton from "react-loading-skeleton";
import view from "../../../../../assets/icons/view.svg";
import callCalling from "../../../../../assets/icons/callCalling.svg";
import ViewLogs from "./ViewLogs";
import "./DataReport.css";
import CallEvents from "./CallEvents";

const DataReport = ({
  selectedReport,
  selectedUsers,
  selectedNumbers,
  selectedQueuesReport,
  selectedQueueMembersReport,
  timeframe,
  viewLogs,
  setViewLogs,
  activeViewLogs,
  loadingCall,
  timeZone,
  completedLogs,
  callEvents,
  setCallEvents,
  totalUsers,
  totalQueues,
}) => {
  const From = `${timeframe.timeStart.split("-")[1]}-${timeframe.timeStart.split("-")[2]}-${timeframe.timeStart.split("-")[0]}`;
  const to = `${timeframe.timeEnd.split("-")[1]}-${timeframe.timeEnd.split("-")[2]}-${timeframe.timeEnd.split("-")[0]}`;

  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const itemsPerPage = useRef("10");
  const itemsPerPageFrontEnd = useRef("10");

  const currentDataQueueLog = useRef(null);
  const filterDataQueueLog = useRef("");
  const firstLoad = useRef(true)

  filterDataQueueLog.current = "&outcome=Answered Calls";

  const [loading, setLoading] = useState(true);
  const [totalCalls, setTotalCalls] = useState(0);
  const [xLabel, setXLabel] = useState([]);
  const [yLabel, setYLabel] = useState([]);

  const [xlabelQueueReportPerHour, setXlabelQueueReportPerHour] = useState([]);
  const [ylabelQueueReportPerHour, setYlabelQueueReportPerHour] = useState([]);
  const [xlabelQueueReportDay, setXlabelQueueReportDay] = useState([]);
  const [ylabelQueueReportDay, setYlabelQueueReportDay] = useState([]);
  const [xlabelQueueReportPerMonth, setXlabelQueueReportPerMonth] = useState([]);
  const [ylabelQueueReportPerMonth, setYlabelQueueReportPerMonth] = useState([]);
  const [xlabelQueueReportPerYear, setXlabelQueueReportPerYear] = useState([]);
  const [ylabelQueueReportPerYear, setYlabelQueueReportPerYear] = useState([]);
  const [loadingViewLogs, setLoadingViewLogs] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("Answered Calls");
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataTable, setDataTable] = useState({
    columns: selectDefaultColumns[selectedReport],
    actions: { title: "Actions", content: [] },
  });
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const activeItemsPerPageQueueLogs = (data) => {
    setLoadingTable(true);

    const paginationTemp = { offset: 0, limit: parseInt(data) };

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true, true );
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const activeItemsPerPageQueueMemberLogs = (data) => {
    setLoadingTable(true);

    const paginationTemp = { offset: 0, limit: parseInt(data) };

    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const changePageQueueLogs = (offset) => {
    setLoadingTable(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true);
    pagination.current = paginationTemp;
  };

  const changePageQueueMemberLogs = (offset) => {
    setLoadingTable(true)

    const paginationTemp = {
      offset: offset,
      limit: pagination.current.limit,
    };

    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, filterDataQueueLog.current, true);
    pagination.current = paginationTemp;
  };

  const onChangeFilterqueueLog = (value) => {
    setLoadingFilter(true);
    const paginationTemp = {
      offset: 0,
      limit: 10,
    };

    let parameterFilter = "";

    if (value !== "all") {
      parameterFilter = `&outcome=${value}`;
    }

    petitionQueueLogs(currentDataQueueLog.current, paginationTemp, parameterFilter, true);
    pagination.current = paginationTemp;
    filterDataQueueLog.current = parameterFilter;
  };

  const onChangeFilterqueueMemberLogs = (value) => {
    setLoadingFilter(true);
    const paginationTemp = {
      offset: 0,
      limit: 10,
    };

    let parameterFilter = "";

    if (value !== "all") {
      parameterFilter = `&outcome=${value}`;
    }
    petitionQueueMemberLogs(currentDataQueueLog.current, paginationTemp, parameterFilter, true);
    pagination.current = paginationTemp;
    filterDataQueueLog.current = parameterFilter;
  };

  const handleChangeItemsPerPage = (data) => {
    itemsPerPageFrontEnd.current = data
    forceUpdate()
  }

  const activeCallEvents = (e) => {
    setCallEvents(e)
  }

  const petitionQueueLogs = async (e, paginacion, filter, noLoading, isChageItems) => {
    if (firstLoad.current) {
      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Agent", key: "agentName" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        actions: { title: "Actions", content: [] }
      });
    }

    if (!noLoading) setLoadingViewLogs(true);

    try {
      const logs = await queueLogsService(e, paginacion, timeframe, isChageItems, setAllResult, filter, timeZone)

      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Agent", key: "agentName" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        content: [...logs],
        actions: { 
          title: "Actions", 
          content: [
            {
              name: "Call Events",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={callCalling}
                  alt=""
                />
              ),
              handleClick: activeCallEvents,
            },
          ] 
        },
        changePage: changePageQueueLogs,
        itemsPerPage: activeItemsPerPageQueueLogs,
      });

      setLoadingFilter(false);
      setLoadingViewLogs(false);
      setLoadingTable(false);
      firstLoad.current = false
      currentDataQueueLog.current = e;
    } catch (error) {
      console.log(error)
      setLoadingViewLogs(false);
      setLoadingFilter(false);
    }
  };

  const petitionQueueMemberLogs = async (e, paginacion, filter, noLoading, isChageItems) => {
    if (firstLoad.current) {
      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Queue Name", key: "queue_name" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        actions: { title: "Actions", content: [] }
      });
    }

    if (!noLoading) setLoadingViewLogs(true);

    try {
      const logs = await queueMemberLogsService(e, setSelectedFilter, filter, paginacion, timeframe, setAllResult, isChageItems, timeZone, selectedQueuesReport)

      setViewLogs({
        columns: [
          { name: "Date", key: "dateFormated" },
          { name: "Caller ID", key: "formatedNumber" },
          { name: "Contact", key: "contactName" },
          { name: "Queue Name", key: "queue_name" },
          { name: "Wait Time", key: "wait_time" },
          { name: "Duration", key: "duration" },
          { name: "Outcome", key: "outcome" },
        ],
        content: [...logs],
        actions: { 
          title: "Actions", 
          content: [
            {
              name: "Call Events",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={callCalling}
                  alt=""
                />
              ),
              handleClick: activeCallEvents,
            },
          ] 
        },
        changePage: changePageQueueMemberLogs,
        itemsPerPage: activeItemsPerPageQueueMemberLogs,
      });
      setLoadingFilter(false);
      setLoadingViewLogs(false);
      setLoadingTable(false);
      firstLoad.current = false
      currentDataQueueLog.current = e;
    } catch (error) {
      console.log(error)
      setLoadingViewLogs(false);
      setLoadingFilter(false);
    }
  };

  const petitionUserReport = async () => {
    setLoading(true);

    try {
      const report = await userReportService(selectedUsers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel)

      setDataTable({
        columns: [
          { name: "Name", key: "user" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Completed Calls", key: "completed" },
          { name: "Missed Calls", key: "missed" },
          { name: "Missed %", key: "missed_percentage" },
          { name: "Total Duration", key: "duration" },
          { name: "Average Duration", key: "avg_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => activeViewLogs(e),
            },
          ],
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionNumbersReport = async () => {
    setLoading(true);

    try {
      const report = await numberReportService(selectedNumbers, timeframe, timeZone, setTotalCalls, setXLabel, setYLabel)

      setDataTable({
        columns: [
          { name: "Number", key: "formatedNumber" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Completed Calls", key: "completed" },
          { name: "Missed Calls", key: "missed" },
          { name: "Missed %", key: "missed_percentage" },
          { name: "Total Duration", key: "duration" },
          { name: "Average Duration", key: "avg_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => activeViewLogs(e),
            },
          ],
        },
      });

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionQueueReports = async () => {
    setLoading(true);

    try {
      const { report, countingTotalCalls, objectHoursPerHour, ObjectCallsPerHour, objectHoursPerDay, ObjectCallsPerDay, objectHoursPerMonth, ObjectCallsPerMonth, objectHoursPerYear, ObjectCallsPerYear } = await queueReportService(selectedQueuesReport, timeframe)

      setTotalCalls(countingTotalCalls);
      setXlabelQueueReportPerHour(objectHoursPerHour);
      setYlabelQueueReportPerHour(ObjectCallsPerHour);

      setXlabelQueueReportDay(objectHoursPerDay);
      setYlabelQueueReportDay(ObjectCallsPerDay);

      setXlabelQueueReportPerMonth(objectHoursPerMonth);
      setYlabelQueueReportPerMonth(ObjectCallsPerMonth);

      setXlabelQueueReportPerYear(objectHoursPerYear);
      setYlabelQueueReportPerYear(ObjectCallsPerYear);

      setDataTable({
        columns: [
          { name: "Queue Name", key: "queueName" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Answered Calls", key: "answered_calls" },
          { name: "Abandoned Calls", key: "abandoned_calls" },
          { name: "Missed Calls", key: "missed_calls" },
          { name: "Avg Call Duration", key: "avg_call_duration" },
          { name: "Avg Hold Time", key: "avg_hold_time" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => petitionQueueLogs( e, pagination.current, filterDataQueueLog.current)
            },
          ],
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  const petitionQueueMembers = async () => {
    setLoading(true);

    try {
      const report = await queueMemberReportService(selectedQueueMembersReport, timeframe, setTotalCalls)

      setDataTable({
        columns: [
          { name: "User", key: "userName" },
          { name: "Total Calls", key: "total_calls" },
          { name: "Answered Calls", key: "answered_calls" },
          { name: "Missed Calls", key: "missed_calls" },
          { name: "Avg Call Duration", key: "avg_call_duration" },
        ],
        content: [...report],
        actions: {
          title: "Actions",
          content: [
            {
              name: "View Call logs",
              icon: (
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={view}
                  alt=""
                />
              ),
              handleClick: (e) => petitionQueueMemberLogs(e, pagination.current, filterDataQueueLog.current),
            },
          ],
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!viewLogs) {
      pagination.current = {
        offset: 0,
        limit: 10,
      };

      itemsPerPage.current = "10";
    }
  }, [viewLogs]);

  useEffect(() => {
    switch (selectedReport) {
      case "Users":
        petitionUserReport();
        break;
      case "Numbers":
        petitionNumbersReport();
        break;
      case "Queues":
        petitionQueueReports();
        break;
      case "Queue Members":
        petitionQueueMembers();
        break;
      default:
        break;
    }
  }, []);

  return (
    <div style={{ marginTop: "3rem" }}>
      {(viewLogs || loadingViewLogs) && !callEvents && (
        <div style={{ marginTop: "3rem" }}>
          <ViewLogs
            data={viewLogs}
            loadingCall={loadingCall}
            From={From}
            to={to}
            completedLogs={completedLogs}
            selectedReport={selectedReport}
            loading={loadingViewLogs}
            onChangeFilterqueueLog={onChangeFilterqueueLog}
            onChangeFilterqueueMemberLogs={onChangeFilterqueueMemberLogs}
            currentDataQueueLog={currentDataQueueLog.current}
            allResult={allResult}
            loadingFilter={loadingFilter}
            selectedFilter={selectedFilter}
            itemsPerPage={itemsPerPage}
            loadingTable={loadingTable}
            pagination={pagination}
          />
        </div>
      )}

      {callEvents && !loadingViewLogs && (
        <CallEvents  
          details={callEvents}
          timeZone={timeZone}
          totalUsers={totalUsers}
          totalQueues={totalQueues}
          selectedReport={selectedReport}
        />
      )}

      {!viewLogs && !loadingViewLogs && !callEvents && (
        <div>
          <div className={selectedReport === "Queue Members" ? "mb-5" : ""}>
            <CustomTableMain 
              data={dataTable} 
              loading={loading}
              module="reports"
              m3={true}
              showSelectSingleCheckbox={true}
              paginationData={parseInt(itemsPerPageFrontEnd.current)}
              onChangeItemsPerPage={handleChangeItemsPerPage}
              actionsRight={[
                {
                  component: (
                    <DateReportCalls 
                      reportDateText="Report Date :"
                      from={From}
                      to={to}
                      totalCallsText="Total Calls :"
                      totalCalls={totalCalls}
                    />
                  )
                }
              ]} 
            />
          </div>
     
          {selectedReport !== "Queues" && selectedReport !== "Queue Members" && (
            <AlignedRow noCentered className="mb-4">
              <Card>
                <CardBody>
                  <div className="grphic-title">
                    <span className="itp-label-input__label">
                      Call Volume per Hour
                    </span>
                  </div>
                  <div>
                    {loading ? (
                      <Skeleton height={350} width="100%" />
                    ) : (
                      <ChartLine xLabel={xLabel} yLabel={yLabel} />
                    )}
                  </div>
                </CardBody>
              </Card>
            </AlignedRow>
          )}
     
          {selectedReport === "Queues" && (
            <>
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Hour
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerHour}
                          yLabel={ylabelQueueReportPerHour}
                          sCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Day
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportDay}
                          yLabel={ylabelQueueReportDay}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Month
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerMonth}
                          yLabel={ylabelQueueReportPerMonth}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
     
              <AlignedRow noCentered className="mb-4">
                <Card>
                  <CardBody>
                    <div className="grphic-title">
                      <span className="itp-label-input__label">
                        Call Volume per Year
                      </span>
                    </div>
                    <div>
                      {loading ? (
                        <Skeleton height={350} width="100%" />
                      ) : (
                        <ChartLine
                          xLabel={xlabelQueueReportPerYear}
                          yLabel={ylabelQueueReportPerYear}
                          isCustomized={false}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </AlignedRow>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DataReport;
