import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Skeleton.css"

const MiniCardInfo = ({ imgSrc, title, info, lastInfo, loading }) => (
  <div className={`${loading ? "p-0 mb-0" : "p-3 mb-5 bg-body"} ms-4 board shadow-sm rounded`}>
    {loading ? (
      <Skeleton height={120} width="240px" />
    ) : (
      <>
        <div className="row">
          <div className="col-10 d-flex">
            {imgSrc && (
              <div
                className="rounded-pill d-flex justify-content-center align-items-center"
                style={{
                  width: "30px",
                  height: "30px",
                  background: "#3257C31F",
                }}
              >
                <img src={imgSrc} alt="" />
              </div>
            )}
            {title && (
              <div className="ms-2 pt-1">
                <p>{title}</p>
              </div>
            )}
          </div>
        </div>
        {info && (
          <div>
            <h5>{info}</h5>
          </div>
        )}
        {lastInfo && (
          <div>
            <p>{lastInfo}</p>
          </div>
        )}
      </>
    )}
  </div>
);

export default MiniCardInfo;
