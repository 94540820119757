import React from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./ColumnHeader.css"

const ColumnHeader = ({ column, defaultOrderColumn, columnNames, changeColumnOrder, loading, columns }) => {
  const putArrow = defaultOrderColumn && columnNames && columnNames[defaultOrderColumn?.key] && columnNames[defaultOrderColumn?.key] === column.name && defaultOrderColumn?.order;
  const stillNotPutArrow = defaultOrderColumn && columnNames && columnNames[defaultOrderColumn?.key] && columnNames[defaultOrderColumn?.key] !== column.name && defaultOrderColumn?.order;

  const handleClick = () => changeColumnOrder(column.name);

  const Arrow = ({ direction }) => {
    if (direction === "asc") {
      return <KeyboardArrowUpIcon className="arrow-direction" />;
    } else if (direction === "desc") {
      return <KeyboardArrowDownIcon className="arrow-direction" />;
    }
  };
  
  return (
    <th
      className={`itp-custom-table-main-th-styles ${(putArrow || stillNotPutArrow) ? 'cursor-pointer' : ''}`}
      onClick={(putArrow || stillNotPutArrow) && !loading ? handleClick : () => {}}
    >
      <div className={`d-flex ${columns.length < 2 ? "justify-content-center" : ""}`}>
        {(putArrow && !loading) && <Arrow direction={defaultOrderColumn.order} />}
        {column.name}
      </div>
    </th>
  );
}

export default ColumnHeader;