import React, { useEffect, useState } from "react";
import { CustomTableMain } from "../../../../../components";
import "./CallParkingSlots.css";

const CallParkingSlots = ({ parkingCall, loading }) => {
  const [dataParkingCall, setDataParkingCall] = useState({
    columns: [
      { name: "Parking Slot No", key: "slot_number" },
      { name: "Caller ID", key: "callerid_num" },
      { name: "Status", key: "slotStatus" },
    ],
  });

  const selectColor = (element) => {
    if (element.callerid_num) {
      return "#D60C0C";
    } else {
      return "#199F28";
    }
  };

  const selectName = (element) => {
    if (element.callerid_num) {
      return "Busy";
    } else {
      return "Available";
    }
  };

  useEffect(() => {
    if (parkingCall) {
      const content = parkingCall.map((element) => {
        element.slotStatus = selectName(element)
        return element
      })

      setDataParkingCall({
        columns: [
          { name: "Parking Slot No", key: "slot_number" },
          { name: "Caller ID", key: "callerid_num" },
          { name: "Status", key: "slotStatus" },
        ],
        content,
      });
    }
  }, [parkingCall]);

  return (
    <>
      <CustomTableMain 
        data={dataParkingCall}
        loading={loading}
        module="parking slots"
        getBorderColor={selectColor}
        getTextColor={selectColor}
        borderColumnName="Parking Slot No"
        colorColumnName="Status"
        titleInCard="Call Parking Slots"
        noCenterTitleCard={true}
        noShowPagination={dataParkingCall?.content?.length > 10 ? false : true}
        m0={true}
      />
  </>
  );
};

export default CallParkingSlots;
