import petitionPost from "../../../../../services/petitionPost";

const addContactLeadService = async (setErrorAddContact, setLoadingLead, form, addNewLead) => {
  setErrorAddContact("");
  setLoadingLead(true);

  try {
    let data = {
      firstname: form.firstname,
      lastname: form.lastname,
      phone: form.phone.length > 0 ? `+${form.phone}` : "",
      email: form.email,
      contact_list_id: form.contact_list_id,
    };

    const { data: result } = await petitionPost("contact", { data });

    let newLead = {
      contact_id: result.result.pk,
      lead_source_id: form.lead_source_id,
      funnel_pipeline_stage_id: form.funnel_pipeline_stage_id,
      funnel_id: form.funnel_id,
    };

    addNewLead(newLead);
  } catch (error) {
    console.log(error);
    if (error.response.status === 400) {
      const errorDetailed = error.response.data.error.error_detailed;
      setErrorAddContact(errorDetailed);
    }
  }
};

export default addContactLeadService;
