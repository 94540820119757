import petitionPatch from "../../../../../services/petitionPatch";

const updateFiltersService = async (myExtension, data) => {
  const { data: result } = await petitionPatch("userDetails", { data })
  const newUserDetails = {
    ...myExtension.current,
    ...result.result
  }

  myExtension.current = newUserDetails

  return newUserDetails
}

export default updateFiltersService;