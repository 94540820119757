import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { LoadingButton, MenuRight, CustomTableMain, AudioMessage, AlertNotificactions } from "../../../../../../components";
import { fetchMediDetails } from "../services";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import petitionPut from "../../../../../../services/petitionPut";
import petitionGet from "../../../../../../services/petitionGet";
import ModalDelete from "../../../ModalUtility/ModalDelete";
import petitionDelete from "../../../../../../services/petitionDelete";
import petitionPost from "../../../../../../services/petitionPost";
import useScreenSize from "../../../../../../hooks/useScreenSize";

const MediaGroupDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get('pk');
  const name = searchParams.get('name');

  const { width } = useScreenSize()

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();
  const navigate = useNavigate()

  const inputRef = useRef();
  const base64AudiosSave = useRef([])

  const [selectedOption, setSelectedOption] = useState("");
  const [reload, setReload] = useState(1);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [modalDelete, setModalDelete] = useState(false)
  const [loadingplayAudio, setLoadingplayAudio] = useState(false)
  const [base64Audio, setBase64Audio] = useState("")
  const [language, setLanguage] = useState("")
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Languaje", key: "language" },
      { name: "Media Group Name", key: "name" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const [form, setForm] = useState({
    language: "",
    tts_text: "",
    gender: "",
  });

  const [options, setOptions] = useState([
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "French", value: "fr" },
  ]);

  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaRecorder, setMediaRecorder] = useState([]);
  let audioChunks = [];

  const defaultOptions = [
    { label: "English", value: "en" },
    { label: "Spanish", value: "es" },
    { label: "French", value: "fr" },
  ];

  const updatedAlertStates = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }

  const startRecording = () => {
    let mediaRecorderTemp;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderTemp = new MediaRecorder(stream);

        mediaRecorderTemp.ondataavailable = (e) => {
          if (e.data.size > 0) {
            audioChunks.push(e.data);
          }
        };

        mediaRecorderTemp.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(audioUrl);
        };

        mediaRecorderTemp.start();
        setRecording(true);
        setMediaRecorder(mediaRecorderTemp);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder && recording) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const selectOption = (value) => {
    setSelectedOption(value);
    setOpenMenu(true);
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const closeMenu = () => {
    setLoadingplayAudio(false)
    setRecording(false);
    setAudioBlob(null);
    setAudioUrl("");
    setForm({
      language: "",
      tts_text: "",
      gender: "",
    });
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
    setSelectedOption("");
  };

  const reloadData = () => {
    closeMenu();
    petitionMedia();
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete(5);
  };

  const activePlayAudio = async (e) => {
    try {
      setOpenMenu(true)
      setSelectedOption("Play Audio")

      const findSaveAudio = base64AudiosSave.current.find((el) => el.pk === e.pk)
      const audioLenguage = defaultOptions.find((option) => option.value === e.language)

      setLanguage(audioLenguage?.label)

      if (findSaveAudio) {
        setBase64Audio(findSaveAudio.audio)
      } else {
        setLoadingplayAudio(true)
  
        const media_group_id = pk
        const media_filename = e.original_file
        const { data: result } = await petitionGet("mediaGroupsAudioFile", { media_group_id, media_filename })
  
        const type = e.original_file.split(".")[1]
        const audio = `data:audio/${type};base64,${result.result}`
        
        const newAudioObj = { pk: e.pk, audio }
        const newStateAudios = [...base64AudiosSave.current, newAudioObj]

        base64AudiosSave.current = newStateAudios
        setBase64Audio(audio)
        setLoadingplayAudio(false)
      }
    } catch (error) {
      console.log(error)
      const message = "En Error has ocurred"
      updatedAlertStates("error", "Error", message, 3000)
      closeMenu()
    }
  }

  const handleChangeSelectOption = (option) => {
    if (loading) return;
    selectOption(option)
  }

  const download = async (element) => {
    const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
    const json = require("../../../../../../config.json");
    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

    const downloadFile = async () =>
      await fetch(
        `${urlBase}/itpvoice/v2/${id}/media-groups/${element.pk}/${element.original_file}`,
        { headers: { Authorization: "Bearer " + token.replace(/['"]+/g, "") } }
      ).then((res) => res.blob());

    const createURL = (blob) => URL.createObjectURL(blob);

    const blob = await downloadFile();
    const create = createURL(blob);

    let link = document.createElement("a");
    link.download = `audio.mp3`;
    link.href = create;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
  };

  const deleteMediaFile = () => {
    setLoadingMenu(true);
    petitionDelete("mediaFile", {
      media_group_id: pk,
      filename: info.filename,
    })
      .then(({ data: result }) => {
        setLoadingMenu(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const save = () => {
    if (selectedOption === "Upload File") {
      setLoadingMenu(true);

      const formData = new FormData();
      formData.append("language", form.language);
      formData.append("file", inputRef.current.files[0]);
      petitionPut("uploadFile", {
        media_group_id: pk,
        data: formData,
      })
        .then(({ data: result }) => {
          setLoadingMenu(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else if (selectedOption === "Text To Speach") {
      setLoadingMenu(true);

      petitionPost("mediaTTS", { media_group_id: pk, data: form })
        .then(({ data: result }) => {
          setLoadingMenu(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    } else if (selectedOption === "Record Sound") {
      setLoadingMenu(true);

      const audioFile = new File([audioBlob], "audiofile.mp3", {
        type: "audio/mp3",
      });
      const formData = new FormData();
      formData.append("language", form.language);
      formData.append("file", audioFile);

      petitionPut("uploadFile", {
        media_group_id: pk,
        data: formData,
      })
        .then(({ data: result }) => {
          setLoadingMenu(false);
          reloadData();
        })
        .catch((error) => console.log(error));
    }
  };

  const petitionMedia = () => {
    setLoading(true);
    petitionGet("mediaGroups")
      .then(({ data: result }) => {
        let defaultSelectValueOptions = [];
        let newOptions = [];

        const MediaGroups = result.result;

        let dataFind = MediaGroups.find(
          (element) => element.pk === pk
        );

        dataFind.media_files.forEach((element) => {
          element.name = name;
          defaultSelectValueOptions.push(element.language);
        });

        defaultOptions.forEach((element) => {
          const data = defaultSelectValueOptions.find(
            (elementTwo) => elementTwo === element.value
          );

          if (!data) newOptions.push(element);
        });

        setOptions(newOptions);
        setDataTable({
          columns: [
            { name: "Languaje", key: "language" },
            { name: "Media Group Name", key: "name" },
          ],
          content: [...dataFind?.media_files],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faDownload} />,
                name: "Download",
                handleClick: download,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModal,
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getData = async () => {
    try {
      const mediaDetails = await fetchMediDetails(pk)

      let defaultSelectValueOptions = [];
      let newOptions = [];
  
      mediaDetails.media_files.forEach((element) => {
        element.name = name;
        defaultSelectValueOptions.push(element.language);
      });
  
      defaultOptions.forEach((element) => {
        const dataFind = defaultSelectValueOptions.find(
          (elementTwo) => elementTwo === element.value
        );
  
        if (!dataFind) newOptions.push(element);
      });
  
      setOptions(newOptions);
      setDataTable({
        columns: [
          { name: "Languaje", key: "language" },
          { name: "Media Group Name", key: "name" },
        ],
        content: [...mediaDetails?.media_files],
        actions: {
          title: "Actions",
          content: [
            {
              icon: <FontAwesomeIcon icon={faPlay} />,
              name: "Play",
              handleClick: activePlayAudio,
            },
            {
              icon: <FontAwesomeIcon icon={faDownload} />,
              name: "Download",
              handleClick: download,
            },
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              name: "Delete",
              handleClick: activeDeleteModal,
            },
          ],
        },
      });

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!pk || !name) {
      navigate("/General-Settings")
    } else {
      setDynamicFunction(() => () => {
        navigate("/General-Settings")
      });
      setIsArrowVisible(true);

      getData()
    }
  }, []);

  return (
    <div className="mt-5">
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingMenu}
          modalFunction={deleteMediaFile}
          module="Media File"
        />
      )}

      <div className="mb-2">
        <CustomTableMain
          data={dataTable}
          loading={loading}
          centered={width >= 1697 ? true : false}
          actionsLeft={[
          {
            component: (
              <h3 className="itp-accordion-header-title">
                Media Files
              </h3>
            )
          }
          ]}
          actionsRight={[
          {
            component: (
              <Dropdown>
                <Dropdown.Toggle variant="primary btn-primary">
                  Add Media
                </Dropdown.Toggle>
                <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleChangeSelectOption("Upload File")}
                      >
                        Upload File
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleChangeSelectOption("Text To Speach")}
                      >
                        Text To Speach
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleChangeSelectOption("Record Sound")}
                      >
                        Record Sound
                      </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
          ]}
        />

        <MenuRight
             show={openMenu}
             title={selectedOption}
             loading={loadingplayAudio}
             closeMenu={closeMenu}
             to=""
             paddingTop="0"
            >
              {openMenu && selectedOption === "Upload File" && (
                <>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Language
                      </label>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <select
                        className="form-select-itp"
                        onChange={onChange}
                        name="language"
                        value={form.language}
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        {options.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.label}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Col
                    className="d-flex col-12 pt-4"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          color: "white",
                          marginRight: "1rem",
                          marginBottom: "0",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "8rem",
                        }}
                        className="btn-primary"
                        htmlFor="input_file"
                      >
                        Browse Files
                      </label>

                      <input
                        onChange={() => {
                          setTimeout(() => {
                            setReload(reload + 1);
                          }, [100]);
                        }}
                        id="input_file"
                        className="d-none form-control"
                        type="file"
                        accept="audio/mp3,audio/wav"
                        ref={inputRef}
                      />
                      <span>Only MP3 or WAV Files Are Allowed</span>
                    </div>
                    <label className="mt-3 itp-label-input__label itp-analytics-label-select">
                      {inputRef.current &&
                        inputRef.current.files.length !== 0 &&
                        inputRef.current.files[0].name}
                    </label>
                  </Col>
                  <button
                    onClick={save}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={
                      !inputRef.current ||
                      inputRef.current.files.length === 0 ||
                      !inputRef.current.files[0].name ||
                      loadingMenu
                    }
                  >
                    {loadingMenu && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

              {openMenu && selectedOption === "Text To Speach" && (
                <>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Language
                      </label>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <select
                        className="form-select-itp"
                        onChange={onChange}
                        name="language"
                        value={form.language}
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        {options.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.label}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Gender
                      </label>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <select
                        className="form-select-itp"
                        onChange={onChange}
                        name="gender"
                        value={form.gender}
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </Col>
                  </Row>
                  <label className="itp-label-input__label itp-analytics-label-select">
                    Text
                  </label>
                  <textarea
                    rows="10"
                    style={{ width: "100%", border: "1px solid #ccc" }}
                    value={form.tts_text}
                    name="tts_text"
                    onChange={onChange}
                  />
                  <button
                    onClick={save}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={!form.language || !form.gender || !form.tts_text}
                  >
                    {loadingMenu && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

              {openMenu && selectedOption === "Record Sound" && (
                <>
                  <Row className="itp-container-label-select">
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <label className="itp-label-input__label itp-analytics-label-select">
                        Language
                      </label>
                    </Col>
                    <Col
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                      xl={6}
                      xxl={6}
                      className="itp-label-input-col"
                    >
                      <select
                        className="form-select-itp"
                        onChange={onChange}
                        name="language"
                        value={form.language}
                      >
                        <option value="" disabled>
                          Select Option
                        </option>
                        {options.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.label}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <label className="itp-label-input__label itp-analytics-label-select">
                    To start recording click on play button and to stop click on
                    pause
                  </label>
                  <div>
                    {!recording ? (
                      <PlayCircleOutlineIcon
                        style={{ cursor: "pointer" }}
                        onClick={startRecording}
                      />
                    ) : (
                      <PauseIcon
                        style={{ cursor: "pointer" }}
                        onClick={stopRecording}
                      />
                    )}
                    {audioBlob && (
                      <div className="mt-2">
                        <audio controls src={audioUrl}></audio>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={save}
                    className="btn-primary itp-lead-detail-button-save loading mb-4"
                    disabled={
                      !form.language || !audioBlob || !audioUrl || loadingMenu
                    }
                  >
                    {loadingMenu && <LoadingButton />}
                    Save
                  </button>
                </>
              )}

              {openMenu && selectedOption === "Play Audio" && (
                <>
                  <label className="itp-label-input__label itp-analytics-label-select">
                    Language: {language}
                  </label>
                  <AudioMessage 
                    audioUrl={base64Audio}
                    noFetch={true}
                    duration={0}
                    icon={true}
                    hiddenTime={true}
                    w100={true}
                    ml0={true}
                    stylesVolume={{ marginLeft: "-2.5rem", marginTop: "1rem" }}
                  />
                </>
              )}
        </MenuRight>
      </div>
    </div>
  );
};

export default MediaGroupDetails;
