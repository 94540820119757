import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "./RowSelect.css"

const RowSelect = ({ children, label, subLabel, loading, hoverInfo, borderBottom }) => (
  <Row className={`itp-contact-details-row-select ${borderBottom ? "b-bottom" : ""}`}>
    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
      <div className="container-label-hover">
        {hoverInfo && (
          <div className="container-hover-info">
            <p className="itp-contact-details-p">{hoverInfo}</p>
          </div>
        )}
        <label className={`itp-contact-details-label ${hoverInfo ? "aligned-label"  :""} ${borderBottom ? "mt-3" : ""}`}>{label}</label>
      </div>

      {subLabel && <p className="itp-contact-details-p">{subLabel}</p>}
    </Col>
    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
      {loading ? <Skeleton height={50} width="100%" /> : <>{children}</>}
    </Col>
  </Row>
);

export default RowSelect;
