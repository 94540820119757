import { useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { asyncSearchContactService } from "../../services";
import AddIcon from "@mui/icons-material/Add";

const ChatListHeader = ({
  deleteAllMessages,
  deleteFunction,
  selectedOption,
  onChangeSelect,
  addChat,
  addNewChat,
  noAddIcon,
  setAddChat,
  loading,
}) => {
  const [input, setInput] = useState("");
  const [countryCode, setCountryCode] = useState("+1");
  const [isNumericInput, setIsNumericInput] = useState(false);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [cleanedNumber, setCleanedNumber] = useState("")

  const asyncSearchContact = async (e) => {
    const contacts = await asyncSearchContactService(e, setInput, setCleanedNumber, setIsNumericInput)
    setFilteredContacts(contacts)
  };

  const toggleActiveChat = () => {
    if (loading) return;

    setAddChat(!addChat);
  };
  
  const handleSubmitNumber = (dataNumber) => {
    if (typeof dataNumber === "string") {
      addNewChat(`${countryCode}${dataNumber}`);
    } else {
      addNewChat(dataNumber);
    }

    toggleActiveChat();
  };

  return (
    <div className="itp-chat-list-header">
      <div className={`d-flex ${deleteAllMessages ? "justify-content-center" : ""}`}>
        {deleteAllMessages ? (
          <div
            className="d-flex gap-2 align-items-center cursor-pointer"
            onClick={deleteFunction}
          >
            <p className="itp-chat-list__no-data">Delete All Messages</p>
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          <div className="d-flex align-items-center w-100">
            {!addChat ? (
              <select
                value={selectedOption}
                onChange={onChangeSelect}
                className="itp-chat-list-header__select w-90"
              >
                <option
                  className="itp-chat-list-header__select-option"
                  value="All"
                >
                  All Chats
                </option>
                <option
                  className="itp-chat-list-header__select-option"
                  value="Unread"
                >
                  Unread Chats
                </option>
                <option
                  className="itp-chat-list-header__select-option"
                  value="AI"
                >
                  AI Chats
                </option>
                <option
                  className="itp-chat-list-header__select-option"
                  value="Archived"
                >
                  Archived
                </option>
              </select>
            ) : (
              <div className="select-container w-90">
                <div className={`select-header${input ? " active-menu" : ""}`}>
                  <input
                    type="text"
                    value={input || ""}
                    onChange={(e) => asyncSearchContact(e)}
                    placeholder="Search or Enter Number"
                    className="itp-chat-list__search-input"
                  />
                </div>
                {input && (
                  <div className="select-options">
                    {isNumericInput && (
                      <div className="new-contact-entry d-flex justify-content-between">
                        {cleanedNumber.length <= 10 ? (
                          <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            className="ms-2 mt-2 mb-2"
                          >
                            <option value="+1">+1</option>
                            {/* <option value="+44">+44</option> */}
                            {/* Add more options as needed */}
                          </select>
                        ) : (
                          <p className="text-danger itp-chat-list-error-number">
                            The number has more than ten digits.
                          </p>
                        )}

                        {cleanedNumber.length <= 10 && (
                          <span
                            onClick={() => {
                              handleSubmitNumber(cleanedNumber);
                              setInput("");
                            }}
                            className="select-option cursor-pointer itp-chat-list__font-select-option"
                          >
                            {" "}
                            {cleanedNumber.length <= 3 &&
                              cleanedNumber.length <= 10 &&
                              `(${cleanedNumber.slice(0, 3)})`}
                            {cleanedNumber.length > 3 &&
                              cleanedNumber.length <= 6 &&
                              cleanedNumber.length <= 10 &&
                              `(${cleanedNumber.slice(
                                0,
                                3
                              )})-${cleanedNumber.slice(3, 6)}`}
                            {cleanedNumber.length > 6 &&
                              cleanedNumber.length <= 10 &&
                              `(${cleanedNumber.slice(
                                0,
                                3
                              )})-${cleanedNumber.slice(
                                3,
                                6
                              )}-${cleanedNumber.slice(6)}`}
                          </span>
                        )}
                      </div>
                    )}

                    {filteredContacts?.length > 0 &&filteredContacts.filter((contact) => contact.phone).map((contact, index) => (
                      <div
                        onClick={() => {
                          handleSubmitNumber(contact);
                          setInput("");
                        }}
                        key={index}
                        className="select-option cursor-pointer itp-chat-list__font-select-option"
                      >
                        {contact.label}
                      </div>
                    ))}

                    {filteredContacts?.length === 0 && !isNumericInput && (
                      <p className="text-danger itp-chat-list-error-number">
                        No results found
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="d-flex">
              {!noAddIcon && (
                <div className="itp-chat-list-header__container-icon">
                  <AddIcon
                    onClick={toggleActiveChat}
                    className={`itp-chat-list-header__icon${addChat ? " rotate-icon" : "" }`}
                  />
                  <div className={`itp-chat-list-header__info-icon ${!addChat ? "add" : "close"}`}>
                    <span className="itp-bulk-icon-name">
                      {!addChat ? "Add New Chat" : "Close"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatListHeader;
