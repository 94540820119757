import { formatHourof24at12 } from "../../../../../utils/getDate";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const MessageDate = ({ element, isOther, scrollContainerRef, handleHover, handleLeave }) => (
  <span className="itp-chat-window__message-date">
    {!element?.sending ? (
      <>
        {element.message_status === "failed" ? (
          <>
            {!isOther ? (
              <div
                ref={scrollContainerRef}
                onMouseEnter={handleHover}
                onMouseLeave={handleLeave}
              >
                <CloseOutlinedIcon style={{ color: "red" }} />
              </div>
            ) : (
              <CloseOutlinedIcon style={{ color: "red" }} />
            )}
          </>
        ) : (
          <>{formatHourof24at12(element?.message_timestamp)}</>
        )}
      </>
    ) : (
      <TimerOutlinedIcon style={{ width: "16px", height: "16px" }} />
    )}
  </span>
);

export default MessageDate;
