import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch";
import Switch from "react-switch";
import "../../MySettings.css";

const Fax = ({ myExtension, setCompletedRequests, loading }) => {
  const [switchs, setSwitchs] = useState({
    faxing_settings_enable_notifications: false,
  });

  const handleChangeSwitch = () => {
    setSwitchs({
      faxing_settings_enable_notifications:
        !switchs.faxing_settings_enable_notifications,
    });

    let data = {
      faxing_settings_enable_notifications:
        !switchs.faxing_settings_enable_notifications,
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length) {
      setSwitchs({
        faxing_settings_enable_notifications: myExtension.faxing_settings_enable_notifications,
      });

      setCompletedRequests((prevStata) => prevStata + 1);
    }
  }, [myExtension]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel title="Faxing" subtitle="Adjust fax settings">
        <span className="itp-my-settings-card-title">Fax Notifications </span>

        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>Send email when a fax has been received.</p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs.faxing_settings_enable_notifications}
                  onChange={handleChangeSwitch}
                />
              </div>
            )}
          </Col>
        </Row>
      </CollapsePanel>
    </>
  );
};

export default Fax;
