import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlignedRow, SipDetailsModal } from "../../../../../../components";
import { editOtherSwitchsService, editSwitchsCallForwardingService, editSwitchsCallRecordingService, editSwitchsCallerIdService, editSwitchsVoicemailsService, editTimeZoneService, editUserService, fetchAccountDetails, fetchDevicesAdmin, fetchIntercomAdmin, fetchNumbers, fetchUserDetails, fetchVoicemailData } from "../../services";
import { BasicSettings, CallForwarding, CallRecording, CallerId, Devices, Faxing, GranullarPermissions, IntercomPaging, JitterBuffer, Voicemails } from "./components";
import { timeZones } from "../../../../../../const/timeZones";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { defaultForm, defaultSwitchs } from "../../const/editUserConst";
import ModalError from "../../../ModalUtility/ModalError";
import petitionPatch from "../../../../../../services/petitionPatch";
import "./EditUser.css";

const EditUSer = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");

  const { setDynamicFunction } = useDynamicFunction();
  const navigate = useNavigate();

  const [details, setDetails] = useState({})
  const [loading, setLoading] = useState(true);
  const [modalSip, setModalSip] = useState(false);
  const [info, setInfo] = useState(null);
  const [accountDetails, setAccountDetails] = useState({});
  const [numbersCallerId, setNumbersCallerId] = useState([]);
  const [intercom, setIntercom] = useState(null);
  const [errorEditing, setErrorEditing] = useState("")
  const [modalError, setModalError] = useState(false)
  const [loadPetition, setLoadPetition] = useState(false)
  const [currentForm, setCurrentForm] = useState({})
  const [granularPermissions, setGranularPermissions] = useState({})
  const [timeZoneValue, setTimeZoneValue] = useState(null)
  const [form, setForm] = useState(defaultForm);
  const [switchs, setSwitchs] = useState(defaultSwitchs);
  const [dataTableUserDevices, setDataTableUserDevices] = useState({
    columns: [
      { name: "Name", key: "deviceName" },
      { name: "Device Type", key: "device_type" },
      { name: "Details", key: "details" },
    ],
  });

  const handleChangeSwitchGranular = async (key) => {
    if (!loadPetition) {
      setLoadPetition(true)

      const currentGranularPermissions = granularPermissions

      setGranularPermissions({ ...granularPermissions, [key]: !granularPermissions[key] })
  
      try {
        const data = { [key]: !granularPermissions[key] }
        await petitionPatch("user", { user_id: details.pk, crm_contact_id: details.crm_contact_id, data })
        setLoadPetition(false)
      } catch (error) {
        console.log(error)
        setLoadPetition(false)
        setGranularPermissions(currentGranularPermissions)
        setErrorEditing("An error has ocurred")
        setModalError(true)
      }
    }
  }

  const handleChange = async (e, fetch, key) => {
    const { name, value, fieldName } = e?.target ? e.target : e;

    const field = fieldName ? fieldName : name
    const newForm = { ...form, [field]: e?.target ? value : e}
    setForm(newForm);

    if (fetch) {
      await editUserService(key, details, field, newForm, setForm, currentForm, setCurrentForm, setErrorEditing, setModalError)
    }
  };

  const handleChangeTimeZone = async (e) => {
    await editTimeZoneService(e, loadPetition, setLoadPetition, form, setForm, details, timeZoneValue, setTimeZoneValue, setErrorEditing, setModalError)
  }

  const handleChangeSwitchCallForwarding = async (switchName) => {
    await editSwitchsCallForwardingService(loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError)
  };

  const handleChangeSwitchCallerID = async () => {
    await editSwitchsCallerIdService(loadPetition, setLoadPetition, setSwitchs, switchs, details, setErrorEditing, setModalError)
  };  

  const handleChangeSwitchCallRecording = async (switchName) => {
    await editSwitchsCallRecordingService(loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError)
  };

  const handleChangeSwitchsVoicemails = async (switchName) => {
    await editSwitchsVoicemailsService(loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError)
  }

  const handleChangeOtherSwitch = async (switchName) => {
    await editOtherSwitchsService(loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, intercom, setIntercom, setErrorEditing, setModalError)
  };

  const closeModal = () => {
    setInfo(null);
    setModalSip(false);
    setModalError(false)
    setErrorEditing("")
  };

  const activeSipModal = (e) => {
    if (loading) return;
    setInfo(e);
    setModalSip(true);
  };

  const handleBlurInputs = async (field, key) => {
    await editUserService(key, details, field, form, setForm, currentForm, setCurrentForm, setErrorEditing, setModalError)
  }

  const petition = async () => {
    try {
      setLoading(true);
  
      const { userDetails, formTemp, foundTimeZone } = await fetchUserDetails(pk, form, timeZones, setGranularPermissions)
      setDetails(userDetails)
      setTimeZoneValue(foundTimeZone)
  
      const { dataFind, switchsTemp } = await fetchIntercomAdmin(pk, switchs, userDetails)
      setIntercom(dataFind)
  
      const { numbers, newFormTemp } = await fetchNumbers(formTemp, userDetails)
      setForm(newFormTemp)
      setCurrentForm(newFormTemp)
      setNumbersCallerId(numbers);
  
      await fetchAccountDetails(setAccountDetails)
  
      await fetchDevicesAdmin(pk, setDataTableUserDevices)

      const newSwitchsTemp = await fetchVoicemailData(pk, switchsTemp)
      setSwitchs(newSwitchsTemp)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!pk) {
      navigate("/Users")
    } else {
      setDynamicFunction(() => () => {
        navigate("/Users");
      });

      petition();
    }
  }, []);

  return (
    <>
      {modalSip && (
        <SipDetailsModal
          modal={modalSip}
          closeModal={closeModal}
          device={info}
          myExtension={accountDetails}
        />
      )}

      {modalError && (
        <ModalError 
          modal={modalError}
          closeModal={closeModal}
          error={errorEditing}
        />
      )}

      <AlignedRow>
        <h3 className="itp-accordion-header-title">
          User Details : Manage settings for this User
        </h3>
      </AlignedRow>
      
      <BasicSettings 
        form={form}
        handleChange={handleChange}
        handleBlurInputs={handleBlurInputs}
        timeZones={timeZones}
        timeZoneValue={timeZoneValue}
        handleChangeTimeZone={handleChangeTimeZone}
        loading={loading}
      />

      <GranullarPermissions 
        form={form}
        granularPermissions={granularPermissions}
        handleChangeSwitchGranular={handleChangeSwitchGranular}
        loading={loading}
      />

      <Devices 
        dataTableUserDevices={dataTableUserDevices}
        activeSipModal={activeSipModal}
        switchs={switchs}
        handleChangeOtherSwitch={handleChangeOtherSwitch}
        loading={loading}
      />
 
      <CallForwarding 
        switchs={switchs}
        handleChangeSwitchCallForwarding={handleChangeSwitchCallForwarding}
        form={form}
        handleChange={handleChange}
        handleBlurInputs={handleBlurInputs}
        loading={loading}
      />

      <CallerId 
        switchs={switchs}
        handleChangeSwitchCallerID={handleChangeSwitchCallerID}
        handleChange={handleChange}
        form={form}
        numbersCallerId={numbersCallerId}
        handleBlurInputs={handleBlurInputs}
        loading={loading}
      />

      <CallRecording 
        switchs={switchs}
        handleChangeSwitchCallRecording={handleChangeSwitchCallRecording}
        loading={loading}
      />

      <IntercomPaging 
        switchs={switchs}
        handleChangeOtherSwitch={handleChangeOtherSwitch}
        loading={loading}
      />

      <Faxing 
        switchs={switchs}
        handleChangeOtherSwitch={handleChangeOtherSwitch}
        loading={loading}
      />

      <Voicemails 
        switchs={switchs}
        handleChangeSwitchsVoicemails={handleChangeSwitchsVoicemails}
        loading={loading}
      />

      <JitterBuffer 
        switchs={switchs}
        handleChangeOtherSwitch={handleChangeOtherSwitch}
        loading={loading}
      />
    </>
  );
};

export default EditUSer;
