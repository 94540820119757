import { areAllChildrenObjects } from "../index";

//actualiza el campo route de cada objeto para saber la ubiacion en la que está
//updates the route field of each object to know the location it is in
const updateRoute = (data, parentRoute = "", key) => {
  // console.log(data)
  // console.log(parentRoute)
  if (!data) {
    return;
  }

  // Agrega la ruta de este objeto al parentRoute
  if (!parentRoute && !key) {
    data.route = "flow";
  } else if (parentRoute && !key) {
    data.route = `${parentRoute}.children`;
  } else if (parentRoute && key) {
    data.route = `${parentRoute}.children.${key}`;
  }

  // Llama recursivamente a esta función para los hijos
  if (data.children && Object.keys(data.children).length > 0) {
    const hasMultipleChildren = areAllChildrenObjects(data.children);
    if (typeof data.children === "object" && hasMultipleChildren) {
      Object.keys(data.children).forEach((key) => {
        updateRoute(data.children[key], data.route, key);
      });
    } else if (typeof data.children === "object" && !hasMultipleChildren) {
      updateRoute(data.children, data.route);
    }
  }

  return data;
};

export default updateRoute;
