import React, { useEffect } from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./AlertNotificactions.css"

const AlertNotificactions = ({ showTime, type, alertMessage, title, callback }) => {
  const createNotification = () => {
    switch (type) {
      case "info":
        NotificationManager.info(alertMessage);
        break;
      case "success":
        NotificationManager.success(alertMessage, title);
        break;
      case "warning":
        NotificationManager.warning(alertMessage, title, showTime);
        break;
      case "error":
        NotificationManager.error(alertMessage, title, showTime, callback ? () => { alert('Callback') } : () => {})
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (showTime) {
      createNotification()
    }
  }, [showTime])

  return (
    <div>
      <NotificationContainer />
    </div>
  )
};

export default AlertNotificactions;
