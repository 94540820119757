const queueEnter = ( socketInfo, forceUpdate, setPeopleWaitingQueue) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return;
  }

  const { callerid_name, callerid_number, queue_id, uniqueid, queuecall_id } = socketInfo?.metadata;

  try {
    setPeopleWaitingQueue((prev) => {
      const prevState = prev;
      const callerIdNameTemp = callerid_name !== callerid_number ? callerid_name : "";
      const queueIdTemp = queue_id.toString();

      const newPerson = {
        callerid_name: callerIdNameTemp,
        queue_id: queueIdTemp,
        queuecall_id,
        time: 0,
        callerid_number,
        uniqueid,
      };

      const newArryPeople = [...prevState, { ...newPerson }];

      return newArryPeople;
    });
    forceUpdate();
  } catch (error) {
    console.log(error);
  }
};

export default queueEnter;
