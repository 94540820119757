import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { AlignedRow, CustomTableMain } from "../../../../../../../components";
import useScreenSize from "../../../../../../../hooks/useScreenSize";

const Step4 = ({
  selectedNumbers,
  pricePerDid,
  setCheckBox,
  errorChecked,
  setErrorChecked,
}) => {
  const { width } = useScreenSize();

  const [dataTable, setDataTable] = useState(null);

  const onChangeCheck = (e) => {
    setCheckBox(e.target.checked);
    setErrorChecked(null);
  };

  useEffect(() => {
    if (Array.isArray(selectedNumbers)) {
      const selectedNumbersTemp = selectedNumbers.map((number) => {
        number.number = !number.number.includes("+1") ? `+1${number.number}` : number.number;
        number.charges = "Additional Local Number(new purchase)";
        number.unitPrice = `$${pricePerDid}`;
        number.quantity = "1";
        number.amount = `$${pricePerDid}`;

        return number;
      });

      const dataTableContent = [
        ...selectedNumbersTemp,
        { number: "", charges: "", unitPrice: "", quantity: "", amount: "", isFake: true },
        { number: "", charges: "", unitPrice: "", quantity: "", amount: "", isFake: true },
        { number: "", charges: "", unitPrice: "", quantity: "", amount: "", isFake: true },
        { number: "", charges: "", unitPrice: "", quantity: "", amount: "", isFake: true },
        { number: "", charges: "", unitPrice: "", quantity: "", amount: "", isFake: true },
        {
          number: "Estimated Total",
          charges: "",
          unitPrice: `$${pricePerDid * selectedNumbers.length}`,
          quantity: "",
          amount: "",
          rowStyles: { borderTop: "1px solid rgba(0, 0, 0, 0.3)" },
          isFake: true,
        },
      ];

      setDataTable({
        columns: [
          { name: "Number", key: "number" },
          { name: "Charges & Credits", key: "charges" },
          { name: "Unit Price", key: "unitPrice" },
          { name: "Quantity", key: "quantity" },
          { name: "Amount", key: "amount" },
        ],
        content: [...dataTableContent],
      });
    }
  }, [selectedNumbers]);

  return (
    <>
      <AlignedRow m3 className="mt-3rem">
        <h3 className="itp-accordion-header-title">Call Sumary</h3>
      </AlignedRow>

      <AlignedRow m3>
        <h6 className="itp-accordion-header-subtitle">
          View & Confirm the Numbers summary
        </h6>
      </AlignedRow>

      {dataTable && (
        <CustomTableMain centered={width >= 1697 ? true : false} data={dataTable} m3={true} />
      )}

      <AlignedRow m3 className="mt-3">
        <div style={{ gap: "12px" }} className="d-flex">
          <input
            style={{ width: "2rem" }}
            onChange={onChangeCheck}
            type="checkbox"
            className="form-check-input itp-period-card-checkbox"
            name=""
            id="exampleCheck1"
          />
          <label
            className="form-check-label itp-custom-table-td-styles"
            htmlFor="exampleCheck1"
          >
            <strong>
              I acknowledge that I have read this information and understand the
              itemized charges listed above. I authorize the total amount of $
              {pricePerDid * selectedNumbers.length} to be charged to my credit
              card on file. I further authorize the monthly recurring charges of
              ${pricePerDid * selectedNumbers.length} until canceled, which is
              in addition to my regular charges. * Prices do not include taxes,
              fees or discounts <br />
            </strong>
            <Badge
              color="danger"
              style={{
                marginTop: "2em",
                padding: "0.3rem 1rem",
                opacity: errorChecked ? "1" : "0",
              }}
            >
              Accept Terms...
            </Badge>
          </label>
        </div>
      </AlignedRow>
    </>
  );
};

export default Step4;
