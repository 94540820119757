import { LoadingButton } from "../../../../index";


const IconAction = ({ element, actions, loadingIcon }) => (
  <>
    {loadingIcon === element ? (
      <>
        <LoadingButton />
      </>
    ) : (
      <span
        onClick={() => actions.handleClick(element)}
      >
        {actions.icon}
      </span>
    )}
  </>
);

export default IconAction;
