import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalError = ({ modal, closeModal }) => {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Error</ModalHeader>
      <ModalBody>
        The connection to the server could not be reestablished, please reload
        the page or click the reload page button
      </ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          Close
        </button>
        <button className="btn-primary" onClick={reloadPage}>
          Reload Page
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalError;
