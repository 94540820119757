import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PickerIcons } from "../index";
import { ToggleImage } from "../../../../index";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import copy from "../../../../../assets/icons/copy.svg";

const HoverOptions = ({ 
  noHover, 
  element, 
  isOther, 
  openPicker, 
  setOpenPicker, 
  chatWindowData, 
  index, 
  selectMessageError, 
  openDropdownMessage,
  toggleMessage,
  activeModalDelete,
  download,
  handleCopyEmail,
}) => {
  const show = !noHover && (element.item_type !== "call" || (element.item_type === "call" && element.cdr && element.cdr.call_recording_filename)) ? true : false;

  return (
    <>
      {show && (
        <>
          <div className="itp-chat-window-content__you-message-hover">
            {!element.audio_message && (
              <div
                onClick={(event) => handleCopyEmail(event, element.message_body) }
                className="itp-chat-window-content__you-item-hover-container"
              >
                <img
                  src={copy}
                  alt="Copy"
                  className="itp-chat-window-content__you-item-hover"
                />
                <div className="itp-chat-window-content__you-item-name-hover-container">
                  <span className="itp-bulk-icon-name">Copy Message</span>
                </div>
              </div>
            )}

            <Dropdown
              className="itp-chat-windiow__header-show-profile-ropdown"
              isOpen={index === openDropdownMessage}
              toggle={(event) => toggleMessage(event, index)}
            >
              <DropdownToggle className="nav-link">
                <ToggleImage />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => activeModalDelete(element)}
                  className="dropdown-item"
                >
                  <FontAwesomeIcon icon={faTrash} /> Delete Message
                </DropdownItem>
                <DropdownItem
                  onClick={() => download(element)}
                  className={`dropdown-item ${element.message_mms_media || element?.cdr?.call_recording_filename ? "" : "d-none"}`}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>

            {isOther && element.message_status === "failed" && (
              <>
                <p className="text-danger mb-0">
                  {selectMessageError(element)}
                </p>
              </>
            )}
          </div>

          {!isOther && (
            <PickerIcons 
              openPicker={openPicker}
              setOpenPicker={setOpenPicker}
              element={element}
              chatWindowData={chatWindowData}
              index={index}
            />
          )}
        </>
      )}
    </>
  );
};

export default HoverOptions;
