const addZeroString = (value) => {
  if (value < 10) return `0${value}`;

  return value;
};

const formatDateTime = (e, setInfo, setformAddEditDateTime, setOpenMenu) => {
  setInfo(e);

  setformAddEditDateTime({
    name: e?.name,
    start_date: `${e?.start_month_year}-${addZeroString(e?.start_month )}-${addZeroString(e?.start_month_day)}`,
    end_date: `${e?.end_month_year}-${addZeroString(e?.end_month)}-${addZeroString(e?.end_month_day)}`,
    start_time: `${addZeroString(e?.start_hour)}:${addZeroString(e?.start_minute)}`,
    end_time: `${addZeroString(e?.end_hour)}:${addZeroString(e?.end_minute)}`,
    start_day_of_week: e?.start_day_of_week,
    end_day_of_week: e?.end_day_of_week,
  });

  setOpenMenu(true);
};

export default formatDateTime;
