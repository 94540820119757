import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton, TagComponent, RowSelect, LabelInput, LabelSelect, CustomFields, Notes, FunnelStages } from "../../../../../components";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import ModalTagsColor from "../../../Contacts/AddContact/ModalUtility/ModalTagsColor";
import "./LeadDetails.css";

const LeadDetails = ({
  leadDetails,
  setLeadDetails,
  loadingEdit,
  editLead,
  funnel_id,
  priveLevel,
  firstname,
  lastname,
  errorEditContact,
}) => {
  const customFields = useRef([])

  const [disabled, setDisabled] = useState(false);
  const [tags, setTags] = useState([]);
  const [optionsTags, setOptionsTags] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueContactList, setValueContactList] = useState(null);
  const [users, setUsers] = useState([]);
  const [asignedUser, setAsignedUser] = useState(null);
  const [funnels, setFunnels] = useState([]);
  const [isLoadingSelectUsers, setIsLoadingSelectUsers] = useState(false);
  const [openModalColor, setOpenModalColor] = useState(false);
  const [defaultFunnel, setDefaultFunnel] = useState(null)
  const [finishFetchNotes, setFinishFetchNotes] = useState(false)
  const [finishPetition, setFinishPetition] = useState(false)
  const [finishFetchFunnels, setFinishFetchFunnels] = useState(false)

  const [formLead, setFormLead] = useState({
    name: !leadDetails.name || leadDetails.name === "Unassigned" ? "" : leadDetails.name,
    amount: leadDetails.amount,
  });

  const [formContact, setFormContact] = useState({
    firstname: leadDetails.contact.firstname,
    lastname: leadDetails.contact.lastname || "",
    email: leadDetails.contact.email || "",
    phone: leadDetails.contact.phone || "",
    address: leadDetails.contact.address || "",
  });

  const onchangeFormContact = (e) => {
    setFormContact({ ...formContact, [e.target.name]: e.target.value });
  };

  const onchangeFormLead = (e) => {
    setFormLead({ ...formLead, [e.target.name]: e.target.value });
  };

  const handleTag = (newValue) => {
    if (newValue.length === 0) {
      setOptionsTags(optionsTags);
      setTags([]);
    } else {
      if (newValue[newValue.length - 1]?.inputValue) {
        let dataFilter = optionsTags.filter(
          (element) =>
            element.name === newValue[newValue.length - 1]?.inputValue
        );
        if (dataFilter.length === 0) {
          setDisabled(true);
          addTag(newValue[newValue.length - 1]?.inputValue);
          setTags(newValue);
        } else {
          setTags(newValue);
        }
      } else {
        setTags(newValue);
      }
    }
  };

  const addTag = (tagName) => {
    petitionPost("addTags", {
      data: {
        name: tagName,
      },
    })
      .then(({ data: result }) => {
        let data = newTags;
        petitionTag();
        setDisabled(false);
        data.push(result.result);
        setNewTags(data);
      })
      .catch((error) => console.log(error));
  };

  const formatTags = () => {
    let dataTagsNew = [];
    tags.forEach((element) => {
      if (typeof element !== "string") {
        if (element.inputValue) {
          let dataFilter = newTags.filter(
            (elementTwo) => elementTwo?.name === element?.inputValue
          );
          dataTagsNew.push(dataFilter[0]?.pk);
        } else {
          dataTagsNew.push(element.pk);
        }
      }
    });

    let result = dataTagsNew.filter((item, index) => {
      return dataTagsNew.indexOf(item) === index;
    });

    return result;
  };

  const onSaveChange = () => {
    if (loading || !formLead.funnel_pipeline_stage_id) return;

    let dataContact = {
      tags: formatTags(),
      contact_list_id: valueContactList.value,
      firstname: formContact.firstname,
      lastname: formContact.lastname,
      ...formContact,
      phone: formContact.phone.length > 0 ? `+${formContact.phone}` : ""
    };

    if (asignedUser) dataContact.owner_id = asignedUser.value;

    editLead(dataContact, formLead);
  };

  const closeModal = () => {
    setOpenModalColor(false)
  };

  const reloadData = async () => {
    await petitionGet("contacts", { parameter: `/${leadDetails.contact.pk}` })
      .then(({ data: result }) => {
        setLeadDetails({
          ...leadDetails,
          contact: result?.result,
        });
      })
      .catch((error) => console.log(error));
  };

  const loadOptions = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const petitionTag = async () => {
    try {
      const response = await petitionGet("tags", { parameter: "?offset=0&limit=10000" });
      const { data: result } = response;

      result.result.forEach((element) => {
        element.title = element.name;
        element.disabled = false;
        element.label = element.name;
        element.value = element.pk;
      });

      setOptionsTags(result.result);

      return result.result; // Devuelve la respuesta del endpoint
    } catch (error) {
      console.log(error);
    }
  };

  const petition = async () => {
    setLoading(true);

    let contactDetailsTemp = {};

    await petitionTag();

    await petitionGet("funnels", { parameter: "?offset=0&limit=1000" })
      .then(({ data: result }) => {
        result.result.forEach((funnel) => {
          funnel.label = funnel.name;
          funnel.value = funnel.pk;
        });

        const defaultValueFunnel = result.result.find((obj) =>  obj.pk.toString() === funnel_id);
        setDefaultFunnel(defaultValueFunnel)
        setFunnels(result.result);
      })
      .catch((error) => console.log(error));

    if (leadDetails.contact?.owner_id) {
        await petitionGet("user", { user_id: leadDetails.contact?.owner_id })
        .then(({ data: result }) => {
          result.result.label = `${result?.result?.firstname} ${result?.result?.lastname}`
          result.result.value = result?.result?.pk
  
          setAsignedUser(result.result);
        })
        .catch((error) => console.log(error));
    } 

    await petitionGet("contactList", { parameter: `/${leadDetails.contact.contact_list_id}` })
    .then(({ data: result }) => {
      setValueContactList({
        label: `${result.result.list_name} (${result?.result?.contact_count || ""})`,
        value: result.result.pk
      })
    })
    .catch((error) => console.log(error));

    await petitionGet("contacts", { parameter: `/${leadDetails.contact.pk}` })
      .then(({ data: result }) => {
        result.result.tags = result.result.tags.map((tag) => ({
          ...tag,
          title: tag.tag.name,
          pk: tag.tag_id,
          tag_color: tag?.tag?.tag_color || "",
        }));

        contactDetailsTemp = result?.result;

        setTags(result.result.tags);
        setFormLead({
          ...formLead,
          funnel_pipeline_stage_id: leadDetails.funnel_pipeline_stage_id,
          funnel_id,
        });
      })
      .catch((error) => console.log(error));

    await petitionGet("customFields")
      .then(({ data: result }) => {
        let newform = formContact;
        let defaultTags = leadDetails?.contact?.tags;

        defaultTags.forEach((element) => {
          element.title = element.tag.name;
          element.disabled = false;
          element.label = element.tag.name;
          element.value = element.tag_id;
          element.pk = element.tag_id;
        });

        result.result.forEach((element) => {
          element.label = element.name;
          element.value = element.pk;
          newform[element.name] = contactDetailsTemp[element.name];
        });

        customFields.current = result.result
        setFormContact(newform);
        setFinishPetition(true)
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (finishPetition && finishFetchNotes && finishFetchFunnels) {
      setLoading(false)
    }
  }, [finishFetchNotes, finishPetition, finishFetchFunnels])
  
  useEffect(() => {
    if (isLoadingSelectUsers) {
      const fetchUsers = async () => {
        await petitionGet("users")
        .then(({ data: result }) => {
          result.result.forEach((element) => {
            element.label = `${element.firstname} ${element.lastname}`;
            element.value = element.pk;
          });
  
          setUsers(result.result);
          setIsLoadingSelectUsers(false)
        })
        .catch((error) => console.log(error));
      }

      fetchUsers()
    }
  }, [isLoadingSelectUsers])

  useEffect(() => {
    console.log(leadDetails)
    petition();
  }, []);

  return (
    <>
      {openModalColor && (
        <ModalTagsColor
          modal={openModalColor}
          closeModal={closeModal}
          title="Add tag"
          sendDatatoParent={handleTag}
          tagsExternal={tags}
        />
      )}

      <Row>
        <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
          {!loading && <label className="itp-component-tag-label">Tag</label>}
          <TagComponent 
             disabled={disabled}
             tags={tags}
             optionsTags={optionsTags}
             handleTag={handleTag}
             loading={loading}
          />
        </Col>
        <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <div className="d-flex align-items-end h-100">
            <button 
              className="btn-primary itp-add-button" 
              onClick={() => {
                if (loading) return;
                setOpenModalColor(!openModalColor)
              }}
            >
              <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add tag
            </button>
          </div>
        </Col>
      </Row>

      <RowSelect
        label="Contact List"
        subLabel="Select the Contact List this Contact belongs to"
        loading={loading}
      >
        <AsyncSelect
          onChange={(e) => {
            setValueContactList(e);
          }}
          loadOptions={loadOptions}
          className="basic-single"
          classNamePrefix="select"
          value={valueContactList}
          isSearchable={true}
          name="contact_list_id"
          placeholder="Select Option"
          defaultOptions
          cacheOptions
        />
      </RowSelect>

      <LabelInput label="First Name" loading={loading}>
        <input
          type="text"
          placeholder=": Enter contact First Name"
          className="form-control-itp itp-label-input__input"
          name="firstname"
          value={formContact.firstname}
          onChange={onchangeFormContact}
        />
      </LabelInput>

      <LabelInput label="Last Name" loading={loading}>
        <input
          type="text"
          placeholder=": Enter contact Last Name"
          className="form-control-itp itp-label-input__input"
          name="lastname"
          value={formContact.lastname}
          onChange={onchangeFormContact}
        />
      </LabelInput>

      <LabelInput label="E-mail" loading={loading}>
        <input
          type="text"
          placeholder=": Enter email address"
          className="form-control-itp itp-label-input__input"
          name="email"
          value={formContact.email}
          onChange={onchangeFormContact}
        />
      </LabelInput>

      <LabelSelect label="Phone Number" loading={loading}>
        <PhoneInput
          enableSearch={true}
          country={"us"}
          value={formContact.phone}
          onChange={(e) => {
            setFormContact({ ...formContact, phone: e });
          }}
        />
      </LabelSelect>

      <LabelInput label="Address" loading={loading}>
        <input
          type="text"
          placeholder=": Enter address"
          className="form-control-itp itp-label-input__input"
          name="address"
          value={formContact.address}
          onChange={onchangeFormContact}
        />
      </LabelInput>

      <RowSelect
        label="Assigned User"
        subLabel="Select the assigned user for this contact"
        loading={loading}
      >
        <Select
          onChange={(e) => setAsignedUser(e)}
          className="basic-single"
          classNamePrefix="select"
          name="owner_id"
          placeholder="Select a User"
          options={users}
          isLoading={isLoadingSelectUsers}
          isSearchable={true}
          value={asignedUser}
          onMenuOpen={() => {
            if (!users.length) {
              // Descargar opciones solo si no hay opciones cargadas
              setIsLoadingSelectUsers(true);
            }
          }}
        />
      </RowSelect>

      <CustomFields 
        customFields={customFields}
        form={formContact}
        setForm={setFormContact}
        onchangeForm={onchangeFormContact}
        loading={loading}
        myExtension={{ priv_level: priveLevel }}
        setAddedCustomField={() => {}}
        customReload={reloadData}
      />

      <LabelInput label="Lead Name" loading={loading}>
        <input
          type="text"
          placeholder=": Enter a lead name"
          className="form-control-itp itp-label-input__input"
          name="name"
          value={formLead.name === "Unassigned" ? "" : formLead.name}
          onChange={onchangeFormLead}
        />
      </LabelInput>

      <LabelInput label="Amount" loading={loading}>
        <input
          type="text"
          placeholder=": Enter a amount"
          className="form-control-itp itp-label-input__input"
          name="amount"
          value={formLead.amount}
          onChange={onchangeFormLead}
        />
      </LabelInput>

      <FunnelStages 
        loading={loading}
        form={formLead}
        setForm={setFormLead}
        funnels={funnels}
        defaultFunnelId={funnel_id}
        defaultFunnel={defaultFunnel}
        setFinishFetchFunnels={setFinishFetchFunnels}
        defaultStageId={leadDetails?.funnel_pipeline_stage_id}
      />

      <Notes 
        contactId={leadDetails.contact.pk}
        setFinishFetchNotes={setFinishFetchNotes}
        loading={loading}
        myExtension={{ firstname, lastname }}
      />

      {errorEditContact && (
        <p className="text-danger">{errorEditContact}</p>
      )}

      <button
        onClick={onSaveChange}
        className="btn-primary itp-lead-detail-button-save loading mb-4"
      >
        {loadingEdit && <LoadingButton />}
        Save Changes
      </button>
  </>
  )
};

export default LeadDetails;
