const kmDevicesHangup = (socketInfo, users) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return users;
  }

  const { kamailio_call_id, call_direction, from_user_id, dialed_number, to_user_id, caller_id_number } = socketInfo?.metadata;

  try {
    const newUserState = users.map((user) => {
      // Buscamos si el user tiene algun objeto con el kamailio_call_id igual al del evento que llego
      const findChannel = user.channels.find((channel) => channel.kamailio_call_id === kamailio_call_id)

      // Si existe el findChannel significa que el evento de KM_DEVICE_DIAL llego primero que el 
      // KM_DEVICE_HANGUP y procedemos a colgar la llamada
      if (findChannel) {
        user.channels.forEach((channel) => {
          if (channel.kamailio_call_id === kamailio_call_id) {
            channel.terminated = 1;
          }
        });

        // Si no existe findChannel buscamos el user donde el pk sea igual al from_user_id o to_user_id
        // dependiendo del call_direction y agregamos un nuevo objeto para la llamada, marcandola como
        // respondida y terminada pero agregando el campo firstBye como true para evitar que se elimime
        // el registro hasta que llegue el el KM_DEVICE_DIAL
      } else if (call_direction === 'outbound' && user.pk === from_user_id) {
        const newChannel = { answered: true, withNumber: dialed_number, kamailio_call_id, time: 0, terminated: 1, firstBye: true }

        user.channels = [...user.channels, { ...newChannel }]
      } else if (call_direction === 'inbound' && user.pk === to_user_id) {
        const newChannel = { answered: true, withNumber: caller_id_number, kamailio_call_id, time: 0, terminated: 1, firstBye: true }

        user.channels = [...user.channels, { ...newChannel }]
      }

      return user;
    });

    return newUserState;
  } catch (error) {
    console.log(error);
    return users;
  }
};

export default kmDevicesHangup;
