import callReceived from "../../../../../assets/icons/callReceived.svg";
import callMinus from "../../../../../assets/icons/callMinus.svg";
import callOutgoing from "../../../../../assets/icons/callOutgoing.svg";

const CallLog = ({ element }) => {
  const show = element.item_type === "call" && (!element.audio_message || (element.cdr && element.cdr.call_recording_filename)) ? true  : false
  const selectMarginLeft = element.audio_message && (element.item_type !== "call" || (element.item_type === "call" && element.cdr && element.cdr.call_recording_filename)) ? "" : "-12px"

  const selectorImage = {
    missed: callMinus,
    answeredIn: callReceived,
    answeredOut: callOutgoing,
  };

  if (show) {
    return (
      <div className={`itp-chat-window-content__download-message-content ${element.isRecording ? "recording" : ""}`}>
        <div className="itp-chat-window-content__download-message-icon-text">
          <img
            style={{
              marginTop: "-8px",
              marginLeft: selectMarginLeft,
            }}
            src={selectorImage[element.selectImage]}
          />
          <div className="d-flex flex-column">
            <p className="itp-chat-window-content__message mt-1 mb-1">
              {element.status}
            </p>
            <p
              style={{ fontSize: "11px" }}
              className="itp-chat-window-content__message mt-1 mb-1"
            >
              {element.message_body} {element?.duration || ""}
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default CallLog;
