import React, { useEffect, useRef, useState } from "react";
import { AlignedRow, Loading, LoadingDots } from "../../../components";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import { callparkingAbandoned, callparkingParked, kmDeviceDial, kmDeviceRegistered, kmDevicesAnswer, kmDevicesHangup, queueEnter, queueHangupAbandoned, queueMemberAnswer, userBarge, userSpy, userSpyStop, userWhisper } from "./events";
import { fetchLivePanelData, fetchQueueCalls, fetchTotalUsers } from "./services";
import * as type from "./eventTypes";
import Extensions from "./components/Extensions/Extensions";
import CallParkingSlots from "./components/CallParkingSlots/CallParkingSlots";
import Queues from "./components/Queues/Queues";
import ModalError from "./components/ModalUtility/ModalError";
import json from "../../../config.json";
import "./LivePanel.css";

const LivePanel = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const urlBase = json.prod ? json.webSocketPanel.prod : json.webSocketPanel.dev;
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const urlWebSocket = `${urlBase}itpvoice?account_id=${id}`;

  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const usersInSpye = useRef([])
  // const peopleWaitingQueue = useRef([])

  const [firstLoad, setFirstLoad] = useState(true)
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [parkingCall, setParkingCall] = useState(null);
  const [queues, setQueues] = useState([]);
  const [activeUsers, setActiveUsers] = useState(null);
  const [totalUsers, setTotalUsers] = useState([]);
  const [modalError, setModalError] = useState(false);
  const [permissions, setPermissions] = useState([])
  const [loadingClickAction, setLoadingClickAction] = useState(false)
  const [peopleWaitingQueue, setPeopleWaitingQueue] = useState([])
  const [loadingDots, setLoadingDots] = useState(true)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const closeModal = () => {
    setModalError(false);
  };
  
  const updateDataInBackground = async () => {
    try {
      await fetchQueueCalls(forceUpdate, setPeopleWaitingQueue)
        
      await fetchLivePanelData(setUsers, setParkingCall, setQueues, setPermissions)
    } catch (error) {
      console.log(error)
    }
  }

  const petition = async () => {
    setLoadingDots(true)

    try {
      await fetchQueueCalls(forceUpdate, setPeopleWaitingQueue)
      setLoadingDots(false)
      setLoading(true);

      const allUsers = await fetchTotalUsers()
      setTotalUsers(allUsers)

      await fetchLivePanelData(setUsers, setParkingCall, setQueues, setPermissions)
      setActiveUsers(true);
      setLoading(false);
      setFirstLoad(false)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!firstLoad) {
      const interval = setInterval(async () => {
        await updateDataInBackground()
      }, 60000)

      return () => {
        clearInterval(interval);
      };
    }
  }, [firstLoad])

  useEffect(() => {
    if (users && parkingCall) {
      // const arrayEvents = ["QUEUE_ENTER", "QUEUE_HANGUP_ABANDONED", "QUEUE_MEMBER_ANSWER", "QUEUE_HANGUP_TIMEOUT", "QUEUE_HANGUP"]
      let saveUsers = [];
      let socket = new WebSocket(urlWebSocket);
      let attemptReconnect = true;
      let retryCount = 0;
      let maxRetries = 5; // número máximo de intentos de reconexión
      let reconnectInterval = 10000; // 10 segundos para el primer intento

      const reconnectWebSocket = () => {
        if (!attemptReconnect) return;

        if (retryCount >= maxRetries) {
          console.log("Max retries reached, no longer trying to reconnect.");
          return;
        }

        if (socket.readyState !== WebSocket.OPEN) {
          // Limpia la instancia anterior antes de crear una nueva
          if (socket) socket.close();
          socket = new WebSocket(urlWebSocket);
          retryCount++;
          reconnectInterval *= 2;
          onmessage(socket);
        }
      };

      const onmessage = (socket) => {
        const token = JSON.parse(
          localStorage.getItem("userDetails")
        ).access_token;

        socket.onopen = (e) => {
          reconnectInterval = 10000;
          retryCount = 0;
          maxRetries = 5;
          socket.send(
            JSON.stringify({
              action: "login",
              payload: {
                jwt_token: token.replace(/['"]+/g, ""),
                account_id: id,
              },
            })
          );
        };

        socket.onmessage = async (e) => {
          let socketInfo = JSON.parse(e.data);
          try {
            if (typeof socketInfo === "string") {
              socketInfo = JSON.parse(socketInfo);
            }
          } catch (error) {
            console.log(error);
          }

          if (!json.prod) {
            console.log(socketInfo);
          }

          if (socketInfo.event_name) {
            
            switch (socketInfo.event_name) {
              case type.KM_DEVICE_REGISTERED:
                setUsers((prevUsers) => kmDeviceRegistered(socketInfo, prevUsers));
                break;
        
              case type.KM_DEVICE_DIAL:
                setUsers((prevUsers) => kmDeviceDial(socketInfo, prevUsers));
                break;
        
              case type.KM_DEVICE_ANSWER:
                setUsers((prevUsers) => kmDevicesAnswer(socketInfo, prevUsers));
                break;
        
              case type.KM_DEVICE_HANGUP:
                setUsers((prevUsers) => kmDevicesHangup(socketInfo, prevUsers));
                break;

              case type.CALLPARKING_PARKED:
                callparkingParked(socketInfo, users, setParkingCall)
                break;

              case type.CALLPARKING_ABANDONED:
                callparkingAbandoned(socketInfo, setParkingCall)
                break;

              case type.CALLPARKING_PICKUP:
                callparkingAbandoned(socketInfo, setParkingCall)
                break;

              case type.QUEUE_ENTER:
                queueEnter(socketInfo, forceUpdate, setPeopleWaitingQueue)
                break;

              case type.QUEUE_HANGUP_ABANDONED:
                queueHangupAbandoned(socketInfo, forceUpdate, setPeopleWaitingQueue)
                break;

              case type.QUEUE_MEMBER_ANSWER:
                queueMemberAnswer(socketInfo, forceUpdate, setPeopleWaitingQueue)
                break;

              case type.QUEUE_HANGUP_TIMEOUT:
                queueHangupAbandoned(socketInfo, forceUpdate, setPeopleWaitingQueue)
                break;

              case type.QUEUE_HANGUP:
                queueHangupAbandoned(socketInfo, forceUpdate, setPeopleWaitingQueue)
                break;

              case type.USER_SPY:
                await userSpy(socketInfo, usersInSpye, myExtension, forceUpdate)
                break;

              case type.USER_SPY_STOP:
                userSpyStop(socketInfo, usersInSpye, setLoadingClickAction, forceUpdate)
                break;

              case type.USER_WHISPER:
                userWhisper(socketInfo, usersInSpye, myExtension, forceUpdate)
                break;

              case type.USER_WHISPER_STOP:
                userSpyStop(socketInfo, usersInSpye, setLoadingClickAction, forceUpdate)
                break;

              case type.USER_BARGE:
                userBarge(socketInfo, usersInSpye, myExtension, forceUpdate)
                break;

              case type.USER_BARGE_STOP:
                userSpyStop(socketInfo, usersInSpye, setLoadingClickAction, forceUpdate)
                break;

              case type.PING:
                socket.send(JSON.stringify({ type: "PONG" }));
                break;

              default:
                break;
            }
          }
        };

        socket.onclose = (event) => {
          if (retryCount >= maxRetries) {
            setModalError(true);
          }
          console.log(event);
        };

        //Error
        socket.onerror = (event) => {
          if (retryCount >= maxRetries) {
            setModalError(true);
          }
          console.log(event);
        };
      };

      onmessage(socket);

      //Interval of opening socket
      const intervalIdTwo = setInterval(() => {
        if (socket.readyState === WebSocket.CLOSED && attemptReconnect) {
          reconnectWebSocket();
        }
      }, reconnectInterval);

      const intervalIdThree = setInterval(() => {
        if (socket.readyState === 1) {
          socket.send(
            JSON.stringify({ action: "ping", payload: "pinging..." })
          );
        }
      }, 30000);

      return () => {
        attemptReconnect = false;
        reconnectInterval = 10000;
        retryCount = 0;
        maxRetries = 5;
        clearInterval(intervalIdTwo);
        clearInterval(intervalIdThree);
        if (socket) socket.close();
      };
    }
  }, [activeUsers]);

  useEffect(() => {
    if (myExtension && !!Object.keys(myExtension).length && !!myExtension?.pk && firstLoad) {
      petition();
    }
  }, [myExtension]);

  return (
    path === "Live-Panel" && (
      <div className="itp-test">
        {modalError && (
          <ModalError modal={modalError} closeModal={closeModal} />
        )}

        {loadingDots ? (
          <LoadingDots />
        ) : (
          <>
            <Extensions 
              users={users} 
              setUsers={setUsers} 
              permissions={permissions} 
              usersInSpye={usersInSpye}
              loadingClickAction={loadingClickAction}
              setLoadingClickAction={setLoadingClickAction}
              myExtension={myExtension}
              loading={loading}
            />

            <AlignedRow noCentered={true} m1={true}>
              <Row>
                <Col className="itp-callParkin-aligned-col">
                  <CallParkingSlots loading={loading} parkingCall={parkingCall} />
                </Col>

                <Col>
                  <Card className="mt-3">
                    <CardBody>
                      <h1 className="itp-livePanel-queue-title">Queues</h1>
                      <p className="itp-livePanel-queue-subtitle">
                        View current queues & their user status
                      </p>
                      <Card className="itp-livePanel-card-container-queues">
                        <CardBody style={{ paddingLeft: "0" }}>
                          <Queues
                            queues={queues}
                            setQueues={setQueues}
                            users={users}
                            totalUsers={totalUsers}
                            peopleWaitingQueue={peopleWaitingQueue}
                            loading={loading}
                          />
                       </CardBody>
                     </Card>
                   </CardBody>
                 </Card>
               </Col>
             </Row>
           </AlignedRow>
          </>
        )}
      </div>
    )
  );
};

export default LivePanel;
