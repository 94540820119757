import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { CustomTableMain, AlignedRow } from "../../../../../../../components";
import useScreenSize from "../../../../../../../hooks/useScreenSize";
import "../AddNumber.css"

const Step3 = ({
  selectedNumbers,
  setSelectedNumbers,
  pricePerDid,
}) => {
  const { width } = useScreenSize();

  const [dataTableCallSummary, setDataTableCallSummary] = useState(null)
  const [dataTableOrderSummary, setDataTableOrderSummary] = useState(null)

  const deleteNumberOfList = (element) => {
    setSelectedNumbers((prevState) =>
      prevState.filter((number) => number.label !== element.label)
    );
  };

  useEffect(() => {
    const callSummaryContent = selectedNumbers.map((number) => {
      number.number = !number.number.includes("+1") ? `+1${number.number}` : number.number
      number.formatLocation = `${number.type !== "City/State" ? `${number.type}: ` : ""}${number.code}`
      number.fixedQuantity = "1"

      return number
    })

    const orderSummaryConten = [
      { 
        recurringChanges: "Additional Local Numbers - Domestic", 
        quantity: selectedNumbers.length, 
        subtotal: "$" + (selectedNumbers.length * pricePerDid).toFixed(2), 
      },
      { recurringChanges: "", quantity: "", subtotal: "" },
      { recurringChanges: "", quantity: "", subtotal: "" },
      { recurringChanges: "", quantity: "", subtotal: "" },
      { recurringChanges: "", quantity: "", subtotal: "" },
      { recurringChanges: "", quantity: "", subtotal: "" },
      { 
        recurringChanges: "Estimated Total", 
        quantity: "", 
        subtotal: "$" + (selectedNumbers.length * pricePerDid).toFixed(2),
        rowStyles: { borderTop: "1px solid rgba(0, 0, 0, 0.3)" }
      },
    ]

    setDataTableCallSummary({
      columns: [
        { name: "Number", key: "number" },
        { name: "Location", key: "formatLocation" },
        { name: "Quantity", key: "fixedQuantity" },
      ],
      content: [...callSummaryContent],
      actions: {
        title: "Actions",
        content: [
          {
            name: "Remove",
            handleClick: deleteNumberOfList,
            icon: <FontAwesomeIcon icon={faTrash} />,
          }
        ]
      }
    })

    setDataTableOrderSummary({
      columns: [
        { name: "Recurring Changes", key: "recurringChanges" },
        { name: "Quantity", key: "quantity" },
        { name: "Subtotal", key: "subtotal" },
      ],
      content: [...orderSummaryConten]
    })

  }, [selectedNumbers])

  return (
    <>
      <AlignedRow m3 className="mt-3rem">
        <h3 className="itp-accordion-header-title">
          Call Sumary
        </h3>
      </AlignedRow>

      <AlignedRow m3>
        <h6 className="itp-accordion-header-subtitle">
          View & Confirm the Numbers summary
        </h6>
      </AlignedRow>

      {dataTableCallSummary && (
        <CustomTableMain 
          data={dataTableCallSummary}
          m3={true}
          centered={width >= 1697 ? true : false}
        />
      )}

      <AlignedRow m3 className="mt-3">
        <h3 className="itp-accordion-header-title">
          Order Sumary
        </h3>
      </AlignedRow>

      {dataTableOrderSummary && (
        <CustomTableMain 
          data={dataTableOrderSummary}
          m3={true}
          noShowPagination={true}
          centered={width >= 1697 ? true : false}
        />
      )}
    </>
  );
};

export default Step3;
