import petitionPatch from "../../../../../services/petitionPatch";

const showColumnsService = async (arrayColumns, myExtension) => {
  try {
    const data = {
      contacts_settings_display_columns: {
        columns: arrayColumns,
      },
    };

    const { data: result } = await petitionPatch("userDetails", { data });

    const newUserDetails = {
      ...myExtension.current,
      ...result.result,
    };

    myExtension.current = newUserDetails;
  } catch (error) {
    console.log(error);
  }
};

export default showColumnsService;
