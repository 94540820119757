import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { CustomTableMain, SearchBoxFilter, AlertNotificactions, MenuRight, AddButton } from "../../../../components";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import { resetPasswordService } from "./services";
import petitionGet from "../../../../services/petitionGet";
import petitionDelete from "../../../../services/petitionDelete";
import petitionPost from "../../../../services/petitionPost";
import ModalDelete from "../ModalUtility/ModalDelete";
import AddUser from "./components/AddUser/AddUser";
import useScreenSize from "../../../../hooks/useScreenSize";
import "./Users.css";

const Users = () => {
  const { width } = useScreenSize()

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();
  const { resetFunction } = useDynamicFunction();

  const users = useRef([]);
  const sortFilter = useRef("")
  const itemsPerPage = useRef("10")

  const [loading, setLoading] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [defaultOrderColumn, setDefaultOrderColumn] = useState({ key: "key", order: "asc" })
  const [searchValue, setSearchValue] = useState("")
  const [openMenu, setOpenMenu] = useState(false)
  const [loadingMenu, setLoadingMenu] = useState(false)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("Unable to perform action Listen on this call")
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "userName" },
      { name: "Email", key: "email" },
      { name: "Extension Number", key: "presence_id" },
      { name: "Type", key: "priv_level" },
    ],
    actions: { title: "Actions", content: [] }
  })

  const columnNames = {
    firstname: "Name",
    email: "Email",
    presence_id: "Extension Number",
    priv_level: "Type",
    key: "key",
  }

  const keyNames = {
    "Name": "firstname",
    "Email": "email",
    "Extension Number": "presence_id",
    "Type": "priv_level",
  }

  const selectOrder = {
    asc: "desc",
    desc: "asc"
  }

  const handleChangeItemsPerPage = (data) => {
    itemsPerPage.current = data
    forceUpdate()
  }

  const closeModal = () => {
    setShowResetPassword(false)
    setOpenMenu(false)
    setInfo(null);
    setModalDelete(false);
  };

  const activeEditUser = (e) => {
    const { pk } = e
    navigate(`/Users/Edit-User?pk=${pk}`)
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const activeResetPassword = (e) => {
    setInfo(e);
    setShowResetPassword(true)
    setModalDelete(true);
  }

  const activeAddUser = () => {
    if (loading) return;
    setOpenMenu(true)
  }

  const selectModalFunction = () => {
    if (modalDelete && !showResetPassword) deleteUser()

    if (modalDelete && showResetPassword) resetPassword()
  }

  const resetPassword = async () => {
    await resetPasswordService(setLoadingUser, info, closeModal, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
  }

  const addUser = async (form) => {
    try {
      setLoadingMenu(true)

      await petitionPost("user", { data: form })
      reloadData()

      setLoadingMenu(false)
    } catch (error) {
      console.log(error)
      setLoadingMenu(false)
      setAlertType("error")
      setAlertTitle("Error")
      setAlertMessage("An error has ocurred")
      setShowErrorTime(3000)

      setTimeout(() => {
        setShowErrorTime(false)
        setAlertType("")
        setAlertTitle("")
        setAlertMessage("")
      }, 3300)
    }
  }

  const searchUser = (e) => {
    if (loading) return;

    const { value } = e.target;
    setSearchValue(value)

    let usersTemp = users.current;

    if (value !== "") {
      usersTemp = usersTemp.filter(
        (element) =>
          element.userName.toLowerCase().includes(value.toLowerCase()) ||
          element.email.toLowerCase().includes(value.toLowerCase()) ||
          element.presence_id.toLowerCase().includes(value.toLowerCase()) ||
          element.priv_level.toLowerCase().includes(value.toLowerCase())
      );

      setDataTable((prev) => {
        return {
          ...prev,
          content: [...usersTemp],
        }
      })
    } else {
      setDataTable((prev) => {
        return {
          ...prev,
          content: [...users.current],
        }
      })
    }
  };

  const reloadData = () => {
    let parameter = sortFilter.current
    closeModal();
    petition(parameter);
  };

  const deleteUser = () => {
    setLoadingUser(true);
    petitionDelete("user", { user_id: info.pk })
      .then(({ data: result }) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const changeColumnOrder = (name) => {
    const defaultOrderColumnCopy = defaultOrderColumn

    try {
      let parameterOrder = ""
    
      if (keyNames[name] === defaultOrderColumn.key) {
        parameterOrder = `?sort_by=${keyNames[name]}&order_by=${selectOrder[defaultOrderColumn.order]}`
        setDefaultOrderColumn({ key: keyNames[name], order: selectOrder[defaultOrderColumn.order] })
        forceUpdate()
      } else {
        parameterOrder = `?sort_by=${keyNames[name]}&order_by=asc`
        setDefaultOrderColumn({ key: keyNames[name], order: "asc" })
        forceUpdate()
      }
          
      let parameter = parameterOrder;
      petition(parameter, true)
      sortFilter.current = parameter
    } catch (error) {
      console.log(error)
      setDefaultOrderColumn(defaultOrderColumnCopy)
      forceUpdate()
    }
  }

  const petition = (parameter, noLoading) => {
    if (!noLoading) setLoading(true);
    
    petitionGet("itpvoiceUsers", { parameter })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.userName = `${element.firstname} ${element?.lastname || ""}`;
        });

        setDataTable({
          columns: [
            { name: "Name", key: "userName" },
            { name: "Email", key: "email" },
            { name: "Extension Number", key: "presence_id" },
            { name: "Type", key: "priv_level" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditUser,
              },
              {
                icon: <FontAwesomeIcon icon={faUnlock} />,
                name: "Reset Password",
                handleClick: activeResetPassword,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeModalDelete,
              },
            ],
          },
        });

        users.current = result.result;
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };
  
  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_users
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          dispatch({ type: "SET_DEFAULT_MENU" });
          navigate("/Dashboard");
        } else {
          resetFunction();
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <>
        <div style={{ marginTop: "3rem" }}>
          <AlertNotificactions 
            type={alertType}
            alertMessage={alertMessage}
            showTime={showErrorTime}
            title={alertTitle}
          />

          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={loadingUser}
              modalFunction={selectModalFunction}
              module={!showResetPassword ? "User" : ""}
              title={!showResetPassword ? "" : "Reset Password"}
              text={showResetPassword ? "" : "Are you sure you wish to delete this User? All user information including Voicemails will be removed."}
            />
          )}

          <MenuRight
            show={openMenu}
            closeMenu={closeModal}
            title="Add New User"
            to=""
          >
            <AddUser  
              openMenu={openMenu}
              loading={loadingMenu}
              addUser={addUser}
            />
          </MenuRight>

          <CustomTableMain 
            data={dataTable} 
            loading={loading}
            centered={width >= 1697 ? true : false}
            module="Users"
            showSelectSingleCheckbox={true}
            paginationData={parseInt(itemsPerPage.current)}
            onChangeItemsPerPage={handleChangeItemsPerPage}
            noDataMessage={searchValue && !dataTable.content.length ? "No results found" : ""}
            actionsLeft={[
              {
                component: (
                  <SearchBoxFilter 
                    placeholder="Search User, Email, Extension Number or Type"
                    handleChange={searchUser}
                    value={searchValue}
                  />
                )
              }
            ]}
            actionsRight={[
              {
                component: (
                  <AddButton 
                    name="Add User"
                    handleClick={activeAddUser}
                  />
                )
              }
            ]}
            defaultOrderColumn={defaultOrderColumn}
            columnNames={columnNames}
            changeColumnOrder={changeColumnOrder}
          />
        </div>
    </>
  );
};

export default Users;
