import React, { useEffect, useState } from "react";
import { AlertNotificactions } from "../../../../../components";
import { Filters, UsersTable } from "./components"
import petitionPost from "../../../../../services/petitionPost";
import "./Extensions.css"

const Extensions = ({ 
  users, 
  setUsers, 
  permissions, 
  usersInSpye, 
  loadingClickAction, 
  setLoadingClickAction,
  myExtension,
  loading, 
  }) => {
  const [searchFilter, setSearchFilter] = useState("");
  const [switchUsersCall, setSwitchUsersCall] = useState(true);
  const [switchUsersOffline, setSwitchUsersOffline] = useState(true);
  const [dataUsers, setDataUsers] = useState(null);
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("Unable to perform action Listen on this call")

  const handleChangeFilterInput = (e) => {
    if (loading) return;
    setSearchFilter(e.target.value);
  };

  const handleClickAction = async (element, action) => {
    if (!loadingClickAction) {
      setLoadingClickAction(true)

      const data = {
        target_user_id: element.pk,
        action,
      }
  
      try {
        await petitionPost("spyLivePanel", { data })
      } catch (error) {
        console.log(error)

        if (error?.response?.status === 400) {
          setAlertType("error")
          setAlertTitle("Error")
          setAlertMessage(`Unable to perform action ${action} on this call`)
          setShowErrorTime(3000)
  
          setTimeout(() => {
            setShowErrorTime(false)
            setAlertType("")
            setAlertTitle("")
            setAlertMessage("")
          }, 3300)
        }
        
        setLoadingClickAction(false)
      }
    }
  }

  const applyFilters = (users) => {
    let filteredUsers = users;
    if (searchFilter.length > 0) {
      filteredUsers = filteredUsers.filter(
        (el) =>
          el.presence_id.toLowerCase().includes(searchFilter.toLowerCase()) ||
          el.callerID.toLowerCase().includes(searchFilter.toLowerCase())
      );
    }
    if (!switchUsersCall) {
      filteredUsers = filteredUsers.filter((el) => el.channels.length > 0);
    }
    if (!switchUsersOffline) {
      filteredUsers = filteredUsers.filter((el) => el.devices_registered === true);
    }
    return filteredUsers;
  };

  useEffect(() => {
    const filteredUsers = applyFilters(users);
    setDataUsers(filteredUsers);
  }, [searchFilter, switchUsersCall, switchUsersOffline, users]);

  useEffect(() => {
    if (users.length > 0) {
      const intervalIdOne = setInterval(() => {
        const updatedUsers = users.map((element) => {
          if (element.channels.length === 0) return element;
  
          if (element.channels.length > 1) {
            element.channels.forEach((el) => {
              if (!el.terminated) {
                el.time_end = (el?.time_end || 0)+ 1;
                el.time = el.time + 1;
                el.firstBye = el?.firstBye || false
              } else if (el.terminated < 5) {
                el.terminated = el.terminated + 1;
                el.firstBye = el?.firstBye || false
              }
            });
  
            element.channels = element.channels.filter((elementTwo) => !elementTwo.terminated && !elementTwo?.firstBye);
          } else {
            if (!element.channels[0].terminated) {
              element.time_end = (element?.time_end || 0) + 1;
              element.channels[0].time = (element?.channels[0]?.time || 0) + 1;
              element.channels[0].firstBye = element.channels[0]?.firstBye || false
            } else if (element.channels[0].terminated < 5) {
              element.channels[0].terminated = element.channels[0].terminated + 1;
              element.channels[0].firstBye = element.channels[0]?.firstBye || false
            } else {
              element.channels = element.channels.filter((elementTwo) => !elementTwo.terminated && !elementTwo?.firstBye);
            }
          }
  
          return element
        });
  
        setUsers(updatedUsers);
      }, 1000);

      return () => {
        clearInterval(intervalIdOne);
      };
    }
  }, [users]);

  return (
    <>
      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <Filters 
        handleChangeFilterInput={handleChangeFilterInput}
        searchFilter={searchFilter}
        switchUsersCall={switchUsersCall} 
        setSwitchUsersCall={setSwitchUsersCall} 
        switchUsersOffline={switchUsersOffline} 
        setSwitchUsersOffline={setSwitchUsersOffline}
      />

      <UsersTable 
        dataUsers={dataUsers}
        users={users}
        setUsers={setUsers}
        handleClickAction={handleClickAction}
        myExtension={myExtension}
        usersInSpye={usersInSpye}
        permissions={permissions}
        loading={loading}
      />
  </>
  )
};

export default Extensions;
