const handleChangeForms = (e, form, setForm, setCurrentForm) => {
  const { name, value } = e.target

  if (name === "timeout") {
    setForm({ ...form, timeout: parseInt(value) });
  } else {
    setForm({ ...form, [name]: value });
  }

  if (name === "ring_strategy") {
    const data = {
      timeout: form.timeout === null ? "" : form.timeout,
      ring_strategy: value,
    };

    setCurrentForm({ ...form, ...data })
    return data
  } else if (name === "ai_calender_strategy") {
    const data = {
      ai_calender_strategy: value,
    };

    setCurrentForm({ ...form, ...data })
    return data
  }

  return null
};

export default handleChangeForms;
