const selectIdForAddSubtitle = (info, tempData) => {
  switch (info.module) {
    case "user":
      if (tempData) return ""
      return parseInt(info.data.idUser);
    case "callflow":
      if (tempData) return ""
      return parseInt(info.data.idUser);
    case "externaltransfer":
      if (tempData) return ""
      return info.data.destination || info.data.number;
    case "language":
      if (tempData) return ""
      return info.data.language;
    default:
      return parseInt(info.data.id);
  }
};

export default selectIdForAddSubtitle;
