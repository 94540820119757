import petitionGet from "../../../../../../services/petitionGet";

const updateChatWindowDataService = async (chat) => {
  const contactID = chat?.notIsSelf?.contact?.pk;

  if (contactID) {
    const parameter = `/${contactID}`
    const { data: result } = await petitionGet("contacts", { parameter });
    const newContactObject = { ...result?.result };
    const currentNotIsSelf = chat?.notIsSelf;
    const currentContactObject = chat?.notIsSelf?.contact;

    return {
      ...chat,
      notIsSelf: {
        ...currentNotIsSelf,
        contact: {
          ...currentContactObject,
          ...newContactObject,
        },
      },
    };
  } else {
    return chat;
  }
};

export default updateChatWindowDataService;
