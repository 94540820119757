import * as type from "../types";

const initialState = {
  isDefault: false,
};

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case type.DEFAULT_SIDEBAR:
      return {
        isDefault: false,
      };
    case type.HIDDEN_SIDEBAR:
      return {
        isDefault: true,
      };
    case type.LOGOUT_REQUEST:
      return {
        isDefault: false,
      };
    case type.LOGOUT_SUCCESS:
      return state;
    default:
      return state;
  }
}
