import React, { useEffect, useState } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ToggleImage } from "../../index";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import useScreenSize from "../../../hooks/useScreenSize";
import "../ChatWindow.css";

const ChatWindowHeader = ({
  chatWindowData,
  openMenuData,
  toggleMenu,
  activeModalDelete,
  changeAiEnabled,
  myInbox,
  loading,
}) => {
  
  const { width } = useScreenSize();
  const [checked, setChecked] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);


  const toggle = () => {
    if (!loading) {
      setOpenDropdown(!openDropdown);
    }
  };

  const onChangeCheck = (e) => {
    if (!loading) {
      setChecked(e.target.checked)
      changeAiEnabled(e.target.checked)
    }
  }

  useEffect(() => {
    setChecked(chatWindowData?.ai_enabled ? true : false)
  }, [chatWindowData])

  return (
    <>
      <Row className="itp-chat-window-header">
        <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
          <Row>
            <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
              <div className={myInbox ? "d-none" : "form-check form-switch itp-chat-window__header-chat-enabled"}>
                <input
                  className="form-check-input form-check-chat-window"
                  onChange={onChangeCheck}
                  checked={checked}
                  disabled={loading}
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className="form-check-label itp-login-remember itp-chat-window__header-text-chat-enabled"
                  htmlFor="flexSwitchCheckDefault"
                >
                  AI Enabled
                </label>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          xxl={3}
          className="itp-chat-windiow__header-show-profile-container"
        >
          <div className={myInbox ? "d-none" : "d-flex"}>
            <label
              onClick={toggleMenu}
              className={
                openMenuData && width > 1399
                  ? "d-none"
                  : "itp-chat-windiow__header-show-profile"
              }
            >
              {chatWindowData.isContact ? "View Contact" : "Add Contact"}
            </label>
            <KeyboardDoubleArrowRightIcon
              onClick={toggleMenu}
              style={{ width: "24px", height: "24px" }}
              className={`itp-chat-windiow__header-show-profile
                        ${openMenuData ? " open-menu-data" : ""}
                      `}
            />
          </div>

          <Dropdown
            className={myInbox ? "d-none" : "itp-chat-windiow__header-show-profile-ropdown"}
            isOpen={openDropdown}
            toggle={toggle}
          >
            <DropdownToggle className="nav-link">
              <ToggleImage />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => activeModalDelete()}
                className="dropdown-item"
              >
                <FontAwesomeIcon icon={faTrash} /> Delete Messages
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default ChatWindowHeader;
