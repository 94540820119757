import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { LoadingButton, MenuRight, CustomTableMain, AddButton, CollapsePanel } from "../../../../../components";
import PhoneInput from "react-phone-input-2";
import ModalDelete from "../../ModalUtility/ModalDelete";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionDelete from "../../../../../services/petitionDelete";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";

const BlockedCallers = ({
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
  modalDelete,
  setModalDelete,
}) => {
  const [showAddBlockCall, setshowAddBlockCall] = useState(false);
  const [loadingBlockCall, setLoadingBlockCall] = useState(false);
  const [errorNumber, setErrorNumber] = useState("");
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  
  const [dataTableBlockecCallers, setDataTableBlockecCallers] = useState({
    columns: [{ name: "Numbers", key: "formatedNumber" }],
    actions: { title: "Actions", content: [] }
  });

  const [formBlockedCall, setFormBlockedCall] = useState({
    phone_number: "",
  });

  const closeMenu = () => {
    setFormBlockedCall({ phone_number: "" });
    setshowAddBlockCall(false);
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const activeDeleteModalBlockedCall = (e) => {
    setInfo(e);
    setModalDelete(3);
  };

  const deleteBlockedCall = () => {
    setLoadingModal(true);
    petitionDelete("blockedCall", { pk: info.pk })
      .then(({ data: result }) => {
        setDataTableBlockecCallers((prevState) => {
          const newContent = prevState.content.filter(
            (element) => element.pk !== info.pk
          );

          return {
            ...prevState,
            content: [...newContent],
          };
        });
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const activeAddBlockedCall = () => {
    if (loading) return;
    setshowAddBlockCall(true);
    setOpenMenu(4);
  };

  const addBlockCall = () => {
    setLoadingBlockCall(true);
    const data = {
      ...formBlockedCall,
      phone_number:
        formBlockedCall.phone_number.length > 0
          ? `+${formBlockedCall.phone_number}`
          : "",
    };
    petitionPost("blockedCalls", { data })
      .then(({ data: result }) => {
        setDataTableBlockecCallers((prevState) => {
          const newContent = [...prevState?.content, result?.result];

          return {
            ...prevState,
            content: [...newContent],
          };
        });
        setLoadingBlockCall(false);
        closeMenu();
      })
      .catch((error) => {
        console.log(error);
        setLoadingBlockCall(false);
        if (error.response.status === 400) {
          setErrorNumber(
            error.response.data.error.error_detailed.error.error_message
          );
        }
      });
  };

  const petitionBlockedCallers = () => {
    petitionGet("blockedCalls")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.formatedNumber = formatPhoneNumber(element.phone_number)
        })

        setDataTableBlockecCallers({
          columns: [{ name: "Numbers", key: "formatedNumber" }],
          content: result.result,
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteModalBlockedCall,
              },
            ],
          },
        });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionBlockedCallers();
  }, []);

  return (
      <>
        {modalDelete === 3 && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeMenu}
            loading={loadingModal}
            modalFunction={deleteBlockedCall}
            module="Number To Block"
          />
        )}

        <MenuRight
          show={openMenu === 4}
          closeMenu={closeMenu}
          title={`${!info ? "Add" : "Edit"} Block Call`}
          to=""
        >
          {openMenu === 4 && showAddBlockCall && (
            <>
              <Row className="itp-container-label-select">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">Phone Number</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <PhoneInput
                    // country={"us"}
                    // value={formBlockedCall.phone_number}
                    // disableCountryFlags={true}
                    // enableSearch={true}
                    // countryCodeEditable={false}
                    // onChange={(e) => {
                    //   setErrorNumber("");
                    //   setFormBlockedCall({ phone_number: `+${e}` });
                    // }}

                    enableSearch={true}
                    country={"us"}
                    value={formBlockedCall.phone_number}
                    onChange={(e) => {
                      setErrorNumber("");
                      setFormBlockedCall({ phone_number: e });
                    }}
                  />
                </Col>
              </Row>
              {errorNumber && <p className="text-danger">{errorNumber}</p>}
              <button
                onClick={addBlockCall}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={
                  !formBlockedCall.phone_number ||
                  formBlockedCall.phone_number === "*" ||
                  loadingBlockCall
                }
              >
                {loadingBlockCall && <LoadingButton />}
                Save
              </button>
            </>
          )}
        </MenuRight>

        <br></br>
        <div>
          <Row>
            <Col>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <CollapsePanel
          id="blockedCallers"
          title="Blocked Callers"
          subtitle="Adding a number here will prevent them from calling any of your numbers"
        >
          <CustomTableMain 
            data={dataTableBlockecCallers}
            m0={true}
            loading={loading}
            module="Blocked Callers"
            actionsRight={[
              {
                component: (
                  <AddButton 
                    handleClick={activeAddBlockedCall}
                    name="Add Number"
                  />
                )
              }
            ]}
          />
        </CollapsePanel>
      </>
  );
};

export default BlockedCallers;
