import React from "react";
import { Spinner } from "reactstrap";

export default function Loading({ secondary }) {
  return (
    <div style={{ textAlign: "center" }}>
      {secondary && (
        <>
          <Spinner color="primary" type="grow" size="sm">
            Loading...
          </Spinner>
          <Spinner color="primary" type="grow" size="sm">
            Loading...
          </Spinner>
          <Spinner color="primary" type="grow" size="sm">
            Loading...
          </Spinner>
        </>
      )}

      {!secondary && (
        <Spinner
          animation="grow"
          style={{
            margin: "10vh auto",
            height: "10vh",
            width: "10vh",
          }}
        />
      )}
    </div>
  );
}
