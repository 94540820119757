export const editNumberDefaultForm = {
  description: "",
  caller_id_name: "",
  callerid_prefix: "",
  timeout: "",
  ai_enabled: false,
  ai_manage_appointments: false,
  ai_calender_strategy: "",
  ai_lead_manager: false,
  ai_faq: false,
  auto_followup: false,
  ring_strategy: "",
  callforward_enable: false,
  callforward_number: "",
  callforward_keep_caller_caller_id: false,
  callforward_call_confirmation: false,
};
