import getQueryString from "../../../../../../utils/getQueryString";

const handleChangeSearch = (value, pagination, petition, filterInput) => {
  const parameterInput = value ? `&name=${value}` : "";

  const paginationTemp = {
      offset: 0,
    ...pagination.current,
  };

  const parameter = getQueryString(paginationTemp) + parameterInput;

  petition(parameter, true);
  pagination.current = paginationTemp;
  filterInput.current = parameterInput;
}

export default handleChangeSearch;