import React, { useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SearchByDate.css";

const SearchByDate = ({ SearchByDateText, applyFilter, defaultValue, loading }) => {
  const [filter, setFilter] = useState({
    start_date: defaultValue?.start_date || "",
    end_date: defaultValue?.end_date || "",
  });

  const handleChangeFilter = (e) => {
    if (loading) return;

    const { name, value } = e.target;

    setFilter({ ...filter, [name]: value });
  };

  const handleClickApply = () => {
    if (loading) return;
    
    applyFilter(filter);
  };

  return (
    <div className="select-date-container">
      <span className="date-range-name">
        {SearchByDateText}
      </span>
      <div className="date-range-container">
        <label className="search-date-range-label ">From:</label>
        <input
          type="date"
          className="search-date-range-input"
          name="start_date"
          value={filter.start_date}
          onChange={handleChangeFilter}
        />
        <div>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="date-range-separator"
          />
        </div>
        <label className="search-date-range-label ">To:</label>
        <input
          type="date"
          className="search-date-range-input search-input-right-date"
          name="end_date"
          value={filter.end_date}
          onChange={handleChangeFilter}
        />
      </div>
      <button
        onClick={handleClickApply}
        className="btn-primary ms-2"
        disabled={!filter.start_date || !filter.end_date}
      >
        Apply
      </button>
    </div>
  );
};

export default SearchByDate;
