import petitionPost from "../../../../../services/petitionPost";
import { formatTime } from "../../utils";

const queueReportService = async (selectedQueuesReport, timeframe) => {
  const data = { queues: selectedQueuesReport.map((element) => element.pk) };
  const parameter = `?start_date=${timeframe.timeStart}&end_date=${timeframe.timeEnd}`;

  const { data: result } = await petitionPost("queueReports", { data, parameter })

  let countingTotalCalls = 0;
  result.result.forEach((element) => {
    element.avg_call_duration = formatTime(element.avg_call_duration);
    element.avg_hold_time = formatTime(element.avg_hold_time);
    element.queueName = element?.queue_object?.queue_name || ""
    countingTotalCalls = countingTotalCalls + element.total_calls;
  });

  const startYear = parseInt(timeframe.timeStart.split("-")[0]);
  const endYear = parseInt(timeframe.timeEnd.split("-")[0]);

  const perHourReport = [
    "12 AM",
    "1 AM",
    "2 AM",
    "3 AM",
    "4 AM",
    "5 AM",
    "6 AM",
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
  ];

  const perDayReport = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const perMonthReport = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const perYearReport = [];

  for (let index = startYear; index < endYear + 1; index++) {
    perYearReport.push(index.toString());
  }

  //PER HOUR REPORT

  let ObjectCallsPerHour = [];
  let objectHoursPerHour = perHourReport;
  let ObjectCallsPerHourTotal = Array(perHourReport.length).fill(0);

  for (let j = 0; j < result.result.length; j++) {
    let ObjectCallsPerHourItem = Array(perHourReport.length).fill(0);
    for (let i = 0; i < perHourReport.length; i++) {
      if (result.result[j].details.hour[i] !== undefined) {
        ObjectCallsPerHourItem[i] += result.result[j].details.hour[i].total_calls;
        ObjectCallsPerHourTotal[i] += result.result[j].details.hour[i].total_calls;
      }
    }
    ObjectCallsPerHour.push({
      name: result.result[j].queue_object.queue_name,
      data: ObjectCallsPerHourItem,
    });
  }

  ObjectCallsPerHour.push({
    name: "TOTAL",
    data: ObjectCallsPerHourTotal,
  });

  //END PER HOUR REPORT

  //PER DAY REPORT

  let ObjectCallsPerDay = [];
  let objectHoursPerDay = perDayReport;
  let ObjectCallsPerDayTotal = Array(perDayReport.length).fill(0);

  for (let j = 0; j < result.result.length; j++) {
    let ObjectCallsPerDayItem = Array(perDayReport.length).fill(0);
    for (let i = 0; i < perDayReport.length; i++) {
      if (result.result[j].details.day[perDayReport[i]] !== undefined) {
        ObjectCallsPerDayItem[i] += result.result[j].details.day[perDayReport[i]].total_calls;
        ObjectCallsPerDayTotal[i] += result.result[j].details.day[perDayReport[i]].total_calls;
      }
    }
    ObjectCallsPerDay.push({
      name: result.result[j].queue_object.queue_name,
      data: ObjectCallsPerDayItem,
    });
  }

  ObjectCallsPerDay.push({
    name: "TOTAL",
    data: ObjectCallsPerDayTotal,
  });

  //END PER DAY REPORT

  //PER MONTH REPORT

  let ObjectCallsPerMonth = [];
  let objectHoursPerMonth = perMonthReport;
  let ObjectCallsPerMonthTotal = Array(perMonthReport.length).fill(0);

  for (let j = 0; j < result.result.length; j++) {
    let ObjectCallsPerMonthItem = Array(perMonthReport.length).fill(0);
    for (let i = 0; i < perMonthReport.length; i++) {
      if (
        result.result[j].details.month[perMonthReport[i]] !== undefined
      ) {
        ObjectCallsPerMonthItem[i] += result.result[j].details.month[perMonthReport[i]].total_calls;
        ObjectCallsPerMonthTotal[i] += result.result[j].details.month[perMonthReport[i]].total_calls;
      }
    }
    ObjectCallsPerMonth.push({
      name: result.result[j].queue_object.queue_name,
      data: ObjectCallsPerMonthItem,
    });
  }

  ObjectCallsPerMonth.push({
    name: "TOTAL",
    data: ObjectCallsPerMonthTotal,
  });

  //END PER MONTH REPORT

  //PER YEAR REPORT

  let ObjectCallsPerYear = [];
  let objectHoursPerYear = perYearReport;
  let ObjectCallsPerYearTotal = Array(perYearReport.length).fill(0);

  for (let j = 0; j < result.result.length; j++) {
    let ObjectCallsPerYearItem = Array(perYearReport.length).fill(0);
    for (let i = 0; i < perYearReport.length; i++) {
      if (result.result[j].details.year[perYearReport[i]] !== undefined) {
        ObjectCallsPerYearItem[i] += result.result[j].details.year[perYearReport[i]].total_calls;
        ObjectCallsPerYearTotal[i] += result.result[j].details.year[perYearReport[i]].total_calls;
      }
    }
    ObjectCallsPerYear.push({
      name: result.result[j].queue_object.queue_name,
      data: ObjectCallsPerYearItem,
    });
  }

  ObjectCallsPerYear.push({
    name: "TOTAL",
    data: ObjectCallsPerYearTotal,
  });

  const report = result.result
  
  return { report, countingTotalCalls, objectHoursPerHour, ObjectCallsPerHour, objectHoursPerDay, ObjectCallsPerDay, objectHoursPerMonth, ObjectCallsPerMonth, objectHoursPerYear, ObjectCallsPerYear }
};

export default queueReportService;
