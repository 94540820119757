import React, { useEffect, useState } from "react";
import { AlignedRow, CustomTableMain } from "../../../../../../../components";

const Step5 = ({ form, serviceItpVoice }) => {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (form) {
      let dataTableContent = [];

      if (form.length > 0) {
        dataTableContent = [
          ...dataTableContent,
          {
            recurringChanges: "Additional Local Numbers - Domestic",
            qty: `${form.length}x$${parseInt(serviceItpVoice[0]?.metadata.price_per_did)}`,
            subTotal: `$${form.length * parseInt(serviceItpVoice[0]?.metadata.price_per_did)}`,
          },
        ];
      }

      dataTableContent = [
        ...dataTableContent,
        {
          recurringChanges: "Estimated Total",
          qty: "",
          subTotal: `$${form.length * parseInt(serviceItpVoice[0]?.metadata.price_per_did)}`,
        },
      ];

      setDataTable({
        columns: [
          { name: "Recurring Changes - monthly", key: "recurringChanges" },
          { name: "Qty", key: "qty" },
          { name: "SubTotal*", key: "subTotal" },
        ],
        content: [...dataTableContent]
      })
    }
  }, [form]);

  return (
    <div>
      <AlignedRow style={{ margin: "1rem 3rem 1rem -1rem" }}>
        <h3 className="itp-accordion-header-title">
          Orden Summary
        </h3>
      </AlignedRow>

      {dataTable && (
        <CustomTableMain 
          data={dataTable}
          style={{ margin: "0 3rem 0 -1rem" }}
        />
      )}

      <AlignedRow style={{ margin: "1rem 3rem 1rem -1rem" }}>
        <h3 className="itp-accordion-header-subtitle">
          *This price does not include calculations for prorated amounts, taxes,
          fees and/or shipping.
        </h3>
      </AlignedRow>
    </div>
  );
};

export default Step5;
