export const selectNoSaveButton = (priveLevel, errorAddOrEditCustomField) => {
  if (priveLevel !== "admin" || errorAddOrEditCustomField) {
    return true;
  } else {
    return false;
  }
};

export const selectTitleModal = (notEdit, errorAddOrEditCustomField) => {
  if (notEdit && !errorAddOrEditCustomField) {
    return "Edit Custom Field";
  } else if (!notEdit && !errorAddOrEditCustomField) {
    return "Delete Custom Field";
  } else if (errorAddOrEditCustomField) {
    return "An Error Has ocurred";
  } else {
    return "Error";
  }
};

export const selectTextModal = (notEdit, priveLevel, errorAddOrEditCustomField) => {
  if (notEdit && priveLevel !== "admin" && !errorAddOrEditCustomField) {
    return "It is not possible to edit this custom field because you are not an administrator";
  } else if (!notEdit && priveLevel !== "admin" && !errorAddOrEditCustomField) {
    return "It is not possible to delete this custom field because you are not an administrator";
  } else if (!notEdit && priveLevel === "admin" && !errorAddOrEditCustomField) {
    return "Delete Custom Field";
  } else if (!notEdit && priveLevel === "admin" && errorAddOrEditCustomField) {
    return errorAddOrEditCustomField;
  }
};

export const selectCloseButtonName = (errorAddOrEditCustomField) => {
  if (errorAddOrEditCustomField) {
    return "Close"
  } else {
    return  ""
  }
}