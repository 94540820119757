import { processVoicemails } from "../../utils";
import petitionGet from "../../../../../../services/petitionGet";

const fetchVoicemailsService = async (parameter, urlBase, id, token, setVoicemails, setLoading, setLoadingScroll, noLoading) => {
  if (!noLoading) setLoading(true);

  try {
    const { data: result } = await petitionGet("voicemailDetails", { parameter: "?pagination=false" });
    const newVoicemails = processVoicemails(result, urlBase, id, token);
    setVoicemails(newVoicemails);
    setLoadingScroll(false);
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
}

export default fetchVoicemailsService;