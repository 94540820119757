import petitionGet from "../../../../../../services/petitionGet";

const fetchCallflowDetails = async (pk, setCallFlowDetails, petition, setCallflowName, setCurrentName) => {
  try {
    const { data: result } = await petitionGet("callflowDetails", { callflow_id: pk })
    setCallflowName(result.result.name)
    setCurrentName(result.result.name)
    setCallFlowDetails(result.result)
    petition(result.result)
  } catch (error) {
    console.log(error)
  }
}

export default fetchCallflowDetails;