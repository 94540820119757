import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

const PickerIcons = ({ openPicker, setOpenPicker, element, chatWindowData, index }) => {
  const isOtherContainer = element.message_participant === chatWindowData.numberChat ? true : false
  const classPickerContainer = `picker-container${isOtherContainer ? "-left" : ""}`

  return (
    <>
      {openPicker === index && (
        <div className={classPickerContainer}>
          <Picker
            data={data}
            onEmojiSelect={() => {
              setOpenPicker(null);
            }}
            previewPosition="none"
            theme="light"
            skinTonePosition="none"
            set="Apple"
          />
        </div>
      )}
    </>
  );
};

export default PickerIcons;
