
import { setupRemoteMedia, cleanupMedia } from "./media"
import { SessionState } from "sip.js";

// const ringtone = new Audio(ringtoneSound);

// ringtone.loop = true;


function onInvite(invitation, setInvitationSession,setCallSessionsFunctionEnd,callAudio,setCallAudio,ringtone,handleAnswerOnInvite,sessionIncall) {
  let callAudioTemp = new Audio();
  // if (invitation) {
    if (invitation._state === "Initial") {
      console.log("Invitation state: Initial");
      setInvitationSession(invitation)
      // console.log("fffffffffffffffffffffffff2",invitation._id)
      ringtone.play()
      .then(() => {
          console.log('Reproducción exitosa');
      })
      .catch(error => {
          console.error('Error al reproducir el sonido:', error);
      });

    }
    invitation.stateChange.addListener((state) => {
      console.log("Invitation state:", state);
      switch (state) {
        case SessionState.Initial:
          console.log("Invitation state: Initial");
          break;
        case SessionState.Establishing:
          console.log("Invitation state: Establishing");
          ringtone.play().catch(error => console.warn('Failed to play ringtone:', error.message));

          break;
        case SessionState.Established:
          console.log("Invitation state: Established");
          setupRemoteMedia([invitation], callAudio);
          setCallAudio(callAudioTemp)
          ringtone.pause()
          handleAnswerOnInvite(invitation,sessionIncall.length - 1,false)
          break;
        case SessionState.Terminating:
        case SessionState.Terminated:
          console.log("Invitation state: Terminating/Terminated"); 
          setCallSessionsFunctionEnd(invitation)
          ringtone.pause()
          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  // }
}

export default onInvite;