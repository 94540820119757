const downloadFileService = async (url, token) => {
  const response = await fetch(url, {
    headers: {
      Authorization: "Bearer " + token.replace(/['"]+/g, ""),
    },
  });
  if (!response.ok) {
    throw new Error("Error downloading file");
  }
  return response.blob();
};

export default downloadFileService;
