import petitionGet from "../../../../../../services/petitionGet";

const fetchUsers = async (setUsers, setIsLoadingSelectUsers) => {
  try {
    const { data: result } = await petitionGet("users");

    result.result.forEach((element) => {
      element.label = `${element.firstname} ${element.lastname}`;
      element.value = element.pk;
    });

    setUsers(result.result);
    setIsLoadingSelectUsers(false);
  } catch (error) {
    console.log(error);
  }
};

export default fetchUsers;
