import { countryCodes } from "../const/countryCodes";

const verifyPhoneNumber = (phone) => {
  // Regular expression to check format
  const regex = /^\+(\d{1,4})(\d{7,15})$/;

  // Checks if the string matches the pattern
  const match = phone?.match(regex);

  if (match) {
    const numberWithoutPlusSign = match[1] + match[2];

    // Find the longest possible country code
    let countryCode = "";
    for (let i = 1; i <= 4; i++) {
      const possibleCode = numberWithoutPlusSign.substring(0, i);
      if (countryCodes.includes(possibleCode)) {
        countryCode = possibleCode;
        break;
      }
    }

    if (countryCode) {
      const phoneNumber = numberWithoutPlusSign.substring(countryCode.length);
      return {
        isValid: true,
        countryCode,
        phoneNumber,
      };
    }
  }

  return {
    isValid: false,
    countryCode: null,
    phoneNumber: null
  };
};

export default verifyPhoneNumber;
