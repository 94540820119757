const isDate = (contactDetailsMenu, element) => {
  return new Date(contactDetailsMenu[element.name]) !== "Invalid Date" && !isNaN(new Date(contactDetailsMenu[element.name]))
};

const processCustomfields = (form, customFields, contactDetailsMenu, setForm) => {
  let newform = form;

  customFields.current.forEach((element) => {

    if (isDate(contactDetailsMenu, element) && contactDetailsMenu[element.name] !== null && contactDetailsMenu[element.name] !== undefined && element.field_type === "date") {
      let temp_date = contactDetailsMenu[element.name].split("/");

      if (temp_date.length == 1) {
        newform[element.name] = temp_date[0];
      } else {
        newform[element.name] = `${temp_date[2]}-${temp_date[0]}-${temp_date[1]}`;
      }
    } else {
      newform[element.name] = contactDetailsMenu[element.name];
    }
  });

  setForm(newform);
};

export default processCustomfields;
