import petitionPatch from "../../../../../../services/petitionPatch";
import petitionPost from "../../../../../../services/petitionPost";

const addOrEditTagService = async (setLoading, tagName, color, useColor, info, reloadData) => {
  try {
    setLoading(true);

    const data = {
      name: tagName,
      tag_color: useColor ? color : "",
    }

    if (info) {
      await petitionPatch("tag", { data, tag_id: info.pk })
    } else {
      await petitionPost("tag", { data })
    }

    setLoading(false);
    reloadData();
  } catch (error) {
    console.log(error)
  }
}

export default addOrEditTagService;