import petitionPatch from "../../../../../../services/petitionPatch";

const saveUsersService = async (setLoadingNumber, number, data, newData, organizeDataUsers, closeMenu, setInfo, allUsers, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage) => {
  try {
    setLoadingNumber(true);

    await petitionPatch("numberEdit", { number, data });
    setInfo(newData);
    organizeDataUsers(newData, allUsers);
    setLoadingNumber(false);
    closeMenu();
  } catch (error) {
    console.log(error);
    setAlertType("error")
    setAlertTitle("Error")
    setAlertMessage("An error has ocured")
    setShowErrorTime(3000)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, 3300)
  }
};

export default saveUsersService;
