const generateFakeChat = () => {
  return {
    isFake: true,
    ai_enabled: false,
    isContact: false,
    last_event_type: "call",
    last_message: "You made a call to +1 (305) 299 - 0233",
    last_updated: "2023-11-24 09:21:40",
    last_updated_utc: "2023-11-24T14:21:40.015195",
    notIsSelf: {
      is_self: false,
      message_thread_id: "000000",
      number: "3456789012",
      pk: 0,
    },
    numberChat: "3456789012",
    number_of_participants: 2,
    participants: [
      {
        is_self: true,
        message_thread_id: "0",
        number: "+17867086484",
        pk: 61583,
      },
      {
        is_self: false,
        message_thread_id: "0",
        number: "+17867086484",
        pk: 61583,
      },
    ],
    pinned: false,
    pk: 0,
    thread_read: false,
    unread_messages: 0,
  };
};

const generateFakeChats = () => {
  const fakeChats = [];
  for (let i = 0; i < 15; i++) {
    fakeChats.push(generateFakeChat());
  }
  return fakeChats;
};

export default generateFakeChats;
