import { v4 as uuidv4 } from "uuid";
import petitionGet from "../../../../../../services/petitionGet";

const fetchCallflowNumbers = async (callFlowDetails, setLoading, setFormNumbersSwitch, setSelectedUsers, setData, setAllData) => {
  try {
    setLoading(true);

    const { data: result } = await petitionGet("viewNumbers")

    let numbersCopy = [];
    let dataUsersCopy = [];
    let selectedUsersCopy = [];

    result.result.forEach((element) => {
      if (!element.used_by) {
        numbersCopy.push({ number: element });
      }
    });

    callFlowDetails?.numbers.forEach((element) => {
      const findOne = numbersCopy.find((elementTwo) => elementTwo.number.number === element);
      if (findOne) {
        selectedUsersCopy.push(findOne);
      } else {
        let generateId = uuidv4();

        selectedUsersCopy.push({
          delete: true,
          number: { number: element },
          id: generateId,
        });

        dataUsersCopy.push({
          delete: true,
          number: { number: element },
          id: generateId,
        });
      }
    });

    let formSwitchs = {};

    numbersCopy.forEach((element) => {
      formSwitchs[element.number.number] = false;
    });

    selectedUsersCopy.forEach((element) => {
      formSwitchs[element.number.number] = true;
    });

    setFormNumbersSwitch(formSwitchs);
    setSelectedUsers(selectedUsersCopy);

    setData([...numbersCopy, ...dataUsersCopy]);
    setAllData([...numbersCopy, ...dataUsersCopy]);

    setLoading(false);
  } catch (error) {
    console.log(error)
  }
}

export default fetchCallflowNumbers;