import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import WaveSurfer from "wavesurfer.js";
import base64ToArrayBuffer from "../../../utils/base64ToArrayBuffer";

const useDownloadAudio = (audioUrl, customDownload, downloadWhenClicked, noFetch, barHeight) => {
  const waveformRef = useRef(null); // Referencia al elemento del canvas

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [startDownload, setStartDownload] = useState(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [audio, setAudio] = useState("")

  const donwloadAudio = useCallback(async() => {
    setLoadingDownload(true);

    const checkAudioValidity = async () => {
      try {
        // const response = await axios.get(audioUrl, {
        //   responseType: "arraybuffer",
        // });

        let response;

        if (customDownload) {
          response = await customDownload();
        } else {
          response = await axios.get(audioUrl);
        }
        
        console.log(response)

        if (response.status === 200) {
          const base64Audio = response.data.result
          const arrayBuffer = base64ToArrayBuffer(base64Audio);

          if (arrayBuffer) {
            const waveSurfer = WaveSurfer.create({
              container: waveformRef.current,
              barWidth: 3,
              barHeight: 0.4,
              barGap: 1,
              waveColor: "rgba(0, 0, 0, 0.2)",
              progressColor: "transparent",
              cursorWidth: 0,
              barRadius: 0,
            });
 
            // Crear un blob a partir del ArrayBuffer
            const blob = new Blob([arrayBuffer], { type: "audio/*" });

            // Crear una URL del blob
            const blobUrl = URL.createObjectURL(blob);

            waveSurfer.load(blobUrl);

            waveSurfer.on("audioprocess", (time) => {
              setTimeProgress(time);
            });

            const contentType = customDownload ? response.data.contentType : "audio/mp3"
            setAudio(`data:${contentType};base64,${base64Audio}`)
            setDisabled(false);
          } else {
            console.log("no es un audio");
            setDisabled(true);
            setModal(true);
          }
        } else {
          console.log("request failed");
          setDisabled(true);
          setModal(true);
        }
      } catch (error) {
        console.error(error);
        setDisabled(true);
        setModal(true);
      } finally {
        setLoadingDownload(false);
        setStartDownload(false);
      }
    };

    // if (customDownload) {
    //   const returnAudioUrl = await customDownload();

    //   if (returnAudioUrl) {
    //     // Crea una instancia de WaveSurfer
    //     const waveSurfer = WaveSurfer.create({
    //       container: waveformRef.current,
    //       barWidth: 3, // Ajusta el ancho de cada elemento
    //       barHeight: 0.4, // Ajusta el alto de cada elemento
    //       barGap: 1,
    //       waveColor: "rgba(0, 0, 0, 0.2)", // Color de la onda
    //       progressColor: "transparent", // Color de la parte progresada
    //       cursorWidth: 0, // Desactiva el cursor interno de WaveSurfer
    //       barRadius: 0, // Desactiva la curvatura de las barras
    //       // normalize: true, // Normaliza los valores de onda
    //     });

    //     // Carga el archivo de audio
    //     setAudio(returnAudioUrl)
    //     waveSurfer.load(returnAudioUrl);

    //     // Escucha el evento de actualización del tiempo
    //     waveSurfer.on("audioprocess", (time) => {
    //       setTimeProgress(time);
    //     });

    //     setDisabled(false);
    //     setLoadingDownload(false);
    //     return () => {
    //       // Limpia al desmontar el componente
    //       waveSurfer.destroy();
    //     };
    //   }
    // } else {
    //   checkAudioValidity();
    // }

    checkAudioValidity();
  }, [setLoadingDownload, setTimeProgress, setDisabled, setStartDownload, customDownload, setModal]);

  useEffect(() => {
    if (!disabled) {
      setLoadingDownload(false);
    }
  }, [disabled, setLoadingDownload]);

  useEffect(() => {
    if (!downloadWhenClicked) {
      if (noFetch) {
        const waveSurfer = WaveSurfer.create({
          container: waveformRef.current,
          barWidth: 3, // Ajusta el ancho de cada elemento
          barHeight: barHeight || 0.4, // Ajusta el alto de cada elemento
          barGap: 1,
          waveColor: "rgba(0, 0, 0, 0.2)", // Color de la onda
          progressColor: "transparent", // Color de la parte progresada
          cursorWidth: 0, // Desactiva el cursor interno de WaveSurfer
          barRadius: 0, // Desactiva la curvatura de las barras
          // normalize: true, // Normaliza los valores de onda
        });

        try {
                  // Carga el archivo de audio
        setAudio(audioUrl)
        waveSurfer.load(audioUrl);

        // Escucha el evento de actualización del tiempo
        waveSurfer.on("audioprocess", (time) => {
          setTimeProgress(time)
        });

        setDisabled(false);
        } catch (error) {
          console.log(error)
          setDisabled(true);
          setModal(true);
        }

        return () => {
          // Limpia al desmontar el componente
          waveSurfer.destroy();
        };
      } else {
        const checkAudioValidity = async () => {
          try {
            const response = await axios.get(audioUrl);
            if (response.status === 200) {
              const base64Audio = response.data.result
              const arrayBuffer = base64ToArrayBuffer(base64Audio);

              if (arrayBuffer) {
                // Crea una instancia de WaveSurfer
                const waveSurfer = WaveSurfer.create({
                  container: waveformRef.current,
                  barWidth: 3, // Ajusta el ancho de cada elemento
                  barHeight: 0.4, // Ajusta el alto de cada elemento
                  barGap: 1,
                  waveColor: "rgba(0, 0, 0, 0.2)", // Color de la onda
                  progressColor: "transparent", // Color de la parte progresada
                  cursorWidth: 0, // Desactiva el cursor interno de WaveSurfer
                  barRadius: 0, // Desactiva la curvatura de las barras
                  // normalize: true, // Normaliza los valores de onda
                });

                // Crear un blob a partir del ArrayBuffer
                const blob = new Blob([arrayBuffer], { type: "audio/*" });

                // Crear una URL del blob
                 const blobUrl = URL.createObjectURL(blob);

                // Carga el archivo de audio
                setAudio(`data:audio/mp3;base64,${response.data.result}`)
                waveSurfer.load(blobUrl);

                // Escucha el evento de actualización del tiempo
                waveSurfer.on("audioprocess", (time) => {
                  setTimeProgress(time)
                });

                setDisabled(false);
                return () => {
                  // Limpia al desmontar el componente
                  waveSurfer.destroy();
                };
              }
            }
          } catch (error) {
            console.error(error);
          }
        };

        checkAudioValidity();
      }
    }
  }, [audioUrl, downloadWhenClicked, noFetch, setTimeProgress, setDisabled]);

  useEffect(() => {
    if (startDownload) {
      donwloadAudio();
    }
  }, [startDownload, donwloadAudio]);

  return {
    waveformRef,
    loadingDownload,
    setStartDownload,
    timeProgress,
    setTimeProgress,
    disabled,
    modal,
    setModal,
    audio,
  };
};

export default useDownloadAudio;
