const checkIfANyIsPinnedOrNot = (chatList, selectedItems, pin) => {
  const selectPinOrUnPin = (chat) => {
    if (pin) {
      return chat.pinned
    } else {
      return !chat.pinned
    }
  }

  const selectedPks = Array.from(selectedItems)

  // We filter the chats whose pk is in the selected array
  const selectedChats = chatList.filter((chat) => selectedPks.includes(chat.pk));

  // We check if at least one of the selected chats is fixed or not depending on the case
  return selectedChats.some((chat) => selectPinOrUnPin(chat));
}

export default checkIfANyIsPinnedOrNot;