import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../components";

const ModalCloseAccount = ({ modal, closeModal, modalFunction, loading, error }) => {
  const confirmText = "I Agree";

  const [agree, setAgree] = useState("");

  const handleChangeAgree = (e) => {
    const { value } = e.target;
    setAgree(value);
  };

  const confirmFunction = () => {
    if (!loading) modalFunction();
  };

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>Closing Account</ModalHeader>
      <ModalBody>
        Closing this account will remove all phone numbers and users from your
        account. You will NOT be able to recover your numbers or data. Are you
        sure you wish to continue?

        <label htmlFor="agree" className="itp-my-settings-card-title mt-3">
          You need to type "I Agree" to continue
        </label>
        <input
          value={agree}
          className="form-control-itp"
          name="agree"
          id="agree"
          onChange={handleChangeAgree}
        />

        {error && <p className="text-danger">{error}</p>}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} onClick={closeModal} className="btn-light">
          Cancel
        </button>
        <button
          disabled={confirmText !== agree}
          onClick={confirmFunction}
          className="btn-primary loading"
        >
          {loading && <LoadingButton />}
          Confirm
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCloseAccount;
