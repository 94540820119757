import { all } from "redux-saga/effects";
import loginSaga from "./loginSaga";
import logoutSaga from "./logoutSaga";
import {defaultSidebarSaga} from "./sidebarSaga";
import {hiddenSidebarSaga} from "./sidebarSaga";
import defaultHeaderSaga from "./defaultHeaderSaga";
import hiddenHeaderSaga from "./hiddenHeaderSaga";
import myExtensionSaga from "./myExtensionSaga";
import updateMyExtensionSaga from "./updateMyExtensionSaga";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    logoutSaga(),
    defaultSidebarSaga(),
    hiddenSidebarSaga(),
    defaultHeaderSaga(),
    hiddenHeaderSaga(),
    myExtensionSaga(),
    updateMyExtensionSaga(),
  ]);
}
