const userBarge = (socketInfo, usersInSpye, myExtension, forceUpdate) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return;
  }

  const { target_user_id, uniqueid, from_user_id } = socketInfo?.metadata;

  try {
    if (myExtension.pk.toString() === from_user_id) {
      const prevState = [...usersInSpye.current]
      const newEvent = { event: "barge", target_user_id, uniqueid, from_user_id };
      const newArray = [...prevState, { ...newEvent }];
  
      usersInSpye.current = newArray
      forceUpdate()
    }
  } catch (error) {
    console.log(error);
  }
};

export default userBarge;
