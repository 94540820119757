import petitionPost from "../../../../services/petitionPost";

const addNoteService = async (contact_id, noteContent, setNoteContent, setLoadingNote, backNote, getNotes) => {
  try {
    setLoadingNote(true);

    const data = {
      contact_id,
      note_message: noteContent,
    };

    await petitionPost("addNoteContact", { data });

    setLoadingNote(false);
    setNoteContent("");
    backNote();
    getNotes();
  } catch (error) {
    console.log(error);
  }
};

export default addNoteService;
