import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { MenuProvider } from './contexts/MenuContext.js';
import { DynamicFunctionProvider } from './contexts/DynamicFunctionContext.js';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './redux/store';
import petitionPost from './services/petitionPost';
import './index.css';

class Root extends Component {
  componentDidCatch(error, errorInfo) {
    // Aquí puedes enviar la información del error a tu endpoint
    const errorMessage = error.toString();
    console.log("errorBoundary",errorMessage)
    let data = {
      error_message: errorMessage,
      user_agent: window.navigator.userAgent,
    };
    petitionPost('errorLog',{data})
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Provider store={store}>
        <DynamicFunctionProvider>
          <MenuProvider>
            <App />
          </MenuProvider>
        </DynamicFunctionProvider>
      </Provider>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);