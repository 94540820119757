import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { AlignedRow } from "../../../../components";
import infoIcon from "../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import petitionPatch from "../../../../services/petitionPatch";
import petitionPost from "../../../../services/petitionPost";
import Skeleton from "react-loading-skeleton";

const EnableAI = ({
  switchAI,
  setSwitchAI,
  industryValue,
  setIndustryValue,
  currentChecks,
  setCurrentChecks,
  subIndustrySelectedIds,
  setSubIndustrySelectedIds,
  setSubIndustries,
  industries,
  subIndustries,
  setIndustries,
  setLoadingChangeSwitchAI,
  petition,
  loading,
}) => {
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;

  const handleChangeSwitchAI = () => {
    if (switchAI) {
      const currentIndustryValue = industryValue;
      const currentCheckbBoxs = currentChecks;
      const currentSubIndustrySelectedIds = subIndustrySelectedIds;
      const currentSubIndustries = subIndustries;
      const currentIndustries = industries;

      setSwitchAI(false);
      setIndustries([]);
      setCurrentChecks({});
      setSubIndustries([]);

      const data = {
        ai_enabled: false,
      };

      petitionPatch("aiDetailsUpdate", { data })
        .then(({ data: result }) => {})
        .catch((error) => {
          console.log(error);
          setSwitchAI(true);
          setIndustryValue(currentIndustryValue);
          setCurrentChecks(currentCheckbBoxs);
          setSubIndustrySelectedIds(currentSubIndustrySelectedIds);
          setSubIndustries(currentSubIndustries);
          setIndustries(currentIndustries);
        });
    } else {
      setSwitchAI(true);
      setLoadingChangeSwitchAI(true);

      const data = {
        asterisk_account_id: id,
        ai_enabled: true,
      };

      petitionPost("ai", { data })
        .then(({ data: result }) => {
          petition(true);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status !== 400) {
            setSwitchAI(false);
            setLoadingChangeSwitchAI(false);
          } else {
            const data = {
              ai_enabled: true,
            };
            petitionPatch("aiDetailsUpdate", { data })
              .then(({ data: result }) => {
                petition(true);
              })
              .catch((error) => {
                console.log(error);
                setSwitchAI(false);
                setLoadingChangeSwitchAI(false);
              });
          }
        });
    }
  };

  return (
    <>
      <AlignedRow>
        <Card>
          <CardBody>
            <span className="itp-my-settings-card-title">Enable AI</span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={10} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>This will enable the Artificial Intelligence Engine</p>
                </div>
              </Col>

              <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
                {loading ? (
                  <Skeleton width="5rem" height="2rem" />
                 ) : (
                  <div className="d-flex gap-5 align-items-center pl-0 justify-content-end">
                    <Switch
                      className="itp-custom-table-switch"
                      onColor="#626ed4"
                      height={20} // Ajusta la altura según tus necesidades
                      width={40}
                      checked={switchAI}
                      onChange={handleChangeSwitchAI}
                    />
                  </div>
                 )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </AlignedRow>
    </>
  );
};

export default EnableAI;
