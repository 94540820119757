import { createNewChat } from "../../utils";
import petitionGet from "../../../../../../services/petitionGet";

const findExistingMessageService = async (assigned_number, new_chat_number, chatList, dataNumber, number) => {
  try {
    const { data: result } = await petitionGet("findExistingMessage", { assigned_number, new_chat_number })
    const filteredChatList = chatList.current.filter((chat) => !chat.notIsSelf.addedFronEnd);
    const objInfo = result?.result && result?.result?.length ? result : null
    const newChat = createNewChat(objInfo, dataNumber, number)
    const newChatList = [newChat, ...filteredChatList]
    return { newChat, newChatList, result: objInfo }
  } catch (error) {
    console.log(error)
  }
}

export default findExistingMessageService;