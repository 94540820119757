import petitionGet from "../../../../../../services/petitionGet";

const searchContactListService = async (inputValue) => {
  try {
    const parameter = inputValue ? `?name=${inputValue}` : "?offset=0&limit=10"
    const { data: result } = await petitionGet("contactList", { parameter })

    result.result.forEach((element) => {
      element.label = `${element.list_name} (${element.contact_count})`;
      element.value = element.pk;
    });

    return result.result
  } catch (error) {
    console.log(error)
  }
}

export default searchContactListService;