import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { LoadingButton } from "../../../../../components";

const ModalAmount = ({
  modal,
  closeModal,
  modalFunction,
  value,
  onChange,
  loading,
  error,
}) => (
  <Modal isOpen={modal ? true : false} className="info">
    <ModalHeader>Amount</ModalHeader>
    <ModalBody>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Enter the amount
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            style={{ borderBottom: "0" }}
            className="form-control-itp"
            placeholder="150"
            value={value}
            onChange={onChange}
          />
        </Col>
      </Row>
      {error && <p className="text-danger">{error}</p>}
    </ModalBody>
    <ModalFooter>
      <button
        className="btn-light"
        onClick={closeModal}
        disabled={loading}
      >
        Close
      </button>
      <button
        className="btn-primary loading"
        onClick={modalFunction}
        disabled={!value}
      >
        {loading && <LoadingButton />}
        Make Payment
      </button>
    </ModalFooter>
  </Modal>
);

export default ModalAmount;
