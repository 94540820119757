const userSpyStop = (socketInfo, usersInSpye, setLoadingClickAction, forceUpdate) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return;
  }

  const { uniqueid } = socketInfo?.metadata

  try {
    const prevState = usersInSpye.current
    const newArray = prevState?.filter((event) => event?.uniqueid && event?.uniqueid !== uniqueid)
    usersInSpye.current = newArray
    setLoadingClickAction(false)
    forceUpdate()
  } catch (error) {
    console.log(error)
  }
}

export default userSpyStop;