import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../components";
import Skeleton from "react-loading-skeleton";
import Switch from "react-switch";
import Select from "react-select";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionGet from "../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber";
import "../../MySettings.css";

const CallerID = ({
  myExtensionTemp,
  setCompletedRequests,
  loading,
  assignedNumbers,
}) => {
  const myExtension = useRef({});

  const [valuenumberCallerID, setValueNumberCallerID] = useState("");
  const [numbersCallerId, setNumbersCallerId] = useState([]);
  const [firstrender, setFirstRender] = useState(true);
  const [switchs, setSwitchs] = useState({
    switchOverrideDefaultCallerID: false,
  });

  const [formCallerId, setFormCallerId] = useState({
    default_outbound_callerid_number: "",
    default_outbound_callerid_name: "",
  });

  const handleChangeNumberCallerID = (e) => {
    setValueNumberCallerID(e);
    setFormCallerId({
      ...formCallerId,
      default_outbound_callerid_number: e.value,
    });

    let data = {
      default_outbound_callerid_number: e.value,
    };

    petitionPatch("userDetails", { data })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension.current,
          ...result.result,
        };

        myExtension.current = newUserDetails;
      })
      .catch((error) => console.log(error));
  };

  const handleChangeSwitchCallerID = () => {
    setSwitchs({
      ...switchs,
      switchOverrideDefaultCallerID: !switchs.switchOverrideDefaultCallerID,
    });

    if (switchs.switchOverrideDefaultCallerID) {
      let data = {
        default_outbound_callerid_number: "",
        default_outbound_callerid_name: "",
      };

      petitionPatch("userDetails", { data })
        .then(({ data: result }) => {
          setFormCallerId(data);
          const newUserDetails = {
            ...myExtension.current,
            ...result.result,
          };

          myExtension.current = newUserDetails;
        })
        .catch((error) => console.log(error));
    }
  };

  const petition = (myExtension) => {
    assignedNumbers.forEach((element) => {
      element.label = formatPhoneNumber(element.number);
      element.value = element.number;
    });

    let defaultNumber = assignedNumbers.find(
      (element) =>
        element.number === myExtension.default_outbound_callerid_number
    );

    setSwitchs({
      ...switchs,

      switchOverrideDefaultCallerID:
        (myExtension.default_outbound_callerid_number === null ||
          myExtension.default_outbound_callerid_number === "") &&
        (myExtension.default_outbound_callerid_name === null ||
          myExtension.default_outbound_callerid_name === "")
          ? false
          : true,
    });

    setFormCallerId({
      default_outbound_callerid_name:
        myExtension.default_outbound_callerid_name || "",
      default_outbound_callerid_number:
        myExtension.default_outbound_callerid_number || "",
    });

    setValueNumberCallerID(defaultNumber);
    setNumbersCallerId(assignedNumbers);
    setFirstRender(false);
    setCompletedRequests((prevStata) => prevStata + 1);
  };

  useEffect(() => {
    if (formCallerId.default_outbound_callerid_name && !firstrender) {
      const timer = setTimeout(() => {
        let data = {
          default_outbound_callerid_name:
            formCallerId.default_outbound_callerid_name,
        };

        petitionPatch("userDetails", { data })
          .then(({ data: result }) => {
            const newUserDetails = {
              ...myExtension.current,
              ...result.result,
            };

            myExtension.current = newUserDetails;
          })
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [formCallerId.default_outbound_callerid_name]);

  useEffect(() => {
    if (myExtensionTemp && Object.keys(myExtensionTemp).length > 0, assignedNumbers) {
      myExtension.current = myExtensionTemp;
      petition(myExtensionTemp);
    }
  }, [myExtensionTemp, assignedNumbers]);

  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        id="CallerID"
        title="Caller ID"
        subtitle="Adjust your outbound caller ID settings when placing calls"
      >
        {" "}
        <span className="itp-my-settings-card-title">
          Override default Caller ID{" "}
        </span>
        <Row className="itp-my-settings-card-help-text">
          <Col md={10} className="d-flex">
            <div>
              <img src={infoIcon} alt="myExtension-icon" />
            </div>
            <div className="ms-3">
              <p>
                Enabling this option will force your devices to use a specific
                phone number when placing a call
              </p>
            </div>
          </Col>

          <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
            {loading ? (
              <Skeleton width="5rem" height="2rem" />
            ) : (
              <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                <Switch
                  className="itp-custom-table-switch"
                  onColor="#626ed4"
                  height={20} // Ajusta la altura según tus necesidades
                  width={40}
                  checked={switchs.switchOverrideDefaultCallerID}
                  onChange={handleChangeSwitchCallerID}
                />
              </div>
            )}
          </Col>
        </Row>
        {switchs.switchOverrideDefaultCallerID && !loading && (
          <>
            <span className="itp-my-settings-card-title">
              Caller ID Number:
            </span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={7} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>Select the number to use for outbound calls</p>
                </div>
              </Col>

              <Col md={5}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Select
                    onChange={(e) => {
                      handleChangeNumberCallerID(e);
                    }}
                    className="basic-single switch-caller-id-number"
                    classNamePrefix="select"
                    value={valuenumberCallerID}
                    isSearchable={true}
                    name="contact_list_id"
                    options={numbersCallerId}
                  />
                </div>
              </Col>
            </Row>

            <span className="itp-my-settings-card-title">Caller ID Name:</span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={9} className="d-flex">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>Type the name that appears when placing outbound calls</p>
                </div>
              </Col>

              <Col md={3}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <input
                    type="text"
                    placeholder=""
                    className="form-control-itp"
                    name="contactname"
                    value={formCallerId.default_outbound_callerid_name}
                    onChange={(e) => {
                      setFormCallerId({
                        ...formCallerId,
                        default_outbound_callerid_name: e.target.value,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
      </CollapsePanel>
    </>
  );
};

export default CallerID;
