import tag from "../../../../assets/icons/tag.svg";
import pin from "../../../../assets/icons/pin.svg";
import unpin from "../../../../assets/icons/unpin.svg";
import folder from "../../../../assets/icons/folder.svg"
import unarchived from "../../../../assets/icons/unarchived.svg"

const BulkButtons = ({ 
  selectedItems, 
  selectedOption, 
  handleClickAction,
}) => {
  const arraySelectedItems = Array.from(selectedItems)
  return (
    <>
      {arraySelectedItems?.length > 0 && (
        <div className="d-flex justify-content-end gap-2 align-items-center me-3 mt-2 mb-2">
          <button onClick={() => handleClickAction("tags")} className="btn-light itp-chat-list-bulkButton">
            <img src={tag} alt="Tag" className="itp-chat-list-bulkActions__button tags"/>
            Tags
          </button>

          <button onClick={() => handleClickAction("pin")} className="btn-light itp-chat-list-bulkButton">
            <img src={pin} alt="Pin" className="itp-chat-list-bulkActions__button pin" />
            Pin
          </button>

          <button onClick={() => handleClickAction("unpin")} className="btn-light itp-chat-list-bulkButton">
            <img src={unpin} alt="Unpin" className="itp-chat-list-bulkActions__button Unpin" />
            Unpin
          </button>

          <button onClick={() => handleClickAction(selectedOption === "Archived" ? "unarchive" : "archive")} className="btn-light itp-chat-list-bulkButton">
            {selectedOption === "Archived" ? (
              <>
                <img src={unarchived} alt="Unarchive" className="itp-chat-list-bulkActions__button unarchived" />
                Unarchive
              </>
            ) : (
              <>
                <img src={folder} alt="Folder" className="itp-chat-list-bulkActions__button archived" />
                Archived
              </>
            )}
          </button>
        </div>
      )}
    </>
  )
}

export default BulkButtons;
