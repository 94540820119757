import petitionPatch from "../../../../../../services/petitionPatch"

const editSwitchsVoicemailsService = async (loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true)

    const currentSwitchs = switchs

    setSwitchs({ ...switchs, [switchName]: switchs[switchName] === "yes" ? "no" : "yes" })

    try {
      const data = { [switchName]: switchs[switchName] === "yes" ? "no" : "yes" }
      await petitionPatch("voicemailAdmins", { data, user_id: details.pk })
      setLoadPetition(false)
    } catch (error) {
      console.log(error)
      setLoadPetition(false)
      setSwitchs(currentSwitchs)
      setErrorEditing("An error has ocurred")
      setModalError(true)
    }
  }
}

export default editSwitchsVoicemailsService;