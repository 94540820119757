import React, { useEffect, useState } from "react";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import { useNavigate } from "react-router-dom";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext.js";
import MessageTemplates from "./MessageTemplates/MessageTemplates.js";
import LeadSource from "./LeadSource/LeadSource.js";
import FeatureCodes from "./FeatureCodes/FeatureCodes.js";
import BlockedCallers from "./BlockedCallers/BlockedCallers.js";
import InternationalCalls from "./InternationalCalls/InternationalCalls.js";
import MediaAudioFile from "./MediaAudioFile/MediaAudioFile.js";
import OptOutKeywords from "./OptOutKeywords/OptOutKeywords.js";
import GeneralForwardingID from "./GeneralForwardingID/GeneralForwardingID.js";
import ClosingAccount from "./ClosingAccount/ClosingAccount.js";
import "../WorkspaceSettings.css";

const GeneralSettings = () => {
  const navigate = useNavigate()
  const dispatch = useMenuDispatch();
  const { resetFunction, setIsArrowVisible } = useDynamicFunction();

  const [completedRequests, setCompletedRequests] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [modalDelete, setModalDelete] = useState(false)

  const gotToMediaGroupsPage = (e) => {
    navigate(`/General-Settings/Media-Group?pk=${e.pk}&name=${e.name}`)
  }

  useEffect(() => {
    if (completedRequests === 9) {
      setLoading(false)
    }
  }, [completedRequests])

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_general_settings 
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          dispatch({ type: "SET_DEFAULT_MENU" });
          navigate("/Dashboard");
        } else {
          resetFunction();
          setIsArrowVisible(false)

          setCompletedRequests(prevStata => prevStata + 1)
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <>
      <div style={{ marginTop: "3rem" }}>
        <GeneralForwardingID
          loading={loading}
          setCompletedRequests={setCompletedRequests}
        />

        <MessageTemplates
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
        />

        <LeadSource
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
        />

        <FeatureCodes
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />

        <BlockedCallers
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
        />

        <InternationalCalls
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
        />

        <MediaAudioFile
          gotToMediaGroupsPage={gotToMediaGroupsPage}
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
        />

        <OptOutKeywords
          loading={loading}
          setCompletedRequests={setCompletedRequests}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          modalDelete={modalDelete}
          setModalDelete={setModalDelete}
        />

        <ClosingAccount loading={loading} />
      </div>
    </>
  );
};

export default GeneralSettings;
