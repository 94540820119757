import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";

const LabelInput = ({ children, label, loading, className }) => (
  <Row className={`itp-container-label-input ${loading ? "loading" : ""} ${className || ""}`}>
    <Col
      xs={5}
      sm={5}
      md={5}
      lg={5}
      xl={5}
      xxl={5}
      className="itp-label-input-col"
    >
      <label className="itp-label-input__label">{label}</label>
    </Col>
    <Col
      xs={7}
      sm={7}
      md={7}
      lg={7}
      xl={7}
      xxl={7}
      className="itp-label-input-col"
    >
      {loading ? <Skeleton height={50} width="100%" /> : <>{children}</>}
    </Col>
  </Row>
);

export default LabelInput;
