import { AlignedRow } from "../../../../../../../../components";

const Title = ({ number }) => (
  <>
    <AlignedRow className="mt-5">
      <h6 className="itp-accordion-header-subtitle">
        Phone Number Details : Manage settings for this number
      </h6>
    </AlignedRow>

    <AlignedRow className="mb-4">
      <h3 className="itp-accordion-header-title">Number : {number}</h3>
    </AlignedRow>
  </>
);

export default Title;
