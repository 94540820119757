import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { LoadingButton } from "../../../../components/index";

const ModalDelete = ({ modal, closeModal, loading, modalFunction, modalDeleteAll }) => {
  return (
    <Modal centered isOpen={modal ? true : false} className="info">
      <ModalHeader>Delete Messages</ModalHeader>
      <ModalBody>
        {modalDeleteAll ? (
          <>
            This will delete ALL Voicemails in your mailbox, do you wish to continue?
          </>
        ) : (
          <>Are you sure?</>
        )}
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          No
        </button>
        <button
          disabled={loading}
          className="btn-primary loading"
          onClick={modalFunction}
        >
          {loading && <LoadingButton />}
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
