const kmDeviceRegistered = (socketInfo, users) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return users;
  }

  const { user_id, device } = socketInfo?.metadata;
  const { owner_id } = device

  try {
    if (user_id && owner_id && user_id === owner_id) {
      const newUserState = users.map((user) => {
        if (user.pk === user_id && !user.devices_registered) {
          user.devices_registered = true
        }

        return user
      })

      return newUserState
    } else {
      console.log("Hay un error al actualizar el evento de KM_DEVICE_REGISTERED")
      return users
    }
  } catch (error) {
    console.log("Hay un error al actualizar el evento de KM_DEVICE_REGISTERED", error)
    return users
  }
}

export default kmDeviceRegistered;