import React from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../components";
import Skeleton from "react-loading-skeleton";

const General = ({ companyValues, setCompanyValues, loading }) => {
  return (
    <>
      <br></br>
      <div>
        <Row>
          <Col style={{ zIndex: "-1 " }}>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="General AI"
        subtitle="Enter some basic information about youir company for general conversation"
      >
        <span className="ai-company-title">Company information</span>

        <p className="ai-company-descp">
          Enter information about your company, such as the products or
          services you offer, any discounts or specials currently
          available, and what distinguishes you from competitors.
        </p>

        {loading ? (
          <div className="w-100">
            <Skeleton height="3.5rem" width="100%" />
          </div>
        ) : (
          <textarea
            value={companyValues}
            className="w-100 ai-textarea"
            onChange={(e) => setCompanyValues(e.target.value)}
            name="company_values"
          />
        )}
      </CollapsePanel>
    </>
  );
};

export default General;
