import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import Switch from "react-switch";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

const AI = ({
  form,
  handleChangeswitch,
  handleChangeForm,
  onChangeFunnel,
  funnelValue,
  funnels,
  loading,
}) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Artificial Intelligence"
      subtitle="Enable Artificial Intelligence to this number"
    >
      <Row>
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">AI</label>
            <label className="itp-edit-number-label-2">
              Enable auto response using AI
            </label>
          </div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          {loading ? (
            <Skeleton height="17px" width="17px" />
          ) : (
            <Switch
              className="itp-custom-table-switch mt-3"
              onColor="#626ed4"
              height={20} // Ajusta la altura según tus necesidades
              width={40}
              checked={form.ai_enabled}
              onChange={() => handleChangeswitch("ai_enabled")}
            />
          )}
        </Col>
      </Row>

      {form.ai_enabled && (
        <>
          <Row className="mt-4">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">
                  Appointment Scheduling
                </label>
                <label className="itp-edit-number-label-2">
                  Ability to Create or Update appointments within User Assigned
                  Calendars
                </label>
              </div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
               {loading ? (
                 <Skeleton height="17px" width="17px" />
               ) : (
                 <Switch
                   className="itp-custom-table-switch mt-3"
                   onColor="#626ed4"
                   height={20} // Ajusta la altura según tus necesidades
                   width={40}
                   checked={form.ai_manage_appointments}
                   onChange={() => handleChangeswitch("ai_manage_appointments")}
                 />
               )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">
                  Appointment Scheduling Strategy
                </label>
                <label className="itp-edit-number-label-2">
                  Select how we should schedule appointments when there is more
                  than one user
                </label>
              </div>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              {loading ? (
                 <Skeleton style={{ marginLeft: "4rem" }} height="2.5rem" width="100%" />
               ) : (
                <select
                  style={{ marginLeft: "0", width: "12.5rem" }}
                  className="form-select-itp"
                  value={form.ai_calender_strategy}
                  name="ai_calender_strategy"
                  onChange={handleChangeForm}
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="round_robin">Round Robin</option>
                  <option value="lease_busy">Lease Busy</option>
                  <option value="random">Random</option>
                </select>
               )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">
                  Lead Management
                </label>
                <label className="itp-edit-number-label-2">
                  Ability to Create, Update Leads, New leads will be assigned to
                  Funnels and Stages which closely resemble the conversation
                  flow. Ensure accurate descriptions and names are used in
                  Funnels and Funnels Stages
                </label>
              </div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              {loading ? (
                 <Skeleton height="17px" width="17px" />
               ) : (
                 <Switch
                   className="itp-custom-table-switch mt-3"
                   onColor="#626ed4"
                   height={20} // Ajusta la altura según tus necesidades
                   width={40}
                   checked={form.ai_lead_manager}
                   onChange={() => handleChangeswitch("ai_lead_manager")}
                 />
               )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">
                  Lead Management Funnel
                </label>
                <label className="itp-edit-number-label-2">
                  Funnel to use for Lead Management
                </label>
              </div>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
              {loading ? (
                 <Skeleton style={{ marginRight: "-4rem" }} height="3rem" width="100%" />
               ) : (
                <div style={{ marginRight: "-4rem" }}>
                  <Select
                    onChange={onChangeFunnel}
                    className="basic-single"
                    classNamePrefix="select"
                    value={funnelValue}
                    isSearchable={true}
                    name="ai_lead_manager_funnel_id"
                    placeholder="Select Option"
                    options={funnels}
                  />
                </div>
               )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">FAQ</label>
                <label className="itp-edit-number-label-2">
                  Ability to respond with answers from the FAQ if a question is
                  asked related to your industry.
                </label>
              </div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              {loading ? (
                 <Skeleton height="17px" width="17px" />
               ) : (
                 <Switch
                   className="itp-custom-table-switch mt-3"
                   onColor="#626ed4"
                   height={20} // Ajusta la altura según tus necesidades
                   width={40}
                   checked={form.ai_faq}
                   onChange={() => handleChangeswitch("ai_faq")}
                 />
               )}
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
              <div className="d-flex flex-column">
                <label className="itp-edit-number-label-1">Auto Followup</label>
                <label className="itp-edit-number-label-2">
                  Enables automatic chat tracking. If a sent message does not
                  receive a response within a set time period, the AI will
                  automatically send a nother message.
                </label>
              </div>
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
              {loading ? (
                 <Skeleton height="17px" width="17px" />
               ) : (
                 <Switch
                   className="itp-custom-table-switch mt-3"
                   onColor="#626ed4"
                   height={20} // Ajusta la altura según tus necesidades
                   width={40}
                   checked={form.auto_followup}
                   onChange={() => handleChangeswitch("auto_followup")}
                 />
               )}
            </Col>
          </Row>
        </>
      )}
    </CollapsePanel>
  </>
);

export default AI;
