import petitionGet from "../../../../../services/petitionGet";

const fetchFunnels = async (parameter) => {
  const { data: result } = await petitionGet("funnels", { parameter });
  result.result.forEach((funnel) => {
    funnel.label = funnel?.name || "";
    funnel.value = funnel?.pk || null; 
  });
  return result.result;
};

export default fetchFunnels;
