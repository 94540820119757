import "./IconAndText.css"

const IconAndText = ({ element, config, column }) => {
  return (
    <div className="d-flex align-items-center gap-2">
      {config.whichIsFirst === "icon" ? (
        <>
          <span>{element.icon}</span>
          <span className="email-text">{element[column.key]}</span>
        </>
      ) : (
        <>
          <span className="email-text">{element[column.key]}</span>
          <span>{element.icon}</span>
        </>
      )}
    </div>
  );
};

export default IconAndText;
