import petitionGet from "../../../../../../services/petitionGet";

const fetchPortRequest = async () => {
  const { data: result } = await petitionGet("portRequests");
  result.result.forEach((element) => {
    element.tableNumber = element.metadata.did;
    element.porting_actual_foc_date = element?.porting_actual_foc_date || "";
  });

  return result.result
};

export default fetchPortRequest;
