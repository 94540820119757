import { Col, Row } from "reactstrap";
import { GithubPicker } from "react-color";
import TextField from "@mui/material/TextField";

const AddTag = ({
  useColor,
  setUseColor,
  color,
  setColor,
  name,
  setName,
  loadingButton,
}) => {
  const colors = [
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#DFF21B",
  ];

  const handleChangeComplete = (color) => {
    if (loadingButton) return;
    setColor(color.hex);
    setUseColor(true);
  };

  const handleChangeName = (e) => {
    if(loadingButton) return;
    const { value } = e.target;
    setName(value);
  };

  const toggleColor = () => {
    if (loadingButton) return;
    setUseColor(!useColor);
  };

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
        <div className="itp-modal-addTag-container">
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <TextField
                variant="standard"
                placeholder="Tag Name"
                value={name}
                onChange={handleChangeName}
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
              <div>
                <Row>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <div className="itp-modal-addTag-color-container">
                      <span>Color</span>
                      <input
                        type="checkbox"
                        className="form-check-input itp-modal-addTag-checkColor"
                        value={useColor}
                        onClick={toggleColor}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    className="p-0"
                  >
                    <div className={`${useColor ? "d-block" : "d-none"}`}>
                      <div className="itp-modal-addColor-pickerColor-container">
                        <GithubPicker
                          color={color}
                          width="100%"
                          onChangeComplete={handleChangeComplete}
                          colors={colors}
                          className="github-picker"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default AddTag;
