const handleTextCopy = (text) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {})
    .catch((error) => {
      console.error(error);
    });
};

export default handleTextCopy;
