import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import informationBlue from "../../assets/icons/information-blue.svg";
import apiKey from "../../assets/images/apiKey.svg";
import ToggleImage from "../DropdownToggle/DropdownToggle";
import PaginationITP from "../PaginationITP/PaginationITP";

const CustomTableBadge = ({
  data,
  variant,
  alertText,
  cardTitle,
  cardSubtitle,
  buttonName,
  onClickFunction,
  paginationData,
  textNoData,
  noPlusIconButton,
  cardTitleVariant,
}) => {
  const cantPagination = parseInt(paginationData) || 10;

  const [dataFilter, setDataFilter] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [pagination, setPagination] = useState(cantPagination);
  const [paginationITP, setPaginationITP] = useState(cantPagination);

  const toggle = (event, index) => {
    event.stopPropagation();
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };

  const returnRowWithIcon = (element, elementTwo) => {
    const onClickIconFunction = (event) => {
      event.stopPropagation();
      if (elementTwo.handleClick) {
        elementTwo.handleClick(element);
      }
    };

    return (
      <div className="d-flex align-items-center">
        <span className="email-text">
          {/* si la key que muestra el icono en del objeto que representa la fila no tiene un
          tipo de dato valido entonces no se muestra en esta sección el valor que represnta la
          key principal esto se hace para que si no hay icono se pueda hacer hover al valor 
          de la key principal*/}
          {element[elementTwo?.keyIcon] && (
            <>
              {elementTwo.secondKey
                ? element[elementTwo.key][elementTwo.secondKey]
                : element[elementTwo.key]}
            </>
          )}
        </span>

        <div
          className="itp-livePanel-extensions__multple-call-container"
          onClick={onClickIconFunction}
        >
          {/*aquí se muestra el valor de la key pirncipal cuando no hay icono, para poder tener algo a lo que hacer hover*/}
          {!element[elementTwo?.keyIcon] && (
            <>
              {elementTwo.secondKey
                ? element[elementTwo.key][elementTwo.secondKey]
                : element[elementTwo.key]}
            </>
          )}

          {/*aquí solo me muestra el icono si la keyIcon dentro del objeto tiene un valor valido*/}
          {element[elementTwo?.keyIcon] && (
            <span>{element[elementTwo?.keyIcon]}</span>
          )}

          {/*aquí se muestra el contenido cuando se hace hover la icono o al valor de la key principal
          segun sea el caso siempre y cuando no sea un array*/}
          {elementTwo?.hasHover && element[elementTwo?.keyHover] && !elementTwo.keyHoverIsArray && (
            <div className="itp-livePanel-extensions__multple-call-info">
              {element[elementTwo?.keyHover]}
            </div>
          )}

          {/*aquí se muestra el contenido cuando se hace hover la icono o al valor de la key principal
          segun sea el caso si es un array de strings o numeros o combinados ya que se hace un mapeo
          de cada elemento*/}
          {elementTwo?.hasHover && element[elementTwo?.keyHover] && elementTwo?.keyHoverIsArray && (
            <div className="itp-livePanel-extensions__multple-call-info">
              {element[elementTwo?.keyHover].map((el, i) => (
                <span key={i}>{el}</span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const returnNormalRow = (element, elementTwo) => {
    if (elementTwo.secondKey) {
      return <>{`${element[elementTwo.key][elementTwo.secondKey]}`}</>;
    } else {
      return <>{`${element[elementTwo.key]}`}</>;
    }
  };

  useEffect(() => {
    if (paginationITP != pagination)
      setPagination(paginationITP * cantPagination);
  }, [paginationITP, pagination]);

  useEffect(() => {
    if (data) {
      setDataFilter(data?.content);
    }
  }, [data]);

  return (
    <div id="startComponent">
      <div className="itp-table-container-centered">
        <Row>
          <Col>
            <Card className="itp-my-settings-card-col">
              <CardBody>
                <Row
                  className={`itp-alert-text alert-background-${variant} d-flex align-items-center mb-4`}
                >
                  <Col md={12} className="d-flex justify-content-start">
                    <div className="d-flex alig-items-center">
                      {variant && variant !== "default" && (
                        <ErrorOutlineIcon
                          className={`itp-alert-icon-${variant}`}
                        />
                      )}

                      {(!variant || variant === "default") && (
                        <div className="itp-alert-icon-default">
                          <img
                            src={informationBlue}
                            alt="myExtensionTemp-icon"
                          />
                        </div>
                      )}

                      <div className={`itp-alert-data-${variant}`}>
                        {alertText || ""}
                      </div>
                    </div>
                  </Col>
                </Row>

                {cardTitle && (
                  <Row className="itp-api-key__add-key-row">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                    <Col
                      style={{
                        marginRight: variant === "success" ? "-9rem" : "",
                      }}
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                      xxl={4}
                    >
                      {variant !== "success" && <img src={apiKey} alt="" />}

                      {variant && variant === "success" && (
                        <CheckCircleOutlineIcon
                          style={{
                            width: "6rem",
                            height: "6rem",
                            color: "rgba(0, 170, 0, 0.8)",
                          }}
                        />
                      )}
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                      <h6
                        className={`itp-api-key__add-title-${
                          cardTitleVariant ? cardTitleVariant : variant
                        }`}
                      >
                        {cardTitle || ""}
                      </h6>
                      <p className={`itp-api-key__add-text-${variant}`}>
                        {cardSubtitle || ""}
                      </p>

                      {buttonName && (
                        <button
                          onClick={onClickFunction}
                          className="btn-primary itp-add-button"
                          style={{ minWidth: "10rem" }}
                        >
                          {!noPlusIconButton && (
                            <FontAwesomeIcon
                              icon={faPlus}
                              className="itp-icon-plus"
                            />
                          )}
                          {buttonName || ""}
                        </button>
                      )}
                    </Col>
                  </Row>
                )}

                {!cardTitle && (
                  <Row className="itp-api-key__add-key-row">
                    <Col>
                      {dataFilter.length === 0 && (
                        <Card className="itp-custom-table">
                          <CardBody className="itp-custom-table-no-data">
                            You currently have no {textNoData || "records"}
                          </CardBody>
                        </Card>
                      )}

                      {dataFilter.length > 0 && (
                        <>
                          <table className="itp-custom-table">
                            <thead>
                              <tr className="itp-custom-table-thead-styles">
                                {data.title.map((element, i) => (
                                  <th
                                    key={i}
                                    className="itp-custom-table-th-styles"
                                  >
                                    <div>{element.title}</div>
                                  </th>
                                ))}

                                {data.actions && (
                                  <th className="itp-custom-table-th-styles">
                                    {data.actions.title}
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {dataFilter.map((element, i) => {
                                if (
                                  (i < pagination &&
                                    i >= pagination - cantPagination) ||
                                  !pagination
                                ) {
                                  return (
                                    <tr
                                      onClick={
                                        data.clickRow
                                          ? () => data.clickRow(element)
                                          : () => {}
                                      }
                                      key={i}
                                      className="itp-custom-table-row"
                                    >
                                      {data.title.map((elementTwo, i2) => (
                                        <td
                                          key={i2}
                                          className="itp-custom-table-td-styles"
                                        >
                                          {elementTwo.hasIcon &&
                                            returnRowWithIcon(
                                              element,
                                              elementTwo
                                            )}

                                          {!elementTwo.hasIcon &&
                                            returnNormalRow(
                                              element,
                                              elementTwo
                                            )}
                                        </td>
                                      ))}

                                      {data.actions && (
                                        <td>
                                          <Dropdown
                                            isOpen={i === openDropdownIndex}
                                            toggle={(event) => toggle(event, i)}
                                          >
                                            <DropdownToggle className="nav-link">
                                              <ToggleImage />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu centered">
                                              {data.actions.content.map(
                                                (elementTwo, i2) => {
                                                  if (
                                                    !elementTwo.shouldValidate ||
                                                    (elementTwo.shouldValidate &&
                                                      element[
                                                        elementTwo.keyValidate
                                                      ])
                                                  ) {
                                                    return (
                                                      <DropdownItem
                                                        key={i2}
                                                        onClick={() => {
                                                          elementTwo.handleClick(
                                                            element
                                                          );
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            elementTwo.canBeDisabled &&
                                                            !element[
                                                              elementTwo
                                                                .keyDisabled
                                                            ]
                                                              ? "disabled-option"
                                                              : ""
                                                          }
                                                        >
                                                          {elementTwo.icon}{" "}
                                                        </span>
                                                        <span
                                                          className={
                                                            elementTwo.canBeDisabled &&
                                                            !element[
                                                              elementTwo
                                                                .keyDisabled
                                                            ]
                                                              ? "disabled-option"
                                                              : ""
                                                          }
                                                        >
                                                          {elementTwo.name}
                                                        </span>
                                                      </DropdownItem>
                                                    );
                                                  }
                                                }
                                              )}
                                            </DropdownMenu>
                                          </Dropdown>
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </table>

                          {dataFilter && dataFilter.length > cantPagination && (
                            <div
                              style={{ maxWidth: "991px", margin: "auto" }}
                              className="mt-4"
                            >
                              <PaginationITP
                                pages={
                                  (dataFilter.length / cantPagination) % 1 === 0
                                    ? Math.floor(
                                        dataFilter.length / cantPagination
                                      )
                                    : Math.floor(
                                        dataFilter.length / cantPagination + 1
                                      )
                                }
                                active={pagination / cantPagination}
                                setActive={setPaginationITP}
                              />
                            </div>
                          )}
                        </>
                      )}

                      {data.addButton && (
                        <div className="d-flex justify-content-end mt-5">
                          <button
                            onClick={() => data.addButton.handleClick()}
                            className="btn-primary itp-add-button"
                          >
                            {!data.addButton.notIcon && (
                              <FontAwesomeIcon
                                icon={faPlus}
                                className="itp-icon-plus"
                              />
                            )}{" "}
                            {data.addButton.label}
                          </button>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CustomTableBadge;
