import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


const ModalInfomation = ({ modal, closeModal, loading, title, text }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{title ? title : "Information"}</ModalHeader>
      <ModalBody>
        <p className="itp-label-input__label mt-4">{text}</p>
      </ModalBody>
      <ModalFooter>
        <button disabled={loading} className="btn-light" onClick={closeModal}>
          Ok
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalInfomation;