import petitionGet from "../../../../../services/petitionGet";
import { processContacts } from "../../utils";

const fetchContacts = async (parameter, myExtension, cancelToken) => {
  const { data: result } = await petitionGet("contacts", { parameter }, cancelToken)
  const contacts = processContacts(result.result, myExtension)

  return { contacts, result }
}

export default fetchContacts;