const callparkingAbandoned = (socketInfo, setParkingCall) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log("Invalid socketInfo or metadata");
    return;
  }

  const { parking_slot } = socketInfo?.metadata

  setParkingCall((prev) => {
    const newData = prev.map((element) => {
      if (element.slot_number === parking_slot) {
        element.call_id = ""
        element.callerid_num = ""
      }

      return element
    })

    return newData
  })
};

export default callparkingAbandoned;
