import petitionPatch from "../../../../../../services/petitionPatch"

const editTimeZoneService = async (e, loadPetition, setLoadPetition, form, setForm, details, timeZoneValue, setTimeZoneValue, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true)

    const currentForm = form
    const currentTimeZoneValue = timeZoneValue

    setForm({ ...form, time_zone: e.value })
    setTimeZoneValue(e)

    try {
      const data = { time_zone: e.value }
      await petitionPatch("changeDataUsers", { crm_contact_id: details?.crm_contact_id, data });
      setLoadPetition(false)
    } catch (error) {
      console.log(error)
      setLoadPetition(false)
      setTimeZoneValue(currentTimeZoneValue)
      setForm(currentForm)
      setErrorEditing("An error has ocurred")
      setModalError(true)
    }
  }
}

export default editTimeZoneService;