import { Card, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket, faCommentDots, faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { AlignedRow } from "../../../../../../../components";
import { getData, isActive, notShowSpyEvent, selectColor, selectNameStatus } from "./utils";
import useScreenSize from "../../../../../../../hooks/useScreenSize";
import Skeleton from "react-loading-skeleton";
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import "./UsersTable.css"

const UsersTable = ({ 
  dataUsers, 
  users, 
  setUsers, 
  handleClickAction, 
  myExtension, 
  usersInSpye, 
  permissions, 
  loading, 
}) => {
  const { width } = useScreenSize()

  const rowContainerClassName = `itp-custom-table-main-container ms-3 me-3 mt-4`;

  const noShow = (element, event) => {
    return notShowSpyEvent(element, event, myExtension, dataUsers, usersInSpye, permissions)
  }

  const data = (loading || !dataUsers) ? [1,1,1,1,1,1,1] : dataUsers

  return (
    <>
      <Row className={rowContainerClassName}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <Card>
            <AlignedRow noCentered m0={true}>
              <h3 className="itp-accordion-header-title mt-2">Extensions</h3>
            </AlignedRow>

            <AlignedRow noCentered m0={true}>
              <h6 className="itp-accordion-header-subtitle">
                View & communicate with all the users with their respective extension
                in the system
              </h6>
            </AlignedRow>

            <div className="resizable-vertical">
              <SimpleBar className="itp-custom-table-main-card mt-2">
              <table className={`itp-custom-table-main ${width >= 1697 ? "itp-custom-table-main-reduced" : "" }`}>
                <thead>
                  <tr className="itp-custom-table-main-thead-styles">
                    <th className="itp-custom-table-main-th-styles">Status</th>
                    <th className="itp-custom-table-main-th-styles">Extension</th>
                    <th className="itp-custom-table-main-th-styles">Caller ID</th>
                    <th className="itp-custom-table-main-th-styles">Call Status</th>
                    <th className="itp-custom-table-main-th-styles">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((element, i) => (
                    <tr className="itp-custom-table-main-row" key={i}>
                      <td 
                        style={{
                          borderLeft: loading ? "" : `6px solid ${selectColor(element, users)}`,
                          color: loading ? "" : selectColor(element, users),
                        }}
                        className="itp-custom-table-main-td-styles"
                      >
                        {loading ? <Skeleton height={30} width="120px" /> : selectNameStatus(element, users)}
                      </td>
                      <td className="itp-custom-table-main-td-styles">
                        {loading ? <Skeleton height={30} width="120px" /> : element.presence_id}
                      </td>
                      <td className="itp-custom-table-main-td-styles">
                        {loading ? <Skeleton height={30} width="120px" /> : element.callerID}
                      </td>
                      <td className="itp-custom-table-main-td-styles">
                        {loading ? <Skeleton height={30} width="120px" /> : getData(element.channels, element.pk, users, setUsers)}
                      </td>
                      <td className="itp-custom-table-main-td-styles">
                        {loading ? (
                          <Skeleton height={30} width="120px" />
                        ) : (
                          <div className="d-flex align-items-center gap-2">
                            <div className={`itp-livePanel-user-actions-icon-container ${noShow(element, "whisper") ? "d-none" : ""}`}>
                              <div className="itp-livePanel-user-actions-icon-hover">
                                Whisper
                              </div>
                              <FontAwesomeIcon onClick={() => handleClickAction(element, "whisper")} icon={faCommentDots} className={`itp-livePanel-users-actions ${isActive(element, "whisper", usersInSpye) ? "active" : ""}`} />
                            </div>
                            <div className={`itp-livePanel-user-actions-icon-container ${noShow(element, "barge") ? "d-none" : ""}`}>
                              <div className="itp-livePanel-user-actions-icon-hover">
                                Barge
                              </div>
                             <FontAwesomeIcon onClick={() => handleClickAction(element, "barge")} icon={faArrowRightToBracket} className={`itp-livePanel-users-actions ${isActive(element, "barge", usersInSpye) ? "active" : ""}`} />
                            </div>
                            <div className={`itp-livePanel-user-actions-icon-container ${noShow(element, "listen") ? "d-none" : ""}`}>
                              <div className="itp-livePanel-user-actions-icon-hover">
                                Listen
                              </div>
                              <FontAwesomeIcon onClick={() => handleClickAction(element, "listen")} icon={faUserSecret} className={`itp-livePanel-users-actions ${isActive(element, "listen", usersInSpye) ? "active" : ""}`} />
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </SimpleBar>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UsersTable;
