import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { LoadingButton, MenuRight, CustomTableMain, AddButton, CollapsePanel } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import ModalDelete from "../../ModalUtility/ModalDelete";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";
import petitionGet from "../../../../../services/petitionGet";

const OptOutKeywords = ({
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
  modalDelete,
  setModalDelete,
}) => {
  const [formKeyword, setFormKeyword] = useState({ keyword: "" });
  const [showAddOrEditKeyword, setShowAddOrEditKeyword] = useState(false);
  const [loadingKeyword, setLoadingKeyword] = useState(false);
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState("");
  const [dataTableOpt, setDataTableOpt] = useState({
    columns: [{ name: "Keyword", key: "keyword" }],
    actions: { title: "Actions", content: [] }
  });

  const closeMenu = () => {
    setLoadingKeyword(false);
    setError("");
    setFormKeyword({ keyword: "" });
    setShowAddOrEditKeyword(false);
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const handleChangeFormKeyword = (e) => {
    setFormKeyword({ ...formKeyword, [e.target.name]: e.target.value });
    if (error) setError("");
  };

  const activeDeleteKeyword = (e) => {
    setInfo(e);
    setModalDelete(6);
  };

  const activeAddOrEditKeyWord = (e) => {
    if (loading) return;

    if (e) {
      setFormKeyword({ ...formKeyword, keyword: e?.keyword || "" });
      setInfo(e);
    }

    setShowAddOrEditKeyword(true);
    setOpenMenu(6);
  };

  const addOrEditKeyword = () => {
    if (!info) {
      setLoadingKeyword(true);
      petitionPost("optOutKeyword", { data: formKeyword })
        .then(({ data: result }) => {
          setDataTableOpt((prevState) => {
            return {
              ...prevState,
              content: [...prevState?.content, result.result],
            };
          });
          setLoadingKeyword(false);
          closeMenu();
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 400) {
            const messageError =
              error?.response?.data?.error?.error_detailed?.error
                ?.error_message;
            setError(messageError);
            setLoadingKeyword(false);
          }
        });
    } else {
      setLoadingKeyword(true);
      petitionPatch("optOutKeyword", {
        data: formKeyword,
        keyword_id: info?.pk,
      })
        .then(({ data: result }) => {
          setDataTableOpt((prevState) => {
            const newContent = prevState?.content.map((element) => {
              if (element.pk === info.pk) {
                element.keyword = formKeyword.keyword;
              }

              return element;
            });
            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingKeyword(false);
          closeMenu();
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 400) {
            const messageError =
              error?.response?.data?.error?.error_detailed?.error
                ?.error_message;
            setError(messageError);
            setLoadingKeyword(false);
          }
        });
    }
  };

  const deleteKeyword = () => {
    setLoadingModal(true);
    petitionDelete("optOutKeyword", { keyword_id: info?.pk })
      .then(({ data: result }) => {
        setDataTableOpt((prevState) => {
          const newContent = prevState?.content.filter(
            (element) => element.pk !== info.pk
          );
          return {
            ...prevState,
            content: [...newContent],
          };
        });
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const petitionOpt = () => {
    petitionGet("optOutKeywords")
      .then(({ data: result }) => {
        setDataTableOpt({
          columns: [{ name: "Keyword", key: "keyword" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeAddOrEditKeyWord,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteKeyword,
              },
            ],
          },
        });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionOpt();
  }, []);

  return (
      <>
        {modalDelete === 6 && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeMenu}
            loading={loadingModal}
            modalFunction={deleteKeyword}
            module="Opt Out Keyword"
          />
        )}

        <MenuRight
          show={openMenu === 6 ? true : false}
          closeMenu={closeMenu}
          title={`${!info ? "Add" : "Edit"} Opt Out Keyword`}
          to=""
        >
          {openMenu === 6 && showAddOrEditKeyword && (
            <>
              <Row className="itp-container-label-input">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">Keyword</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <input
                    type="text"
                    placeholder=": Enter Keyword"
                    className="form-control-itp itp-label-input__input"
                    name="keyword"
                    value={formKeyword.keyword}
                    onChange={handleChangeFormKeyword}
                  />
                </Col>
              </Row>

              {error && <p className="text-danger">{error}</p>}

              <button
                onClick={addOrEditKeyword}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={!formKeyword.keyword || loadingKeyword}
              >
                {loadingKeyword && <LoadingButton />}
                Save
              </button>
            </>
          )}
        </MenuRight>

        <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1 " }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <CollapsePanel
          id="optOutKeywords"
          title="Opting Out Keywords"
          subtitle="If the words below appear in any messages a Contact sends they will be set to Do Not Disturb. Do Not Disturb mode prevents any user from messaging the Contact"
        >
          <CustomTableMain 
            data={dataTableOpt}
            m0={true}
            loading={loading}
            module="Opting Out Keywords"
            actionsRight={[
              {
                component: (
                  <AddButton 
                    handleClick={() => activeAddOrEditKeyWord()}
                    name="Add Keyword"
                  />
                )
              }
            ]}
          />
        </CollapsePanel>
      </>
  );
};

export default OptOutKeywords;
