import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { AlignedRow, CustomTableMain } from "../../../../../../../components";

const Step4 = ({ form, setForm, allData, setAllData }) => {
  const [dataTable, setDataTable] = useState(null)

  const handleDelete = (el, index) => {
    const allForm = form.filter((element, i) => i !== index);
    setForm(allForm);

    const newAllData = allData.filter((element, i) => i !== index);
    setAllData(newAllData);
  };

  useEffect(() => {
    if (Array.isArray(allData)) {
      const dataTableContent = allData.map((element) => {
        element.locationData = "Domestic - US/CANADA"

        return element
      })

      setDataTable({
        columns: [
          { name: "Location", key: "locationData" },
          { name: "Number Type", key: "type" },
          { name: "Number", key: "did" },
        ],
        content: [...dataTableContent],
        actions: {
          title: "Actions",
          content: [
            {
              name: "Delete",
              handleClick: handleDelete,
              icon: <FontAwesomeIcon icon={faTrash} />
            }
          ]
        }
      })
    }
  }, [allData])

  return (
    <div>
      <AlignedRow style={{ margin: "1rem 3rem 1rem -1rem" }}>
        <h3 className="itp-accordion-header-title">
          List all Numbers
        </h3>
      </AlignedRow>

      {dataTable && (
        <CustomTableMain 
          data={dataTable} 
          style={{ margin: "0 3rem 0 -1rem" }}
        />
      )}
    </div>
  );
};

export default Step4;
