import { useEffect, useState } from "react";
import { LabelSelect } from "../index";
import petitionGet from "../../services/petitionGet";
import Select from "react-select";

const FunnelStages = ({ loading, form, setForm, funnels, defaultFunnelId, defaultFunnel, setFinishFetchFunnels, defaultStageId }) => {
  const [stages, setStages] = useState([]);
  const [valueFunnel, setValueFunnel] = useState(null);
  const [valueStage, setValueStage] = useState(null);
  const [loadingChangeFunnel, setLoadingChangeFunnel] = useState(false);

  const onChangeFunnel = (e) => {
    setLoadingChangeFunnel(true);
    petitionGet("stages", { parameter: `?funnel_id=${e.pk}` })
      .then(({ data: result }) => {
        if (result.result.length > 0) {
          result.result.forEach((stage) => {
            stage.label = stage.name;
            stage.value = stage.pk;
          });

          setStages(result.result);
        } else {
          setStages([]);
        }

        setForm({
          ...form,
          funnel_id: e.pk,
          funnel_pipeline_stage_id: "",
        });
        setValueFunnel(e);
        setValueStage(null);
        setLoadingChangeFunnel(false);
      })
      .catch((error) => console.log(error));
  };

  const petition = async () => {
    await petitionGet("stages", { parameter: `?funnel_id=${defaultFunnelId}` })
    .then(({ data: result }) => {
      if (result.result.length > 0) {
        result.result.forEach((stage) => {
          stage.label = stage.name;
          stage.value = stage.pk;
        });

        const defaultValueStage = result.result.find( (element) => element.pk === defaultStageId);

        setValueStage(defaultValueStage);
        setStages(result.result);
        setFinishFetchFunnels(true)
      }
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    setValueFunnel(defaultFunnel || null);
  }, [defaultFunnel])

  useEffect(() => {
    petition()
  }, [])

  return (
    <>
      <LabelSelect label="Funnel" loading={loading}>
        <Select
          onChange={(e) => onChangeFunnel(e)}
          className="basic-single"
          classNamePrefix="select"
          value={valueFunnel}
          isSearchable={true}
          name="funnel_id"
          placeholder="Select a Funnel"
          options={funnels}
        />
      </LabelSelect>

      {loadingChangeFunnel ? (
        <LabelSelect label="Stages" loading={loadingChangeFunnel}>
          <></>
        </LabelSelect>
      ) : (
        <>
          {form.funnel_id && (
            <>
              {stages.length > 0 ? (
                <LabelSelect label="Stages" loading={loading}>
                  <Select
                    onChange={(e) => {
                      setForm({
                        ...form,
                        funnel_pipeline_stage_id: e.value,
                      });
                      setValueStage(e);
                    }}
                    className="basic-single"
                    classNamePrefix="select"
                    value={valueStage}
                    isSearchable={true}
                    name="funnel_pipeline_stage_id"
                    placeholder="Select a Stage"
                    options={stages}
                  />
                </LabelSelect>
              ) : (
                <p>There are no stages created for this funnel</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FunnelStages;
