const kmDeviceDial = (socketInfo, users) => {
  if (!socketInfo || !socketInfo.metadata) {
    console.log('Invalid socketInfo or metadata');
    return users;
  }


  // Hacemos una destructuración de los campos que vienen en la metadata del evento
  const { dialed_number, caller_id_number, call_direction, kamailio_call_id, from_user_id, to_user_id } = socketInfo?.metadata

  try {
    const newUserState = users.map((user) => {
      // Con !user.channels.length (longitud igual a cero del campo channels del user) sabemos que el 
      // evento de KM_DEVICE_DIAL llegó primero que el KM_DEVICE_ANSWER o el KM_DEVICE_HANGUP. Sabiendo 
      // esto, depiendo del call_direction y el user que el pk sea igual al from_user_id o al to_user_id 
      // (esto depende del call_direction) se agrega un objeto nuevo al array channels del user

      if (!user.channels.length && call_direction === 'outbound' && user.pk === from_user_id) {
        const newChannel = { answered: false, withNumber: dialed_number, kamailio_call_id, time: 0 }

        user.channels = [{ ...newChannel }]
      } else if (!user.channels.length && call_direction === 'inbound' && user.pk === to_user_id) {
        const newChannel = { answered: false, withNumber: caller_id_number, kamailio_call_id, time: 0 }

        user.channels = [{ ...newChannel }]
      } else if (!!user.channels.length) {
        // Con !!user.channels.length (longitud mayor a cero del campo channels del user) vamos a mirar
        // si el KM_DEVICE_ANSWER o el KM_DEVICE_HANGUP llegaron primero que el KM_DEVICE_DIAL.

        // Buscamos algún objeto donde su campo kamailio_call_id sea igual al 
        // kamailio_call_id del eventó actual
        const findChannel = user.channels.find((channel) => channel.kamailio_call_id === kamailio_call_id);
  
        // En caso de que findChannel existe, significa que el evento de 
        // KM_DEVICE_ANSWER o el KM_DEVICE_HANGUP llegaron primero. Si el campo firstBye es true en el 
        // canal siginfica que el KM_DEVICE_HANGUP llego primero por lo que eliminamos ese canal de la 
        // lista y si es un valor falso llego primero el KM_DEVICE_ANSWER y a ese canal le reemplzamos 
        // el campo answered a true y el campo withNumber dependiendo del call_direction y el pk del 
        // user que sea igual al from_user_id o al to_user_id
        if (findChannel) {
          let newChannels = []
          
          user.channels.forEach((channel) => {
            if (channel.kamailio_call_id === kamailio_call_id) {
              if (call_direction === 'outbound' && user.pk === from_user_id && !channel?.firstBye) {
                channel.withNumber = dialed_number
                channel.answered = true

                newChannels.push(channel)
              } else if (call_direction === 'inbound' && user.pk === to_user_id && !channel?.firstBye) {
                channel.withNumber = caller_id_number
                channel.answered = true

                newChannels.push(channel)
              }
            } else {
              newChannels.push(channel)
            }
          });

          user.channels = newChannels
        } else {
            // En caso de que findChannel no exista significa que el user agregó otra
            // llamada y el KM_DEVICE_DIAL de esa nueva llamada llegó primero, para este caso agregamos 
            // un nuevo objeto al campo channels del user, esto depende del call_direction
            if (call_direction === 'outbound' && user.pk === from_user_id) {
              const newChannel = { answered: false, withNumber: dialed_number, kamailio_call_id, time: 0 }

              user.channels = [...user.channels, { ...newChannel }]
            } else if (call_direction === 'inbound' && user.pk === to_user_id) {
              const newChannel = { answered: false, withNumber: caller_id_number, kamailio_call_id, time: 0 }
              
              user.channels = [...user.channels, { ...newChannel }]
            }
        }
      }

      return user
    })

    return newUserState
  } catch (error) {
    console.log(error)
    return users
  }
}

export default kmDeviceDial;