import petitionDelete from "../../../../../../services/petitionDelete";
import petitionPost from "../../../../../../services/petitionPost";

const toggleAddvancedCallflowService = async (number, advanceCallFlows, setAdvanceCallFlows, setLoadingConfirm, closeMenu, petition) => {
  if (advanceCallFlows) {
    try {
      setLoadingConfirm(true);
      await petitionPost("basicModeCallFlowNumber", { data: {}, number });

      setLoadingConfirm(false);
      closeMenu();
      setAdvanceCallFlows(!advanceCallFlows);
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      setLoadingConfirm(true);
      await petitionDelete("basicModeCallFlowNumber", { number })
      
      setLoadingConfirm(false);
      closeMenu();
      setAdvanceCallFlows(!advanceCallFlows);
      petition();
    } catch (error) {
      console.log(error)
    }
  }
};

export default toggleAddvancedCallflowService;
