import React, { useEffect, useState } from "react";
import { Loading, CustomTable } from "../../../../../../../components";
import { convertUtcDateToTimeZoneDate } from "../../../../../../../utils/getDate";
import petitionGet from "../../../../../../../services/petitionGet";
import petitionDelete from "../../../../../../../services/petitionDelete";
import ModalDelete from "../../../ModalUtility/ModalDelete";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import "../Import.css";

const Jobs = ({ refreshJobs, setRefreshJobs, myExtension }) => {
  const [loading, setLoading] = useState(true);
  const [dataTable, setDataTable] = useState(null);
  const [dataTableError, setDataTableError] = useState(null);
  const [infoContactError, setInfoContactError] = useState(null);
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [modalDelete, setModalDelete] = useState(null);
  const [loadingUndo, setLoadingUndo] = useState(false);
  const [errorUndo, setErrorUndo] = useState("");

  const showContactsWithError = (e) => {
    setInfoContactError(e);
  };

  const petitionContactsWithError = () => {
    setLoading(true);
    petitionGet("importJobsErrors", { jobs_id: infoContactError.pk })
      .then(({ data: result }) => {
        if (
          result.result.data.contacts_not_imported &&
          result.result.data.contacts_not_imported.length > 0
        ) {
          let dataContactsError = Object.keys(
            result.result.data.contacts_not_imported[0].csv_contact
          );

          dataContactsError.map((element) => {
            result.result.data.contacts_not_imported.map((contact) => {
              contact[element] = !contact.csv_contact[element]
                ? ""
                : contact.csv_contact[element];
            });
          });

          setDataTableError({
            title: [
              { title: "Reason", key: "reason" },
              { title: "First Name", key: "firstname" },
              { title: "Last Name", key: "lastname" },
              { title: "Phone", key: "phone" },
              { title: "Email", key: "email" },
            ],
            content: [...result.result.data.contacts_not_imported],
          });
        } else {
          setDataTableError({
            title: [
              { title: "Reason", key: "reason" },
              { title: "First Name", key: "firstname" },
              { title: "Last Name", key: "lastname" },
              { title: "Phone", key: "phone" },
              { title: "Email", key: "email" },
            ],
            content: [],
          });
        }

        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const back = () => {
    setLoading(true);
    setInfoContactError(null);
    setDataTableError(null);
  };

  const activeUndoModal = (e) => {
    setModalDelete(e);
  };

  const closeModal = () => {
    setModalDelete(null);
    setErrorUndo("");
  };

  const undoContacts = () => {
    setLoadingUndo(true);
    setErrorUndo("");
    petitionDelete("undoContacsInJob", { job_id: modalDelete.pk })
      .then(({ data: result }) => {
        setLoadingUndo(false);
        closeModal();
        petition();
      })
      .catch((error) => {
        console.log(error);
        if (error?.response && error.response.status === 500) {
          const text = "An error occurred, please try again later.";
          setErrorUndo(text);
          setLoadingUndo(false);
        }
      });
  };

  const petition = (noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("importJobs")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.validate = element.status === "completed" ? true : false;

          element.convertedDate = element?.job_start_date
            ? convertUtcDateToTimeZoneDate(
                element?.job_start_date.replaceAll(" ", "T"),
                myExtension.time_zone
              )
            : "";
        });
        
        setDataTable({
          title: [
            { title: "Status", key: "status", noStatus: true },
            { title: "Start Date", key: "convertedDate", isDate: true },
            { title: "Imported", key: "imported" },
            { title: "Not Imported", key: "not_imported" },
          ],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                name: "View Results",
                handleClick: showContactsWithError,
                icon: <RemoveRedEyeOutlinedIcon />,
              },
              {
                name: "Undo",
                handleClick: activeUndoModal,
                icon: <UndoOutlinedIcon />,
                shouldValidate: true,
                keyValidate: "validate",
              },
            ],
          },
        });
        setLoadingRefresh(false);
        setRefreshJobs(false);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (refreshJobs) {
      if (!loadingRefresh) {
        setLoadingRefresh(true);
        petition(true);
      }
    }
  }, [refreshJobs]);

  useEffect(() => {
    if (infoContactError) {
      petitionContactsWithError();
    }
  }, [infoContactError]);

  useEffect(() => {
    if (!infoContactError) {
      petition();
    }
  }, [infoContactError]);

  return loading || loadingRefresh ? (
    <Loading />
  ) : (
    <>
      {infoContactError && dataTableError ? (
        <>
          <div className="itp-jobs-container-table">
            <CustomTable
              data={dataTableError}
              noPagBack={true}
              textNoData="Contacts With Error"
            />
          </div>
          <button
            className="btn-light itp-button-jobs-error mt-4"
            onClick={back}
          >
            Back
          </button>
        </>
      ) : (
        <>
          {modalDelete && (
            <ModalDelete
              modal={modalDelete}
              closeModal={closeModal}
              loading={loadingUndo}
              modalFunction={undoContacts}
              error={errorUndo}
              title="Undo"
              text="Are you sure you wish to undo this import? This will delete all contacts that were imported in this job."
            />
          )}

          <div className="itp-jobs-container-table">
            <CustomTable data={dataTable} noPagBack={true} w100={true} />
          </div>
        </>
      )}
    </>
  );
};

export default Jobs;
