import React from "react";
import { Card, CardBody } from "reactstrap";
import { LoadingButton } from "../../../../components";
import "../SignUp.css";

const Step5One = ({ onClickSkip, loading }) => {
  return (
    <div className="itp-signUp">
      <Card className="itp-signUp__card">
        <CardBody>
          <h1 className="itp-signUp__card-body__title--message">
            SMS 10DLC Registration
          </h1>

          <p className="itp-signUp__card-body__textInfo--message">
            In order to comply with new{" "}
            <span className="itp-signUp__card-body__textInfo--message-span">
              Federal Telecom
            </span>
            <br />
            <span className="itp-signUp__card-body__textInfo--message-span">
              Regulations
            </span>
            , and help increase deliverability, we will
            <br />
            need further information about you or your business.
          </p>

          <p className="itp-signUp__card-body__textInfo--message">
            Complete this quick wizard to complete your 10DLC
          </p>

          {loading ? (
            <div className="d-flex w-100 justify-content-center align-items-center mt-4">
              <LoadingButton />
            </div>
          ) : (
            <>
              <button
                className="btn-primary btn-primary-signup w-100"
                onClick={() => onClickSkip(true)}
              >
                Start Registration Process
              </button>

              <button
                className="itp-signUp__card-body__form__button-text"
                onClick={() => onClickSkip(false)}
              >
                <p className="itp-signUp__card-body__textInfo--message-skip">
                  Skip for now (not recommended)
                </p>
              </button>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Step5One;
