import React, { useState, useEffect,useRef } from "react";
import {
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { count } from "sms-length";
import { LoadingButton } from "../../index";
import happyFace from "../../../assets/icons/happyFace.svg";
import clip from "../../../assets/icons/clip.svg";
import recordingAudio from "../../../assets/icons/recordingAudio.svg";
import printer from "../../../assets/icons/printer.svg";
import sendMessage from "../../../assets/icons/sendMessage.svg";
import slash from "../../../assets/icons/slash.svg";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import ModalPhotos from "./ModalPhotos";
import petitionGet from "../../../services/petitionGet";
import ModalInfo from "../../../pages/private/WorkspaceSettings/ModalUtility/ModalInfo";
import "../ChatWindow.css";
import EmojiPicker from 'emoji-picker-react';
import { display } from "@mui/system";
import { position } from "stylis";
import { C } from "sip.js/lib/core";


const ChatWindowComposer = ({
  submitMessage,
  pk,
  loadingSendMessages,
  chatWindowData,
  loading,
}) => {
  const [messageBody, setMessageBody] = useState("");
  const [imgMessages, setImgMessages] = useState([]);
  const [contactMessage, setContactMessage] = useState([]);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [modalImages, setModalImages] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const [text, setText] = useState(false);
  const [contMessage, setContMessage] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageTemplateTranslate, setMessageTemplateTranslate] = useState("");

  const isOnlyWhitespace = /^\s*$/.test(messageBody);

  let show_list = [];
  let temp_messages = [];

  const noSendMessage = chatWindowData?.notIsSelf?.contact?.dnd_sms || false;
  const textareaRef = useRef();
  /* const [cursorPositionBefore, setCursorPositionBefore] = useState(null); */
  const [cursorPosition, setCursorPosition] = useState(null);

  const handleChange = (e) => {
    if (loading) return;
    setMessageBody(e.target.value);
    setContMessage(count(e.target.value))
  };

  const activeModalImages = () => {
    if (loading) return;
    setModalImages(true);
  };

  const activeContactWindows = () => {
    if (loading) return;
    document.getElementById("vcf_file_upload").click();
  };

  const toggle = (icon) => {
    if (loading) return;

    if (!noSendMessage) {
      if (openDropdown) {
        setOpenDropdown(null);
      } else {
        setOpenDropdown(icon);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (loading) return;

    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      messageBody &&
      !isOnlyWhitespace
    ) {
      event.preventDefault(); // Evita el salto de línea en el textarea
      sendMessages();
    }
  };

  const handlePaste = (event) => {
    if (loading) return;

    let newImages = imgMessages;

    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (const item of items) {
      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64Image = e.target.result;
          newImages.push({
            data_url: base64Image,
            file: blob,
          });

          setImgMessages(newImages);
          activeModalImages();
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  const vcfFileUpload = (event) => {
    if (loading) return;

    if (event.target.files[0]) {
      setContactMessage(event.target.files);
    }
  };

  const handleClick = (event) => {
    if (loading) return;

    async function fetchData() {
      await petitionGet("messageTemplateTranslate", {
        pk: selected_element,
        custom_pk: pk == "" ? null : pk,
      })
        .then((result) => {
          setMessageTemplateTranslate(result.data.result);
          var input_messagetemplate =
            document.getElementById("input_message").value;
          setMessageBody(input_messagetemplate + result.data.result);
        })
        .catch((result) => {
          if (result.response.data.error.code == "C360-06") {
            setModalConfirm(true);
            setInfoText(
              "You are attempting to use a message template with variables but there is no Contact associated with this number you are messaging"
            );
          }
        });
    }
    var selected_element = event.target.getAttribute("data-value");

    fetchData();
  };

  const toggleDropdown = () => {
    if (loading) return;

    if (!noSendMessage) setIsOpen(!isOpen);
  };

  const sendMessages = () => {
    if (loading) return;

    if (
      (messageBody && !isOnlyWhitespace) ||
      imgMessages.length > 0 ||
      contactMessage.length > 0
    ) {
      submitMessage(messageBody, imgMessages, contactMessage);
      setMessageBody("");
      setImgMessages([]);
      document.getElementById("vcf_file_upload").value = "";
      setContactMessage([]);
    }
  };

  const closeMenu = () => {
    if (loading) return;

    setModalConfirm(false);
  };

  const showText = () => {
    if (loading) return;

    if (noSendMessage) {
      setText(true);
    }
  };

  const hiddenText = () => {
    if (loading) return;

    if (noSendMessage) {
      setText(false);
    }
  };

  useEffect(() => {
    temp_messages = messageTemplates.filter((item) => {
      return item.title.includes(searchText);
    });

    var dropdown_items = document.querySelectorAll(
      ".messagetemplates-property button.dropdown-item"
    );
    dropdown_items.forEach((menu_items) => {
      menu_items.classList.add("d-none");
      if (temp_messages.some((i) => i.title.includes(menu_items.innerHTML))) {
        menu_items.classList.remove("d-none");
      }
    });
  }, [searchText]);

  useEffect(() => {
    messageTemplates.forEach((element) => {
      var paragraph = document.createElement("p");
      paragraph.innerHTML = element.title;
      paragraph.className = "mb-1";
    });
  }, [messageTemplates]);

  useEffect(() => {
    const fetchData = async () => {
      const { data: result } = await petitionGet("messageTemplates")
      setMessageTemplates(result.result)
    }
    
    fetchData();
  }, []);

  const onEmojiClick = (emoji) => {
    if (loading) return;

    const {selectionStart, selectionEnd} = textareaRef.current;
    const newMessage = messageBody.slice(0, selectionStart) + emoji + messageBody.slice(selectionEnd);
    setShowEmojiPicker(false);
    setMessageBody(newMessage);
  };


  const toggleEmojiPicker = () => {
    if (loading) return;

    setShowEmojiPicker(prevState => !prevState);
  };

  useEffect(() => {
    setContMessage(count(messageBody));
  }, [messageBody]);

  return (
    <>
      {modalImages && (
        <ModalPhotos
          modal={modalImages}
          setModal={setModalImages}
          imgMessages={imgMessages}
          setImgMessages={setImgMessages}
        />
      )}

      {modalConfirm && (
        <ModalInfo
          modal={modalConfirm}
          closeModal={closeMenu}
          text={infoText}
          module="Information"
        />
      )}

      <Row className="itp-chat-window-footer">
        <textarea
          className={`itp-chat-window-footer__textarea w-100 ${noSendMessage ? "dnd" : ""}`}
          placeholder={!noSendMessage ? "Write a message" : ""}
          onChange={handleChange}
          onPaste={handlePaste}
          value={messageBody}
          onKeyDown={handleKeyDown}
          id="input_message"
          disabled={noSendMessage}
          onMouseOver={showText}
          onMouseOut={hiddenText}
          rows="2"
          ref={textareaRef}
          /* onClick={handleClickTextarea} */
        ></textarea>
        <input
          type="file"
          id="vcf_file_upload"
          accept=".vcf"
          className="d-none"
          onChange={vcfFileUpload}
        />
        <div className="d-flex flex-column justify-content-end">
          <div className={messageBody ? "" : "v-hidden"}>
            <p className="itp-chat-window-footer-character">{contMessage?.length}/{contMessage?.characterPerMessage*contMessage?.messages} ({contMessage?.messages})</p>
          </div>
          <div className="d-flex position-relative">
            <div className="itp-chat-window-footer-clip-image">
              {/* <img src={happyFace} alt="Face" className="itp-icon-footer" /> */}
              <div className="itp-footer-container-clip-icon">
                {(imgMessages.length > 0 || contactMessage.length > 0) && (
                  <div className="add-photo-label itp-clip-icon-menu">
                    {imgMessages.length + contactMessage.length}
                  </div>
                )}
                <Dropdown
                  toggle={() => toggle("clip")}
                  isOpen={openDropdown === "clip" ? true : false}
                  tabIndex={1}
                >
                  <DropdownToggle className="nav-link clip">
                    <img src={clip} alt="Clip" className="itp-icon-footer" />
                  </DropdownToggle>
                  <DropdownMenu className="clip">
                    <DropdownItem
                      onClick={activeModalImages}
                      className="dropdown-item"
                    >
                      <AddPhotoAlternateOutlinedIcon className="add-photo-icon" />{" "}
                      Add Images
                    </DropdownItem>
                    {/* -------------------------------------------------------------- */}
                    <DropdownItem
                      onClick={activeContactWindows}
                      className="dropdown-item"
                    >
                      <ContactMailOutlinedIcon className="add-photo-icon" />{" "}
                      Send Contact Card
                    </DropdownItem>
                    {/* -------------------------------------------------------------- */}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
            <div className="itp-chat-window-footer-dropdown-template" style={{display:"flex", maxHeight:"43px"}}>
              <Dropdown isOpen={isOpen} toggle={() => toggleDropdown()}>
                <DropdownToggle
                  style={{ backgroundColor: "white", border: "none" }}
                  id="clickmessagebox"
                >
                  <>
                    <span className="container-copy">
                      <img
                        src="message-text-1.svg"
                        style={{ opacity: "0.6" }}
                      ></img>
                    </span>
                    <span className="text-copy">Message Template</span>
                  </>
                </DropdownToggle>
                <DropdownMenu className="p-2 messagetemplates-property mb-5">
                  <DropdownItem header>
                    <input
                      value={searchText}
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                      type="search"
                      className="w-100 ps-3"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#fffffe",
                        height: "27px",
                      }}
                    ></input>
                  </DropdownItem>
                  {messageTemplates.map((item) => (
                    <DropdownItem
                      key={item.pk}
                      className="d-block"
                      onClick={handleClick}
                      data-value={item.pk}
                    >
                      {item.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <div>
                  <button  className="container-copy" onClick={toggleEmojiPicker} style={{border:"transparent", backgroundColor:"white", padding:"6px 0px"}}>
                    <svg width="24px" height="24px  " viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" style={{ opacity: "0.6" }}>
                      <path fill="#000000" fillRule="evenodd" d="M255,160 L256,160 C256,162.209139 254.209139,164 252,164 C249.790861,164 248,162.209139 248,160 L249,160 C249,161.656854 250.343146,163 252,163 C253.656854,163 255,161.656854 255,160 Z M252,168 C256.970563,168 261,163.970563 261,159 C261,154.029437 256.970563,150 252,150 C247.029437,150 243,154.029437 243,159 C243,163.970563 247.029437,168 252,168 Z M252,167 C256.418278,167 260,163.418278 260,159 C260,154.581722 256.418278,151 252,151 C247.581722,151 244,154.581722 244,159 C244,163.418278 247.581722,167 252,167 Z M249,158 C249.552285,158 250,157.552285 250,157 C250,156.447715 249.552285,156 249,156 C248.447715,156 248,156.447715 248,157 C248,157.552285 248.447715,158 249,158 Z M255,158 C255.552285,158 256,157.552285 256,157 C256,156.447715 255.552285,156 255,156 C254.447715,156 254,156.447715 254,157 C254,157.552285 254.447715,158 255,158 Z" transform="translate(-243 -150)"/>
                    </svg>
                  </button>
                    <EmojiPicker style={{position: "relative", top: "-485px"}} open={showEmojiPicker} lazyLoadEmojis={false} searchDisabled={false} onEmojiClick={(emojiObject, event) => onEmojiClick(emojiObject.emoji)} />
              </div>
            </div>
            {noSendMessage && (
              <div
                onMouseOver={showText}
                onMouseOut={hiddenText}
                className="itp-ChatWindowComposer-icon-no-send-message mt-1"
              >
                <img
                  src={slash}
                  alt="slash"
                  style={{ width: "20px", height: "20px" }}
                />
              </div>
            )}

            {text && (
              <div className="itp-ChatWindowComposer-text-no-send-message">
                Contact has Opted Out from Messages
              </div>
            )}

            {!noSendMessage && (
              <button
                className="itp-footer-send-button loading"
                onClick={sendMessages}
                disabled={loadingSendMessages || noSendMessage}
              >
                {loadingSendMessages && (
                  <div style={{ marginTop: "-12px" }}>
                    <LoadingButton />
                  </div>
                )}
                <img src={sendMessage} alt="send" className="itp-icon-footer" />
              </button>
            )}
          </div>
        </div>
      </Row>
    </>
  );
};

export default ChatWindowComposer;
