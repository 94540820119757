import person_vcard from "../../../../../assets/icons/person_vcard.svg";

const ContactCard = ({ urlBase, api_id, element, token }) => {
  const show = element.mms_file_type === "vcf" && element.item_type !== "call" && !element.audio_message ? true : false

  if (show) {
    return (
      <>
        <a
          className="downloadicon"
          style={{ textDecoration: "none", color: "black" }}
          href={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${element.message_mms_media}?token=${token}`}
          download
        >
          <img style={{ opacity: "0.8" }} src={person_vcard} className="" />
        </a>
        <div style={{ opacity: "0.6" }}>Contact Card</div>
      </>
    );
  }
};

export default ContactCard;
