import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addTagService } from "./services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddTag } from "./components"
import { checkIfAnyIsNotContact } from "./utils";
import { LoadingButton, TagComponent } from "../../../index";
import "./BulkActionTags.css"

const BulkActionTags = ({ 
  modal, 
  tags, 
  setTags, 
  optionsTags, 
  setOptionsTags, 
  selectedItems, 
  setSelectedItems,
  chatList, 
  addTagsToContacts, 
  closeModal,
}) => {
  const someElementIsNotContact = checkIfAnyIsNotContact(chatList, selectedItems)

  const [loadingButton, setLoadingButton] = useState(false)
  const [showAddTag, setShowAddTag] = useState(false)
  const [useColor, setUseColor] = useState(false);
  const [color, setColor] = useState("#D0021B");
  const [name, setName] = useState("");

  const handleClickAddTag = () => {
    if (loadingButton) return;

    setShowAddTag(true)
  }

  const closeAddTag = () => {
    setShowAddTag(false)
  }

  const handleClickCancelButton = () => {
    if (loadingButton) return;

    if (showAddTag) {
      closeAddTag()
    } else {
      closeModal()
    }
  }

  const handleTag = async (newValue) => {
    if (newValue.length === 0) {
      setTags([]);
    } else {
      setTags(newValue);
    }
  };

  const handleClickSaveButton = async () => {
    if (loadingButton) return;

    if (showAddTag) {
      await addTagService(setLoadingButton, name, color, useColor, handleClickCancelButton, setOptionsTags)
    } else {
      setLoadingButton(true)
      await addTagsToContacts(selectedItems)
      setSelectedItems(new Set([]))
      setTags([])
      setLoadingButton(false)
      closeModal()
      setShowAddTag(false)
      setUseColor(false)
      setColor("#D0021B")
      setName("")
    }
  }

  const selectDisabledSaveButton = () => {
    if (showAddTag) {
      return !name ? true : false
    } else {
      return !tags?.length ? true : false
    }
  }

  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{showAddTag ? "Add Tag" : "Modal Tags"}</ModalHeader>
      <ModalBody>
        {someElementIsNotContact ? (
          <p className="text-danger">
            You can only add tags to items that are contacts, please uncheck items that are not contacts.
          </p>
        ) : (
          <>
            {showAddTag ? (
              <AddTag 
                useColor={useColor}
                setUseColor={setUseColor}
                color={color}
                setColor={setColor}
                name={name}
                setName={setName}
                loadingButton={loadingButton}
              />
            ) : (
              <Row>
                <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}>
                  <label className="itp-component-tag-label">Tag</label>
                  <TagComponent 
                    disabled={loadingButton}
                    tags={tags}
                    optionsTags={optionsTags}
                    handleTag={handleTag}
                  />
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                  <div className="d-flex align-items-end h-100">
                    <button 
                      className="btn-primary itp-add-button" 
                      onClick={handleClickAddTag}
                    >
                      <FontAwesomeIcon icon={faPlus} className="itp-icon-plus" /> Add tag
                    </button>
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <button onClick={handleClickCancelButton} className="btn-light">
          {showAddTag ? "Back" : "Cancel"}
        </button>

        {!someElementIsNotContact && (
          <button 
            disabled={selectDisabledSaveButton()} 
            onClick={handleClickSaveButton} 
            className="btn-primary loading"
          >
            {loadingButton && <LoadingButton />}
            {showAddTag ? "Add Tag" : "Save"}
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default BulkActionTags;
