import React, { Fragment, useEffect, useState } from "react";
import { FullscreenImage } from "../../../../../components";
import { convertLocalTimeToTimezoneTimeOnFormated, formatDate } from "../../../../../utils/getDate";
import Skeleton from "react-loading-skeleton";
import petitionGet from "../../../../../services/petitionGet";
import json from "../../../../../config.json";
import person_vcard from "../../../../../assets/icons/person_vcard.svg";
import "../ChatMenu/ChatMenu.css";

const ImagesDetails = ({ chatWindowData, number, timeZone, openMenu }) => {
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
  const [loading, setLoading] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [loadingImages, setLoadingImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState({ firstLevel: null, secondLevel: true });
  const [showFullscreen, setShowFullscreen] = useState(false);

  const currentDate = convertLocalTimeToTimezoneTimeOnFormated(timeZone);
  const currentYear = currentDate.split(" ")[0].split("-")[0];
  const currentMonth = currentDate.split(" ")[0].split("-")[1];

  const typesOfImages = [
    "png",
    "PNG",
    "bmp",
    "BMP",
    "gif",
    "GIF",
    "jpg",
    "JPG",
    "tif",
    "TIF",
    "svg",
    "SVG",
  ];

  const openFullscreen = (i, i2) => {
    setCurrentImageIndex({ firstLevel: i, secondLevel: i2 });
    setShowFullscreen(true);
  };

  const closeFullscreen = () => {
    setShowFullscreen(false);
    setCurrentImageIndex({ firstLevel: null, secondLevel: null });
  };

  const handleNextImage = () => {
    if (currentImageIndex.firstLevel < imageList.length - 1) {
      if (
        currentImageIndex.secondLevel <
        imageList[currentImageIndex.firstLevel].files.length - 1
      ) {
        setCurrentImageIndex({
          ...currentImageIndex,
          secondLevel: currentImageIndex?.secondLevel + 1,
        });
      } else {
        setCurrentImageIndex({
          firstLevel: currentImageIndex.firstLevel + 1,
          secondLevel: 0,
        });
      }
    }
  };

  const handlePrevImage = () => {
    if (
      currentImageIndex?.firstLevel === 0 &&
      currentImageIndex?.secondLevel === 0
    ) {
      return;
    }

    if (
      (currentImageIndex?.firstLevel === 0 &&
        currentImageIndex?.secondLevel > 0) ||
      (currentImageIndex?.firstLevel > 0 && currentImageIndex?.secondLevel > 0)
    ) {
      let newObject = {
        ...currentImageIndex,
        secondLevel: currentImageIndex.secondLevel - 1,
      };

      setCurrentImageIndex(newObject);
    }

    if (
      currentImageIndex?.firstLevel > 0 &&
      currentImageIndex?.secondLevel === 0
    ) {
      let newObject = {
        firstLevel: currentImageIndex.firstLevel - 1,
        secondLevel:
          imageList[currentImageIndex.firstLevel - 1].files.length - 1,
      };

      setCurrentImageIndex(newObject);
    }
  };

  const returnImages = (filegroup) => {
    const thisMonthImages = [];
    const otherMonths = [];

    const numberMonth = {
      January: "01",
      February: "02",
      March: "03",
      April: "04",
      May: "05",
      June: "06",
      July: "07",
      August: "08",
      September: "09",
      October: "10",
      November: "11",
      December: "12",
    };

    for (const year in filegroup) {
      for (const month in filegroup[year]) {
        const files = filegroup[year][month];

        if (year === currentYear && numberMonth[month] === currentMonth) {
          thisMonthImages.push({ year, month, files });
        } else {
          otherMonths.push({ year, month, files });
        }
      }
    }

    const sortedImageGroups = [
      ...thisMonthImages,
      ...otherMonths.sort((a, b) =>
        a.year === b.year ? b.month.localeCompare(a.month) : b.year - a.year
      ),
    ];

    let imageElements = [];
    let ids = [];
    for (const { year, month, files } of sortedImageGroups) {
      let title = "";

      if (year === currentYear && numberMonth[month] === currentMonth) {
        title = "This Month";
      } else if (year === currentYear && numberMonth[month] !== currentMonth) {
        title = `${month}`;
      } else if (year !== currentYear && numberMonth[month] !== currentMonth) {
        title = `${month} ${year}`;
      }

      files.forEach((file) => {
        let formatedDate = formatDate(file.message_timestamp);

        file.src = file.message_image;
        file.timeStap = formatedDate;
      });

      ids = ids.concat(files.map((file) => file.pk));

      imageElements.push({
        title,
        files,
      });
    }

    setLoadingImages(ids);
    console.log(imageElements);
    setImageList(imageElements);
  };

  const petition = () => {
    setLoading(true);
    petitionGet("imagesThread", { number, thread: chatWindowData?.current?.pk })
      .then(({ data: result }) => {
        const messages = result?.result?.messages;

        const filegroup = messages.reduce((groups, message) => {
          const date = new Date(message.message_timestamp);
          const year = date.getFullYear();
          const month = date.toLocaleString("en-US", { month: "long" });

          if (!groups[year]) {
            groups[year] = {};
          }

          if (!groups[year][month]) {
            groups[year][month] = [];
          }

          groups[year][month].push(message);

          return groups;
        }, {});

        returnImages(filegroup);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (openMenu) {
      petition();
    }
  }, [openMenu]);

  const fakeData = [1, 2, 3, 4, 5]

  return (
    <>
      {loading && (
        <div className="itp-chat-menu__tab-images-details__container-timestap-img">
          <Skeleton style={{ marginLeft: "10px" }} height="100%" width="6rem" />
          <div className="itp-chat-menu__tab-images-details__container-img d-flex align-items-center">
            {fakeData.map((element) => (
              <div key={element} className="itp-chat-menu__tab-images-details__img">
                <div className="itp-chat-menu__tab-images-details__img">
                  <Skeleton height="100%" width="100%" />
                </div>
              </div>
            ))}  
          </div>
        </div>
      )}

      {!loading && imageList.map((element, i) => (
        <div
          className="itp-chat-menu__tab-images-details__container-timestap-img"
          key={i}
        >
          <h6>{element?.title}</h6>
          <div className="itp-chat-menu__tab-images-details__container-img d-flex align-items-center">
            {element.files.map((file, i2) => (
              <Fragment key={file.pk}>
                {file.mms_file_type === "vcf" && (
                  <div style={{ marginLeft: i2 > 0 ? "20px" : "" }} className="itp-chat-window-content__other-message-content">
                    <a
                      className="downloadicon"
                      style={{ textDecoration: "none", color: "black" }}
                      href={`${urlBase}/itpvoice/v2/${api_id}/my-extension/chat/media/${file.message_mms_media}?token=${token}`}
                      download
                    >
                      <img
                        style={{ opacity: "0.8" }}
                        src={person_vcard}
                        className=""
                      />
                    </a>
                    <div style={{ opacity: "0.6" }}>Contact Card</div>
                  </div>
                )}

                {typesOfImages.includes(file?.mms_file_type) && (
                  <div
                    className="itp-chat-menu__tab-images-details__img"
                  >
                    {loadingImages.includes(file.pk) && (
                     <div className="itp-chat-menu__tab-images-details__img">
                        <Skeleton height="100%" width="100%" />
                      </div>
                    )}

                    <img
                      src={file?.message_image}
                      alt=""
                      className={`itp-chat-menu__tab-images-details__img ${loadingImages.includes(file?.pk) ? "v-hidden" : ""}`}
                      onClick={() => openFullscreen(i, i2)}
                      onLoad={() => setLoadingImages((prevLoading) => prevLoading.filter((pk) => pk !== file?.pk))}
                    />
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      ))}

      {!loading && showFullscreen && currentImageIndex?.firstLevel !== null && currentImageIndex?.secondLevel !== null && (
        <FullscreenImage
          closeFullscreen={closeFullscreen}
          image={
            currentImageIndex?.firstLevel !== null &&
            currentImageIndex?.secondLevel !== null
              ? imageList[currentImageIndex?.firstLevel].files[
                  currentImageIndex?.secondLevel
                 ]
              : {}
          }
          handlePrevImage={handlePrevImage}
          handleNextImage={handleNextImage}
        />
      )}
    </>
  );
};

export default ImagesDetails;
