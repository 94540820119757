import React, { useState, useEffect } from "react";
import { TabMenu } from "../../../../../../components";
import ImportContacts from "./Tabs/ImportContacts";
import ViewJobs from "./Tabs/ViewJobs";
import RefreshIcon from "@mui/icons-material/Refresh";

const Import = ({ 
  backFunction,
  setImportedContacts,
  setAddedContactListImort, 
  customFields,
  myExtension,
}) => {
  const [changeTab, setChangeTab] = useState(null)
  const [refreshJobs, setRefreshJobs] = useState(false)

  const activeRefreshJobs = () => {
    setRefreshJobs(true)
  }

  const tabs = [
    {
      title: "Import Contacts",
      component: (
        <ImportContacts
          backFunction={backFunction}
          setChangeTab={setChangeTab}
          setAddedContactListImort={setAddedContactListImort}
          customFields={customFields}
        />
      ),
    },
    {
      title: "Jobs",
      component: <ViewJobs refreshJobs={refreshJobs} setRefreshJobs={setRefreshJobs} myExtension={myExtension} />,
      icon: (
        <RefreshIcon 
          style={{ width: "20px", height: "20px" }} 
          className="icon-hover" 
          onClick={activeRefreshJobs} 
        />
      ),
    },
  ];

  useEffect(() => {
    if(changeTab){
      setChangeTab(null)
      setImportedContacts(true)
    }
  }, [changeTab])
  return (
    <>
      <TabMenu tabs={tabs} changeTab={changeTab} />
    </>
  );
};

export default Import;
