import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { LoadingButton, MenuRight, CustomTableMain, AddButton, CollapsePanel } from "../../../../../components";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";

const MediaAudioFile = ({
  gotToMediaGroupsPage,
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
}) => {
  const [showAddMediaGroup, setShowAddMediaGroup] = useState(false);
  const [loadingMediaGroup, setLoadingMediaGroup] = useState(false);
  const [info, setInfo] = useState(null);

  const [formMediaGroup, setFormMediaGroup] = useState({
    name: "",
  });

  const [dataTableMedia, setDataTableMedia] = useState({
    columns: [{ name: "Name", key: "name" }],
    actions: { title: "Actions", content: [] }
  });

  const closeMenu = () => {
    setShowAddMediaGroup(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const activeAddMediaGroup = () => {
    if (loading) return;
    setShowAddMediaGroup(true);
    setOpenMenu(5);
  };

  const addMediaGroup = () => {
    setLoadingMediaGroup(true);
    petitionPost("mediaGroup", { data: formMediaGroup })
      .then(({ data: result }) => {
        const newMediaFile = {
          ...result.result,
          media_files: [],
        };

        setDataTableMedia((prevState) => {
          const newContent = [...prevState.content, newMediaFile];

          return {
            ...prevState,
            content: [...newContent],
          };
        });
        setLoadingMediaGroup(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const petitionMedia = () => {
    petitionGet("mediaGroups")
      .then(({ data: result }) => {
        setDataTableMedia({
          columns: [{ name: "Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Edit",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: gotToMediaGroupsPage,
              },
            ],
          },
        });

        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionMedia();
  }, []);

  return (
      <>
        <MenuRight
          show={openMenu === 5}
          closeMenu={closeMenu}
          title={`${!info ? "Add" : "Edit"} Media Group`}
          to=""
        >
          {openMenu === 5 && showAddMediaGroup && (
            <>
              <Row className="itp-container-label-input">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">Name</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <input
                    type="text"
                    placeholder=": Enter Name"
                    className="form-control-itp itp-label-input__input"
                    name="name"
                    value={formMediaGroup.name}
                    onChange={(e) => {
                      setFormMediaGroup({ name: e.target.value });
                    }}
                  />
                </Col>
              </Row>
              <button
                onClick={addMediaGroup}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={!formMediaGroup.name || loadingMediaGroup}
              >
                {loadingMediaGroup && <LoadingButton />}
                Save
              </button>
            </>
          )}
        </MenuRight>

        <br></br>
            <div>
              <Row>
                <Col style={{ zIndex: "-1" }}>
                  <div className="itp-my-settings-separator-line"></div>
                </Col>
              </Row>
            </div>
            <br></br>

            <CollapsePanel
              id="mediaFiles"
              title="Media Files"
              subtitle="Upload media files (mp3 or wav) that can be used for Music on Hold or Menus inside Callflows"
            >
              <CustomTableMain 
                data={dataTableMedia}
                m0={true}
                loading={loading}
                module="Media Files"
                actionsRight={[
                      {
                        component: (
                          <AddButton 
                            handleClick={activeAddMediaGroup}
                            name="Add Group Name"
                          />
                        )
                      }
                ]}
              />
            </CollapsePanel>
      </>
  );
};

export default MediaAudioFile;
