import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faClock } from "@fortawesome/free-solid-svg-icons";
import { wDaysOptions } from "../../../../const";
import { LoadingButton } from "../../../../../../../../components";
import moment from "moment-timezone";
import "./AddEditTimeDate.css"

const AddEditTimeDate = ({
  formAddEditDateTime,
  handleChangeForm,
  loadingDateTime,
  addEditDateTime,
  timeZone,
}) => {
  const [currentTime, setCurrentTime] = useState(moment().tz(timeZone).format("hh:mm A"));

  useEffect(() => {
    // Función para actualizar la hora
    const updateCurrentTime = () => {
      setCurrentTime(moment().tz(timeZone).format("hh:mm A"));
    };

    // Obtener segundos actuales y calcular milisegundos hasta el próximo minuto
    const now = moment().tz(timeZone);
    const seconds = now.seconds();
    const millisecondsUntilNextMinute = (60 - seconds) * 1000;

    // Primer timeout para alinear al próximo minuto completo
    const initialTimeout = setTimeout(() => {
      updateCurrentTime();
      // Configura el intervalo para actualizar cada 60 segundos
      const interval = setInterval(updateCurrentTime, 60000);
      // Limpiar el intervalo al desmontar el componente
      return () => clearInterval(interval);
    }, millisecondsUntilNextMinute);

    // Limpieza del timeout al desmontar el componente
    return () => clearTimeout(initialTimeout);
  }, [timeZone]);

  return (
    <>
      <Link to="/General-Settings">
        <Row className="itp-timeGroupDetails-addEditTimeDate-clock-row">
          <Col>
            <div className="clock-container">
              <span>Time Zone: {timeZone}</span>
              <FontAwesomeIcon icon={faClock} className="clock-icon" />
              <span className="clock-time">{currentTime}</span>
            </div>
          </Col>
        </Row>
      </Link>

      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Time Date Name</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Add Name to the time group"
            className="form-control-itp itp-label-input__input"
            name="name"
            value={formAddEditDateTime.name}
            onChange={handleChangeForm}
          />
        </Col>
      </Row>

      <label className="itp-edit-number-label-1">Set Week :</label>
      <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
        <div className="container-date-range justify-content-around">
          <label className="date-range-label">From:</label>
          <select
            value={formAddEditDateTime.start_day_of_week}
            onChange={handleChangeForm}
            name="start_day_of_week"
            className="date-range-input bgc-transparent itp-add-date-time-select"
            type="date"
          >
            {wDaysOptions.map((element, i) => (
              <option key={i} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="date-range-separator"
            />
          </div>
          <label className="date-range-label">To:</label>
          <select
            value={formAddEditDateTime.end_day_of_week}
            onChange={handleChangeForm}
            name="end_day_of_week"
            className="date-range-input bgc-transparent itp-add-date-time-select"
            type="date"
          >
            {wDaysOptions.map((element, i) => (
              <option key={i} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        </div>
      </Col>

      <label className="itp-edit-number-label-1 mt-4">Set Date :</label>
      <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
        <div className="container-date-range">
          <label className="date-range-label">From:</label>
          <input
            type="date"
            className="date-range-input bgc-transparent"
            name="start_date"
            value={formAddEditDateTime.start_date}
            onChange={handleChangeForm}
          />
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="date-range-separator"
            />
          </div>
          <label className="date-range-label">To:</label>
          <input
            type="date"
            className="date-range-input bgc-transparent"
            name="end_date"
            value={formAddEditDateTime.end_date}
            onChange={handleChangeForm}
          />
        </div>
      </Col>

      <label className="itp-edit-number-label-1 mt-4">Set Time :</label>
      <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={7}>
        <div className="container-date-range justify-content-around">
          <div style={{ gap: "12px" }} className="d-flex">
            <label className="date-range-label">From:</label>
            <input
              type="time"
              className="date-range-input bgc-transparent"
              name="start_time"
              value={formAddEditDateTime.start_time}
              onChange={handleChangeForm}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="date-range-separator"
            />
          </div>
          <div style={{ marginRight: "1rem" }} className="d-flex">
            <label className="date-range-label">To:</label>
            <input
              type="time"
              className="date-range-input bgc-transparent"
              name="end_time"
              value={formAddEditDateTime.end_time}
              onChange={handleChangeForm}
            />
          </div>
        </div>
      </Col>

      <button
        onClick={addEditDateTime}
        className="btn-primary itp-lead-detail-button-save loading"
        disabled={
          !formAddEditDateTime.name ||
          !formAddEditDateTime.start_time ||
          !formAddEditDateTime.end_time ||
          formAddEditDateTime.start_day_of_week === "" ||
          formAddEditDateTime.end_day_of_week === ""
        }
      >
        {loadingDateTime && <LoadingButton />}
        Save
      </button>
    </>
  );
};

export default AddEditTimeDate;
