const handleChangeColumns = (setDataTable, arrColumns, showColumns) => {
  let noViewColumns = [];

  setDataTable((prevState) => {
    const newTitleObject = prevState.columns.map((element) => {
      if (!arrColumns.includes(element.name)) {
        element.viewColumn = false;
        noViewColumns.push(element.name);
      } else {
        element.viewColumn = true;
      }

      return element;
    });

    return {
      ...prevState,
      columns: [...newTitleObject],
    };
  });

  showColumns(noViewColumns);
};

export default handleChangeColumns;
