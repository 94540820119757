import petitionGet from "../../../../../../services/petitionGet";

const fetchVoicemailData = async (pk, switchsTemp) => {
  const { data: result } = await petitionGet("voicemailAdmins", { user_id: pk })

  const sendVM = result?.result?.sendvoicemail === "yes" ? "yes" : "no"
  const deleteVM = result?.result?.deletevoicemail === "yes" ? "yes" : "no"

  const newSwitchsTemp = {
    ...switchsTemp,
    sendvoicemail: sendVM,
    deletevoicemail: deleteVM,
  }

  return newSwitchsTemp
}

export default fetchVoicemailData;