import petitionGet from "../../../../../../services/petitionGet";

const fetchDevicesAdmin = async (pk, setDataTable) => {
  const { data: result } = await petitionGet("devicesAdmin", { owner_id: pk })
  result.result.forEach((element) => {
    element.deviceName = element.device_type;
    element.details = "Details";
  });

  setDataTable({
    columns: [
      { name: "Name", key: "deviceName" },
      { name: "Device Type", key: "device_type" },
      { name: "Details", key: "details" },
    ],
    content: [...result.result]
  });
}

export default fetchDevicesAdmin;