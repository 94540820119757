import { convertTimeStapToTimeZoneOnFormated } from "../../../../../utils/getDate"
import formatPhoneNumber from "../../../../../utils/formatPhoneNumber"

const findUser = (call, key, keyUserObject, isNumberReport) => {
  const id = call[key]
  
  if (id) {
    const name = `${call[keyUserObject]?.firstname || ""} ${call[keyUserObject]?.lastname || ""}`
    return name
  } else {
    const numberReport = key === "from_user_id" ? call.src : ""
    const userReport = call.direction	=== "inbound" ? call.src : call.direction	=== "outbound" ? call.dst : ""
    const number = isNumberReport ? numberReport : userReport
    return formatPhoneNumber(number)
  }
}
  
const findExtensionNumber = (call, key, keyUserObject) => {
  const id = call[key]
  
  if (id) {
    const extensionNumber = call[keyUserObject]?.presence_id || ""
    return extensionNumber
  } else {
    return null
  }
}
  
export const processCalls = (call, myExtension, isNumberReport) => {
  call.fromNumber = findUser(call, "from_user_id", "from_user", isNumberReport);
  call.fromNumberHover = findExtensionNumber(call, "from_user_id", "from_user")
    
  call.toNumber = isNumberReport ? formatPhoneNumber(call.dnis) : findUser(call, "to_user_id", "to_user");
  call.toNumberHover = findExtensionNumber(call, "to_user_id", "to_user")
    
  call.saveDurationCall = new Date(call.billsec * 1000) .toISOString().slice(call.billsec - call.billing_seconds < 3600 ? 14 : 11, call.billsec - call.billing_seconds < 3600 ? 5 : 8);
    
  call.datetime = convertTimeStapToTimeZoneOnFormated(call.calldate, myExtension.time_zone);
    
  if (call.disposition === "ANSWERED") {
    call.style = {
      background: "#F6F6F9 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#199F28",
      opacity: "1",
    }
  } else {
    call.style = {
      background: "#1F3D571E 0% 0% no-repeat padding-box",
      borderRadius: "12px",
      font: "normal normal normal 12px/16px Instrument Sans",
      letterSpacing: "0px",
      color: "#3A3A3ACC",
      opacity: "1",
    }
  }
}