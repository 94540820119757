import petitionGet from "../../../../../services/petitionGet";

const fetchTags = async (setTags, forceUpdate) => {
  try {
    const { data: result } = await petitionGet("tags", { parameter: "?offset=0&limit=10000"});

    result.result.forEach((element) => {
      element.title = element.name;
      element.disabled = false;
      element.label = element.name;
      element.value = element.pk;
    });

    setTags(result.result);
    forceUpdate()

    return result.result;
  } catch (error) {
    console.log(error);
  }
};

export default fetchTags;
