import { Col, Row } from "reactstrap";
import { AddButton, CollapsePanel, CustomTableMain } from "../../../../../../components";

const Switches = ({ dataTableSwitches, activeAddSwitch, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Switches"
      subtitle="Create & manage your switches for your advanced call flow"
    >
      <CustomTableMain
        data={dataTableSwitches}
        loading={loading}
        m0={true}
        module="switches"
        actionsRight={[
          {
            component: (
              <AddButton handleClick={activeAddSwitch} name="Add Switch" />
            ),
          },
        ]}
      />
    </CollapsePanel>
  </>
);

export default Switches;
