import { convertArrBufferToBase64 } from "../../utils";
import axios from "axios";
import json from "../../../../../../config.json"

const downloadVoicemailService = async (number) => {
  try {
    const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
    const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
    const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;
    const url = urlBase + `/itpvoice/v2/${id}/numbers/${number}/voice-messages`;

    const config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
      responseType: "arraybuffer", // Indica que esperamos datos binarios
    };

    const response = await axios.get(url, config);
    if (response.data) {
      const contentType = response.headers.get("Content-Type");
      const base64Audio = convertArrBufferToBase64(response.data);

      return {
        ...response,
        data: {
          data: response.data,
          result: base64Audio,
          contentType,
        },
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export default downloadVoicemailService;
