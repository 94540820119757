import getQueryString from "../../../../../utils/getQueryString";

const getLeadsFilter = (myExtensionTemp, query, filterRef) => {
  if (myExtensionTemp?.crm_filters?.leads?.owner_id) {
    myExtensionTemp.crm_filters.leads.owner_id = myExtensionTemp?.pk;
  }

  const leadsFilter = myExtensionTemp?.crm_filters?.leads;
  const filterModal = getQueryString(leadsFilter).replaceAll("?", "&");
  const parameter = (query || "") + filterModal;
  filterRef.current = filterModal;

  return parameter;
};

export default getLeadsFilter;
