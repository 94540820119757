import Skeleton from "react-loading-skeleton";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const ChatItemInfo = ({ element, loading }) => (
  <div className="itp-chat-list-item__info">
    {(loading || element.isFake) ? (
      <div className="d-flex justify-content-between">
        <Skeleton width="7rem" height="1rem" />
        <Skeleton width="3rem" height="1rem" />
      </div>
    ) : (
      <h1 className={`itp-chat-list-tem__info-name ${(loading || element.isFake) ? "loading" : ""}`}>
        {element?.isContact ? element?.nameContact : formatPhoneNumber(element?.numberChat)}
    </h1>
    )}

    <p className="itp-chat-list-item-info__lastMessage">
      {(loading || element.isFake) ? (
        <Skeleton width="100%" height="1rem" />
      ) : (
        <>{element?.last_message}</>
      )}
    </p>
  </div>
)

export default ChatItemInfo;