import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalNoCard = ({ modal, closeModal }) => (
  <Modal isOpen={modal ? true : false} className="info">
    <ModalHeader>Missing credit card information</ModalHeader>
    <ModalBody>To make a payment you need to add a credit card</ModalBody>
    <ModalFooter>
      <button className="btn-light" onClick={closeModal}>
        Close
      </button>
    </ModalFooter>
  </Modal>
);

export default ModalNoCard;
