import petitionPatch from "../../../../../../services/petitionPatch";

const editSwitchsCallerIdService = async (loadPetition, setLoadPetition, setSwitchs, switchs, details, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true)

    const currentSwitchs = switchs

    try {
      setSwitchs({
        ...switchs,
        switchOverrideDefaultCallerID: !switchs.switchOverrideDefaultCallerID,
      });
    
      if (switchs.switchOverrideDefaultCallerID) {
        const data = {
          default_outbound_callerid_number: "",
          default_outbound_callerid_name: "",
        };
    
        await petitionPatch("user", { data, user_id: details.pk })
      }

      setLoadPetition(false)
    } catch (error) {
      console.log(error)
      setLoadPetition(false)
      setSwitchs(currentSwitchs)
      setErrorEditing("An error has ocurred")
      setModalError(true)
    }
  }
}

export default editSwitchsCallerIdService;