import { findAndFormatPhoneNumbers, selectTypeLastMessage } from "../index";

const formatedThreads = (result) => {
  result.result.message_threads.forEach((element) => {
    const notIsSelf = element.participants.find((par) => !par.is_self) || { ...element.participants.find((par) => par.is_self), is_self: false };
  
    element.isContact = !!notIsSelf.contact;
    element.numberChat = notIsSelf?.number || "";
    element.notIsSelf = notIsSelf;
    element.last_message = selectTypeLastMessage(element);
  
    if (notIsSelf?.contact) {
      const { firstname, lastname = "" } = notIsSelf.contact;
      element.firstname = firstname;
      element.lastname = lastname;
      element.nameContact = `${firstname} ${lastname}`;
    }
  });
  
  return result.result.message_threads
};
  
export default formatedThreads;