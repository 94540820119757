import React, { useState, useEffect } from "react";
import { TabContent, TabPane } from "reactstrap";
import { Stepper } from "react-form-stepper";
import { LoadingButton } from "../../../../../../components";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import Step5 from "./Steps/Step5";
import IgnoreNumbersModal from "../../../ModalUtility/IgnoreNumbersModal";
import petitionGet from "../../../../../../services/petitionGet";
import petitionPost from "../../../../../../services/petitionPost";
import "../../PhoneNumbers.css"

const TransferNumber = () => {
  const id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const [step, setStep] = useState(0);
  const [stepTwo, setStepTwo] = useState(0);
  const [form, setForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingIgnoreNumbers, setLoadingIgnoreNumbers] = useState(false);
  const [invalidModal, setInvalidModal] = useState(false);
  const [invalidNumbers, setInvalidNumbers] = useState(false);
  const [supportedLosingCarriers, setSupportedLosingCarriers] = useState(null);
  const [allData, setAllData] = useState([]);
  const [states, setStates] = useState(null);
  const [serviceItpVoice, setServiceItpVoice] = useState([]);
  const [inputError, setInputError] = useState(null);
  const [formMetadata, setFormMetadata] = useState({
    porting_number: false,
    allow_none: false,
    porting_business_type: "",
    porting_firstname: "",
    porting_lastname: "",
    porting_business_name: "",
    porting_house_number: "",
    porting_street_name: "",
    porting_city_name: "",
    porting_state_code: "",
    porting_zip_code: "",
    porting_btn: "",
    porting_pin: "",
    porting_account_number: "",
    porting_carrier: "",
    porting_address_line_2: "",
    pin: "",
  });

  const filterValidNumbers = () => {
    const newForm = form.split("\n").filter((element) => {
      if (
        element.trim() !== "" &&
        parseInt(element.trim()).toString().length === element.trim().length
      ) {
        return element.trim();
      }
    });
    const formTrim = newForm.map((element) => {
      return element.trim();
    });

    const filterNumbers = formTrim.filter((element) => {
      let findNumber = false;
      if (typeof invalidNumbers === "string") {
        if (invalidNumbers === element) findNumber = true;
      } else {
        findNumber = invalidNumbers.find(
          (elementTwo) => elementTwo === element
        );
      }

      if (!findNumber) return element;
    });

    if (filterNumbers.length === 0) {
      setInvalidModal(false);
    }

    setLoadingIgnoreNumbers(true);
    if (filterNumbers.length !== 0) {
      petitionPost("verifyTransferNumbers", {
        id,
        data: { numbers: filterNumbers },
      })
        .then(({ data: result }) => {
          let temNumbers = [];
          if (
            result.result.NumberPortabilityResponse.SupportedTollFreeNumbers
          ) {
            if (
              result.result.NumberPortabilityResponse.SupportedTollFreeNumbers
                .TollFreeNumberGroup.TnList[0]
            ) {
              temNumbers.push({
                LosingCarrierName: "Toll Free Number",
                porting_number_type: "toll_free",
                TnList:
                  result.result.NumberPortabilityResponse
                    .SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0],
              });
            } else {
              temNumbers.push({
                LosingCarrierName: "Toll Free Number",
                porting_number_type: "toll_free",
                TnList:
                  result.result.NumberPortabilityResponse
                    .SupportedTollFreeNumbers.TollFreeNumberGroup.TnList,
              });
            }
          }
          if (result.result.NumberPortabilityResponse.SupportedLosingCarriers) {
            let temLosing =
              result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat();
            temLosing[0].porting_number_type =
              temLosing[0].LosingCarrierIsWireless === "false"
                ? "local"
                : "wireless";
            temNumbers.push(...temLosing);
          }

          setSupportedLosingCarriers(temNumbers);
          setInvalidModal(false);
          setLoadingIgnoreNumbers(false);
          setForm(filterNumbers);
          setStep(step + 1);
          setInvalidNumbers(false);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setInvalidModal(false);
      setLoadingIgnoreNumbers(false);
    }
  };

  const reloadForm = () => {
    setFormMetadata({
      porting_number: false,
      allow_none: false,
      porting_business_type: "",
      porting_firstname: "",
      porting_lastname: "",
      porting_business_name: "",
      porting_house_number: "",
      porting_street_name: "",
      porting_city_name: "",
      porting_state_code: "",
      porting_zip_code: "",
      porting_btn: "",
      porting_pin: "",
      porting_account_number: "",
      porting_carrier: "",
      porting_address_line_2: "",
      pin: "",
    });
  };

  const validateForm = () => {
    let error = null;

    if (formMetadata.porting_business_type.trim() === "" || !formMetadata?.porting_business_type)
      error = "porting_business_type";
    else if (formMetadata.porting_firstname.trim() === "")
      error = "porting_firstname";
    else if (formMetadata.porting_lastname.trim() === "")
      error = "porting_lastname";
    else if (formMetadata.porting_house_number.trim() === "")
      error = "porting_house_number";
    else if (formMetadata.porting_street_name.trim() === "")
      error = "porting_street_name";
    else if (formMetadata.porting_city_name.trim() === "")
      error = "porting_city_name";
    else if (formMetadata.porting_state_code.trim() === "")
      error = "porting_state_code";
    else if (formMetadata.porting_zip_code.trim() === "")
      error = "porting_zip_code";
    else if (formMetadata.porting_btn.trim() === "") error = "porting_btn";
    else if (formMetadata.porting_account_number.trim() === "")
      error = "porting_account_number";
    else if (formMetadata.porting_carrier.trim() === "")
      error = "porting_carrier";

    if (error) {
      setInputError(error);
      return true;
    }

    return false;
  };

  const handleNext = () => {
    switch (step) {
      case 0:
        if (form.length === 0) {
          return;
        }
        const newForm = form.split("\n").filter((element) => {
          let tempNumber =
            element[0] === "+"
              ? `+${parseInt(element.trim()).toString()}`
              : parseInt(element.trim()).toString();
          if (
            element.trim() !== "" &&
            tempNumber.length === element.trim().length
          )
            return element.trim();
        });
        const formTrim = newForm.map((element) => {
          return element.trim();
        });

        setLoading(true);
        petitionPost("verifyTransferNumbers", {
          id,
          data: { numbers: formTrim },
        })
          .then(({ data: result }) => {
            let temNumbers = [];
            if (
              result.result.NumberPortabilityResponse.SupportedTollFreeNumbers
            ) {
              if (
                result.result.NumberPortabilityResponse.SupportedTollFreeNumbers
                  .TollFreeNumberGroup.TnList[0]
              ) {
                temNumbers.push({
                  LosingCarrierName: "Toll Free Number",
                  porting_number_type: "toll_free",
                  TnList:
                    result.result.NumberPortabilityResponse
                      .SupportedTollFreeNumbers.TollFreeNumberGroup.TnList[0],
                });
              } else {
                temNumbers.push({
                  LosingCarrierName: "Toll Free Number",
                  porting_number_type: "toll_free",
                  TnList:
                    result.result.NumberPortabilityResponse
                      .SupportedTollFreeNumbers.TollFreeNumberGroup.TnList,
                });
              }
            }
            if (
              result.result.NumberPortabilityResponse.SupportedLosingCarriers
            ) {
              let temLosing =
                result.result.NumberPortabilityResponse.SupportedLosingCarriers.LosingCarrierTnList.flat();
              temLosing[0].porting_number_type =
                temLosing[0].LosingCarrierIsWireless === "false"
                  ? "local"
                  : "wireless";

              temNumbers.push(...temLosing);
            }

            setSupportedLosingCarriers(temNumbers);
            setInvalidModal(false);
            setLoadingIgnoreNumbers(false);
            setForm(formTrim);
            setStep(step + 1);
            setInvalidNumbers(false);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);

            if (error.response.data.result.NumberPortabilityResponse) {
              if (
                error.response.data.result.NumberPortabilityResponse.Errors
                  .Description === "Telephone number is invalid"
              ) {
                setInvalidNumbers(
                  error.response.data.result.NumberPortabilityResponse.Errors
                    .TelephoneNumbers.Tn
                );
                setInvalidModal(true);
                setLoading(false);
              }
            } 
          });
        break;
      case 1:
        setStep(step + 1);
        break;
      case 2:
        console.log(stepTwo)
        console.log(supportedLosingCarriers)
        if (stepTwo < supportedLosingCarriers.length) {
          let result = validateForm();

          if (result) return;

          const newAllData = allData;
          if (typeof supportedLosingCarriers[stepTwo].TnList.Tn === "string") {
            newAllData.push({
              ...formMetadata,
              did: supportedLosingCarriers[stepTwo].TnList.Tn,
              type: supportedLosingCarriers[stepTwo].LosingCarrierName,
            });
          } else {
            supportedLosingCarriers[stepTwo].TnList.Tn.map((element) => {
              newAllData.push({
                ...formMetadata,
                did: element,
                type: supportedLosingCarriers[stepTwo].LosingCarrierName,
              });
            });
          }

          setAllData(allData);
          reloadForm();
          if (stepTwo === supportedLosingCarriers.length - 1)
            return setStep(step + 1);
          return setStepTwo(stepTwo + 1);
        }

        setStep(step + 1);

        break;
      case 3:
        if (form.length > 0 && form.length) setStep(step + 1);

        break;
      case 4:
        setLoading(true);

        let types = {};
        let porting_number_type = {};

        supportedLosingCarriers.map((element) => {
          types[element.LosingCarrierName] = [];
          porting_number_type[element.LosingCarrierName] =
            element.porting_number_type;
        });

        allData.map((element) => {
          types[element.type] = [
            ...types[element.type],
            {
              did: element.did.trim(),
              business_type: element.porting_business_type,
              firstname: element.porting_firstname,
              lastname: element.porting_lastname,
              business_name: element.porting_business_name,
              house_number: element.porting_house_number,
              street_name: element.porting_street_name,
              city_name: element.porting_city_name,
              state_code: element.porting_state_code,
              zip_code: element.porting_zip_code,
              btn: element.porting_btn,
              account_number: element.porting_account_number,
              porting_carrier: element.porting_carrier,
              pin: element.pin,
              porting_number_type: porting_number_type[element.type],
              porting_address_line_2: element.porting_address_line_2,
            },
          ];
        });
        const allPetitions = Object.keys(types);
        const promiseAll = [];
        allPetitions.map((element) => {
          promiseAll.push(
            petitionPost("transferNumbers", {
              id,
              data: {
                numbers: [...types[element]],
                internal_port: element === "ITP" ? true : false,
              },
            })
          );
        });

        Promise.all(promiseAll)
          .then((values) => {
            setStep(0);
            setStepTwo(0);
            setForm("");
            setLoading(false);
            setAllData([]);
          })
          .catch((reason) => console.log(reason));

        break;

      default:
        break;
    }
  };

  const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  const petition = async () => {
    await petitionGet("estates")
      .then(({ data: result }) => {
        result.result.forEach((state) => {
          const splitedStateName = state.name.split(" ")
          let capitalizedFirstLetter = ""

          splitedStateName.forEach((element) => {
            capitalizedFirstLetter = capitalizedFirstLetter ? `${capitalizedFirstLetter} ${capitalizeWord(element)}` : capitalizeWord(element)
          })

          state.name = capitalizedFirstLetter

        })

        setStates(result.result);
      })
      .catch((error) => {
        console.log(error);
      });

    await petitionGet("services").then((result) => {
      setServiceItpVoice(
        result.data.result.filter((service) => service.api_id == id)
      );
    });
  }

  useEffect(() => {
    petition()
  }, []);

  return (
    <>
      {invalidModal && (
        <IgnoreNumbersModal
          modal={invalidModal}
          setModal={setInvalidModal}
          invalidNumbers={invalidNumbers}
          loading={loadingIgnoreNumbers}
          filterValidNumbers={filterValidNumbers}
        />
      )}

      <Stepper
        steps={[
          { label: "Step 1" },
          { label: "Step 2" },
          { label: "Step 3" },
          { label: "Step 4" },
          { label: "Step 5" },
        ]}
        activeStep={step}
      />  

      <div className="row">
        <div className="col-xl-12 col-xxl-12 ms-5">
          <TabContent activeTab={step.toString()}>
            <TabPane tabId="0" className="p-3">
              <Step1
                formMetadata={formMetadata}
                setFormMetadata={setFormMetadata}
                setForm={setForm}
                form={form}
              />
            </TabPane>
            <TabPane tabId="1" className="p-3">
              <Step2 supportedLosingCarriers={supportedLosingCarriers} />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Step3
                inputError={inputError}
                step={stepTwo}
                formMetadata={formMetadata}
                setFormMetadata={setFormMetadata}
                setForm={setForm}
                form={form}
                supportedLosingCarriers={supportedLosingCarriers}
                states={states}
              />
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <Step4
                setAllData={setAllData}
                allData={allData}
                setForm={setForm}
                form={form}
              />
            </TabPane>
            <TabPane tabId="4" className="p-3">
              <Step5
                serviceItpVoice={serviceItpVoice}
                setForm={setForm}
                form={form}
              />
            </TabPane>
          </TabContent>
        </div>
        <div className="d-flex ms-5  mt-3">
          {step > 0 && (
            <button
              className="btn-light"
              disabled={loading}
              style={{ padding: "0.60rem 2rem", marginRight: "0.5rem", marginLeft: "8px" }}
              onClick={() => {
                setStep(step - 1);
                if (step === 1) {
                  setForm("");
                  setFormMetadata("");
                }
              }}
            >
              Back
            </button>
          )}

          <button
            className="btn-primary loading"
            disabled={loading}
            style={{ padding: "0.60rem 2.5rem", marginLeft: "8px" }}
            onClick={handleNext}
          >
            {loading && <LoadingButton />}
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default TransferNumber;