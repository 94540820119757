import { capitalizeWords } from "../../../../../../utils/capitalizeWords";
import { areAllChildrenObjects, returnTimeGroup, selectIdForAddSubtitle, selectSubTitleModule } from "../index";
import ChatIcon from "@mui/icons-material/Chat";

// transforma los datos para que los hijos queden en un array en vez de un objeto
// transforms the data so that the children are an array instead of an object
const transformDataCallFlows = (info, key, timeGroups, activePanelCallFlows, queues, switches, voicemails, users, plays, fatherModule, tempData) => {
  const arrBranchModule = ["timecondition", "menu", "switch"];

  const transformedItem = {
    ...info,
    title: info.isFirst === true ? info?.module : info?.module === "externaltransfer" ? "External Transfer" : `${capitalizeWords(info?.module)}`,
    subtitle: selectSubTitleModule(info.module, selectIdForAddSubtitle(info, tempData), queues, switches, voicemails, users, plays, tempData),
    cardClick: () => console.log("click card"),
    addClick: (element, end, edit) => activePanelCallFlows(element, end, edit),
    icon: <ChatIcon style={{ transform: "scale(1.5)" }}></ChatIcon>,
    child: [],
    branchName: key ? returnTimeGroup(key, timeGroups, fatherModule, tempData) : null,
    isDeleted:  key && returnTimeGroup(key, timeGroups, fatherModule, tempData) === "This Time Condition Was Removed" ? true : false,
    isKeyObject: key ? key : null,
    noMoreAction: info.module === "voicemail" || "externaltransfer" ? true : null,
    fatherModule: fatherModule ? fatherModule : null,
  };

  const childKeys = Object.keys(info?.children || {});
  const hasMultipleChildren = areAllChildrenObjects(info?.children || {});
  const mustHaveBranchName = arrBranchModule.includes(info.module);

  if (childKeys.length > 0 && hasMultipleChildren && mustHaveBranchName) {

    transformedItem.child = childKeys.map((key) => transformDataCallFlows(info?.children[key], key, timeGroups, activePanelCallFlows, queues, switches, voicemails, users, plays, info.module, tempData));

  } else if (childKeys.length === 1 && hasMultipleChildren && !mustHaveBranchName) {

    const uniqueKey = childKeys[0];
    const returnData = transformDataCallFlows(info?.children[uniqueKey], false, timeGroups, activePanelCallFlows, queues, switches, voicemails, users, plays, false, tempData);
    transformedItem.child = [returnData];

  } else if (childKeys.length > 0 && !hasMultipleChildren) {

    const returnData = transformDataCallFlows(info?.children, false, timeGroups, activePanelCallFlows, queues, switches, voicemails, users, plays, false, tempData);
    transformedItem.child = [returnData];

  }
  return transformedItem;
};

export default transformDataCallFlows
