import React, { useEffect, useRef, useState } from "react";
import { MenuRight, LoadingButton, CustomTableMain, AlignedRow, SearchBoxFilter, AddButton, TabMenu } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { GithubPicker } from 'react-color'
import { addOrEditTagService, deleteTagService } from "../services";
import { handleChangeSearch } from "../utils";
import { useNavigate } from "react-router-dom";
import petitionGet from "../../../../../services/petitionGet";
import ModalDelete from "../ModalUtility/ModalDelete";
import getQueryString from "../../../../../utils/getQueryString";
import "../../Contacts.css";

const Tags = () => {
  const navigate = useNavigate()

  const loading = useRef(true)
  const itemsPerPage = useRef("10");
  const filterInput = useRef("");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [info, setInfo] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [tagName, setTagName] = useState("");
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [loadingPagItems, setLoadingPagItems] = useState(false);
  const [useColor, setUseColor] = useState(false);
  const [color, setColor] = useState('#D0021B');
  const [searchValue, setSearchValue] = useState("")
  const [dataTable, setDataTable] = useState({
    columns: [{ name: "Tag Name", key: "name" }],
    actions: { title: "Actions", content: [] }
  });

  const backFunction = () => {
    navigate("/Contacts")
  };

  const updatedTab = (index) => {
    if (index === 0) {
      navigate("/Contacts/Contact-List")
    }
  }

  const handleChangeComplete = (color) => {
    setColor(color.hex);
  };

  const activeMenu = (e) => {
    if (loading.current) return;

    if (e.name) {
      setInfo(e);
    }
    setOpenMenu(true);
    setUseColor(false);
  };

  const closeMenu = () => {
    setModalDelete(false);
    setTagName("");
    setColor('#D0021B');
    setInfo(null);
    setOpenMenu(false);
    setUseColor(false);
  };

  const activeModalDelete = (e) => {
    setInfo(e);
    setModalDelete(true);
  };

  const handleChange = (e) => {
    setTagName(e.target.value);
  };

  const reloadData = (pag) => {
    closeMenu();
    const parameter = getQueryString(pag ? pag : pagination.current) + filterInput.current
    petition(parameter)

    if (pag) {
      pagination.current = pag;
    }
  };

  const saveFunctionMenu = async () => {
    await addOrEditTagService(setLoadingMenuButton, tagName, color, useColor, info, reloadData)
  };

  const deleteFunction = async () => {
    await deleteTagService(setLoadingMenuButton, info, allResult, pagination, reloadData)
  };

  const activeItemsPerPage = (data) => {
    setLoadingPagItems(true);
    const paginationTemp = {
      offset: 0,
      limit: parseInt(data),
    };

    const parameter = getQueryString(paginationTemp) + filterInput.current
    petition(parameter, true);
    itemsPerPage.current = data;
    pagination.current = paginationTemp;
  };

  const changePage = (offset) => {
    setLoadingPagItems(true)
    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    const parameter = getQueryString(paginationTemp) + filterInput.current

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const handleChangeInput = (e) => {
    if (loading.current) return;
    const { value } = e.target
    setSearchValue(value)
    handleChangeSearch(value, pagination, petition, filterInput)
  };

  const handleCheckboxChange = (event) => {
    setUseColor(event.target.checked);
  };

  const petition = async (parameter, noLoading) => {
    if (!noLoading) loading.current = true

    try {
      const { data: result } = await petitionGet("tags", { parameter })
      setAllResult(result);

      setDataTable({
        columns: [{ name: "Tag Name", key: "name" }],
        content: [...result.result],
        actions: {
          title: "Actions",
          content: [
            {
              icon: <FontAwesomeIcon icon={faPenToSquare} />,
              name: "Edit",
              handleClick: activeMenu,
            },
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              name: "Delete",
              handleClick: activeModalDelete,
            },
          ],
        },
      });

      loading.current = false
      setLoadingPagItems(false);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (info && !modalDelete) {
      setTagName(info.name);
      if(info.tag_color){
        setColor(info.tag_color);
        setUseColor(true);
      }
    }
  }, [info]);

  useEffect(() => {
    petition(getQueryString(pagination.current));
  }, []);

  const tabs = [
    {
      title: "Contact List",
      component: <></>,
    },
    {
      title: "Tags",
      component: <></>,
    },
  ];

  return (
    <div className="mt-4">
      <TabMenu
        tabs={tabs}
        textBack="Back to Contacts"
        defaultTab={1}
        backFunction={backFunction}
        setTab={updatedTab}
        noUpdateTab={true}
      />

      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingMenuButton}
          modalFunction={deleteFunction}
          info={info}
        />
      )}
  
      <AlignedRow className="mt-4">
        <h3 className="itp-accordion-header-title">
          Tags
        </h3>
      </AlignedRow>

      <AlignedRow>
        <h6 className="itp-accordion-header-subtitle">
          Tags used for the contacts
        </h6>
      </AlignedRow>
      

      <CustomTableMain
        data={dataTable}
        loading={loading.current}
        loadingPagItems={loadingPagItems}
        module="tags"
        noDataMessage={searchValue && !allResult.result.length ? "No results found" : ""}
        isBackend={true}
        m3={true}
        paginationData={parseInt(itemsPerPage.current)}
        totalPages={allResult?.total_pages}
        onPageChange={changePage}
        showSelectSingleCheckbox={true}
        onChangeItemsPerPage={activeItemsPerPage}
        offset={pagination.current.offset}
        actionsLeft={[
          {
            component: (
              <SearchBoxFilter 
                placeholder="Search Tag"
                handleChange={handleChangeInput}
                value={searchValue}
              />
            )
          }
        ]}
        actionsRight={[
          {
            component: (
              <AddButton 
                name="Add New Tag"
                handleClick={activeMenu}
              />
            )
          }
        ]}
      />

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Create New Tag"
        subtitle="Add a tag name which can appear in contact list"
        to="to Tags"
      >
        {info?.is_default ? (
          <h6>It is not possible to edit the default list</h6>
        ) : (
          <>
            <Row className="itp-container-label-input">
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                xxl={2}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Tag Name</label>
              </Col>
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="itp-label-input-col"
              >
                <input
                  type="text"
                  placeholder=": Add a name for tag"
                  className="form-control-itp itp-label-input__input"
                  name="name"
                  value={tagName}
                  onChange={handleChange}
                />
              </Col>
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                xxl={2}
                className="itp-label-input-col"
              >
                <label className="itp-label-input__label">Tag Color</label>
              </Col>
              <Col
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                xxl={4}
                className="itp-label-input-col"
              >
                <div style={{display:"flex", alignItems:"end", height:"30px"}}>
                  <input type="checkbox" style={{marginLeft:"10px", marginBottom:"12px"}} checked={useColor} onChange={handleCheckboxChange}></input>
                  <div style={{display: useColor ? "block" : "none"}}>
                    <div style={{ display: 'block', position: 'relative', zIndex: '2' , marginLeft: '10px'}} className="">
                      <GithubPicker
                        color={color}
                        width="100%"
                        onChangeComplete={handleChangeComplete}
                        colors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505','#DFF21B']}
                        className="github-picker"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <button
              disabled={loadingMenuButton}
              className="btn-primary loading"
              onClick={saveFunctionMenu}
            >
              {loadingMenuButton && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
  </div>
  )
};

export default Tags;
