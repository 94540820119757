import petitionGet from "../../../../../../services/petitionGet";

const fetchFunnels = async (info, setFunnelValue, setFunnels) => {
  const { data: result } = await petitionGet("funnelsAdmin") 

  result.result.forEach((funnel) => {
    funnel.label = funnel.name
    funnel.value = funnel.pk

    if (funnel.pk === info.ai_lead_manager_funnel_id) {
      setFunnelValue(funnel)
    }
  })

  setFunnels(result.result)
}

export default fetchFunnels;