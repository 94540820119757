import json from "../../../../../../config.json"

const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;
const token = JSON.parse(localStorage.getItem("userDetails"))?.access_token;

const isCallItem = (element) => {
  const { item_type, cdr } = element;
  return item_type === "call" && (!cdr || !cdr.call_recording_filename);
}

const isCallAudio = (element) => {
  const { item_type, cdr } = element;
  return item_type === "call" && cdr && cdr.call_recording_filename;
}

const selectStatus = (element) => {
  const { call_status, call_direction } = element

  if (call_status === "missed") return "Missed Call";

  if (call_status === "answered") {
    switch (call_direction) {
      case "inbound":
        return "Inbound Call";
      case "outbound":
        return "Outbound Call";
      case "forwarded":
        return "Forwarded Call";
      default:
        return "Unknown Call Direction";
    }
  }

  return "Unknown Call Status";
};

const selectMessageBody = (element) => {
  const { call_status, call_direction, answered_by, cdr } = element;
  const { firstname, lastname } = answered_by || {};
  const { forwarded_to_number } = cdr || {};
  const name = firstname ? `${firstname} ${lastname || ""}` : "";
  
  if (call_status === "missed") {
    return "Unanswered call";
  }
  
  if (call_status === "answered") {
    if (call_direction === "forwarded") {
      return (
        <>
          <span>Call answered by {name}</span>
          <br />
          <span>Call was forwarded to: {forwarded_to_number || ""}</span>
        </>
      );
    }
  
    if (call_direction === "inbound") {
      return <>Call answered by {name || "you"}</>;
    }
  
    if (call_direction === "outbound") {
      return <>Call made by {name || "you"}</>;
    }
  }
  
  return null; // Optional: Return null if no conditions are met
};

const selectImage = (element) => {
  const { call_status, call_direction } = element

  if (call_status === "missed") return "missed";
  
  if (call_status === "answered") {
    switch (call_direction) {
      case "inbound":
        return "answeredIn";
      case "outbound":
        return "answeredOut";
      case "forwarded":
        return "answeredIn";
      default:
        return "unknown";
    }
  }
  
  return "unknown";
};

const returnAudioMessageUrl = (element) => {
  const { cdr } = element

  return !cdr ? "" : `${urlBase}/itpvoice/v2/${id}/cdr/${cdr.pk}/download-recording/raw?token=${token}&convert_base64=true`
}

const returnVoicemailUrl = (element) => {
  const { voicemail_id } = element
  return `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${voicemail_id}?token=${token}&convert_base64=true`
}
  
const formatedMessageList = (result) => {
  result.result.messages.forEach((element) => {
    if (isCallItem(element)) {
      element.status = selectStatus(element);
      element.message_body = selectMessageBody(element);
      element.selectImage = selectImage(element);
    } else if (isCallAudio(element)) {
      element.isRecording = true;
      element.status = selectStatus(element);
      element.audio_message = returnAudioMessageUrl(element);
      element.message_body = selectMessageBody(element);
      element.selectImage = selectImage(element);
      element.voicemail_duration = element.cdr.duration;
    } else if (element.item_type === "voicemail") {
      element.audio_message = returnVoicemailUrl(element);
      element.message_body = null;
    }
  });

  return result.result.messages
}

export default formatedMessageList;