//Transformar los hijos que están en array de nuevo a objetos
//Transform the children that are in array back to objects
const convertToNestedObject = (data, route = "flow.children") => {
  const result = {};

  result.route = route;
  result.module = data.module;
  result.data = data.data;
  result.children = {};

  if (Array.isArray(data.child) && data.child.length > 0) {
    if (data.child.length === 1 && !data.child[0].fatherModule) {
      // Si solo hay un objeto en child, y el padre de ese child no es un timecondition, menu o switch no agregamos un objeto adicional
      result.children = convertToNestedObject(data.child[0], route);
    } else {
      result.children = {};
      data.child.forEach((item, index) => {
        const key = item.isKeyObject ? item.isKeyObject : index + 1;
        const childRoute = `${route}.child.${index}`;
        result.children[key] = convertToNestedObject(item, childRoute);
      });
    }
  }

  return result;
};

export default convertToNestedObject;
