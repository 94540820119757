import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "./SelectSingleCheckbox.css";

const SelectSingleCheckbox = ({ name, options, defaultSelected, checkFunction, loading }) => {
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultSelected || null);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    if (selectedOption === value) {
      checkFunction(null);
      setSelectedOption(null);
    } else {
      checkFunction(value);
      setSelectedOption(value);
    }

    toggleSelect();
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton height={30} width="120px" />
      ) : (
        <div className="select-container" ref={containerRef}>
          <div
            className={`select-header ${isOpen ? "active-menu" : ""}`}
            onClick={toggleSelect}
          >
            {name}
          </div>
          {isOpen && (
            <div className="select-options">
              {options.map((option, i) => (
                <Row
                  key={i}
                  className={`select-option ${
                    selectedOption === option.value ? "selected" : ""
                  }`}
                  onClick={() => handleOptionClick(option.value)}
                >
                  <Col lg={8}>
                    <span>{option.label}</span>
                  </Col>
                  <Col lg={4}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={selectedOption === option.value}
                      readOnly
                    />
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectSingleCheckbox;
