import petitionPatch from "../../../../../../services/petitionPatch";

const editSwitchsCallRecordingService = async (loadPetition, setLoadPetition, switchName, setSwitchs, switchs, details, setErrorEditing, setModalError) => {
  if (!loadPetition) {
    setLoadPetition(true)

    const currentSwitchs = switchs

    try {
      setSwitchs({ ...switchs, [switchName]: !switchs[switchName] });
  
      const data = {
        [switchName]: !switchs[switchName] ? "enabled" : "disabled",
      };
  
      await petitionPatch("user", { data, user_id: details.pk })
      setLoadPetition(false)
    } catch (error) {
      console.log(error);
      setLoadPetition(false)
      setSwitchs(currentSwitchs)
      setErrorEditing("An error has ocurred")
      setModalError(true)
    }
  }
};

export default editSwitchsCallRecordingService;
