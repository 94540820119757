import React, { useEffect, useState } from "react";
import { businessType } from "../../../../../const/businessType";
import { countryList } from "../../../../../const/countryList";
import { states } from "../../../../../const/states";
import { Row, Col } from "reactstrap";

const AddOrEditBrand = ({
  form,
  setForm,
  handleChange,
  errorText,
  arrError,
  setArrError,
}) => {
  const [optionsBusinessType, setOptionsBusinessType] = useState([]);

  const onChangeCheckbox = () => {
    if (arrError.includes("confirmCheckbox")) {
      const newArrError = arrError.filter((item) => item !== "confirmCheckbox");
      setArrError(newArrError);
    }

    setForm({ ...form, confirmCheckbox: !form.confirmCheckbox });
  };

  useEffect(() => {
    let options = [];
    for (const key in businessType) {
      options.push({ value: key, displayName: businessType[key].displayName });
    }
    setOptionsBusinessType(options);
  }, [businessType]);

  return (
    <>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("displayName") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Legal Company Name *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter a Legal Company Name"
            className="form-control-itp itp-label-input__input"
            name="displayName"
            value={form.displayName}
            onChange={(e) => handleChange(e, "companyName")}
          />
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("street") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Address / Street *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Address/Street"
            className="form-control-itp itp-label-input__input"
            name="street"
            value={form.street}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Company Type *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className={`form-select-itp ${
              arrError.includes("entityType") && "form-select-itp-error"
            }`}
            onChange={handleChange}
            name="entityType"
            value={form.entityType}
          >
            <option value="">Select</option>
            <option value="PRIVATE_PROFIT">Private Corporation</option>
            <option value="PUBLIC_PROFIT">Public Corporation</option>
            <option value="NON_PROFIT">Non Profit</option>
            <option value="GOVERNMENT">Government</option>
            <option value="SOLE_PROPRIETOR">Sole Proprietor</option>
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">
            Country Of Registration *
          </label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className={`form-select-itp ${
              arrError.includes("country") && "form-select-itp-error"
            }`}
            onChange={handleChange}
            name="country"
            value={form.country}
          >
            <option value="">Select Country</option>

            {countryList.map((element, i) => (
              <option key={i} value={element.alpha2}>
                {element.name}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row
        className={
          form.country !== "US"
            ? `itp-container-label-input ${
                arrError.includes("state") && "itp-container-label-input-error"
              }`
            : "itp-container-label-select"
        }
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">State / Region *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          {form.country !== "US" ? (
            <input
              type="text"
              placeholder=": Enter State"
              className="form-control-itp itp-label-input__input"
              name="state"
              value={form.state}
              onChange={handleChange}
            />
          ) : (
            <select
              className={`form-select-itp ${
                arrError.includes("state") && "form-select-itp-error"
              }`}
              name="state"
              value={form.state}
              onChange={handleChange}
            >
              <option value="">Select</option>
              {states
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((element, i) => (
                  <option key={i} value={element.shortcode}>
                    {element.name}
                  </option>
                ))}
            </select>
          )}
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("city") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">City *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter City"
            className="form-control-itp itp-label-input__input"
            name="city"
            value={form.city}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("ein") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Tax Number / EIN *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Tax Number / EIN"
            className="form-control-itp itp-label-input__input"
            name="ein"
            value={form.ein}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="itp-container-label-select">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Business industry *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <select
            className={`form-select-itp ${
              arrError.includes("vertical") && "form-select-itp-error"
            }`}
            onChange={handleChange}
            name="vertical"
            value={form.vertical}
          >
            <option value="">Select Business industry</option>

            {optionsBusinessType.map((element, i) => (
              <option key={i} value={element.value}>
                {element.displayName}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className="itp-container-label-input">
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Web Site</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Web Site"
            className="form-control-itp itp-label-input__input"
            name="website"
            value={form.website}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("postalCode") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Postal Code *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Postal Code"
            className="form-control-itp itp-label-input__input"
            name="postalCode"
            value={form.postalCode}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("phone") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Phone *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Phone"
            className="form-control-itp itp-label-input__input"
            name="phone"
            value={form.phone}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row
        className={`itp-container-label-input ${
          arrError.includes("email") && "itp-container-label-input-error"
        }`}
      >
        <Col
          xs={5}
          sm={5}
          md={5}
          lg={5}
          xl={5}
          xxl={5}
          className="itp-label-input-col"
        >
          <label className="itp-label-input__label">Email *</label>
        </Col>
        <Col
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          xxl={7}
          className="itp-label-input-col"
        >
          <input
            type="text"
            placeholder=": Enter Email"
            className="form-control-itp itp-label-input__input"
            name="email"
            value={form.email}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          <input
            type="checkbox"
            className="form-check-input mt-2"
            checked={form.confirmCheckbox}
            onChange={onChangeCheckbox}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
          <label
            style={{ fontSize: "14px", letterSpacing: "0" }}
            className={
              arrError.includes("confirmCheckbox")
                ? "text-danger"
                : "itp-label-input__label"
            }
          >
            * By Submitting a Campaign you agree there will be a one-time
            registration fee of $4. Any unverified brands that require an update
            will also incur a $4 fee for each resubmission. Please ensure the
            company is active and the TAX ID matches the Business Name
          </label>
        </Col>
      </Row>
      {errorText && <p className="text-danger mt-4">{errorText}</p>}
    </>
  );
};

export default AddOrEditBrand;
