import getValuesInObject from "../../../../../utils/getValuesInObject";

const getFiltersActive = (formLabels) => {
  let filtersActive = getValuesInObject(formLabels, formLabels["Only Show Leads Assigned to Me"] ? "" : "Only Show Leads Assigned to Me");

  if (filtersActive.added_after && filtersActive.added_before) {
    filtersActive["Lead Created Within"] = `${filtersActive.added_after.replaceAll("-", "/")}-${filtersActive.added_before.replaceAll("-", "/")}`
    delete filtersActive.added_after
    delete filtersActive.added_before
  }

  return filtersActive
}

export default getFiltersActive;