import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import "./DateRange.css"

const DateRange = ({ timeStart, timeEnd, onChangeDate }) => (
  <Row>
    <Col>
      <div className="container-date-range">
        <label className="date-range-label">From:</label>
        <input
          type="date"
          className="date-range-input"
          name="timeStart"
          value={timeStart}
          onChange={onChangeDate}
        />
        <div>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="date-range-separator"
          />
        </div>
        <label className="date-range-label">To:</label>
        <input
          type="date"
          className="date-range-input"
          name="timeEnd"
          value={timeEnd}
          onChange={onChangeDate}
        />
      </div>
    </Col>
  </Row>
);

export default DateRange;
