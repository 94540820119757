import React, { useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faThumbTack } from "@fortawesome/free-solid-svg-icons";
import { Loading, CustomTableMain, AddButton } from "../../../../components/index.js";
import "./Forms.css";

export default function Forms() {
  const loading = false

  const [dataTableForms, setDataTableForms] = useState(null);

  const initForms = [
    {
      page: "form",
      form_name: "3hk-47kl-8923",
      created_on: "May 17, 2023",
      status: "enabled",
    },
    {
      page: "form",
      form_name: "3hk-47kl-8923",
      created_on: "May 17, 2023",
      status: "enabled",
    },
  ];

  useMemo(() => {
    setDataTableForms({
      columns: [
        { name: "Form Name", key: "form_name" },
        { name: "Created on", key: "created_on" },
        { name: "Status", key: "status" },
      ],
      content: [...initForms],
      actions: {
        title: "Actions",
        content: [
          {
            icon: <FontAwesomeIcon icon={faThumbTack} style={{ rotate: "45deg" }} />,
            name: "Pin form",
            handleClick: () => {},
          },
          {
            icon: <FontAwesomeIcon icon={faTrash} />,
            name: "Delete form",
            handleClick: () => {},
          },
        ],
      },
    });
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ marginTop: "3rem" }}>
          <CustomTableMain
            data={dataTableForms}
            actionsLeft={[
              {
                component: (
                  <div className="d-flex flex-column gap-2">
                    <h3 className="itp-accordion-header-title">Forms</h3>
                    <h6 className="itp-accordion-header-subtitle">
                      Forms Allow you to create Web Forms that can be integrated with existing 
                      websites to gather leads. 
                      <br />
                      Forms will be assigned to a specific funnel and 
                      stage and will create leads automatically.                    
                    </h6>
                  </div>
                )
              }
            ]}
            actionsRight={[
              {
                component: (
                  <AddButton 
                    handleClick={() => {}}
                    name="Add New Form"
                  />
                )
              }
            ]}
          />
        </div>
      )}
    </div>
  );
}
