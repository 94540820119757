import { AddButton, CollapsePanel, CustomTableMain } from "../../../../../../components";

const Callflows = ({ dataTableCallFlows, activeAddCallFlow, loading }) => (
  <>
    <CollapsePanel
      title="Advanced Call Flows"
      subtitle="Create & manage your advanced call flow"
    >
      <CustomTableMain
        data={dataTableCallFlows}
        loading={loading}
        m0={true}
        module="advanced call flows"
        actionsRight={[
          {
            component: (
              <AddButton
                handleClick={activeAddCallFlow}
                name="Create New Call Flow"
              />
            ),
          },
        ]}
      />
    </CollapsePanel>
  </>
);

export default Callflows;
