// Importar React y los componentes necesarios
import React, { useEffect, useState } from "react";
import { faEllipsis, faUserPlus, faMicrophone, faGrip, faHand, faPhoneFlip, faDeleteLeft, faMicrophoneSlash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownMenu, ButtonDropdown, DropdownToggle, DropdownItem } from 'reactstrap';
import "./Keyboard.css"
// Definir el componente TransferScreen
const IncomingCall = ({ numberToCall, setNumberTocall, handleCall, sipconfiguration, setCloseSettings, enablePhone, connecting, setShowWebphone }) => {
  // Definir el estado del número de teléfono

  // const [numberToCall, setNumberTocall] = useState('');
  const [keyboardOpen, setkeyboardOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [mute, setMute] = useState(false)
  const [botonHabilitado, setBotonHabilitado] = useState(true);

  // Definir la función que se ejecuta al presionar una tecla
  const handleKeyPress = (key) => {
    // Añadir el símbolo al número de teléfono
    let newNumber = numberToCall.number + key;

    // Aplicar la expresión regular para permitir dígitos, "*" y "#" en cualquier combinación
    if (/^[*#]?\d*[*#]?$/.test(newNumber)) {
      setNumberTocall({ number: newNumber, data: {} });
    } else {
      // Si no cumple con el formato deseado, puedes manejarlo según tus necesidades
      console.log("Formato no válido");
    }
  };

  const handleGreenButton = async () => {

    if (!botonHabilitado) {
      return;
    }

    setBotonHabilitado(false);

    // Eliminar caracteres no deseados excepto dígitos, "*" y "#"
    const cleanedNumber = numberToCall.number.replace(/[^\d*#]/g, '');

    // Verificar si el "*" o "#" están al principio o al final del número
    if (/^([*#]?\d+|\d+[*#]?)$/.test(cleanedNumber)) {
      setNumberTocall({ number: cleanedNumber, data: {} });

      if (enablePhone) {
        if (cleanedNumber.length !== 0) {
          await sipconfiguration
            .endPointToFindContactsToCall("findContact", { number: cleanedNumber })
            .then(({ data: result }) => {
              console.log("------------------------------pz", result);
              setNumberTocall({ number: cleanedNumber, data: result.result });
            })
            .catch((error) => {
              console.log(error);
              setNumberTocall({ number: cleanedNumber, data: {} });
            });
        }
        await handleCall();
      }
    } else {
      // Si no cumple con el formato deseado, puedes manejarlo según tus necesidades
      console.log("Formato no válido");
    }

    setTimeout(() => {
      setBotonHabilitado(true);
    }, 3000);
  };

  // Definir la función que se ejecuta al presionar el botón de cancelar
  const handleCancel = () => {
    // Llamar a la función que cancela la transferencia de llamada
    // cancelTransfer();
  };

  const handleNumberDelete = () => {
    if (numberToCall.number.length > 0) {
      // Elimina el último carácter utilizando slice
      const newNumber = numberToCall.number.slice(0, -1);
      // setNumberTocall(newNumber);
      setNumberTocall({ ...numberToCall, number: newNumber })
    }
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  const toggleMute = () => {
    setMute(!mute)
  }

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Usa una expresión regular para permitir dígitos, "*" y "#" al principio o al final
    if (/^\d*[#*]?$|^[#*]?\d*$/.test(value)) {
      setNumberTocall({ ...numberToCall, number: value });
    }
  };


  useEffect(() => {
    // Función para desactivar la edición del input después de pegar
    const handlePaste = (e) => {
      e.preventDefault(); // Previene la acción predeterminada de pegar
      const pastedText = e.clipboardData.getData("text/plain");
      // setNumberTocall(pastedText); // Establece el valor del input con el texto pegado
      setNumberTocall({ ...numberToCall, number: pastedText })
    };

    // Agrega un event listener al input para manejar el pegado
    const inputElement = document.getElementById("numberInput");
    inputElement.addEventListener("paste", handlePaste);

    return () => {
      // Elimina el event listener cuando el componente se desmonta
      inputElement.removeEventListener("paste", handlePaste);
    };
  }, []);

  // Retornar el elemento JSX que representa la pantalla de transferencia
  return (
    <div className={`keyboard ${!keyboardOpen ? "keyboard-close" : "keyboard-open"}`}>
      <div className="keyboard-header">
        <div className="keyboard-top-data"><div>{connecting ? 'Connecting' : sipconfiguration.name}
        </div>
          <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="onCall-dropdown">
              <FontAwesomeIcon icon={faEllipsis} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => { setCloseSettings(false) }}>{"Settings"}</DropdownItem>
              <DropdownItem onClick={() => { setShowWebphone(false) }}>{"Close"}</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>

      </div>
      <div className="keyboard-call-info"><div>
      </div>
      </div>
      {!keyboardOpen && <div className="keyboard-call-info"><div><FontAwesomeIcon icon={faUserPlus} /> <span className="keyboard-span" onClick={() => { setAddNumber(true); setkeyboardOpen(true) }}>Add Call</span>
      </div>
      </div>
      }
      {!keyboardOpen && <div className="keyboard-buttons">
        <div className="keyboard-buttons-item keyboard-buttons-item-mic" onClick={toggleMute}>{mute ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon icon={faMicrophone} />}</div>

        <div className="keyboard-buttons-item keyboard-buttons-item-grip" onClick={() => { setkeyboardOpen(!keyboardOpen) }}><FontAwesomeIcon icon={faGrip} /></div>

        <div className="keyboard-buttons-item keyboard-buttons-item-hand"><FontAwesomeIcon icon={faHand} /></div>

        <div className="keyboard-buttons-item keyboard-buttons-item-bye"><FontAwesomeIcon className="keyboard-buttons-item-bye" icon={faPhoneFlip} /></div>

      </div>}
      {keyboardOpen && <div className="keyboard-number">
        <input onChange={handleInputChange} type={"text"} className="keyboard-number-span" value={numberToCall.number} id="numberInput"  autoComplete="off" />
        <div className="keyboard-number-delete" >
          <div onClick={handleNumberDelete} id="keyboard-number-delete"><FontAwesomeIcon icon={faDeleteLeft} /></div>
        </div>

      </div>}
      {(keyboardOpen) && <>   <div className="keyboard-keypad">
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("1") }} id="1">1</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("2") }} id="2">2</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("3") }} id="3">3</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("4") }} id="4">4</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("5") }} id="5">5</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("6") }} id="6">6</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("7") }} id="7">7</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("8") }} id="8">8</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("9") }} id="9">9</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("*") }} id="*">*</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("0") }} id="0">0</div>
        <div className="keyboard-button-keyboard" onClick={() => { if (enablePhone) handleKeyPress("#") }} id="#">#</div>
      </div>
        <div className="buttons">
          <div className={!botonHabilitado ? "keyboard-buttons-actions keyboard-call-inactive" : "keyboard-buttons-actions keyboard-call"} onClick={!botonHabilitado ? null : handleGreenButton}>
            {!botonHabilitado ? <FontAwesomeIcon icon={faSpinner} className="fa-spin" /> : <FontAwesomeIcon icon={faPhoneFlip} />}
          </div>
        </div></>}
    </div>
  );
};

export default IncomingCall
