import petitionGet from "../../../../../services/petitionGet";
import covertTimeStapToSeconds from "../../../../../utils/covertTimeStapToSeconds";

const fetchQueueCalls = async (forceUpdate, setPeopleWaitingQueue) => {
  const { data: result } = await petitionGet("queueCalls")
  const keys = Object.keys(result.result)
  let newPeopleWaiting = []

  keys.forEach((key) => {
    const array = result.result[key]
    array.forEach((element) => {
      if (element.status === "waiting") {
        newPeopleWaiting.push({
          callerid_name: element?.callerid_name, 
          callerid_number: element?.callerid_number,
          queue_id: element.queue.pk.toString(),
          uniqueid: element.call_id,
          queuecall_id: element.pk,
          time: covertTimeStapToSeconds(element.enter_time, "secs"),
        })
      }
    })
  })

  setPeopleWaitingQueue(newPeopleWaiting)
  forceUpdate()
}

export default fetchQueueCalls;