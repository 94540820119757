import dashboard from "../assets/icons/dashboard.svg";
import contacts from "../assets/icons/contacts.svg";
import leads from "../assets/icons/leads.svg";
import funnels from "../assets/icons/funnels.svg";
import panel from "../assets/icons/panel.svg";
import analytics from "../assets/icons/analytics.svg";
import campaigns from "../assets/icons/campaigns.svg";
import settings from "../assets/icons/settings.svg";
import key from "../assets/icons/key.svg";
import form from "../assets/icons/form.svg";
import webhook from "../assets/icons/webhook.svg";
import phoneNumbers from "../assets/icons/phoneNumbers.svg";
import workflow from "../assets/icons/workflow.svg";
import incomingCall from "../assets/icons/incomingCall.svg";
import dlcicon from "../assets/icons/10dlc.svg";
import troubleshooting from "../assets/icons/troubleshooting.svg";
import setting from "../assets/icons/setting.svg";
import cpuCharge from "../assets/icons/cpuCharge.svg";
import card from "../assets/icons/card.svg";

const itemsMenu = [
  {
    name: "Dashboard",
    icon: <img src={dashboard} alt="Dashboard" />,
    start: true,
    url: "Dashboard",
  },
  {
    name: "Contacts",
    icon: <img src={contacts} alt="Contacts" />,
    url: "Contacts",
  },
  {
    name: "Leads",
    icon: <img src={leads} alt="Leads" />,
    url: "Leads",
  },
  {
    name: "Funnels",
    icon: <img src={funnels} alt="Funnels" />,
    active: true,
    url: "Funnels",
  },
  {
    name: "Live Panel",
    icon: <img src={panel} alt="Live Panel" />,
    url: "Live-Panel",
  },
  {
    name: "Analytics",
    icon: <img src={analytics} alt="Analytics" />,
    url: "Analytics",
    key_permission: "admin_analytics",
  },
  {
    name: "Smart Campaigns",
    icon: <img src={campaigns} alt="Smart Campaigns" />,
    url: "Smart-Campaigns",
    // prod: true,
  },
  {
    name: "Settings",
    icon: <img src={setting} alt="Settings" />,
    url: "",
    param: "My-Settings",
  },
];

const secondItemsMenu = [
  {
    name: "My Settings",
    icon: <img src={setting} alt="Devices" />,
    url: "My-Settings",
    style: { marginBottom: "-1rem" }
  },
  {
    isTitle: true,
    title: "Workspace Settings",
    validate: true,
    items: [
      {
        name: "General Settings",
        icon: <img src={settings} alt="General Settings" />,
        url: "General-Settings",
        key_permission: "admin_general_settings",
      },
      {
        name: "Billing",
        icon: (
          <img
            style={{ width: "20px", height: "20px" }}
            src={card}
            alt="Billing"
          />
        ),
        url: "Billing",
        prepaid: true,
      },
      {
        name: "Live Panel",
        icon: <img src={analytics} alt="Analytics" />,
        url: "Live-Panel-Settings",
        key_permission: "admin_live_panel",
      },
      {
        name: "API Keys",
        icon: <img src={key} alt="API Keys" />,
        url: "API-Keys",
        key_permission: "admin_api_keys",
      },
      {
        name: "Forms",
        icon: <img src={form} alt="Forms" />,
        url: "Forms",
        prod: true,
        key_permission: "admin_forms",
      },
      {
        name: "Webhooks",
        icon: <img src={webhook} alt="Webhooks" />,
        url: "Webhooks",
        key_permission: "admin_webhooks",
      },
      {
        name: "Phone Numbers",
        icon: <img src={phoneNumbers} alt="Phone Numbers" />,
        url: "Phone-Numbers",
        key_permission: "admin_phone_numbers",
      },
      {
        name: "Workflows",
        icon: <img src={workflow} alt="Workflows" />,
        url: "Workflows",
        prod: true,
        key_permission: "admin_workflows",
      },
      {
        name: "Advanced Call flows",
        icon: <img src={incomingCall} alt="Advanced Call flows" />,
        url: "Advanced-Call-flows",
        key_permission: "admin_advanced_call_flows",
      },
      {
        name: "10DLC",
        icon: <img src={dlcicon} alt="10DLC" />,
        url: "10dlc",
        // prod: true,
        key_permission: "admin_ten_dlc",
      },
      {
        name: "Troubleshooting",
        icon: (
          <img
            src={troubleshooting}
            alt="10DLC"
            style={{ width: "20px", height: "20px" }}
          />
        ),
        url: "Troubleshooting",
        key_permission: "admin_troubleshooting",
      },
      {
        name: "AI",
        icon: <img src={cpuCharge} alt="10DLC" />,
        url: "AI",
        //prod: true,
        key_permission: "admin_ai",
      },
      {
        name: "Users",
        icon: <img src={contacts} alt="Users" />,
        url: "Users",
        key_permission: "admin_users",
      },
    ],
  },
];

export { itemsMenu, secondItemsMenu };
