import petitionPatch from "../../../../../../services/petitionPatch";

const handleBlurTimeGroupNameService = async (timeGroupName, currentName, setCurrentName, pk) => {
  if (timeGroupName.name !== currentName.name) {
    try {
      await petitionPatch("timeGroup", { data: timeGroupName, time_id: pk })
      setCurrentName(timeGroupName)
    } catch (error) {
      console.log(error)
    }
  }
}

export default handleBlurTimeGroupNameService;