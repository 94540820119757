import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalInfo = ({ modal, closeModal, module, text }) => {
  return (
    <Modal isOpen={modal ? true : false} className="info">
      <ModalHeader>{module}</ModalHeader>
      <ModalBody>{text}</ModalBody>
      <ModalFooter>
        <button className="btn-light" onClick={closeModal}>
          OK
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalInfo;
