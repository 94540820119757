import "./ArrayInfo.css"

const ArrayInfo = ({ element, column }) => {
  const arr = element[column?.key] || []

  return arr?.map((element, i) => (
    <span key={i} className="array-info">
      {element?.name}{" "}{element?.icon}
    </span>
  ));
};

export default ArrayInfo;
