import petitionPut from "../../../../../../services/petitionPut";

const sendVoicemailService = async (selectedOptionVoicemail, inputRef, audioBlob, number) => {
  const formData = new FormData();

  if (selectedOptionVoicemail === "upload") {
    formData.append("file", inputRef.current.files[0]);
  } else if (selectedOptionVoicemail === "record") {
    const audioFile = new File([audioBlob], "audiofile.mp3", { type: "audio/mp3" });
    formData.append("file", audioFile);
  }

  await petitionPut("loadVoicemailNumber", { data: formData, number });
};

export default sendVoicemailService;
