const allAreTrue = (arr) => {
  return arr.every((element) => element === true);
};

const diabledAssignedMemberQueue = (selectedUsers, defaultUsers) => {
  if (selectedUsers.length === defaultUsers.length) {
    let iquals = [];
    selectedUsers.forEach((element) => {
      defaultUsers.forEach((elementTwo) => {
        if (element.pk === elementTwo.pk) iquals.push(true);
      });
    });

    if (iquals.length === selectedUsers.length && allAreTrue(iquals)) {
      return true;
    }

    return false;
  } else {
    if (selectedUsers.length === 0) return true;

    return false;
  }
};

export default diabledAssignedMemberQueue;
