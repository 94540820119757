import React, { useEffect, useState } from "react";
import petitionGet from "../../../../../../../services/petitionGet";
import Select from "react-select";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactTooltip from "react-tooltip";
import Skeleton from "react-loading-skeleton";
import "./Modules.css"

const Voicemails = ({ saveModule, edit, data, editModule, loadingCallFlowChart }) => {
  const [voicemails, setVoicemails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [valueVoicemail, setValueVoicemail] = useState(null);
  const [skipGreeting, setSkipGreeting] = useState(false)

  const saveVoicemail = () => {
    const sendData = {
      id: valueVoicemail.pk,
      timeout: "20",
      skip_greeting: skipGreeting,
    };

    if (edit) {
      editModule(sendData, "voicemail")
    } else {
      saveModule(sendData, "voicemail")
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("voicemail-v2")
      .then(({ data: result }) => {
        let voicemails = []

        result?.result?.sort((a, b) => a?.owner?.firstname?.localeCompare(b?.owner?.firstname))

        result?.result?.forEach((element) => {
          if (element.owner.lastname) {
            element.label = `${element?.owner?.firstname} ${element?.owner?.lastname || ""} (${element?.owner?.presence_id})`;
            element.value = element?.pk;
            voicemails.push(element)
          }
        });

        if (edit) {
          const defaultVoicemail = voicemails?.find((element) => element?.pk === data?.data?.id)
          setValueVoicemail(defaultVoicemail)
        }

        setVoicemails(voicemails);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (data) {
      petition()
      setSkipGreeting(data?.data?.skip_greeting || false)
    }
  }, [data]);

  return (
    <>
      <div className="itp-callFlow-container-module">
        <label className="itp-label-input__label mb-2">User Voicemail</label>
        {(loadingCallFlowChart || loading) ? (
          <div className="w-100">
            <Skeleton width="100%" height="2.5rem" />
          </div>
        ) : (
          <Select
            onChange={(e) => setValueVoicemail(e)}
            className="basic-single"
            classNamePrefix="select"
            value={valueVoicemail}
            isSearchable={true}
            name="queue"
            placeholder="Select a Voicemail"
            options={voicemails}
          />
        )}

        {!loading && (
          <div className="d-flex gap-2 mt-2 align-items-center">
            <div className="mt-2">
              <label className="itp-label-input__label mb-2" htmlFor="skip_greeting">Skip Greeting</label>
              <InfoOutlinedIcon
                data-tip
                data-for="text-skip-greeting"
                style={{ fontSize: "1.2rem" }}
              />
            </div>

            <ReactTooltip id="text-skip-greeting">
              <div className="text-left" style={{ fontSize: "12px" }}>
                When enabled, this will directly play a recording 
                 <br />
                tone and will not playing the greeting
              </div>
            </ReactTooltip>

            <input 
              type="checkbox"
              className="form-check-input skip-greeting"
              name="skip_greeting"
              id="skip_greeting"
              checked={skipGreeting}
              onChange={() => setSkipGreeting(!skipGreeting)}
            />
          </div>
        )}

        <button
          onClick={saveVoicemail}
          className="btn-primary mt-4"
          disabled={!valueVoicemail || loading}
        >
          Save
        </button>
      </div>
    </>
  );
};

export default Voicemails;
