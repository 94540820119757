import React from "react";
import { UserAvatar } from "../../../../../index";

const AvatarAndIcon = ({ element, config, column }) => {
  const avatar = config?.isAvatar;
  const firstname = element[config?.keyFirstname];
  const lastname = element[config?.keyLastname];
  const contacts = config?.contacts || false;
  const minHeight = config?.minHeight || false;
  const keyLastColor = config.keyLastColor;
  const lastColor = config?.getLastColor
    ? () => config?.getLastColor(element[keyLastColor])
    : () => {};

  const icon = config?.icon;
  const keyIcon = config?.keyIcon
  const iconFunction = () => config.iconFunction(element)

  const handleClickIcon = (event) => {
    event.stopPropagation();
    iconFunction()
  }

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex gap-1">
        {avatar && (
          <span>
            <UserAvatar
              firstname={firstname}
              lastname={lastname}
              contacts={contacts}
              minHeight={minHeight}
              tagColor={lastColor()}
              className="itp-chat-window__header-user-img"
            />
          </span>
        )}
        <span className="email-text d-flex align-items-center me-2">
          {element[column.key]}
        </span>
      </div>
      {icon && element[keyIcon] && element[column.key] && (
        <span
          onClick={handleClickIcon}
        >
          {element[keyIcon]}
        </span>
      )}
    </div>
  );
};

export default AvatarAndIcon;
