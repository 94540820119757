import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MiniCardInfo } from "../../../components/index";
import { myExtensionPetition } from "../../../services/myExtension.service";
import petitionGet from "../../../services/petitionGet";
import userMyExtension from "../../../assets/icons/userMyExtension.svg";
import notificationStatus from "../../../assets/icons/notificationStatus.svg";
import "./Dashboard.css";

function Dashboard() {
  const myExtension = useRef({});

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [voicemails, setVoicemails] = useState(0);

  const handleClickCards = () => {
    navigate("/My-Inbox?myInbox=true");
  };

  const petition = async () => {
    setLoading(true);

    await petitionGet("voicemailDetails", { parameter: "?pagination=false" })
      .then(({ data: result }) => {
        let totalVoicemails = 0;
        Object.keys(result.result).forEach((element) => {
          let voicemails = result.result[element];
          totalVoicemails = totalVoicemails + voicemails.length;
        });

        setVoicemails(totalVoicemails);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();

        if (data && Object.keys(data).length > 0) {
          myExtension.current = data;
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <>
      <div className="mt-4">
        <div className="d-flex gap-2 ms-2">
          <div onClick={handleClickCards} className="cursor-pointer">
            <MiniCardInfo
              imgSrc={userMyExtension}
              title="My Extension"
              info={Object.keys(myExtension.current).length > 0 ? myExtension.current.presence_id : ""}
              loading={loading}
            />
          </div>
          <div onClick={handleClickCards} className="cursor-pointer">
            <MiniCardInfo
              imgSrc={notificationStatus}
              title="Voicemails"
              info={voicemails}
              lastInfo="Total Voicemails"
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
