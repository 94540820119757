import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { Loading } from "../../../../components";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import petitionGet from "../../../../services/petitionGet";
import petitionPatch from "../../../../services/petitionPatch";
import EnableAI from "./EnableAI";
import Industry from "./Industry";
import SubIndustries from "./SubIndustries";
import General from "./General";
import FrequentlyAsked from "./FrequentlyAsked";
import SalesLead from "./SalesLead";
import FollowUps from "./FollowUps";
import "./AI.css";

const AI = () => {
  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const [loading, setLoading] = useState(true);
  const [switchAI, setSwitchAI] = useState(false);
  const [industryValue, setIndustryValue] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [subIndustries, setSubIndustries] = useState([]);
  const [subIndustrySelectedIds, setSubIndustrySelectedIds] = useState([]);
  const [currentChecks, setCurrentChecks] = useState({});
  const [loadingChangeIndustry, setLoadingChangeIndustry] = useState(false);
  const [loadingChangeSwitchAI, setLoadingChangeSwitchAI] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [companyValues, setCompanyValues] = useState("");
  const [accountDetails, setAccountDetails] = useState({});
  const [tagValue, setTagValue] = useState(null);

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const petitionTag = async (tagId) => {
    petitionGet("tags", { parameter: `/${tagId}` })
      .then(({ data: result }) => {
        result.result.label = result.result.name;
        result.result.value = result.result.pk;

        setTagValue(result.result);
      })
      .catch((error) => console.log(error));
  };

  const petition = (noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("aiDetails")
      .then(async ({ data: result }) => {
        setAccountDetails(result?.result);
        //preguntamos si existe result.result
        if (result?.result && result?.result?.ai_enabled) {
          if (result?.result?.no_response_tag_id) {
            await petitionTag(result?.result?.no_response_tag_id);
          }

          //Aquí en subIndustryIdsTemp vamos a guardar los pk de los sub industries seleccionados
          let subIndustryIdsTemp = [];

          setCompanyValues(result?.result?.company_values || "");

          //si result.result.ai_enabled es true se activa el switch principal de AI
          if (result.result.ai_enabled) setSwitchAI(true);

          //si el campo sub_industries existe y ademas el camopo result?.result?.sub_industries es
          //un array que no esté vacio, se almacena dentro de subIndustryIdsTemp los pk y luego
          //se actualiza la variable setSubIndustrySelectedIds(subIndustryIdsTemp);
          if (
            result?.result?.sub_industries &&
            result?.result?.sub_industries.length
          ) {
            subIndustryIdsTemp = result?.result?.sub_industries.map(
              (element) => element.pk
            );
            setSubIndustrySelectedIds(subIndustryIdsTemp);
          }

          await petitionGet("aiIndustries")
            .then(({ data: response }) => {
              //aquí agregamos los campos de label y value a cada objeto de un industry
              //esto se hace para poder pasar este array y el componente Select los pueda
              //leer como opciones
              response.result.forEach((element) => {
                element.label = element?.industry_name;
                element.value = element?.pk;
              });

              //aquí con el metodo .find buscamos si dentro de la lista de industries
              //encontramos uno que tenga un pk igual al campo result?.result?.industry_id
              //que viene dentro del endpoint aiDetails
              const findDefaultIndustry = response.result.find(
                (element) => element?.pk === result?.result?.industry_id
              );

              if (findDefaultIndustry) {
                //como si encontramos un industry elegido lo cargamos a la variable industryValue
                setIndustryValue(findDefaultIndustry);

                //luego hacemos una petición para conocer los sub industries que hay en el
                //industry elegido para eso usamos el pk del industry
                petitionGet("aiIndustryDetails", {
                  industry_id: findDefaultIndustry?.pk,
                })
                  .then(({ data: result }) => {
                    //aquí preguntamos si el campo result?.result?.sub_industries existe y si también
                    //es un array que no esté vacío
                    if (
                      result?.result?.sub_industries &&
                      result?.result?.sub_industries.length > 0
                    ) {
                      //aqui cargamos los sub industries que se van a mostrar para poder elegirlos
                      setSubIndustries(result.result.sub_industries);

                      //aquí cargamos el nombre del sub industry y su valor booleano
                      let subIndustryCheck = {};

                      //aquí recorremos los sub industries y dentro del objeto subIndustryCheck colocamos
                      //como key el nombre del sub industry y su valor reulta de la operación .includes
                      //que lo que hace es preguntar que si dentro subIndustryIdsTemp está incluido el
                      //pk del sub industry que se recorre y devuelve true o false, recordemos que
                      //subIndustryIdsTemp se carga arriba con los pk de los subIndustries que están
                      //elegidos
                      result.result.sub_industries.forEach((element) => {
                        subIndustryCheck[element?.name] =
                          subIndustryIdsTemp.includes(element?.pk);
                      });

                      //luego cargamos currenChecks con subIndustryCheck
                      setCurrentChecks(subIndustryCheck);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setLoadingChangeSwitchAI(false);
                  });
              }

              //aqui actualizamos a industries, estas son las opciones que se pasan al select dropdown
              //autocomplete para elegir el Industry
              setIndustries(response.result);
              setLoading(false);
              setLoadingChangeSwitchAI(false);
              setFirstRender(false);
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              setFirstRender(false);
            });
        } else {
          setFirstRender(false);
          setLoading(false);
          setLoadingChangeSwitchAI(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setLoadingChangeSwitchAI(false);
        setFirstRender(false);
      });
  };

  useEffect(() => {
    if (!firstRender) {
      const timer = setTimeout(() => {
        let data = {
          company_values: companyValues,
        };

        petitionPatch("aiDetailsUpdate", { data })
          .then(({ data: result }) => {})
          .catch((error) => console.log(error));
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [companyValues]);

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_ai
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Puedes ajustar el valor según sea necesario
    }),
  };

  return (
    <div style={{ marginTop: "3rem" }}>
      <EnableAI
        switchAI={switchAI}
        setSwitchAI={setSwitchAI}
        industryValue={industryValue}
        setIndustryValue={setIndustryValue}
        currentChecks={currentChecks}
        setCurrentChecks={setCurrentChecks}
        subIndustrySelectedIds={subIndustrySelectedIds}
        setSubIndustrySelectedIds={setSubIndustrySelectedIds}
        subIndustries={subIndustries}
        setSubIndustries={setSubIndustries}
        industries={industries}
        setIndustries={setIndustries}
        setLoadingChangeSwitchAI={setLoadingChangeSwitchAI}
        petition={petition}
        loading={loading}
      />

      {switchAI && (
        <>
          <Industry
            industryValue={industryValue}
            setIndustryValue={setIndustryValue}
            industries={industries}
            customStyles={customStyles}
            setLoadingChangeIndustry={setLoadingChangeIndustry}
            currentChecks={currentChecks}
            setCurrentChecks={setCurrentChecks}
            subIndustries={subIndustries}
            setSubIndustries={setSubIndustries}
            subIndustrySelectedIds={subIndustrySelectedIds}
            setSubIndustrySelectedIds={setSubIndustrySelectedIds}
            loading={loading || loadingChangeSwitchAI}
          />

          <SubIndustries
            subIndustries={subIndustries}
            currentChecks={currentChecks}
            setCurrentChecks={setCurrentChecks}
            subIndustrySelectedIds={subIndustrySelectedIds}
            setSubIndustrySelectedIds={setSubIndustrySelectedIds}
            loading={loading || loadingChangeSwitchAI || loadingChangeIndustry}
          />

          <General
            companyValues={companyValues}
            setCompanyValues={setCompanyValues}
            loading={loading || loadingChangeSwitchAI}
          />

          <FrequentlyAsked 
            accountDetails={accountDetails} 
            loading={loading || loadingChangeSwitchAI}
          />

          <SalesLead 
            accountDetails={accountDetails} 
            loading={loading || loadingChangeSwitchAI}
          />

          <FollowUps
            customStyles={customStyles}
            tagValue={tagValue}
            setTagValue={setTagValue}
            loading={loading || loadingChangeSwitchAI}
          />
        </>
      )}
  </div>
  );
};

export default AI;
