const checkIfAnyIsNotContact = (chatList, selectedItems) => {
  const selectedPks = Array.from(selectedItems)

  // We filter the chats whose pk is in the selected array
  const selectedChats = chatList.filter((chat) => selectedPks.includes(chat.pk));

  // We check if at least one of the selected chats has isContact as false value
  return selectedChats.some((chat) => !chat.isContact);
}

export default checkIfAnyIsNotContact;