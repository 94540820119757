/* global google */
import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useNavigate } from "react-router";
import { LoadingButton } from "../../../../components";
import usePlacesAutocomplete from "use-places-autocomplete";
import "../SignUp.css";

const Step4 = ({ nextStep, alreadyExist, loading }) => {
  const navigate = useNavigate();

  const [showListOptions, setShowListOptions] = useState(false);
  const [form, setForm] = useState({
    first_name: "",
    full_address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    last_name: "",
    suite_num:"",
    number_of_users: 10,
    account_type: "residential",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });

  const backToLogin = () => {
    navigate("/login");
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm({ ...form, [name]: value });
  };

  const handleInput = (e) => {
    setShowListOptions(true);
    setValue(e.target.value);
  };

  const selectedOption = ({ place_id, description }) => {
    setShowListOptions(false);
    const map = new google.maps.Map(document.createElement("div"));
    const service = new google.maps.places.PlacesService(map);

    service.getDetails(
      {
        placeId: place_id,
        language: "en",
      },
      (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const streetNumber = place.address_components.find(
            (element) => element?.types[0] === "street_number"
          );
          const address = place.address_components.find(
            (element) => element?.types[0] === "route"
          );
          const city = place.address_components.find(
            (element) => element?.types[0] === "locality"
          );
          const administrativeCity = place.address_components.find(
            (element) => element?.types[0] === "administrative_area_level_2"
          );
          const state = place.address_components.find(
            (element) => element?.types[0] === "administrative_area_level_1"
          );
          const country = place.address_components.find(
            (element) => element?.types[0] === "country"
          );
          const postalCode = place.address_components.find(
            (element) => element?.types[0] === "postal_code"
          );

          const inputAddress = `${
            streetNumber ? `${streetNumber.long_name} ` : ""
          }${address ? address.long_name : ""}`;

          const inputCountry = country?.short_name;
          const inputState = state?.short_name;
          const inputCity = city
            ? city?.short_name
            : administrativeCity?.short_name;
          const inputZipCode = postalCode ? postalCode?.short_name : "";

          setValue(inputAddress);
          setForm({
            ...form,
            full_address: inputAddress,
            country: inputCountry,
            state: inputState,
            city: inputCity,
            zip: inputZipCode,
          });
          clearSuggestions();
        }
      }
    );
  };

  const submitForm = (e) => {
    e.preventDefault();
    nextStep(form);
  };

  return (
    <div className="itp-signUp">
      <Card className="itp-signUp__card">
        <CardBody>
          {alreadyExist ? (
            <>
              <p className="text-danger text-center already-account">
                An account already exists with the e-mail address entered.
              </p>

              <button
                onClick={backToLogin}
                className="btn-primary btn-primary-signup loading"
              >
                Back To Login
              </button>
            </>
          ) : (
            <>
              <h1 className="itp-signUp__card-body__title">
                Enter your password
              </h1>

              <p className="itp-signUp__card-body__textInfo">
                Enter a password for your account to login
              </p>

              <form
                onSubmit={submitForm}
                className="itp-signUp__card-body__form-container"
              >
                <label htmlFor="first_name">
                  <span></span>
                  <input
                    className="itp-signUp__card-body__form__input"
                    name="first_name"
                    id="first_name"
                    placeholder="Name or Company Name"
                    type="text"
                    autoComplete="name"
                    required
                    value={form.first_name}
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out this field.")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    onChange={handleChangeForm}
                  />
                </label>

                <label htmlFor="full_address">
                  <span></span>
                  <input
                    className="itp-signUp__card-body__form__input"
                    name="full_address"
                    id="full_address"
                    placeholder="Address"
                    type="text"
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out this field.")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    value={value}
                    onChange={handleInput}
                  />
                  <ul
                    className={`p-0 list ${
                      showListOptions && data.length > 0 ? "" : "d-none"
                    }`}
                  >
                    {status === "OK" &&
                      data.map((suggestion) => (
                        <li
                          className="list__options"
                          key={suggestion.place_id}
                          onClick={() => selectedOption(suggestion)}
                        >
                          {suggestion.description}
                        </li>
                      ))}
                  </ul>
                </label>

                <label htmlFor="suite_num">
                  <span></span>
                  <input
                    value={form.suite_num}
                    className="itp-signUp__card-body__form__input"
                    name="suite_num"
                    id="suite_num"
                    placeholder="Suite/APT"
                    type="text"
                    autoComplete="Suite/APT"
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out this field.")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    onChange={handleChangeForm}
                  />
                </label>

                <label htmlFor="city">
                  <span></span>
                  <input
                    value={form.city}
                    className="itp-signUp__card-body__form__input"
                    name="city"
                    id="city"
                    placeholder="City"
                    type="text"
                    autoComplete="city"
                    required
                    disabled
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please fill out this field.")
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                    onChange={handleChangeForm}
                  />
                </label>

                <div className="d-flex gap-2">
                  <label className="position-relative" htmlFor="state">
                    <span></span>
                    <input
                      value={form.state}
                      className="itp-signUp__card-body__form__input"
                      name="state"
                      id="state"
                      placeholder="State"
                      type="text"
                      required
                      disabled
                      autoComplete="state"
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please fill out this field."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      onChange={handleChangeForm}
                    />
                  </label>

                  <label htmlFor="zip">
                    <span></span>
                    <input
                      value={form.zip}
                      className="itp-signUp__card-body__form__input"
                      name="zip"
                      id="zip"
                      placeholder="Zip code"
                      type="text"
                      autoComplete="postal-code"
                      required
                      disabled
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "Please fill out this field."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      onChange={handleChangeForm}
                    />
                  </label>
                </div>

                <button
                  type="submit"
                  className="btn-primary btn-primary-signup loading"
                  disabled={loading}
                >
                  {loading && <LoadingButton />}
                  Create my account
                </button>
              </form>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Step4;
