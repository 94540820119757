import { Col, Row } from "reactstrap";
import { AddButton, CollapsePanel, CustomTableMain } from "../../../../../../components";

const TimeGroups = ({ dataTableTimeGroups, activeAddTimeGroup, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Time Groups for flows"
      subtitle="Create & manage team groups for your advanced call flow"
    >
      <CustomTableMain
        data={dataTableTimeGroups}
        loading={loading}
        m0={true}
        module="time groups"
        actionsRight={[
          {
            component: (
              <AddButton
                handleClick={activeAddTimeGroup}
                name="Add Time group"
              />
            ),
          },
        ]}
      />
    </CollapsePanel>
  </>
);

export default TimeGroups;
