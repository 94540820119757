const DetectLinks = ({ element, chatWindowData }) => {
  const isOtherContainer = element.message_participant === chatWindowData.numberChat ? true : false
  const classContainerLink = `itp-chat-window-content__message${isOtherContainer ? "" : "_you"}`
  const show = element.message_body && element.item_type !== "call" && !element.audio_message ? true : false

  const detectLinks = (message) => {
    const linkPattern = /(https?:\/\/[^\s]+)/g;
    const parts = message.split(linkPattern);

    return parts.map((part, index) =>
      part.match(linkPattern) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  if (show) {
    return (
      <p className={classContainerLink}>
        {detectLinks(element.message_body)}
      </p>
    );
  }
};

export default DetectLinks;
