import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomTableMain, AlignedRow } from "../../../../components";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import petitionGet from "../../../../services/petitionGet";
import getQueryString from "../../../../utils/getQueryString";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";

const ViewLogs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get('pk');
  const name = searchParams.get('name');

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();
  const navigate = useNavigate()

  const filterStatus = useRef("");
  const itemsPerPage = useRef("10");
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const [loading, setLoading] = useState(true);
  const [allResult, setAllResult] = useState(null);
  const [valueLog, setValueLog] = useState("");
  const [loadingPagItem, setLoadingPagItem] = useState(false);
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Task Status",  key: "formatStatus" },
      { name: "Task Priority", key: "task_priority" },
      { name: "Max Retries", key: "max_retries" },
      { name: "Recipient Number", key: "formatedNumber" },
      { name: "Schedule Start Time", key: "schedule_start_time" },
      { name: "Schedule End Time", key: "schedule_end_time" },
      { name: "Created At", key: "created_at" },
      { name: "Updated At", key: "updated_at" },
      { name: "Time Zone", key: "time_zone" },
    ],
  });

  const formatText = (text) => {
    switch (text) {
      case "sms":
        return "SMS Campaign";
      case "in_progress":
        return "In Progress";
      case "pending":
        return "Pending";
      case "completed":
        return "Completed";
      case "paused":
        return "Paused";
      case "starting":
        return "Starting";
      case "delivered":
        return "Delivered";
      case "dnd_sms":
        return "DND SMS";
      default:
        return text;
    }
  };

  const changePage = (offset) => {
    setLoadingPagItem(true)

    const paginationTemp = {
      offset,
      limit: pagination.current.limit,
    };

    let parameter = getQueryString(paginationTemp) + filterStatus.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
  };

  const activeItemsPerPage = (data) => {
    setLoadingPagItem(true)

    const paginationTemp = {
      offset: 0,
      limit: data,
    };

    const parameter = getQueryString(paginationTemp) + filterStatus.current;

    petition(parameter, true);
    pagination.current = paginationTemp;
  }

  const returnTextHover = (status) => {
    switch(status) {
      case "dnd_sms":
        return "Contact Opted Out, no message was sent"
      default:
        return "";
    }
  }

  const onChangeLogs = (e) => {
    if (loading || loadingPagItem) return;

    setValueLog(e.target.value);

    setLoading(true);

    const paginationTemp = {
      offset: 0,
      limit: 10,
    };

    const status = e.target.value !== "" ? `&status=${e.target.value}` : "";

    const parameter = getQueryString(paginationTemp) + status;

    petition(parameter, true);
    filterStatus.current = status;
    pagination.current = paginationTemp;
  };

  const petition = (parameter, noLoading) => {
    if (!noLoading) setLoading(true);

    petitionGet("tasksSmartCampaign", {
      campaign_id: pk,
      parameter,
    })
      .then(({ data: result }) => {
        setAllResult(result);
        result.result.forEach((element) => {
          element.formatStatus = formatText(element.status);
          element.keyHover = returnTextHover(element.status)
          element.formatedNumber = formatPhoneNumber(element.recipient_number)
        });

        setDataTable({
          columns: [
            { name: "Task Status",  key: "formatStatus" },
            { name: "Task Priority", key: "task_priority" },
            { name: "Max Retries", key: "max_retries" },
            { name: "Recipient Number", key: "formatedNumber" },
            { name: "Schedule Start Time", key: "schedule_start_time" },
            { name: "Schedule End Time", key: "schedule_end_time" },
            { name: "Created At", key: "created_at" },
            { name: "Updated At", key: "updated_at" },
            { name: "Time Zone", key: "time_zone" },
          ],
          content: [...result.result],
        });

        setLoading(false);
        setLoadingPagItem(false)
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (!pk || !name) {
      navigate("/Smart-Campaigns")
    } else {
      setDynamicFunction(() => () => {
        navigate("/Smart-Campaigns")
      });
      setIsArrowVisible(true);

      petition(getQueryString(pagination.current));
    }
  }, []);

  return (
    <div style={{ marginTop: "3rem" }}>
      <AlignedRow m1={true}>
        <h3 className="itp-accordion-header-title">
          {name}
        </h3>
      </AlignedRow>

      <CustomTableMain 
        data={dataTable}
        loading={loading}
        loadingPagItems={loadingPagItem}
        module="logs"
        isBackend={true}
        totalPages={allResult?.total_pages}
        onPageChange={changePage}
        offset={pagination.current.offset}
        showSelectSingleCheckbox={true}
        onChangeItemsPerPage={activeItemsPerPage}
        paginationData={parseInt(itemsPerPage.current)}
        noDataMessage={valueLog && !allResult.result.length ? "No results found" : ""}
        columnConfig={{
          "Task Status": {
            type: "hoverInfo",
            hoverKey: "keyHover",
          },
          "Created At": {
            type: "date",
            spliter: "T",
          },
          "Updated At": {
            type: "date",
            spliter: "T",
          },
        }}
        actionsLeft={[
          {
            component: (
              <select
                onChange={onChangeLogs}
                value={valueLog}
                disabled={loading || loadingPagItem}
                className="form-select-itp itp-call-logs-select"
              >
                <option value="">All Logs</option>
                <option value="pending">Pending</option>
                <option value="delivered">Delivered</option>
                <option value="failed">Failed</option>
              </select>
            )
          }
        ]}
      />
    </div>
  )
};

export default ViewLogs;
