const formatTime = (seconds) => {
  let hour = Math.floor(seconds / 3600);
  hour = hour < 10 ? `0${hour}h` : `${hour}h`;

  let minute = Math.floor((seconds / 60) % 60);
  minute = minute < 10 ? `0${minute}m` : `${minute}m`;

  let second = (seconds % 60).toFixed(2); // Redondea a dos decimales
  second = second < 10 ? `0${second}s` : `${second}s`;

  return `${hour} ${minute} ${second}`;
};

export default formatTime;
