import { useRef, useState } from "react";
import { CallLog, ContactCard, DetectLinks, HoverOptions, Image, MessageDate, PickerIcons, RecordedCall } from "../index";
import json from "../../../../../config.json";
import Skeleton from "react-loading-skeleton";

const MessageItem = ({ 
  index, 
  element, 
  chatWindowData, 
  messages, 
  noHover,
  activeModalDelete,
  download,
  loadingPage,
  loadAudio,
}) => {
  const api_id = JSON.parse(localStorage.getItem("userDetails")).api_id;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;

  const scrollContainerRef = useRef(null);

  const trueData = element?.message_participant && chatWindowData?.numberChat ? true : false
  const isEqual = element?.message_participant === chatWindowData?.numberChat ? true : false
  const isOther = (element?.other || (trueData && isEqual)) ? true :  false

  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [openPicker, setOpenPicker] = useState(null);
  const [openDropdownMessage, setOpenDropdownMessage] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(null);

  const selectMessageError = (item) => {
    if (item?.message_error_code === 4750) {
      return "AT&T has rejected the message. This could be due to being identified as Spam or the user blocking the number."
    } else if (item?.message_error_description) {
      return item?.message_error_description
    } else {
      return "Message Failed to be delivered"
    }
  }

  const handleHover = (event) => {
    const iconPosition = event.currentTarget.getBoundingClientRect();

    const top = iconPosition.top - 30;

    // Actualizar el estado
    setHoverPosition({ top });
  };

  const handleLeave = () => {
    // Restablecer la posición cuando el cursor deja el ícono
    setHoverPosition({ top: 0, left: 0 });
  };

  const toggleMessage = (event, index) => {
    event.stopPropagation();
    setOpenDropdownMessage(index === openDropdownMessage ? null : index);
  };

  const handleClick = (src) => {
    setShowFullScreen(src);
  };

  const handleClose = () => {
    setShowFullScreen(null);
  };

  const handleCopyEmail = (event, messageBody) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(messageBody)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };
  const handleMouseLive = (index) => {
    if (index === openDropdownMessage) {
      setOpenDropdownMessage(false)
    }
  }

  return (
    <div
      id={`message-${index}`}
      className={`itp-chat-window-content__${isOther ? "other" : "you"}-message-container`}
      onMouseLeave={() => handleMouseLive(index)}
    >
      {!isOther && !loadingPage && (
        <HoverOptions 
          noHover={noHover} 
          element={element} 
          isOther={isOther} 
          openPicker={openPicker} 
          setOpenPicker={setOpenPicker} 
          chatWindowData={chatWindowData} 
          index={index} 
          selectMessageError={selectMessageError} 
          openDropdownMessage={openDropdownMessage}
          toggleMessage={toggleMessage}
          activeModalDelete={activeModalDelete}
          download={download}
          handleCopyEmail={handleCopyEmail}
        />
      )}

      {isOther && !loadingPage && (
        <PickerIcons 
          openPicker={openPicker}
          setOpenPicker={setOpenPicker}
          element={element}
          chatWindowData={chatWindowData}
          index={index}
        />
      )}

      {!isOther && !loadingPage && element.message_status === "failed" && (
        <div style={{ top: hoverPosition.top }} className="itp-chat-window-content__container-message-falied">
          <span className="itp-bulk-icon-name">
            {selectMessageError(element)}
          </span>
        </div>
      )}

      <div className={`itp-chat-window-content__${isOther ? "other" : "you"}-message-content ${element?.isRecording ? "recording" : ""} ${loadingPage ? "loading" : ""} ${index === messages.length - 1 ? "mb-4" : ""}`}>
        {!loadingPage ? (
          <>
            <DetectLinks 
              element={element} 
              chatWindowData={chatWindowData} 
            />

            <Image 
              element={element}
              urlBase={urlBase}
              api_id={api_id}
              token={token}
              handleClick={handleClick}
              showFullScreen={showFullScreen}
              handleClose={handleClose}
            />

            <ContactCard 
              urlBase={urlBase}
              api_id={api_id}
              element={element}
              token={token}
            />

            <CallLog element={element} />

            <RecordedCall element={element} chatWindowData={chatWindowData} />

            <MessageDate 
              element={element} 
              isOther={isOther}
              scrollContainerRef={scrollContainerRef}
              handleHover={handleHover}
              handleLeave={handleLeave}
            />
          </>
        )  :(
          <Skeleton height={loadAudio ? 100 : 50} width={loadAudio ? 250 : 200} />
        )}
      </div>

      {isOther && !loadingPage && (
        <HoverOptions 
          noHover={noHover} 
          element={element} 
          isOther={isOther} 
          openPicker={openPicker} 
          setOpenPicker={setOpenPicker} 
          chatWindowData={chatWindowData} 
          index={index} 
          selectMessageError={selectMessageError} 
          openDropdownMessage={openDropdownMessage}
          toggleMessage={toggleMessage}
          activeModalDelete={activeModalDelete}
          download={download}
          handleCopyEmail={handleCopyEmail}
        />
      )}
    </div>
  )
}

export default MessageItem;