const LoadMessages = ({ loading, error }) => (
  <>
    {loading && (
      <div className="itp-chat-window__container-text-loading">
        {loading && (
          <span className="itp-chat-window__text-loading">Loading...</span>
        )}
        {error && <p className="text-danger">{error}</p>}
      </div>
    )}
  </>
);

export default LoadMessages;
