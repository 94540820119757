import petitionPatch from "../../../../services/petitionPatch";

const editNoteService = async (note_id, setLoadingNote, contact_id, noteContent, noShowEditNote, getNotes) => {
  try {
    setLoadingNote(true);

    const data = {
      contact_id,
      note_message: noteContent,
    };

    await petitionPatch("editNote", {note_id, data })

    noShowEditNote();
    
    await getNotes();
  } catch (error) {
    console.log(error)
  }
}

export default editNoteService;