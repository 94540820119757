import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { MenuRight, LoadingButton, CustomTableMain, AddButton, AlignedRow, CollapsePanel, AlertNotificactions } from "../../../../../../components";
import { useDynamicFunction } from "../../../../../../contexts/DynamicFunctionContext";
import { defaultQueueForm } from "../../const";
import { editQueueBlurService, fetchQueueDetails } from "../../services";
import { diabledAssignedMemberQueue } from "../../utils";
import Switch from "react-switch";
import Select from "react-select";
import petitionGet from "../../../../../../services/petitionGet";
import petitionPost from "../../../../../../services/petitionPost";
import petitionPatch from "../../../../../../services/petitionPatch";
import petitionDelete from "../../../../../../services/petitionDelete";
import ModalDelete from "../ModalUtitlity/ModalDelete";
import "./QueueDetails.css";
import Skeleton from "react-loading-skeleton";

const QueueDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get("pk");

  const { setDynamicFunction } = useDynamicFunction();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [info, setInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [openMenu, setOpenMenu] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [form, setForm] = useState(defaultQueueForm);
  const [currentForm, setCurrentForm] = useState(form)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState()
  const [alertMessage, setAlertMessage] = useState("Unable to perform action Listen on this call")
  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "label" },
      { name: "Extension Number", key: "presence_id" },
    ],
    actions: { title: "Actions", content: [] }
  });

  const disabledButton = () => {
    return diabledAssignedMemberQueue(selectedUsers, defaultUsers)
  };

  const closeModal = () => {
    setSelectedUsers(defaultUsers);
    setOpenMenu(false);
    setInfo(null);
    setModalDelete(false);
  };

  const activeDeleteModal = (e) => {
    setInfo(e);
    setModalDelete("other");
  };

  const activeAddUserQueue = () => {
    if (loading) return;
    setOpenMenu(true);
  };

  const onChangeForm = async (e) => {
    const { name, value } = e.target
    if (name === "wrapuptime" || name === "timeout" || name === "queue_timeout" || name === "retry") {

      const newValue = /^\d+$/.test(value);
      if (newValue) setForm({ ...form, [name]: value });

    } else {
      setForm({ ...form, [name]: value });

      if (name === "strategy") {
        const formTemp = { ...form, strategy: value }
        await editQueueBlurService("strategy", formTemp, setForm, currentForm, setCurrentForm, pk, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
      }
    }
  };

  const onChangeSwitchs = (name) => {
    let data = {};

    if (name === "queue_args") {
      setForm({
        ...form,
        queue_args: form.queue_args === "r" ? "" : "r",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        queue_args: form.queue_args === "r" ? "" : "r",
      };
    } else if (name === "skip_busy_agents") {
      setForm({
        ...form,
        skip_busy_agents: !form.skip_busy_agents,
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        skip_busy_agents: !form.skip_busy_agents,
      };
    } else {
      setForm({
        ...form,
        [name]: form[name] === "yes" ? "no" : "yes",
      });

      data = {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        [name]: form[name] === "yes" ? "no" : "yes",
      };
    }

    petitionPatch("queue", { queue_id: pk, data })
      .then(({ data: result }) => {})
      .catch((error) => console.log(error));
  };

  const onChangeUsers = (e) => {
    setSelectedUsers(e);
  };

  const deleteUser = () => {
    setLoadingUser(true);
    petitionDelete("queueMember", {
      queue_id: pk,
      member_id: info.queueMemberId,
    })
      .then(({ data: result }) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const addUsers = () => {
    setLoadingUser(true);

    let petitionMembers = [];

    defaultUsers.forEach((element) => {
      const dataFind = selectedUsers.find(
        (elementTwo) => elementTwo.pk === element.pk
      );

      if (!dataFind) {
        petitionMembers.push(
          petitionDelete("queueMember", {
            queue_id: pk,
            member_id: element.queueMemberId,
          })
        );
      }
    });

    selectedUsers.forEach((element) => {
      const dataFind = defaultUsers.find(
        (elementTwo) => elementTwo.pk === element.pk
      );

      if (!dataFind) {
        const data = {
          user_id: element.pk,
          penalty: "0",
          wrapuptime: "1",
          paused: "0",
        };

        petitionMembers.push(
          petitionPost("queueMember", { queue_id: pk, data })
        );
      }
    });

    Promise.all(petitionMembers)
      .then((result) => {
        setLoadingUser(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  const reloadData = () => {
    closeModal();
    petition();
  };

  const handleBlurInputs = async (key) => {
    await editQueueBlurService(key, form, setForm, currentForm, setCurrentForm, pk, setShowErrorTime, setAlertTitle, setAlertType, setAlertMessage)
  }

  const petition = async () => {
    setLoading(true);

    let usersTemp = [];

    await petitionGet("itpvoiceUsers")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element?.lastname || ""}`;
          element.value = element.pk;
        });

        usersTemp = result.result;
        setUsers(usersTemp);
      })
      .catch((error) => console.log(error));

    await petitionGet("queueMembers", { queue_id: pk })
      .then(({ data: result }) => {
        let listMembers = [];

        result.result.forEach((element) => {
          const findUser = usersTemp.find(
            (user) => user.pk === element.user_id
          );

          if (findUser) {
            findUser.queueMemberId = element.pk;
            listMembers.push(findUser);
          }
        });

        setDefaultUsers(listMembers);
        setSelectedUsers(listMembers);

        const tableData = {
          columns: [
            { name: "Name", key: "label" },
            { name: "Extension Number", key: "presence_id" },
          ],
          content: [...listMembers],
          actions: {
            title: "Actions",
            content: [
              {
                handleClick: activeDeleteModal,
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Remove"
              }
            ]
          },
        };

        setDataTable(tableData);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const getData = async () => {
    await fetchQueueDetails(pk, setForm, setCurrentForm, petition)
  }

  useEffect(() => {
    if (!pk) {
      navigate("/Advanced-Call-flows");
    } else {
      setDynamicFunction(() => () => {
        navigate("/Advanced-Call-flows");
      });
  
      getData();
    }
  }, []);

  return (
    <div className="mt-5">
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeModal}
          loading={loadingUser}
          modalFunction={deleteUser}
          module="User"
        />
      )}

      <AlertNotificactions 
        type={alertType}
        alertMessage={alertMessage}
        showTime={showErrorTime}
        title={alertTitle}
      />

      <AlignedRow>
        <h6 className="itp-accordion-header-subtitle">
          Queue Details : Manage settings for this queue
        </h6>
      </AlignedRow>

      <AlignedRow>
        <h3 className="itp-accordion-header-title">
          Queue : {!loading ? currentForm.queue_name : <Skeleton height="1rem" width="8rem" />}
        </h3>
      </AlignedRow>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Basic Settings"
        subtitle="Configure the queue name and description"
      >
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Name</label>
            <label className="itp-edit-number-label-2">Change the name</label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              placeholder="Add Name"
              className="form-control-itp itp-queue-details-input"
              name="queue_name"
              value={form?.queue_name || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("queue_name")}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div className="d-flex flex-column">
            <label className="itp-edit-number-label-1">Description</label>
            <label className="itp-edit-number-label-2">
              Description related to this queue
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              placeholder="Add Description"
              className="form-control-itp itp-queue-details-input"
              name="queue_description"
              value={form?.queue_description || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("queue_description")}
            />
          )}
        </div>
      </CollapsePanel>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Members"
        subtitle="Add or remove members from a queue"
      >
        <CustomTableMain 
          data={dataTable}
          loading={loading}
          m0={true}
          module="queue members"
          actionsRight={[
            {
              component: (
                <AddButton 
                  handleClick={activeAddUserQueue}
                  name="Add Member"
                />
              )
            }
          ]}
        />
      </CollapsePanel>

      <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
      <br></br>

      <CollapsePanel
        title="Advanced Settings"
        subtitle="Configure advanced options for calling queue members"
        className="mb-4"
      >
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Member Timeout</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Number of seconds to ring Member until pausing for the amount of
              seconds set in the "Retry Seconds" field
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input mt-3">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              type="number"
              placeholder="change timeout"
              className="form-control-itp itp-queue-details-input mt-3"
              name="timeout"
              value={form?.timeout || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("timeout")}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Retry Member In</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Number of seconds to wait before attempting to call a member again
              after the Member Timeout was reached
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input mt-3">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              type="number"
              placeholder="Change retry member in"
              className="form-control-itp itp-queue-details-input mt-3"
              name="retry"
              value={form?.retry || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("retry")}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Queue Timeout</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Maximum number of seconds to place a caller on hold before exiting
              the Queue.<span className="v-hidden">in the "Retry Seconds" field</span>
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input mt-3">
              <Skeleton width="100%" height="2rem"/>
            </div>
          ) : (
            <input
              type="number"
              placeholder="Change Queue Timeout"
              className="form-control-itp itp-queue-details-input mt-3"
              name="queue_timeout"
              value={form?.queue_timeout || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("queue_timeout")}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Strategy</label>
            <label className="itp-edit-number-label-2 v-hidden">
              aaaaaaa bbbbbb cc ddddddd ee fffff g hhhhhh ii jjjj kkkkkk lllllll
              mmm nnnnnn<span>oo ppp qqqqqq rrrrrrrr sssss</span>
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input ">
              <Skeleton width="100%" height="2.5rem"/>
            </div>
          ) : (
            <select
              className="form-select-itp itp-queue-details-input"
              name="strategy"
              value={form?.strategy || ""}
              onChange={onChangeForm}
            >
              <option value="" disabled>
                Select Option
              </option>
              <option value="ringall">Ring all</option>
              <option value="random">Random</option>
              <option value="linear">Linear</option>
            </select>
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Wrap up Time</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Number of seconds to wait before attempting to redial a member
              after the Member Timeout was reached.
            </label>
          </div>

          {loading ? (
            <div className="itp-queue-details-input ">
              <Skeleton width="100%" height="2.5rem"/>
            </div>
          ) : (
            <input
              type="number"
              placeholder="Add Seconds"
              className="form-control-itp itp-queue-details-input mt-3"
              name="wrapuptime"
              value={form?.wrapuptime || ""}
              onChange={onChangeForm}
              onBlur={() => handleBlurInputs("wrapuptime")}
            />
          )}
        </div>
        
        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Auto Pause</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Pause a Member if they miss a call.
            </label>
          </div>

          {loading ? (
            <div className="d-flex justify-content-end">
              <Skeleton width="5rem" height="2rem" />
            </div>
          ) : (
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("autopause")}
              checked={form.autopause === "yes" ? true : false}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Join Empty</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Force caller in Queue even if there are no available agents.
            </label>
          </div>

          {loading ? (
            <div className="d-flex justify-content-end">
              <Skeleton width="5rem" height="2rem" />
            </div>
          ) : (
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("joinempty")}
              checked={form.joinempty === "yes" ? true : false}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">Leave When Empty</label>
            <br></br>
            <label className="itp-edit-number-label-2">
              If all Members become unavailable while a caller is on hold, exit
              the Queue.
            </label>
          </div>

          {loading ? (
            <div className="d-flex justify-content-end">
              <Skeleton width="5rem" height="2rem" />
            </div>
          ) : (
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("leavewhenempty")}
              checked={form.leavewhenempty === "yes" ? true : false}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">
              Ring instead of Playing Music
            </label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Play a ringing tone instead of music on hold while callers are
              waiting in the Queue.
            </label>
          </div>

          {loading ? (
            <div className="d-flex justify-content-end">
              <Skeleton width="5rem" height="2rem" />
            </div>
          ) : (
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("queue_args")}
              checked={form.queue_args === "r" ? true : false}
            />
          )}
        </div>

        <div className="mt-4 d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <label className="itp-edit-number-label-1">
              Skip busy agents
            </label>
            <br></br>
            <label className="itp-edit-number-label-2">
              Do not call the agent if you are in a queue call.
            </label>
          </div>

          {loading ? (
            <div className="d-flex justify-content-end">
              <Skeleton width="5rem" height="2rem" />
            </div>
          ) : (
            <Switch
              className="itp-custom-table-switch"
              onColor="#626ed4"
              height={20}
              width={40}
              onChange={() => onChangeSwitchs("skip_busy_agents")}
              checked={form.skip_busy_agents}
            />
          )}
        </div>
      </CollapsePanel>

      <MenuRight
        show={openMenu}
        closeMenu={closeModal}
        title="Add Member"
        to=""
      >
        {openMenu && (
          <>
            <label className="itp-label-input__label">Users</label>
            <Select
              isMulti
              onChange={onChangeUsers}
              className="basic-single mb-4"
              classNamePrefix="select"
              value={selectedUsers}
              isSearchable={true}
              placeholder="Select User(s)"
              options={users}
            />
            <button
              onClick={addUsers}
              className="btn-primary itp-lead-detail-button-save loading"
              disabled={disabledButton()}
            >
              {loadingUser && <LoadingButton />}
              Save
            </button>
          </>
        )}
      </MenuRight>
    </div>
  );
};

export default QueueDetails;
