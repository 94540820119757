const processVoicemails = (result, urlBase, id, token) => {
  const newVoicemails = Object.keys(result.result).map((element) => {
    const name = element.split("'").length < 2 && element.split('"').length < 2
      ? element
      : element.split("'")[1]
      ? element.split("'")[1]
      : element.split('"')[1];

    const number = element.split("'").length < 2 && element.split('"').length < 2
      ? element
      : element.split("'")[2]
      ? element.split("'")[2].split("<")[1].split(">")[0]
      : element.split('"')[2].split("<")[1].split(">")[0];

    const currentVoicemail = result.result[element];

    const voicemails = currentVoicemail.map((item) => {
      item.message_participant = 0;
      item.audio_message = `${urlBase}/itpvoice/v2/${id}/my-extension/voicemail-messages/${item.pk}?token=${token}&convert_base64=true`;
      item.message_timestamp = item?.usertime.slice(0, 19) || "";
      item.firstname = name.split(" ")[0] ? name.split(" ")[0] : "";
      item.lastname = name.split(" ")[1] ? name.split(" ")[1] : "";
      item.voicemail_duration = item?.duration || 0;

      return item
    });

    const voicemail = {
      isContact: true,
      firstname: name.split(" ")[0] || "",
      lastname: name.split(" ")[1] || "",
      nameContact: name || "",
      last_updated: "",
      last_message: number || "",
      voicemails: voicemails || [],
      pk: number || "",
    };

    return voicemail
  });

  return newVoicemails;
};

export default processVoicemails;
