import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { CollapsePanel, StartComponent } from "../../../../../components";
import { NO_CALENDLY_ALERT, NO_CALENDLY_TITLE, NO_CALENDLY_SUBTITLE, NO_CALENDLY_BUTTON, CALENDLY_REJECTED_ALERT, CALENDLY_REJECTED_TITLE, CALENDLY_REJECTED_SUBTITLE, CALENDLY_REJECTED_BUTTON, CALENDLY_SUCCESS_ALERT, CALENDLY_SUCCESS_TITLE, CALENDLY_SUCCESS_SUBTITLE, CALENDLY_SUCCESS_BUTTON } from "./const";
import Select from "react-select";
import calendlyLogo from "../../../../../assets/icons/calendlyLogo.svg";
import infoIcon from "../../../../../assets/icons/information-icon.svg";
import petitionGet from "../../../../../services/petitionGet";
import petitionPut from "../../../../../services/petitionPut";
import json from "../../../../../config.json";

const CalendarIntegration = ({ myExtension, setCompletedRequests, loading, assignedNumbers }) => {
  const CLIENT_ID = json.prod ? json.calendlyClienID.prod : json.calendlyClienID.dev;
  const REDIRECT_URI = json.prod ? json.calendly.prod : json.calendly.dev;
  const token = JSON.parse(localStorage.getItem("userDetails")).access_token;
  const id = JSON.parse(localStorage.getItem("userDetails"))?.api_id;
  const urlBase = json.prod ? json.urlBase.prod : json.urlBase.dev;

  const [alertText, setAlertText] = useState(NO_CALENDLY_ALERT);
  const [title, setTitle] = useState(NO_CALENDLY_TITLE);
  const [subTitle, setSubTitle] = useState(NO_CALENDLY_SUBTITLE);
  const [buttonName, setButtonName] = useState(NO_CALENDLY_BUTTON);
  const [mode, setMode] = useState("primary");
  const [alertTextMode, setAlertTextMode] = useState("");
  const [calendars, setCalendars] = useState([]);
  const [addedCalendar, setAddedCalendar] = useState(false);
  const [valueCalendar, setValueCalendar] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [calendarToNumbersValue, setCalendarToNumbersValue] = useState({});

  const icon = (
    <img
      style={{ width: "15rem", height: "15rem", marginTop: "-4rem" }}
      src={calendlyLogo}
    />
  );

  const onChangeCalendarNumber = (e, number) => {
    const currentValue = calendarToNumbersValue;

    const newValue = {
      ...calendarToNumbersValue,
      [number]: e,
    };

    setCalendarToNumbersValue(newValue);

    const numbersInObjectValues = Object.keys(newValue);
    let numbersResource = [];

    numbersInObjectValues.forEach((number) => {
      if (!newValue[number]?.isDisabled) {
        numbersResource.push({
          resource_type: "number",
          resource_identifier: number,
          calendar: {
            calendly_event_type_id: newValue[number]?.value,
            name: newValue[number]?.label,
          },
        });
      }
    });

    const data = {
      default_calendar: {
        calendly_event_type_id: valueCalendar.value,
        name: valueCalendar.label,
      },
      resource_specific_calendars: numbersResource,
    };

    petitionPut("resources", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setCalendarToNumbersValue(currentValue);
      });
  };

  const onChangeCalendar = (e) => {
    const currentValue = valueCalendar;
    setValueCalendar(e);

    let data = {};

    if (!currentValue) {
      data = {
        default_calendar: {
          calendly_event_type_id: e.value,
          name: e.label,
        },
        resource_specific_calendars: [],
      };
    } else {
      const numbersInObjectValues = Object.keys(calendarToNumbersValue);
      let numbersResource = [];

      numbersInObjectValues.forEach((number) => {
        if (!calendarToNumbersValue[number]?.isDisabled) {
          numbersResource.push({
            resource_type: "number",
            resource_identifier: number,
            calendar: {
              calendly_event_type_id: calendarToNumbersValue[number]?.value,
              name: calendarToNumbersValue[number]?.label,
            },
          });
        }
      });

      data = {
        default_calendar: {
          calendly_event_type_id: e.value,
          name: e.label,
        },
        resource_specific_calendars: numbersResource,
      };
    }

    petitionPut("resources", { data })
      .then(({ data: result }) => {})
      .catch((error) => {
        console.log(error);
        setValueCalendar(currentValue);
      });
  };

  const handleCalendlyIntegration = async () => {
    try {
      const url = urlBase + `/360/accounts/${id}/my-account/calendar/calendly-oauth-token`
      const config = {
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }

      const customTokenResponse = await fetch(url, config);

      if (!customTokenResponse.ok) {
        throw new Error("Failed to fetch custom token");
      }

      const responseJson = await customTokenResponse.json();
      const customToken = responseJson.result; // Correctly extracting the token

      const calendlyUrl = `https://auth.calendly.com/oauth/authorize?client_id=${CLIENT_ID}&state=${customToken}&response_type=code&redirect_uri=${REDIRECT_URI}`;
      window.location.href = calendlyUrl;
    } catch (error) {
      console.error("Error during Calendly integration:", error);
    }
  };

  const petition = async () => {
    const numbersTemp = assignedNumbers
    let numbersObject = {};
    let calendarsTemp = []

    await petitionGet("calendars")
      .then(({ data: result }) => {
        calendarsTemp = result.result
        
        calendarsTemp.forEach((element) => {
          element.label = element.name;
          element.value = element.uri;
        });

        setCalendars(calendarsTemp);
        setAlertText(CALENDLY_SUCCESS_ALERT);
        setTitle(CALENDLY_SUCCESS_TITLE);
        setSubTitle(CALENDLY_SUCCESS_SUBTITLE);
        setButtonName(CALENDLY_SUCCESS_BUTTON);
        setAlertTextMode("");
        setMode("success");
        setAddedCalendar(true);
        setNumbers(numbersTemp);
        setCompletedRequests(prevStata => prevStata + 1)
      })
      .catch((error) => console.log(error));

      await petitionGet("resources")
      .then(({ data: result }) => {
        if (result.result) {
          numbersTemp.forEach((number) => {
            let findSource = result?.result?.resource_specific_calendars.find(
              (source) => source?.resource_identifier === number.number
            );

            if (findSource) {
              const defaultCalendar = calendarsTemp.find((element) => element.value === findSource.calendar.calendly_event_type_id)

              if (defaultCalendar) {
                findSource.calendar.label = findSource.calendar.name;
                findSource.calendar.value =
                  findSource.calendar.calendly_event_type_id;
  
                numbersObject[number.number] = findSource.calendar;
              } else {
                numbersObject[number.number] = {
                  label: "Using Default",
                  value: "default",
                  isDisabled: true,
                };
              }
            } else {
              numbersObject[number.number] = {
                label: "Using Default",
                value: "default",
                isDisabled: true,
              };
            }
          });

          const defaultCalendarValue = calendarsTemp.find((element) => element.value === result.result.default_calendar.calendly_event_type_id)

          if (defaultCalendarValue) setValueCalendar(defaultCalendarValue)
        }

        setCalendarToNumbersValue(numbersObject);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length > 0 && assignedNumbers) {
      if (myExtension?.calendly_access_token && myExtension?.calendly_error_with_integration) {
        setAlertText(CALENDLY_REJECTED_ALERT);
        setTitle(CALENDLY_REJECTED_TITLE);
        setSubTitle(CALENDLY_REJECTED_SUBTITLE);
        setButtonName(CALENDLY_REJECTED_BUTTON);
        setAlertTextMode("danger");
        setCompletedRequests(prevStata => prevStata + 1)
      } else if (myExtension?.calendly_access_token && !myExtension?.calendly_error_with_integration) {
        petition();
      } else {
        setCompletedRequests(prevStata => prevStata + 1)
      }
    }
  }, [myExtension, assignedNumbers]);

  return (
    <>
      <CollapsePanel
        id="calendarIntegration"
        title="Calendar Integration"
        subtitle="Calendar settings"
      >
        <StartComponent
          alertText={alertText}
          cardTitle={title} 
          cardSubtitle={subTitle}
          buttonName={buttonName}
          onClickFunction={handleCalendlyIntegration}
          mode={mode}
          noPlusIconButton={true}
          alertTextMode={alertTextMode}
          icon={icon}
          loading={loading}
          noCard
        />

        {addedCalendar && !loading && (
          <>
            <span className="itp-my-settings-card-title">
              Default Calendar
            </span>

            <Row className="itp-my-settings-card-help-text">
              <Col md={7} className="d-flex mt-2">
                <div>
                  <img src={infoIcon} alt="myExtension-icon" />
                </div>
                <div className="ms-3">
                  <p>
                    Select the default calendar to use for Scheduling
                    appointments
                  </p>
                </div>
              </Col>

              <Col style={{ marginTop: "-0.5rem" }} md={5}>
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Select
                    onChange={onChangeCalendar}
                    className="basic-single switch-caller-id-number"
                    classNamePrefix="select"
                    value={valueCalendar}
                    isSearchable={true}
                    name="contact_list_id"
                    options={calendars}
                    placeholder="Select Default Calendar"
                    styles={{
                      menu: provided => ({
                        ...provided,
                        position: "relative",
                        zIndex: 9999,
                      }),
                    }}
                  />
                </div>
              </Col>
            </Row>

            {valueCalendar && (
              <>
                <span className="itp-my-settings-card-title">
                  Phone Numbers
                </span>

                {numbers.map((number) => (
                  <Row
                    key={number.number}
                    className="itp-my-settings-card-help-text"
                  >
                    <Col md={7} className="d-flex mt-2">
                      <div className="ms-3">
                        <p className="mb-0">{number.number}</p>
                        <span className="itp-bulk-icon-name">
                          {number.description || "Phone Number"}
                        </span>
                      </div>
                    </Col>

                    <Col style={{ marginTop: "-0.5rem" }} md={5}>
                      <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                        <Select
                          onChange={(e) => onChangeCalendarNumber(e, number.number)}
                          className="basic-single switch-caller-id-number"
                          classNamePrefix="select"
                          value={calendarToNumbersValue[number.number]}
                          isSearchable={true}
                          name="contact_list_id"
                          options={calendars}
                          styles={{
                            menu: provided => ({
                              ...provided,
                              position: "relative",
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </>
        )}
      </CollapsePanel>

    </>
  );
};

export default CalendarIntegration;
