import { Col, Row } from "reactstrap";
import { CollapsePanel } from "../../../../../../../../components";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import "./BasicSettings.css"

const BasicSettings = ({ 
  form, 
  handleChange, 
  handleBlurInputs, 
  timeZones, 
  timeZoneValue, 
  handleChangeTimeZone,
  loading, 
}) => (
  <>
    <br></br>
      <div>
        <Row>
          <Col>
            <div className="itp-my-settings-separator-line"></div>
          </Col>
        </Row>
      </div>
    <br></br>

    <CollapsePanel
      title="Basic Settings"
      subtitle="Change basic data for this user"
    >
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">First Name</label>
          <label className="itp-edit-number-label-2">
            Change the first name
          </label>
        </div>

        {loading ? (
          <div className="itp-edit-number-input">
            <Skeleton width="100%" height="2rem"/>
          </div>
        ) : (
          <input
            placeholder="Add first name"
            className="form-control-itp itp-edit-number-input"
            name="firstname"
            type="text"
            value={form.firstname}
            onChange={handleChange}
            onBlur={() => handleBlurInputs("firstname", "changeDataUsers")}
          />
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">Last Name</label>
          <label className="itp-edit-number-label-2">
            Change the last name
          </label>
        </div>

        {loading ? (
          <div className="itp-edit-number-input">
            <Skeleton width="100%" height="2rem"/>
          </div>
        ) : (
          <input
            placeholder="Add last name"
            className="form-control-itp itp-edit-number-input"
            name="lastname"
            type="text"
            value={form.lastname}
            onChange={handleChange}
            onBlur={() => handleBlurInputs("lastname", "changeDataUsers")}
          />
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">Email</label>
          <label className="itp-edit-number-label-2">Change the email</label>
        </div>

        {loading ? (
          <div className="itp-edit-number-input">
            <Skeleton width="100%" height="2rem"/>
          </div>
        ) : (
          <input
            placeholder="Add email"
            className="form-control-itp itp-edit-number-input"
            name="email"
            type="text"
            value={form.email}
            onChange={handleChange}
            onBlur={() => handleBlurInputs("email", "changeDataUsers")}
          />
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">Extension number</label>
          <label className="itp-edit-number-label-2">
            Change the extension number
          </label>
        </div>

        {loading ? (
          <div className="itp-edit-number-input">
            <Skeleton width="100%" height="2rem"/>
          </div>
        ) : (
          <input
            placeholder="Add Extension Number"
            className="form-control-itp itp-edit-number-input"
            name="presence_id"
            type="number"
            value={form.presence_id}
            onChange={handleChange}
            onBlur={() => handleBlurInputs("presence_id", "user")}
          />
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">Type</label>
          <label className="itp-edit-number-label-2">
            Change user permissions
          </label>
        </div>

        {loading ? (
          <div className="itp-edit-user-skeleton-timeZone ">
            <Skeleton height="2.2rem" width="100%" />
          </div>
        ) : (
          <select
            className="form-select-itp itp-select-edit-user"
            name="priv_level"
            value={form.priv_level}
            onChange={(e) => handleChange(e, true, "user")}
          >
            <option value="" disabled>
              Select Option
            </option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        )}
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
        <div className="d-flex flex-column">
          <label className="itp-edit-number-label-1">Time Zone</label>
          <label className="itp-edit-number-label-2">
            Change user time zone
          </label>
        </div>

        {loading ? (
          <div className="itp-edit-user-skeleton-timeZone ">
            <Skeleton height="2.2rem" width="100%" />
          </div>
        ) : (
          <Select
            onChange={handleChangeTimeZone}
            className="basic-single edit-user-time-zone"
            classNamePrefix="select"
            name="time_zone"
            placeholder="Select Time Zone"
            options={timeZones}
            isSearchable={true}
            value={timeZoneValue}
          />
        )}
      </div>
    </CollapsePanel>
  </>
);

export default BasicSettings;
