import petitionPatch from "../../../../../../services/petitionPatch";
import petitionPost from "../../../../../../services/petitionPost";

const addOrEditContactListService = async (setLoading, list_name, info, reloadData) => {
  try {
    setLoading(true);

    const data = { list_name };

    if (info) {
      await petitionPatch("contactList", { data, contact_list_id: info.pk, })
    } else {
      await petitionPost("addContactsList", { data })
    }

    setLoading(false);
    reloadData();
  } catch (error) {
    console.log(error)
  }
}

export default addOrEditContactListService;