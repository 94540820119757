export const KM_DEVICE_DIAL = "KM_DEVICE_DIAL"
export const KM_DEVICE_ANSWER = "KM_DEVICE_ANSWER"
export const KM_DEVICE_HANGUP = "KM_DEVICE_HANGUP"
export const KM_DEVICE_REGISTERED = "KM_DEVICE_REGISTERED"
export const CALLPARKING_PARKED = "CALLPARKING_PARKED"
export const CALLPARKING_ABANDONED = "CALLPARKING_ABANDONED"
export const CALLPARKING_PICKUP = "CALLPARKING_PICKUP"
export const QUEUE_ENTER = "QUEUE_ENTER"
export const QUEUE_HANGUP_ABANDONED = "QUEUE_HANGUP_ABANDONED"
export const QUEUE_MEMBER_ANSWER = "QUEUE_MEMBER_ANSWER"
export const QUEUE_HANGUP_TIMEOUT = "QUEUE_HANGUP_TIMEOUT"
export const QUEUE_HANGUP = "QUEUE_HANGUP"
export const USER_SPY = "USER_SPY"
export const USER_SPY_STOP = "USER_SPY_STOP"
export const USER_WHISPER = "USER_WHISPER"
export const USER_WHISPER_STOP = "USER_WHISPER_STOP"
export const USER_BARGE = "USER_BARGE"
export const USER_BARGE_STOP = "USER_BARGE_STOP"
export const PING = "PING"