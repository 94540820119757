import React from "react";
import { ColumnHeader } from "../index"
import "./TableHeader.css";

const TableHeader = ({
  columns,
  actions,
  withCheckboxes,
  toggleAll,
  isAllSelected,
  selectedAllRows,
  validViewColumn,
  defaultOrderColumn,
  columnNames,
  changeColumnOrder,
  loading,
}) => (
  <>
    <thead>
      <tr className="itp-custom-table-main-thead-styles">
        {withCheckboxes && (
          <th className="itp-custom-table-main-th-styles">
            <input
              type="checkbox"
              className="form-check-input itp-custom-table-main-check-header"
              checked={isAllSelected || selectedAllRows}
              onChange={toggleAll}
              disabled={selectedAllRows || loading}
            />
          </th>
        )}

        {validViewColumn(columns)?.map((column) => (
          <ColumnHeader 
            key={column.name}
            column={column}
            defaultOrderColumn={defaultOrderColumn} 
            columnNames={columnNames} 
            changeColumnOrder={changeColumnOrder} 
            loading={loading}
            columns={columns}
          />
        ))}

        {actions?.title && (
          <th className="itp-custom-table-main-th-styles">
            {actions?.title}
          </th>
        )}
      </tr>
    </thead>
  </>
);

export default TableHeader;
