const handleChangeSearchTimeDate = (e, setSearchValue, timeRules, setDataTable) => {
  const { value } = e.target;

  setSearchValue(value);

  let timeRulesTemp = timeRules;

  if (value !== "") {
    timeRulesTemp = timeRulesTemp.filter(
      (element) =>
        element.name.toLowerCase().includes(value.toLocaleLowerCase()) ||
        element.pk.toString().toLowerCase().includes(value.toLocaleLowerCase()) ||
        element.startDate.toLowerCase().includes(value.toLocaleLowerCase()) ||
        element.endDate.toLowerCase().includes(value.toLocaleLowerCase())
    );

    setDataTable((prev) => {
      return {
        ...prev,
        content: [...timeRulesTemp],
      };
    });
  } else {
    setDataTable((prev) => {
      return {
        ...prev,
        content: [...timeRules],
      };
    });
  }
};

export default handleChangeSearchTimeDate;
