import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartLine = ({ xLabel, yLabel, isCustomized = true, isOneReport, name }) => {
  let series = [];
  if (isCustomized) {
    series = [
      {
        name: "Calls Throughout the Day",
        data: yLabel,
      },
    ];
  } else if (isOneReport) {
    series = [
      {
        name: name ? name : "Calls Throughout the Day",
        data: yLabel,
      },
    ];
  } else {
    series = yLabel;
  }

  const options = {
    chart: {
      height: 350,
      type: "area",
      group: "social",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
      markers: {
        fillColors: ["#00a15d", "#709fba"],
        width: 19,
        height: 19,
        strokeWidth: 0,
        radius: 19,
      },
    },
    markers: {
      size: 6,
      border: 0,
      colors: ["#002340", "#709fba"],
      hover: {
        size: 6,
      },
    },
    xaxis: {
      categories: xLabel,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#3e4954",
          fontSize: "14px",
          fontFamily: "Poppins",
          fontWeight: 100,
        },
      },
    },
    fill: {
      colors: ["#00a15d", "#709fba"],
      type: "solid",
      opacity: 0.07,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default ChartLine;
