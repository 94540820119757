import petitionGet from "../../../../services/petitionGet";
import formatPhoneNumber from "../../../../utils/formatPhoneNumber";

const includesPattern = (input) => {
  const patterns = [
    /^\(\d{3}\)\s\d{3}-\d{4}$/, // (305) 299-0233
    /^\d{3}-\d{3}-\d{4}$/, // 305-299-0233
    /^\d{10}$/, // 3052990233
    /^\d{11}$/, // 13052990233
  ];

  let find = "";
  patterns.forEach((pattern) => {
    if (pattern.test(input)) {
      if (/^\d{11}$/.test(input) && input[0] === "1") {
        find = input.slice(1);
        // console.log(1)
      } else {
        find = input.replace(/\D/g, "");
      }
    }
  });

  return find;
};

const asyncSearchContactService = async (e, setInput, setCleanedNumber, setIsNumericInput) => {
  try {
    const { value } = e.target;
    const formatedNumber = includesPattern(value);
    const newValue = formatedNumber || value;

    setInput(value);
    setCleanedNumber(newValue);

    if (value !== "") {
      setIsNumericInput(false);

      const parameter = `?search_query=${newValue}`
      const { data: result } = await petitionGet("contacts", { parameter });

      let newContactoptions = [];

      result.result.forEach((element) => {
        if (element.phone) {
          element.label = `${element.firstname} ${element.lastname || ""}: ${formatPhoneNumber(element.phone)}`;
          element.value = element.pk;
          newContactoptions.push(element);
        }
      });

      const numericInput = /^\d+$/.test(newValue);

      if (newContactoptions.length === 0 && numericInput) {
        setIsNumericInput(true);
      }

      return result.result
    }
  } catch (error) {
    console.log(error);
  }
};

export default asyncSearchContactService;
