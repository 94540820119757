import React from "react";
import petitionGet from "../../../../../services/petitionGet";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const Step3 = ({ form, setForm }) => {
  const loadOptions = (inputValue, callback) => {
    petitionGet("contacts", {
      parameter: `?search_query=${inputValue}`,
    })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.firstname} ${element.lastname || ""}`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptionsContactList = (inputValue, callback) => {
    petitionGet("contactList", { parameter: `?name=${inputValue}` })
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.label = `${element.list_name} (${element.contact_count})`;
          element.value = element.pk;
        });

        callback(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <span className="table-alt-text-title-description">
        In this step you can choose individual contacts or contact list or both.
      </span>

      <label className="itp-label-input__label">Contacts</label>
      <AsyncSelect
        isMulti
        onChange={(e) => setForm({ ...form, contacts: e })}
        className="basic-single add-lead-select"
        classNamePrefix="select"
        loadOptions={loadOptions}
        isSearchable={true}
        name="contacts"
        placeholder="Choose Contacts"
        value={form.contacts}
        defaultOptions
        cacheOptions
      />

      <label className="itp-label-input__label mt-4">Contact List</label>
      <AsyncSelect
        isMulti
        onChange={(e) => setForm({ ...form, contact_list: e })}
        className="basic-single add-lead-select"
        classNamePrefix="select"
        loadOptions={loadOptionsContactList}
        isSearchable={true}
        name="contact_list"
        placeholder="Choose Contact List"
        value={form.contact_list}
        defaultOptions
        cacheOptions
      />
    </>
  );
};

export default Step3;
