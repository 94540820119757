import React from "react";
import { Link } from "react-router-dom";

const RowLink = ({ element, config, column }) => {
  //en el campo key dentro del config se debe mandar hasta donde se debe acceder del objeto que
  //representa a cada fila que en este caso es element. Si solo se quiere acceder al primer nivel
  //del objeto solo se manda el nombre del campo pero si se quiere acceder a mas niveles dentro del
  //objeto de fila deben ir separados por puntos ejemplo: contact.pk, esto accederá al campo contact
  //dentro del objeto de fila (element) y luego al campo pk dentro del campo contact. el campo to es
  //donde se coloca a que otra página se quiere redireccionar mas algun query, ejemplo del to,
  //to = "/Contacts?". si se quiere entender mejor en la página de el código de la página de Leads
  //hay un ejemplo
  const keySplited = config.key.split(".")

  let endValue = element ? element : {}

  keySplited.forEach((key) => {
    endValue = (endValue[key] || false)
  })

  const to = endValue ? (config.to + endValue) : "#"

  const icon = config?.icon || ""

  return (
    <Link className="redirect" to={to}>
      {icon}
      {element[column.key]}
    </Link>
  );
};

export default RowLink;
