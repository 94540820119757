import petitionGet from "../../../../../services/petitionGet";

const returnNumberInChannel = (channel, users) => {
  if (!channel.receiving && channel.dialed_number) {
    return channel.dialed_number;
  }

  if (!channel.receiving && !channel.dialed_number) {
    const findUser = users.find((user) => user.pk === channel.to_user_id);
    return findUser ? findUser?.presence_id : "";
  }

  if (channel.receiving && channel.caller_id_number) {
    return channel.caller_id_number;
  }

  if (channel.receiving && !channel.caller_id_number) {
    const findUser = users.find((user) => user.pk === channel.from_user_id);
    return findUser ? findUser?.presence_id : "";
  }
};

const fetchLivePanelData = async (setUsers, setParkingCall, setQueues, setPermissions) => {
  const { data: result } = await petitionGet("operationPanel")
  const { users, parked_calls, queues, permissions } = result.result
  const arrayPermissions = permissions.map((element) => element?.permission_name)

  users.forEach((element, i) => {
    element.callerID = `${element.firstname} ${element.lastname || ""}`;
    element.channels.forEach((channel) => {
      channel.answered = (channel?.state === 3 || channel?.state === 4) ? true : false;
      channel.kamailio_call_id = channel["call-id"];
      channel.time = channel?.call_seconds;
      channel.withNumber = returnNumberInChannel(channel, users);
    });
  });

  setUsers(users);
  setParkingCall(parked_calls);
  setQueues(queues);
  setPermissions(arrayPermissions)
}

export default fetchLivePanelData;