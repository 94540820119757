import petitionPost from "../../../../../services/petitionPost";

const addLeadSendToFunnelService = async (setLoading, form, allResult, pagination, reloadData) => {
  try {
    setLoading(true);

    const data = {
      lead_source_id: form?.lead_source_id?.value,
      funnel_id: form?.funnel_id?.value,
      funnel_pipeline_stage_id: form?.funnel_pipeline_stage_id?.value,
      contact_id: form?.contact_id?.value,
    };

    await petitionPost("leads", { data });
    setLoading(false);

    if (allResult.result.length === 1 && parseInt(allResult.offset) !== 0) {
      const paginationTemp = {
        offset: pagination.current.offset - pagination.current.limit,
        limit: pagination.current.limit,
      };
      reloadData(false, paginationTemp);
    } else {
      reloadData();
    }
  } catch (error) {
    console.log(error);
  }
};

export default addLeadSendToFunnelService;
