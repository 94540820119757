import React from "react";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import { timeZones } from "../../../../../const/timeZones";

const Step1 = ({ form, setForm, handleChangeForm, numbers, loading }) => {
  const handleChangeNumbers = (e) => {
    if (e.length === 1) {
      setForm({
        ...form,
        campaign_strategy: "balanced",
        numbers: e,
      });
    } else if (e.length === 2) {
      setForm({
        ...form,
        campaign_strategy: "",
        numbers: e,
      });
    } else {
      setForm({ ...form, numbers: e });
    }
  };

  return (
    <>
      <label className="itp-label-input__label">Name</label>
      {loading ? (
        <Skeleton height={50} width="100%" /> 
      ) : (
        <input
          style={{marginBottom: "20px"}}
          name="name"
          placeholder="Enter a name for your campaign"
          value={form.name}
          onChange={handleChangeForm}
          className="form-control-itp"
        />
      )}

      <label className="itp-label-input__label mt-4">Time Zone</label>
      {loading ? (
        <Skeleton height={50} width="100%" /> 
      ) : (
        <Select
          onChange={(e) => setForm({ ...form, default_time_zone: e })}
          className="basic-single"
          classNamePrefix="select"
          value={form.default_time_zone}
          isSearchable={true}
          name="default_time_zone"
          options={timeZones}
        />
      )}

      <label className="itp-label-input__label mt-4">Type of Campaign</label>
      {loading ? (
        <Skeleton height={50} width="100%" /> 
      ) : (
        <select
          name="campaign_type"
          value={form.campaign_type}
          onChange={handleChangeForm}
          className="form-select-itp"
        >
          <option value="" disabled>
            Select Option
          </option>
          <option value="sms">Send a Text Message</option>
        </select>
      )}

      {form.campaign_type === "sms" && (
        <>
          <label className="itp-label-input__label mt-4">
            Select Number(s)
          </label>
          {loading ? (
            <Skeleton height={50} width="100%" /> 
          ) : (
            <Select
              isMulti
              onChange={handleChangeNumbers}
              className="basic-single"
              classNamePrefix="select"
              value={form.numbers}
              isSearchable={true}
              name="numbers"
              placeholder="Select Numbers"
              options={numbers}
            />
          )}

          {form.numbers.length > 1 && (
            <>
              <div className="d-flex flex-column">
                <label className="itp-label-input__label mt-4">
                  Strategy to use
                </label>
                <span className="table-alt-text-title-description cursor-help mt-1 mb-1">
                  Decide on a strategy when using multiple numbers. Random selects a number randomly on each message.
                   Balanced will equally balance all the texts between the numbers.
                </span>
              </div>
              {loading ? (
                <Skeleton height={50} width="100%" /> 
              ) : (
                <select
                  name="campaign_strategy"
                  value={form.campaign_strategy}
                  onChange={handleChangeForm}
                  className="form-select-itp"
                >
                  <option value="" disabled>
                    Select Option
                  </option>
                  <option value="balanced">Balanced</option>
                  <option value="random">Random</option>
                </select>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Step1;
