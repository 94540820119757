import { processForm } from "../../utils";
import petitionGet from "../../../../../../services/petitionGet";

const fetchNumberDetails = async (number, setAdvanceCallFlows, setForm, setCurrentForm) => {
  const { data: result } = await petitionGet("viewNumberDetails", { phoneNumber: number })

  if (!result.result.number_callflow) {
    setAdvanceCallFlows(true);
  }

  if (result.result.number_callflow && !result.result.number_callflow.internal_use) {
    setAdvanceCallFlows(true);
  }

  if (result.result.number_callflow && result.result.number_callflow.internal_use) {
    setAdvanceCallFlows(false);
  }

  const form = processForm(result.result)
  setForm(form)
  setCurrentForm(form)

  return result.result
}

export default fetchNumberDetails;