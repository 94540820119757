import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "reactstrap";
import { CustomTableMain, LoadingButton, MenuRight, AddButton, CollapsePanel } from "../../../../../components";
import ModalDelete from "../../ModalUtility/ModalDelete";
import petitionGet from "../../../../../services/petitionGet";
import petitionPost from "../../../../../services/petitionPost";
import petitionPatch from "../../../../../services/petitionPatch";
import petitionDelete from "../../../../../services/petitionDelete";

const LeadSource = ({
  loading,
  setCompletedRequests,
  openMenu,
  setOpenMenu,
  modalDelete,
  setModalDelete,
}) => {
  const [showAddSource, setShowAddSource] = useState(false);
  const [loadingSource, setLoadingSource] = useState(false);
  const [formSource, setFormSource] = useState({ name: "" });
  const [info, setInfo] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [dataTableSources, setDataTableSources] = useState({
    columns: [{ name: "Name", key: "name" }],
    actions: { title: "Actions", content: [] }
  });

  const closeMenu = () => {
    setShowAddSource(false);
    setModalDelete(false);
    setInfo(null);
    setOpenMenu(false);
  };

  const activeAddSource = () => {
    if (loading) return;
    setShowAddSource(true);
    setOpenMenu(2);
  };

  const onChangeFormSource = (e) => {
    setFormSource({ ...formSource, [e.target.name]: e.target.value });
  };

  const addEditSource = () => {
    if (!info) {
      setLoadingSource(true);
      petitionPost("leadSource", { data: formSource })
        .then(({ data: result }) => {
          setDataTableSources((prevState) => {
            const newContent = [...prevState?.content, result.result];

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingSource(false);
          closeMenu();
        })
        .catch((error) => console.log(error));
    } else {
      setLoadingSource(true);
      petitionPatch("leadSource", { data: formSource, source_lead_id: info.pk })
        .then(({ data: result }) => {
          setDataTableSources((prevState) => {
            const newContent = prevState?.content.map((element) => {
              if (element.pk === info.pk) {
                element = result.result;
              }

              return element;
            });

            return {
              ...prevState,
              content: [...newContent],
            };
          });
          setLoadingSource(false);
          closeMenu();
        })
        .catch((error) => console.log(error));
    }
  };

  const activeDeleteSource = (e) => {
    setInfo(e);
    setModalDelete(2);
  };

  const deleteSource = () => {
    setLoadingModal(true);
    petitionDelete("leadSource", { source_lead_id: info.pk })
      .then(({ data: result }) => {
        setDataTableSources((prevState) => {
          const newContent = prevState?.content.filter(
            (element) => element.pk !== info.pk
          );

          return {
            ...prevState,
            content: [...newContent],
          };
        });
        setLoadingModal(false);
        closeMenu();
      })
      .catch((error) => console.log(error));
  };

  const activeEditSource = (e) => {
    setFormSource({ name: e.name });
    setInfo(e);
    setShowAddSource(true);
    setOpenMenu(2);
  };

  const petitionLeadSource = () => {
    petitionGet("sources", {})
      .then(({ data: result }) => {
        setDataTableSources({
          columns: [{ name: "Name", key: "name" }],
          content: [...result.result],
          actions: {
            title: "Actions",
            content: [
              {
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                name: "Edit",
                handleClick: activeEditSource,
              },
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                name: "Delete",
                handleClick: activeDeleteSource,
              },
            ],
          },
        });
        setCompletedRequests((prevStata) => prevStata + 1);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    petitionLeadSource();
  }, []);

  return (
      <>
        {modalDelete === 2 && (
          <ModalDelete
            modal={modalDelete}
            closeModal={closeMenu}
            loading={loadingModal}
            modalFunction={deleteSource}
            module="Lead Source"
          />
        )}

        <MenuRight
          show={openMenu === 2 ? true : false}
          closeMenu={closeMenu}
          title={`${!info ? "Add" : "Edit"} Lead Source`}
          to=""
        >
          {openMenu === 2 && showAddSource && (
            <>
              <Row className="itp-container-label-input">
                <Col
                  xs={5}
                  sm={5}
                  md={5}
                  lg={5}
                  xl={5}
                  xxl={5}
                  className="itp-label-input-col"
                >
                  <label className="itp-label-input__label">Name</label>
                </Col>
                <Col
                  xs={7}
                  sm={7}
                  md={7}
                  lg={7}
                  xl={7}
                  xxl={7}
                  className="itp-label-input-col"
                >
                  <input
                    type="text"
                    placeholder=": Add Name to this lead source"
                    className="form-control-itp itp-label-input__input"
                    name="name"
                    value={formSource.name}
                    onChange={onChangeFormSource}
                  />
                </Col>
              </Row>
              <button
                onClick={addEditSource}
                className="btn-primary itp-lead-detail-button-save loading mb-4"
                disabled={formSource.name ? false : true}
              >
                {loadingSource && <LoadingButton />}
                Save
              </button>
            </>
          )}
        </MenuRight>

        <br></br>
        <div>
          <Row>
            <Col style={{ zIndex: "-1" }}>
              <div className="itp-my-settings-separator-line"></div>
            </Col>
          </Row>
        </div>
        <br></br>

        <CollapsePanel
          id="leadSource"
          title="Lead Source"
          subtitle="Add lead sources here to be used in Leads"
        >
          <CustomTableMain 
            data={dataTableSources}
            loading={loading}
            m0={true}
            module="Lead Source"
            actionsRight={[
              {
                component: (
                  <AddButton 
                    handleClick={activeAddSource}
                    name="Add Lead Source"
                  />
                )
              }
            ]}
          />
        </CollapsePanel>
      </>
  );
};

export default LeadSource;
