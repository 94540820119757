import petitionPost from "../../../../../../services/petitionPost"

const addTagService = async (setLoadingButton, tagName, color, useColor, handleClickCancelButton, setOptionsTags) => {
  try {
    setLoadingButton(true)

    const data = {
      name: tagName,
      tag_color: useColor ? color : "",
    }

    const { data: result } = await petitionPost("tag", { data })

    setOptionsTags((prev) => {
      const newTag = { 
        ...result.result, 
        disabled: false, 
        label: result.result.name, 
        title: result.result.name, 
        value: result.result.pk,
      }
      const newTagList = [...prev, newTag]
      return newTagList
    })

    setLoadingButton(false)
    handleClickCancelButton()
  } catch (error) {
    console.log(error)
  }
}

export default addTagService;