import { months } from "../../const";
import petitionGet from "../../../../../../services/petitionGet";

const formatedDate = (day, month, year) => {
  const monthName = months[month - 1].slice(0, 3);
  return `${monthName} ${day}, ${year}`;
};

const fetchTimeRules = async (pk) => {
  const { data: result } = await petitionGet("timeRules", { parameter: `?timegroup_id=${pk}` })

  result.result.forEach((element) => {
    element.startDate = formatedDate(element.start_month_day, element.start_month, element.start_month_year);
    element.endDate = formatedDate(element.end_month_day, element.end_month, element.end_month_year);
  });

  return result.result
}

export default fetchTimeRules;