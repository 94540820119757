import { Col, Row } from "reactstrap";
import { CollapsePanel, CustomTableMain } from "../../../../../../../../components";
import infoIcon from "../../../../../../../../assets/icons/information-icon.svg";
import Switch from "react-switch";
import Skeleton from "react-loading-skeleton";

const Devices = ({ dataTableUserDevices, activeSipModal, switchs, handleChangeOtherSwitch, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Devices"
      subtitle="Here is all the information related to devices integrated with their respective SIP Credential details & You can register the devices manually if you have a desk phone"
    >
      <CustomTableMain
        data={dataTableUserDevices}
        noDataMessage="No data available"
        m0={true}
        loading={loading}
        noShowPagination={true}
        columnConfig={{
          Details: {
            type: "clickColumn",
            handleClick: activeSipModal,
          },
        }}
      />

      <Row className="mt-5">
        <Col>
          <span className="itp-my-settings-card-title">
            Jitter Buffer Enabled
          </span>

          <Row className="itp-my-settings-card-help-text">
            <Col md={10} className="d-flex">
              <div>
                <img src={infoIcon} alt="myExtension-icon" />
              </div>
              <div className="ms-3">
                <p>
                  Enabling this feature can improve call quality on unstable
                  internet connections.
                </p>
              </div>
            </Col>

            <Col className={loading ? "d-flex justify-content-end" : ""} md={2}>
              {loading ? (
                <Skeleton width="5rem" height="2rem" />
              ) : (
                <div className="itp-custom-table-container-switchs d-flex align-items-center pl-0 justify-content-end">
                  <Switch
                    className="itp-custom-table-switch"
                    onColor="#626ed4"
                    height={20} // Ajusta la altura según tus necesidades
                    width={40}
                    checked={switchs.jitter_buffer}
                    onChange={() => handleChangeOtherSwitch("jitter_buffer")}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </CollapsePanel>
  </>
);

export default Devices;
