import React from "react";
import Date from "../Date/Date";

const LastActivity = ({ element, config }) => {
  const icon = element[config?.keyIcon]
  const arrow = element[config?.keyArrow]
  const date = element[config?.keyDate]

  return (
    <div className="d-flex gap-1">
      <div>{icon}</div>
      <div>{arrow}</div>
      {date && (
        <Date 
          element={element}
          config={{ spliter: config?.spliter  }}
          column={{ key: "keyDate" }}
          style={{ marginTop: "0.3rem" }}
        />
      )}
    </div>
  );
};

export default LastActivity;
