import { itemsMenu, secondItemsMenu } from "../../../../../../const/itemsMenu";
import petitionGet from "../../../../../../services/petitionGet";

const fetchUserDetails = async (pk, form, timeZones, setGranularPermissions) => {
  const { data: result } = await petitionGet("userDetailsAdmin", { user_id: pk })
  const userDetails = result.result

  const foundTimeZone = timeZones.find((timeZone) => timeZone.value === userDetails?.time_zone)

  const formTemp = {
    ...form,
    firstname: userDetails?.firstname || "",
    lastname: userDetails?.lastname || "",
    email: userDetails?.email || "",
    presence_id: userDetails?.presence_id || "",
    priv_level: userDetails?.priv_level || "",
    callforward_number: userDetails?.callforward_number,
    time_zone: userDetails?.time_zone || "",
  }

  let granularPermissionsTemp = {}

  itemsMenu.forEach((item) => {
    if (item?.key_permission) {
      granularPermissionsTemp[item.key_permission] = userDetails[item.key_permission] || false
    }
  })

  secondItemsMenu[1].items.forEach((item) => {
    if (item?.key_permission) {
      granularPermissionsTemp[item.key_permission] = userDetails[item.key_permission] || false
    }
  })
  setGranularPermissions(granularPermissionsTemp)

  return { userDetails, formTemp, foundTimeZone }
}

export default fetchUserDetails;