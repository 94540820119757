import camera from "../../../../../../assets/icons/camera.svg"
import sms from "../../../../../../assets/icons/sms2.svg"
import call from "../../../../../../assets/icons/call.svg"
import findAndFormatPhoneNumbers from "../findAndFormatPhoneNumbers/findAndFormatPhoneNumbers"

const selectTypeLastMessage = (element) => {
  if (element?.last_event_type === "sms" && !element?.last_message) {
    return <><img src={camera} alt="Camera" className="last-message-icons" /> Image</>
  } 
  
  if (element?.last_event_type === "sms" && element?.last_message) {
    return <><img src={sms} alt="SMS" className="last-message-icons" /> {findAndFormatPhoneNumbers(element?.last_message)}</>
  } 
  
  if (element?.last_event_type === "call") {
    return <><img src={call} alt="Call" className="last-message-icons" /> {findAndFormatPhoneNumbers(element?.last_message)}</>
  }

  return ""
}

export default selectTypeLastMessage;