import React, { useState } from "react"
import { Card, CardBody } from "reactstrap";
import "../SignUp.css";

const Step3 = ({ nextStep }) => {
  const [confirmed, setConfirmed] = useState(true)
  const [form, setForm] = useState({
    password: "",
    confirm: "",
  })

  const handleChangeForm = (e) => {
    const { name, value } = e.target

    setForm({ ...form, [name]: value })

    if (name === "password" || (name === "confirm" && value === form.password)) {
      setConfirmed(true)
    } else if (name === "confirm" && value !== form.password) {
      setConfirmed(false)
    }
  }

  const submitForm = (e) => {
    e.preventDefault()
    nextStep(form.password)
  }

  return (
    <div className="itp-signUp">
      <Card className="itp-signUp__card">
        <CardBody>
          <h1 className="itp-signUp__card-body__title">
            Enter your password
          </h1>

          <p className="itp-signUp__card-body__textInfo">
            Enter a password for your account to login
          </p>

          <form
            onSubmit={submitForm}
            className="itp-signUp__card-body__form-container"
          >
            <label htmlFor="password">
              <span></span>
              <input
                className="itp-signUp__card-body__form__input"
                name="password"
                id="password"
                placeholder="password"
                type="password"
                required
                onInvalid={(e) => e.target.setCustomValidity("Please fill out this field.")}
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={handleChangeForm}
              />
            </label>

            <label htmlFor="confirm">
              <span></span>
              <input
                className="itp-signUp__card-body__form__input"
                name="confirm"
                id="confirm"
                placeholder="Retype Password"
                type="password"
                required
                onInvalid={(e) => e.target.setCustomValidity("Please fill out this field.")}
                onInput={(e) => e.target.setCustomValidity("")}
                onChange={handleChangeForm}
              />
            </label>

            {!confirmed && (
              <p className="text-danger">Passwords do not match</p>
            )}

            <button
              type="submit"
              className="btn-primary btn-primary-signup"
              disabled={form.password !== form.confirm}
            >
              Create my account
            </button>
          </form>
        </CardBody>
      </Card>
    </div>
  )
}

export default Step3