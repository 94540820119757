import petitionGet from "../../../../../services/petitionGet";

const fetchQueues = async (setOptionsQueuesReport, setSelectedQueuesReport) => {
  const { data: result } = await petitionGet("queues")

  result.result.forEach((element) => {
    element.label = element.queue_name;
    element.value = element.pk;
  });

  setOptionsQueuesReport(result.result)
  setSelectedQueuesReport(result.result)
}

export default fetchQueues;