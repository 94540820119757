import "./Avatar.css"

const Avatar = ({ firstname, lastname, style, tagColor }) => {
  const firtsLetterFirstName = firstname ? firstname?.toUpperCase()?.charAt(0) : ""
  const firtsLetterLastName = firstname ? lastname?.toUpperCase()?.charAt(0) : ""
  const text = firtsLetterFirstName + firtsLetterLastName

  let tagColorTemp = tagColor

  if (tagColor === undefined || tagColor === null || tagColor === "" || tagColor === "#fff") {
    tagColorTemp = "#6d28d9";
  }

  const backgroundColor = tagColorTemp || ""

  return (
    <span style={{ backgroundColor: backgroundColor, ...style }} className="itp-avatar">
      <span className="itp-avatar-text">{text}</span>
    </span>
  )
}

export default Avatar;