import { Col, Row } from "reactstrap";
import { AddButton, CollapsePanel, CustomTableMain } from "../../../../../../components";

const Queues = ({ dataTableQueues, activeAddQueue, loading }) => (
  <>
    <br></br>
    <div>
      <Row>
        <Col>
          <div className="itp-my-settings-separator-line"></div>
        </Col>
      </Row>
    </div>
    <br></br>

    <CollapsePanel
      title="Queues"
      subtitle="Create & manage your queues for your advanced call flow"
    >
      <CustomTableMain
        data={dataTableQueues}
        loading={loading}
        m0={true}
        module="queues"
        actionsRight={[
          {
            component: (
              <AddButton handleClick={activeAddQueue} name="Add Queue" />
            ),
          },
        ]}
      />
    </CollapsePanel>
  </>
);

export default Queues;
