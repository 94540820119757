const covertTimeStapToSeconds = (timestamp, format) => {
  const timestampTemp = timestamp;
  const time = format === "secs" ? 1000 : 1

  // Convierte el timestamp a un objeto Date (multiplicando por 1000)
  const date = new Date(timestampTemp * time);

  // Obtén el tiempo actual
  const now = new Date();  

  // Calcula la diferencia en milisegundos
  const differenceInMilliseconds = now - date;

  // Convierte la diferencia a segundos
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

  return differenceInSeconds
}

export default covertTimeStapToSeconds;