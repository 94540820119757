import { useState, useCallback, useEffect } from "react";

const useRowSelect = (rows, externalSelectedRows, onSelectionChange) => {
  const [selectedRows, setSelectedRows] = useState(
    new Set(externalSelectedRows)
  );

  const arraysAreEqual = (array1, array2) => {
    // First, compare the lengths of both arrays.
    if (array1.length !== array2.length) {
      return false;
    }
  
    // Sort both arrays to ensure that the elements are in the same order.
    let sortedArray1 = [...array1].sort();
    let sortedArray2 = [...array2].sort();
  
    // Checks if all elements in sortedArray1 are equal to those in sortedArray2.
    return sortedArray1.every((value, index) => value === sortedArray2[index]);
  }

  useEffect(() => {
    // this ensures that if there is a change in externalSelectedRows, 
    // setSelectedRows is only updated if the new value of externalSelectedRows 
    // is different from selectedRows.
    
    if (!arraysAreEqual(Array.from(selectedRows), externalSelectedRows)) {
      setSelectedRows(new Set(externalSelectedRows));
    }
  }, [externalSelectedRows]);

  const toggleRow = useCallback(
    (pk) => {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        if (newSelectedRows.has(pk)) {
          newSelectedRows.delete(pk);
        } else {
          newSelectedRows.add(pk);
        }
        // Convert the set to array and notify the outside only if it has changed.
        onSelectionChange(Array.from(newSelectedRows));
        return newSelectedRows;
      });
    },
    [onSelectionChange]
  );

  const toggleAll = useCallback(() => {
    // Determines whether all rows on the current page are selected.
    const areAllSelected = rows.every((row) => selectedRows.has(row.pk));

    if (areAllSelected) {
      // If all are selected, it creates a new Set without the rows of the current page.
      const newSelectedRows = new Set(selectedRows);
      rows.forEach((row) => newSelectedRows.delete(row.pk));
      setSelectedRows(newSelectedRows);
      onSelectionChange(Array.from(newSelectedRows));
    } else {
      // If not all are selected, add all rows of the current page to the Set.
      const newSelectedRows = new Set(selectedRows);
      rows.forEach((row) => newSelectedRows.add(row.pk));
      setSelectedRows(newSelectedRows);
      onSelectionChange(Array.from(newSelectedRows));
    }
  }, [rows, selectedRows, onSelectionChange]);

  const isSelected = useCallback(
    (pk) => {
      return selectedRows.has(pk);
    },
    [selectedRows]
  );

  const isAllSelected =
    rows?.length > 0 && rows?.every((row) => selectedRows?.has(row?.pk));

  return {
    toggleRow,
    isSelected,
    toggleAll,
    isAllSelected,
  };
};

export default useRowSelect;
