import petitionDelete from "../../../../../../services/petitionDelete"

const deleteMessageService = async (modalDelete, messages) => {
  try {
    const number = modalDelete?.message_participant
    const message_thread_id = modalDelete?.pk
    const smsId = modalDelete?.pk

    await petitionDelete("deleteMessageSms", { number, message_thread_id, smsId })

    const newMessages = messages.filter((sms) => sms.pk !== modalDelete.pk)

    return newMessages
  } catch (error) {
    console.log(error)
  }
}

export default deleteMessageService;