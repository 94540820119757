import React from "react";
import { Col } from "reactstrap";
import Loading from "../Loading/Loading";
import ReactDOM from "react-dom";
import arrow from "../../assets/images/arrow.svg";
import "./MenuRight.css";

function MenuRight({ children, show, closeMenu, title, to, w50, subtitle, sizeSubtitle, contentStyles, loading, paddingTop }) {
  return ReactDOM.createPortal(
    <div>
      <div
        className={`itp-menu-right-container ${show ? "open" : "hidden"}`}
      ></div>
      <div style={contentStyles || {}} className={`itp-menu-right-content ${show ? "open" : "hidden"}${w50 ? " w50" : ""}`}>
        <div onClick={closeMenu} className="itp-menu-right-header">
          <img src={arrow} alt="Voice360" className="back-arrow" />
          <span className="itp-menu-right-back">Back {to ? to : ""}</span>
        </div>
        <div className="itp-menu-right-title">{title}</div>
        {subtitle && (
          <>
            {sizeSubtitle ? (
              <Col 
                xs={sizeSubtitle} 
                sm={sizeSubtitle} 
                md={sizeSubtitle} 
                lg={sizeSubtitle} 
                xl={sizeSubtitle} 
                xxl={sizeSubtitle}
                className="itp-menu-right-subtitle-container"
              >
                <p className="itp-menu-right-subtitle">{subtitle}</p>
              </Col>
            ) : (
              <>
                <p className="itp-menu-right-subtitle">{subtitle}</p>
              </>
            )}
          </>
        )}
        {loading ? (
          <div className="w-100 h100 d-flex justify-content-center align-items-center">
            <Loading />
          </div>
        ) : (
          <>
            <div style={paddingTop ? { paddingTop } : {}} className="itp-menu-right-body">{children}</div>
          </>
        )}
      </div>
    </div>,

    document.getElementById("menu")
  );
}

export default MenuRight;
