import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useMenuDispatch } from "../../../../contexts/MenuContext";
import { useNavigate } from "react-router-dom";
import { Loading, LoadingButton, MenuRight, CustomTableMain } from "../../../../components";
import { updateMyExtention } from "../../../../redux/actions/myExtension";
import petitionPost from "../../../../services/petitionPost";
import petitionGet from "../../../../services/petitionGet";
import ModalNoCard from "./ModalUtility/ModalNoCard";
import ModalAmount from "./ModalUtility/ModalAmount";
import petitionPatch from "../../../../services/petitionPatch";
import "./Billing.css";

const Prepaid = () => {
  const id = JSON?.parse(localStorage?.getItem("userDetails"))?.api_id;
  const billing_api_id = JSON?.parse(localStorage?.getItem("userDetails"))?.account?.billing_api_id;

  const dispatchMenu = useMenuDispatch();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const myExtension = useSelector((state) => state.myExtension.userDetails);

  const [cardNumber, setCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [expiry, setExpiry] = useState("");
  const [amount, setAmount] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [variant, setVariant] = useState("");
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [currentBalance, setCurrentBalance] = useState(myExtension?.voice_account?.prepaid_balance || 0);
  const [creditCardInfo, setCreditCardInfo] = useState("");
  const [loadingInfo, setLoadingInfo] = useState(true);
  const [modalNoCard, setModalNoCard] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedCardType, setSelectedCardType] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorAddCard, setErrorAddCard] = useState("")
  const [modalAmount, setModalAmount] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [cardData, setCardData] = useState({})
  const [editingCard, setEditingCard] = useState(false)
  const [dataTable, setDataTable] = useState(null)
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const handleBackClick = () => {
    dispatchMenu({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
};

  const reloadForm = () => {
    setCardNumber("");
    setCardType("");
    setAmount("");
  };

  const handleChangeAmmount = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "");

    setAmount(value);
  };

  const detectCardType = (number) => {
    const regexPatterns = {
      visa: /^4/,
      mastercard: /^5[1-5]/,
      amex: /^3[47]/,
      discover: /^6(?:011|5)/,
    };

    const found = Object.keys(regexPatterns).find((key) => {
      return regexPatterns[key].test(number);
    });

    if (number === "") {
      setCardType("");
    } else {
      setCardType(found || "error"); // Si no coincide con ninguna, muestra error
    }
  };

  const handleChangeCardNumber = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, "").substring(0, 16); // Solo permite números y limita a 16 dígitos
    const cardNumberFormatted = value.replace(/(.{4})/g, "$1      ").trim();
    setCardNumber(cardNumberFormatted);
    detectCardType(value); // Pasamos el valor sin espacios para la detección
  };

  const handleExpiryChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, ""); // Elimina todo lo que no sean números

    if (value.length > 2) {
      value = value.substring(0, 2) + "/" + value.substring(2, 4);
    }

    setExpiry(value);
  };

  const disabledButton = () => {
    const isDisabledButton =
      !firstName ||
      !lastName ||
      !selectedCardType ||
      cardType === "error" ||
      cardNumber.length !== 34 ||
      expiry.length !== 5 || 
      loadingButton

    return isDisabledButton;
  };

  const onDismiss = () => {
    setVariant("");
    setMessage("");
    setVisible(false);
  };

  const closeModal = () => {
    if (!loadingButton && !loadingModal) {
      setEditingCard(false)
      setModalNoCard(false);
      setOpenMenu(false);
      setModalAmount(false)
      setErrorAddCard("")
      setMessage("")
      setAmount("")
    }
  };

  const openMenuAddCard = () => {
    closeModal();
    setOpenMenu(true);
  };

  const returnTextColorColumnStatus = () => {
    return "rgb(25, 159, 40)"
  }

  const activeAddOrEditCard = () => {
    if (creditCardInfo) {
      setEditingCard(true)
      setSelectedCardType(cardData.type)
      setFirstName(cardData.first_name)
      setLastName(cardData.last_name)
      setCardNumber(`************${cardData.last_4}`)
      setExpiry(`${cardData.exp_month}/${cardData.exp_year.slice(2)}`)
      setOpenMenu(true)
    } else {
      openMenuAddCard()
    }
  }

  const addOrEditCreditCard = (e) => {
    e.preventDefault()
    if (!disabledButton()) {
      setLoadingButton(true);
      setErrorAddCard("")

      const data = {
        credit_card_info: {
          exp_month: expiry.slice(0, 2),
          exp_year: `20${expiry.slice(3)}`,
          first_name: firstName,
          last_name: lastName,
          number: cardNumber.replace(/\D/g, ""),
          type: cardType,
        }
      }

      petitionPatch("NewCreditcard", { data, billing_api_id })
        .then(({ data: result }) => {
          setLoadingButton(false)
          closeModal()
          petition()
        })
        .catch((error) => {
          let messageError = ""
          if (
            error?.response?.status === 400 &&
            error?.response?.data?.error?.error_message
          ) {
            messageError = error?.response?.data?.error?.error_message;
          } else {
            messageError = "An error occurred while adding credit card, please try again"
          }

          setErrorAddCard(messageError)
          setLoadingButton(true);
          console.log(error)
        })
    }
  };

  const makePayment = () => {
    if (!creditCardInfo) {
      setModalNoCard(true);
    } else {
      setModalAmount(true)
    }
  };

  const pay = () => {
    if (!loadingModal && amount) {
      setLoadingModal(true);

      const data = {
        account_pk: id,
        amount: amount,
      };

      petitionPost("prepaid", { data, parameter: "?payment_type=card_on_file" })
        .then(({ data: result }) => {
          const newUserDetails = {
            ...myExtension,
            voice_account: {
              ...myExtension.voice_account,
              prepaid_balance: result.result.prepaid_balance,
            }
          }
          dispatch(updateMyExtention(newUserDetails))
          setCurrentBalance(result.result.prepaid_balance)
          closeModal()
          reloadForm();
          setVariant("success");
          setMessage("Successful payment");
          setVisible(true);
          setLoadingModal(false);
          setTimeout(() => {
            onDismiss();
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
          let messageError = "error when making payment";

          if (
            error?.response?.status === 400 &&
            error?.response?.data?.error?.error_message
          ) {
            messageError = error?.response?.data?.error?.error_message;
          }

          setMessage(messageError);
          setLoadingModal(false);
        });
    }
  };

  const petition = async () => {
    setLoadingInfo(true);

    await petitionGet("billingPayments", { billing_api_id })
      .then(({ data: result }) => {
        const paids = result.result.filter((element) => {
          if (element.payment_status === "Paid") {
            element.credit_card_number = `************${element?.last_4_of_credit_card || "****"}`
            return element
          }
        })

        setDataTable({
          columns: [
            { name: "Date", key: "created" },
            { name: "Status", key: "payment_status" },
            { name: "Amount", key: "amount" },
            { name: "Credit Card", key: "credit_card_number" },
          ],
          content: [...paids]
        })
        forceUpdate()
      })
      .catch((error) => console.log(error))

    await petitionGet("billing", { billing_api_id })
      .then(({ data: result }) => {
        const { last_4, exp_month, exp_year } = result.result.credit_card_info;
        const newCardNumber = last_4 ? `************${last_4}` : ""

        if (exp_month && exp_year) {
          const expDate = `${exp_month}/${exp_year.slice(2)}`
          setExpiry(expDate)
        }

        if (last_4) {
          setCardData(result.result.credit_card_info)
        }

        setCreditCardInfo(newCardNumber);
        setLoadingInfo(false);
        setFirstLoad(false)
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (myExtension && Object.keys(myExtension).length > 0 && firstLoad) {
      if (myExtension.priv_level !== "admin") {
        handleBackClick()
      } else {
        setCurrentBalance(myExtension?.voice_account?.prepaid_balance || 0)
        petition();
      }
    }
  }, [myExtension]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "300px",
          zIndex: "1000",
          right: "0px",
          top: "0px",
        }}
      >
        <Alert
          variant={variant}
          className="alert-dismissible fade show"
          show={visible}
          onClose={onDismiss}
          dismissible={true}
        >
          {message}
        </Alert>
      </div>

     
      {modalNoCard && (
        <ModalNoCard
          modal={modalNoCard}
          closeModal={closeModal}
        />
      )}

      {modalAmount && (
        <ModalAmount 
          modal={modalAmount}
          closeModal={closeModal}
          modalFunction={pay}
          loading={loadingModal}
          value={amount}
          onChange={handleChangeAmmount}
          error={message}
        />
      )}

      <MenuRight
        show={openMenu}
        closeMenu={closeModal}
        title="Add Credit Card"
      >
        <form onSubmit={addOrEditCreditCard}>
          <Row className="itp-container-label-select">
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label
                htmlFor="selectedCardType"
                className="itp-label-input__label mt-3"
              >
                Card Type
              </label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              <select
                value={selectedCardType}
                onChange={(e) => setSelectedCardType(e.target.value)}
                className="form-select-itp"
                id="selectedCardType"
                name="selectedCardType"
              >
                <option value="" disabled>
                  Select Option
                </option>
                <option value="visa">Visa</option>
                <option value="mastercard">Master Card</option>
                <option value="amex">American Express</option>
                <option value="discover">Discover</option>
              </select>
            </Col>
          </Row>
          <Row className="itp-container-label-input">
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label htmlFor="firstName" className="itp-label-input__label">
                First Name
              </label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              <input
                className="form-control-itp"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="firstName"
                id="firstName"
              />
            </Col>
          </Row>
          <Row className="itp-container-label-input">
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label htmlFor="lastName" className="itp-label-input__label">
                Last Name
              </label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              <input
                className="form-control-itp"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name="lastName"
                id="lastName"
              />
            </Col>
          </Row>
          <Row className={`itp-container-label-input ${cardType}`}>
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label htmlFor="cardNumber" className="itp-label-input__label">
                Card number
              </label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              <input
                className="form-control-itp itp-label-input__input"
                placeholder="1111      2222      3333      4444"
                value={cardNumber}
                onChange={handleChangeCardNumber}
                name="cardNumber"
                id="cardNumber"
              />
            </Col>
          </Row>
          {cardType === "error" && (
            <p className="text-danger">
              Please enter some of the types of credit cards accepted
            </p>
          )}
          <Row className="itp-container-label-input">
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="itp-label-input-col"
            >
              <label htmlFor="expiry" className="itp-label-input__label">
                Expiry
              </label>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              className="itp-label-input-col"
            >
              <input
                className="form-control-itp"
                placeholder="MM/YY"
                value={expiry}
                onChange={handleExpiryChange}
                maxLength="5"
                name="expiry"
                id="expiry"
              />
            </Col>
          </Row>
          {errorAddCard && <p className="text-danger">{errorAddCard}</p>}
          <button
            type="submit"
            className="btn-primary loading"
          >
            {loadingButton && <LoadingButton />}
            {editingCard ? "Edit" : "Add"} Credit Card
          </button>
        </form>
      </MenuRight>

      {loadingInfo ? (
        <Loading />
      ) : (
        <>
          <Row className="ms-3 mt-4 me-3 justify-content-between">
            <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
              <Card>
                <CardBody>
                  <Row className="w-100 d-flex align-items-center justify-content-center itp-row-balance">
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                      <div className="itp-billing-balance-container">
                        <p className="itp-billing-balance-text">
                          Current Balance
                        </p>
                        <p className="itp-billing-balance">${currentBalance}</p>
                      </div>
                      <button
                        className="btn-primary w-100 mt-3 mb-5 loading"
                        onClick={makePayment}
                      >
                        {(loadingButton && !openMenu) && <LoadingButton />}
                        Make Payment
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
              <Card>
                <CardBody>
                  <Row className="w-100 d-flex align-items-center justify-content-center itp-row-balance">
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
                      <div className="itp-billing-card-info">
                        <Row>
                          <Col>
                            <div className="d-flex flex-column gap-2">
                              <label className="itp-billing-card-number-text">
                                Card Number
                              </label>
                              <input 
                                defaultValue={creditCardInfo} 
                                className="itp-billing-card-input" 
                                disabled
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={7} sm={7} md={7} lg={7} xl={7} xxl={7}></Col>
                          <Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                            <label className="itp-billing-card-number-text">
                              Expiration
                            </label>
                            <input 
                              defaultValue={expiry} 
                              className="itp-billing-card-input" 
                              disabled
                            />
                          </Col>
                        </Row>
                      </div>
                      <button onClick={activeAddOrEditCard} className="btn-primary w-100 mt-3">
                        {!creditCardInfo ? "Add" : "Edit"} Card
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {dataTable && (
            <CustomTableMain 
              data={dataTable}
              borderColumnName="Status"
              getTextColor={returnTextColorColumnStatus}
              module="payments"
              columnConfig={{
                "Date": {
                  type: "date",
                  spliter: "T"
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Prepaid;
