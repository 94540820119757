import petitionGet from "../../../../../../services/petitionGet";

const fetchContactListDetails = async (contactDetailsMenu, setValueContactList) => {
  const parameter = `/${contactDetailsMenu.contact_list_id}`
  const { data: result } = await petitionGet("contactList", { parameter });

  result.result.label = `${result.result.list_name} (${
    result?.result?.contact_count || ""
  })`;

  result.result.value = result.result.pk
  setValueContactList(result.result)
};

export default fetchContactListDetails;
