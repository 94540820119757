import { AlignedRow, SearchBoxFilter } from "../../../../../../../components";
import Switchs from "./Switchs";

const Filters = ({ handleChangeFilterInput, searchFilter, switchUsersCall, setSwitchUsersCall, switchUsersOffline, setSwitchUsersOffline }) => (
  <AlignedRow noCentered={true} m1={true}>
    <div className="d-flex justify-content-between align-items-center">
      <SearchBoxFilter 
        placeholder="Search Number, name or assigned user"
        handleChange={handleChangeFilterInput} 
        value={searchFilter}
      />
      <Switchs 
        switchUsersCall={switchUsersCall} 
        setSwitchUsersCall={setSwitchUsersCall} 
        switchUsersOffline={switchUsersOffline} 
        setSwitchUsersOffline={setSwitchUsersOffline}
      />
    </div>
  </AlignedRow>
)

export default Filters;