import { AudioMessage } from "../../../../index";

const RecordedCall = ({ element, chatWindowData }) => {
  const show = element.audio_message && (element.item_type !== "call" || (element.item_type === "call" && element.cdr && element.cdr.call_recording_filename)) ? true : false
  
  if (show) {
    return (
      <AudioMessage
        audioUrl={element.audio_message}
        duration={element.voicemail_duration}
        firstname={chatWindowData.isContact ? chatWindowData.firstname : false}
        lastname={chatWindowData.isContact ? chatWindowData.lastname : false}
        avatar={chatWindowData.isContact}
        voicemailIcon={element.cdr ? false : true}
        recordingIcon={element.cdr ? true : false}
        downloadWhenClicked={true}
        leftInAudioIcon="0"
        leftInMessageIcon="1.5rem"
        marginLeftContainerAudio="1.5rem"
        leftAvatar="0"
        styles={{ marginLeft: "12px" }}
        w100
        contacts={chatWindowData.isContact}
      />
    );
  }
};

export default RecordedCall;
