import petitionGet from "../../../../../services/petitionGet";

const fetchUsers = async (setSelectedUsers, setOptionsUserReport, setOptionsQueueMembersReport, setSelectedQueueMembersReport) => {
  const { data: result } = await petitionGet("itpvoiceUsers")

  result.result.sort((a, b) => a.firstname.localeCompare(b.firstname)).forEach((element) => {
    element.label = `${element.firstname} ${element?.lastname || ""}`;
    element.value = element.pk;
  });

  setSelectedUsers(result.result);
  setOptionsUserReport(result.result);
  setOptionsQueueMembersReport(result.result)
  setSelectedQueueMembersReport(result.result)
}

export default fetchUsers;