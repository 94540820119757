import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMenuDispatch } from "../../../../contexts/MenuContext.js";
import { MenuRight, CustomTableMain } from "../../../../components";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import petitionGet from "../../../../services/petitionGet.js";
import view from "../../../../assets/icons/view.svg";
import useScreenSize from "../../../../hooks/useScreenSize.js";

const Troubleshooting = () => {
  const { width } = useScreenSize()

  const navigate = useNavigate();
  const dispatch = useMenuDispatch();

  const itemsPerPage = useRef("10");

  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [devicesDetails, setDevicesDetails] = useState(null);
  const [dataDevices, setDataDevices] = useState(null);
  const [dataDetails, setDataDetails] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [dataTable, setDataTable] = useState({
    columns: [
      { name: "Name", key: "name" },
      { name: "Presence Id", key: "presence_id" },
    ],
    actions: { title: "Devices", content: [] }
  });

  const handleChangeItemsPerPage = (data) => {
    itemsPerPage.current = data
    forceUpdate()
  }

  const handleBackClick = () => {
    dispatch({ type: "SET_DEFAULT_MENU" });
    navigate("/Dashboard");
  };

  const closeMenu = () => {
    setOpenMenu(false);
    setDevicesDetails(null);
    setDataDevices(null);
    setDataDetails(null);
  };

  const handleClickDetails = (element) => {
    let allTitles = [];
    Object.keys(element).map((element) =>
      allTitles.push({ title: element, key: element })
    );

    setDevicesDetails(true);
    setDataDetails({
      title: allTitles,
      content: [{ ...element }],
    });
  };

  const backToDevicesTable = () => {
    setDevicesDetails(false);
  };

  const activeOpenMenu = (e) => {
    if (e) {
      let registeredDevicesTemp = [];
      e.registered_devices.map((element) => {
        let tempElement = {
          ...element,
          ...element.location,
          sourceIp: element.location.socket.split(":")[1],
          sourcePort: element.location.socket.split(":")[2],
        };
        delete tempElement.location;

        registeredDevicesTemp.push(tempElement);
      });
      setDataDevices({
        columns: [{ name: "Device Type", key: "device_type" }],
        content: registeredDevicesTemp,
        actions: {
          title: "Details",
          content: [
            {
              icon: "Description",
              handleClick: handleClickDetails,
            },
          ],
        },
      });
      setOpenMenu(true);
    }
  };

  const petition = () => {
    setLoading(true);
    petitionGet("sipRegistration")
      .then(({ data: result }) => {
        result.result.forEach((element) => {
          element.name = `${element.firstname} ${element?.lastname || ""}`;
        });

        setDataTable({
          columns: [
            { name: "Name", key: "name" },
            { name: "Presence Id", key: "presence_id" },
          ],
          content: [...result.result],
          actions: {
            title: "Devices",
            content: [
              {
                name: "Details",
                handleClick: activeOpenMenu,
                icon: (
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={view}
                    alt=""
                  />
                ),
              },
            ],
          },
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {    
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition();
        const granularPermission = data?.admin_troubleshooting
        if (Object.keys(data).length > 0 && data?.priv_level !== "admin" && !granularPermission) {
          handleBackClick();
        } else {
          petition();
        }
      } catch (error) {
        console.log(error);
      }
    };

    myExtensionData();
  }, []);

  return (
    <div style={{ marginTop: "3rem" }}>
    <CustomTableMain 
      data={dataTable}
      centered={width >= 1697 ? true : false}
      loading={loading}
      showSelectSingleCheckbox={true}
      paginationData={parseInt(itemsPerPage.current)}
      onChangeItemsPerPage={handleChangeItemsPerPage}
      m3
    />

    <MenuRight
      show={openMenu}
      closeMenu={!devicesDetails ? closeMenu : backToDevicesTable}
      title="Details"
      to={devicesDetails ? "to Devices Table" : ""}
    >
      {devicesDetails ? (
        <>
          <div className="p-3">
            {dataDetails &&
              dataDetails.title.map((element, i) => {
                return (
                  <label
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    key={i}
                    className="itp-label-input__label"
                  >
                    {element.title} :{" "}
                    {dataDetails.content[0][element.key]}{" "}
                  </label>
                );
              })}
          </div>
        </>
      ) : (
        <>
          {" "}
          {dataDevices && (
            <>
              <CustomTableMain 
                data={dataDevices}
                m0={true}
              />
            </>
          )}
        </>
      )}
    </MenuRight>
  </div>
  );
};

export default Troubleshooting;
