export const NO_CALENDLY_ALERT = "Your calendar is currently not linked to a calendly account."
export const NO_CALENDLY_TITLE = "Connect Your Calendly Account"
export const NO_CALENDLY_SUBTITLE = "Link your calendly account to have VOICE360 access your calendar for AI appointments and more."
export const NO_CALENDLY_BUTTON = "Link Calendly"

export const CALENDLY_REJECTED_ALERT = "Your calendly account has an issue"
export const CALENDLY_REJECTED_TITLE = "Reconnect Your Calendly Account"
export const CALENDLY_REJECTED_SUBTITLE = "There was an issue connecting to your Calendly account. Link your Calendly account again to reestablish the connection."
export const CALENDLY_REJECTED_BUTTON = "Link Calendly"

export const CALENDLY_SUCCESS_ALERT = "Congrats, your Calendly account is now linked"
export const CALENDLY_SUCCESS_TITLE = "Succesfully Linked"
export const CALENDLY_SUCCESS_SUBTITLE = "Your calendly account has been succesfully linked."
export const CALENDLY_SUCCESS_BUTTON = ""
