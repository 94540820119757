const findChatByNumber = (chatList, dataNumber) => {
  let findOneChat = null;

  chatList.forEach((element) => {
    let findNumber = null;

    if (typeof dataNumber === "string") {
      findNumber = element.participants.find((part) => part.number === dataNumber);
    } else if (typeof dataNumber === "object") {
      findNumber = element.participants.find((part) => part.number === dataNumber?.phone);
    }

    if (findNumber) {
      findOneChat = { ...element, temporarilyFirst: true };
    }
  });

  return findOneChat;
};

export default findChatByNumber;
