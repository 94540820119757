import { formatedMessageList } from "../../utils/index";
import petitionGet from "../../../../../../services/petitionGet";

const messagesService = async (chatWindowData, number, threadId, query) => {
  const thread = threadId || chatWindowData.current?.notIsSelf?.message_thread_id
  const parameter = query || "?offset=0&limit=20"

  const response = await petitionGet("messageThread", { number, thread, parameter })
  const { data: result } = response
  const messages = formatedMessageList(result)

  return messages.reverse()
}

export default messagesService;