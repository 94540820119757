import petitionGet from "../../../../../../services/petitionGet";
import formatPhoneNumber from "../../../../../../utils/formatPhoneNumber";

const fetchNumbers = async () => {
  const { data: result } = await petitionGet("viewNumbers");
  result.result.forEach((element) => {
    element.description = element?.description || "";
    element.statusReal = element.itpvoice_metadata ? element.itpvoice_metadata.state.replace("_", " ") : "null";
    element.formatedNumber = formatPhoneNumber(element.number)
  });

  return result.result
};

export default fetchNumbers;
